(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .controller('ServiceHistoryAnnualHoursRecordController', ServiceHistoryAnnualHoursRecordController);

    ServiceHistoryAnnualHoursRecordController.$inject = ['$stateParams', 'authentication'];

    function ServiceHistoryAnnualHoursRecordController($stateParams, authentication) {
        var vm = this;

        vm.annualHoursRecords = null;
        vm.isLoading = true;

        activate();

        function activate() {
            getAnnualHoursRecord();
        }

        function getAnnualHoursRecord() {
            vm.isLoading = true;
            return authentication.getVolunteerServiceRecord({
                masterUserId: $stateParams.id || undefined
            }).then(function (result) {
                vm.annualHoursRecords = result.annualHoursRecord;
            }).finally(function () {
                vm.isLoading = false;
            });
        }
    }
})();