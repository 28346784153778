(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .controller('VolunteerApplicationDetailsController', VolunteerApplicationDetailsController);

    VolunteerApplicationDetailsController.$inject = ['$q', '$state', '$stateParams', '$modal', 'authentication'];

    function VolunteerApplicationDetailsController($q, $state, $stateParams, $modal, authentication) {
        var vm = this;

        vm.onDelete = onDelete;

        activate();

        function activate() {
            getApplicationDetails();
        }

        function getApplicationDetails() {
            var opportunityPromise;
            var applicationPromise;
            var params;
            var volunteerApplicationId;
            if ($stateParams.opportunityId) {
                volunteerApplicationId = parseInt($stateParams.applicationId);
                params = {
                    volunteerOpportunityId: parseInt($stateParams.opportunityId)
                };
                opportunityPromise = authentication.getOpportunityDetails(params);
                applicationPromise = authentication.getApplicationDetails({volunteerApplicationId: volunteerApplicationId});
            } else if ($stateParams.communityVolunteerOrganizationApplicationId) {
                params = {
                    startingRowIndex: 0,
                    numberOfRows: 1,
                    communityVolunteerOrganizationApplicationId: parseInt($stateParams.communityVolunteerOrganizationApplicationId)
                };
                opportunityPromise = authentication.getOpportunitiesAppliedFor(params);
                applicationPromise = authentication.getCommunityApplicationDetails({communityVolunteerOrganizationApplicationId: params.communityVolunteerOrganizationApplicationId});
            }

            return $q.all({
                positionDetails: opportunityPromise,
                userProfile: authentication.getUserProfile(),
                applicationDetails: applicationPromise
            })
                .then(function (result) {
                    if ($stateParams.opportunityId) {
                        vm.position = result.positionDetails;
                        vm.application = {
                            statusReason: result.applicationDetails.application.statusReason
                        };
                        vm.application.status = result.applicationDetails.application.status;
                        vm.isVolunteerSubmitted = vm.position.volunteerOrganization === 'Volunteer Submitted';
                    } else if ($stateParams.communityVolunteerOrganizationApplicationId) {
                        vm.position = result.positionDetails.opportunities[0];
                        vm.application = {
                            status: result.positionDetails.opportunities[0].status,
                            statusReason: result.positionDetails.opportunities[0].statusReason
                        };
                        vm.isVolunteerSubmitted = true;
                    }
                    vm.position.isBackgroundCheckRequired = vm.position.isBackgroundCheckRequired ? 'Yes' : 'No';
                    vm.position.supervisionConditionFlag = vm.position.supervisionConditionFlag ? 'Yes' : 'No';
                    vm.profile = result.userProfile;
                    vm.showRank = vm.profile.aosUser.dispositions.some(function (disposition) {
                        return disposition === 'Service Member';
                    });
                    vm.showCivilianRank = vm.profile.aosUser.dispositions.some(function (disposition) {
                        return disposition === 'DoD Civilian';
                    });
                });
        }

        function onDelete() {
            $modal.open({
                templateUrl: 'app/components/volunteer/applications/deleteApplicationConfirmation.view.html',
                controller: 'DeleteApplicationConfirmationController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    title: function () {
                        return vm.position.title;
                    }
                },
                size: 'tiny'
            }).result.then(function () {
                var params = {};

                if ($stateParams.applicationId) {
                    params.volunteerApplicationId = parseInt($stateParams.applicationId);
                } else if ($stateParams.communityVolunteerOrganizationApplicationId) {
                    params.communityVolunteerOrganizationApplicationId = parseInt($stateParams.communityVolunteerOrganizationApplicationId);
                }

                return authentication.deleteApplication(params);
            }).then(function () {
                $state.go('vmis.volunteer.applications', null, {reload: true});
            });
        }
    }
})();