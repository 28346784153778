/**
 * Created by nbhadsavle3 on 11/3/2017.
 */

(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('HoursAppsDetailsController', HoursAppsDetailsController);

    HoursAppsDetailsController.$inject = ['$modalInstance', 'title', 'data'];

    function HoursAppsDetailsController($modalInstance, title, data) {
        var vm = this;
        
        vm.title = title;
        vm.applications = [];
        vm.hours = [];

        if(title === "Applications Details") {
            vm.applications = data;
        }

        if(title === "Hours Details") {
            vm.hours = data;
        }


        vm.onCancel = onCancel;
        vm.onConfirm = onConfirm;

        activate();

        function activate() {
        }
        
        function onConfirm() {
            $modalInstance.close();
        }

        function onCancel() {
            $modalInstance.dismiss('cancel');
        }
    }
})();