/*
 http://foundation-datepicker.peterbeno.com/example.html
 */
(function () {
    'use strict';

    angular
        .module('app')
        .directive('vmisDatepicker', VmisDatepicker);

    VmisDatepicker.$inject = ['$timeout'];

    function VmisDatepicker($timeout) {
        return {
            restrict: 'A',
            require: 'ngModel',
            scope: {
                format: '@?',
                startDate: '<?',
                endDate: '<?',
                leftArrow: '@?',
                rightArrow: '@?'
            },
            link: link
        };

        function link(scope, element, attributes, controller) {
            var target = attributes.target ? '#' + attributes.target : element;
            var format = scope.format || moment.ISO_8601;
            var datepicker;


            $timeout(function () {
                datepicker = $(target).fdatepicker({
                    initialDate: new Date(),
                    leftArrow: scope.leftArrow || defaultArrow('left'),
                    rightArrow: scope.rightArrow || defaultArrow('right'),
                    onRender: onRender
                }).on('changeDate', onChangeDate).on('show', onShow).data('datepicker');
            }, 100);

            controller.$validators.dateValidator = function (modelValue, viewValue) {
                if (modelValue) {
                    var startDate = dateExist('startDate');
                    if (startDate && moment(modelValue).isBefore(startDate, 'day')) return false;
                    var endDate = dateExist('endDate');
                    if (endDate && moment(modelValue).isAfter(endDate, 'day')) return false;
                }
                return viewValue ? moment(viewValue, format, true).isValid() : true;
            };

            controller.$formatters.unshift(function (modelValue) {
                if (!format || !modelValue) return "";
                var retVal = moment(new Date(modelValue));
                return scope.format ? retVal.format(scope.format) : retVal;
            });

            controller.$parsers.unshift(function (viewValue) {
                var date = moment(viewValue, format);
                return (date && date.isValid() && date.year() > 1900 ) ? date.toDate() : "";
            });

            scope.$on('$destroy', function () {
                datepicker.remove();
            });

            function defaultArrow(direction) {
                return '<icon>' +
                    '<svg class="icon icon-fa-chevron-' + direction + '">' +
                    '<use xlink:href="#icon-fa-chevron-' + direction + '"></use>' +
                    '</svg>' +
                    '</icon>';
            }

            function onRender(utcDate) {
                var localDate = moment(utcDate).add(utcDate.getTimezoneOffset(), 'minutes');
                var startDate = dateExist('startDate');
                var endDate = dateExist('endDate');
                var renderStart = startDate ? moment(localDate).isSameOrAfter(startDate, 'day') : true;
                var renderEnd = endDate ? moment(localDate).isSameOrBefore(endDate, 'day') : true;
                return renderStart && renderEnd ? '' : 'disabled';
            }

            function onChangeDate(event) {
                var date = moment(event.date).add(event.date.getTimezoneOffset(), 'minutes').startOf('day').toDate();
                scope.$apply(function () {
                    controller.$setViewValue(date, 'programmatic');
                    controller.$modelValue = date;
                });
            }

            function onShow() {
                if (!controller.$modelValue) return;
                var date = moment(controller.$modelValue).startOf('day').toDate();
                datepicker.update(date);
            }

            function dateExist(date) {
                if (angular.isDefined(attributes[date])) {
                    if (scope[date] === undefined) return attributes[date] ? false : new Date();
                    if (scope[date] === null) return false;
                    return scope[date];
                }
                return false;
            }
        }
    }
})();
