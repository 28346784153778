(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('OrganizationsController', OrganizationsController);

    OrganizationsController.$inject = ['$q', '$modal', '$scope', 'authentication', 'loadStatus', '_'];

    function OrganizationsController($q, $modal, $scope, authentication, loadStatus, _) {
        var vm = this;

        vm.isLoading = true;
        vm.alerts = [];
        vm.organizations = [];
        vm.commArchived = false;
        vm.sortColumn = "name";
        vm.sortReverse = false;
        vm.dropDowns = {};
        vm.volunteerSubmitted = "Volunteer Submitted";

        vm.statuses = [
            {
                name: "All",
                value: -1
            },
            {
                name: "Active",
                value: 1
            },
            {
                name: "Inactive",
                value: 0
            }
        ];
        vm.status = vm.statuses[1].value; // Default to "Active"

        vm.loadStatus = loadStatus.init();

        vm.onClickAddNewOrganization = onClickAddNewOrganization;
        vm.onClickEditOrganization = onClickEditOrganization;
        vm.onChangeStatus = onChangeStatus;
        vm.onChangeCommunity = onChangeCommunity;
        vm.onChangeType = onChangeType;
        vm.onCloseAlert = onCloseAlert;
        vm.onLoadMore = onLoadMore;
        vm.onLoadAll = onLoadAll;

        activate();

        function activate() {
            loadStatus.setup(vm.loadStatus, "organizations")
                .then(function () {
                    getDropdownData();
                    return $q.all({
                        communities: getCommunities(),
                        organizations: getOrganizations(vm.loadStatus.currentIndex,
                            vm.loadStatus.defaultNumRows, null, vm.status)
                    });
                })
                .then(function (responses) {
                    vm.communities = responses.communities;
                    if (vm.communities.length !== 1) {
                        vm.communities.unshift({
                            id: -1,
                            name: "All"
                        });
                    }
                    vm.selectedCommunityId = vm.communities[0].id;
                }).catch(displayError).finally(function () {
                    vm.isLoading = false;
                });
        }

        function getCommunities() {
            return authentication.getUserProfile().then(function (response) {
                var responsibilities = response.aosUser.vmisUser.adminProfile.responsibilities;
                var communities = [];

                if (responsibilities.avcc) {
                    communities = _.concat(communities, _.map(responsibilities.avcc, function (community) {
                        return {
                            id: community.communityAffiliationId,
                            name: community.communityAffiliationName
                        };
                    }));
                }
                return _.sortBy(communities, ['name']);
            });
        }

        function getDropdownData() {
            var elements = [
                'volunteerorganizationtype'
            ];
            return authentication.getUiData(elements)
                .then(function (response) {
                    vm.dropDowns.types = _.concat({
                        name: 'All'
                    }, _.map(response.volunteerorganizationtype, function (type) {
                        return {
                            name: type,
                            value: type
                        };
                    }));
                    vm.organizationType = vm.dropDowns.types[0];
                });
        }

        function getOrganizations(index, num, communityId, status) {
            vm.isLoading = true;
            return authentication.getOrganizationsAdmin({
                startingRowIndex: index,
                numberOfRows: num,
                volunteerOrganizationType: vm.organizationType ? vm.organizationType.value : undefined,
                communityAffiliationId: (((communityId !== null) && (communityId >= 0)) ? communityId : undefined),
                isActive: (_.isFinite(status) && (status >= 0)) ? status : undefined
            }).then(function (response) {
                return getOrganizationsSuccess(response, index, num);
            }).catch(function (err) {
                getOrganizationsError(err);
            });

            function getOrganizationsSuccess(response, index, num) {
                if (index === 0) {
                    vm.loadStatus.currentIndex = 0;
                    vm.organizations = [];
                }

                if (response.organizations.organizations) {
                    response = response.organizations;
                }

                // Either set current index to max if all users loaded or increment by rows loaded
                vm.loadStatus.currentIndex = (vm.loadStatus.currentIndex + num > response.count) ? response.count : (vm.loadStatus.currentIndex + num);
                // Track total number of users not loaded for "Load All" button
                vm.loadStatus.numResultsNotLoaded = response.count - vm.loadStatus.currentIndex;
                // Set text for "Load More" button
                vm.loadStatus.nextResultLoadNum = (response.count === vm.loadStatus.currentIndex) ? 0 :
                    (vm.loadStatus.numResultsNotLoaded > num) ? num : vm.loadStatus.numResultsNotLoaded;

                var organization = _.map(response.organizations, function (organization) {
                    return {
                        id: organization.volunteerOrganizationId,
                        group: {
                            id: organization.volunteerOrganizationGroupId,
                            name: organization.volunteerOrganizationGroup
                        },
                        type: organization.volunteerOrganizationType,
                        name: organization.volunteerOrganizationFullName,
                        description: organization.volunteerOrganizationDescription || '',
                        status: organization.isActive,
                        community: {
                            id: organization.communityAffiliationId,
                            name: organization.commArchived ? organization.communityAffiliation + " (inactive)" : organization.communityAffiliation
                        },
                        commArchived: organization.commArchived ? true : false,
                        standardOrganization: {
                            id: organization.volunteerOrganizationTemplateId,
                            name: organization.volunteerOrganizationTemplate
                        }
                    };
                });
                vm.organizations = vm.organizations.concat(organization);
                vm.isLoading = false;
                return response;
            }

            function getOrganizationsError(error) {
                if (angular.isDefined(error)) {
                    var errorStr = error.error ? error.error : error;
                    $scope.$emit('organizations:alert', errorStr);
                }
                else
                    $scope.$emit('organizations:alert', "Failed to load list! Please try again.");

                vm.isLoading = false;
            }
        }

        function onClickAddNewOrganization() {
            openModal('Add New Organization', { status: 1 });
        }

        function onClickEditOrganization(organization) {
            openModal('Edit Organization', organization);
        }

        function openModal(title, organization) {
            var modalInstance = $modal.open({
                templateUrl: 'app/components/administrator/organizations/editOrganizationForm.template.html',
                controller: 'EditOrganizationsController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    params: function () {
                        return {
                            organization: organization,
                            title: title,
                            communities: vm.communities,
                            commArchived: vm.commArchived
                        };
                    }
                }
            });

            // Process result from edit modal
            modalInstance.result.then(function () {
                vm.loadStatus.currentIndex = 0;
                vm.isLoading = true;
                return getOrganizations(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows, vm.selectedCommunityId, vm.status);
            }).catch(displayError).finally(function (err) {
                getOrganizationsError(err);
            });
        }

        function onChangeStatus(status) {
            changeFilters(vm.selectedCommunityId, status);
        }

        function onChangeCommunity(communityId) {
            changeFilters(communityId, vm.status);
        }

        function onChangeType() {
            changeFilters(vm.selectedCommunityId, vm.status);
        }

        function changeFilters(communityId, status) {
            vm.loadStatus.currentIndex = 0;
            vm.isLoading = true;
            return getOrganizations(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows, communityId, status)
                .catch(displayError).finally(function () {
                    vm.isLoading = false;
                });
        }

        function onCloseAlert(index) {
            vm.alerts.splice(index, 1);
        }

        function displayAlert(type, message) {
            vm.alerts.push({
                type: type,
                message: message
            });
        }

        function displayError(error) {
            vm.isLoading = false;
            if (error === 'cancel' || error === 'backdrop click') {
                return;
            }
            if (error) {
                displayAlert('alert', error);
            }
        }

        function onLoadMore() {
            getOrganizations(vm.loadStatus.currentIndex, vm.loadStatus.nextResultLoadNum, vm.selectedCommunityId, vm.status);
        }

        function onLoadAll() {
            getOrganizations(vm.loadStatus.currentIndex, vm.loadStatus.numResultsNotLoaded, vm.selectedCommunityId, vm.status);
        }
    }
})();
