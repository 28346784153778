/**
 * Created by scuzzort3 on 8/10/17.
 */
(function () {
    'use strict';

    angular
        .module('app.learnAboutThisPage')
        .controller('LearnAboutModalController', LearnAboutModalController);

    LearnAboutModalController.$inject = ['$modalInstance'];

    function LearnAboutModalController($modalInstance) {
        var vmWidgetModal = this;
        vmWidgetModal.learnTitle = $modalInstance.learnTitle;
        vmWidgetModal.textBodyParagraphs = $modalInstance.textBody.split('\n');
    }
})();