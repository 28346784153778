(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('ConfirmRoleRemoveController', ConfirmRoleRemoveController);

    ConfirmRoleRemoveController.$inject = ['$modalInstance', 'params'];

    function ConfirmRoleRemoveController($modalInstance, params) {
        var vm = this;

        vm.name = params.userInfo.aosUser.firstName + " " + params.userInfo.aosUser.lastName;
        vm.role = params.role;
        vm.title = "Remove " + vm.role + " - " + vm.name;

        vm.onAccept = onAccept;

        function onAccept() {
            $modalInstance.close();
        }
    }
})();
