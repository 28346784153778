(function () {
    'use strict';

    angular
        .module('app')
        .directive('massAction', MassAction);

    MassAction.$inject = [];

    function MassAction() {
        return {
            restrict: 'E',
            templateUrl: 'app/common/directives/massAction/massAction.template.html',
            controller: function () {},
            controllerAs: 'vm',
            scope: {
                massActions: '=',
                selectedMassAction: '=',
                numSelected: '=',
                disabled: '='
            },
            bindToController: true
        };
    }
})();
