(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('PeopleController', PeopleController);

    PeopleController.$inject = ['$stateParams', '$scope', '$location', '$state', 'params'];

    function PeopleController($stateParams, $scope, $location, $state, params) {
        var vm = this;

        vm.isLoading = true;

        vm.alerts = [];

        vm.roleObjectList = params.roleObjectList;
        //vm.responsibilities = params.responsibilities;

        // Set initial drop down selection
        vm.selectedRoleObject = vm.roleObjectList[vm.roleObjectList.length - 1];
        // If state parameter, attempt to use it as initial role
        var initRoleStr = $stateParams.role;
        if (initRoleStr) {
            var initRole = vm.roleObjectList.filter(function (role) {
                return role.shortName.toLowerCase() === initRoleStr.toLowerCase();
            })[0];
            if (initRole)
                vm.selectedRoleObject = initRole;
        }

        $scope.$on('people:role', selectRoleHandler);
        $scope.$on('people:success', successHandler);
        $scope.$on('people:alert', alertHandler);

        vm.onSelectRole = onSelectRole;
        vm.onCloseAlert = onCloseAlert;

        activate();

        function activate() {
            vm.isLoading = false;
        }

        function onSelectRole(newRole) {
            vm.selectedRoleObject = vm.roleObjectList.filter(function (role) {
                return role.name === newRole.name;
            })[0];

            $location.search({}); // Remove filtering parameters when switching roles
            $location.path($location.path().substring(0, $location.path().lastIndexOf('/') + 1) +
                vm.selectedRoleObject.shortName.toLowerCase());
        }

        function selectRoleHandler(event, role) {
            event.stopPropagation();

            onSelectRole(role);
        }

        function successHandler(event, message) {
            event.stopPropagation();

            displayAlert('success', message);
        }

        function alertHandler(event, message) {
            event.stopPropagation();

            displayError(message);
        }

        function onCloseAlert(index) {
            vm.alerts.splice(index, 1);
        }

        function displayAlert(type, message) {
            vm.alerts.push({
                type: type,
                message: message
            });
        }

        function displayError(error) {
            if (error === 'cancel' || error === 'backdrop click')
                return;
            if (error)
                displayAlert('alert', error);
        }
    }
})();