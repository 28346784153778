/**
 * Created by scuzzort3 on 6/8/16.
 */
(function () {
    'use strict';

    angular
        .module('app.auth')
        .factory('usersServices', users);

    users.$inject = [
        '$q',
        'moment',
        'authentication'
    ];

    function users($q,
                   moment,
                   authentication) {

        return {
            isBasicAdminUserDataComplete: isBasicAdminUserDataComplete,
            currentUserFirstName: currentUserFirstName,
            getTotalHoursForUserForMonth: getTotalHoursForUserForMonth
        };

        function currentUserFirstName() {
            return authentication.getUserProfile()
                .then(function (user) {
                    return user.aosUser.firstName;
                })
                .catch(function (error) {
                    return $q.reject(error);
                });
        }

        function getTotalHoursForUserForMonth(date) {
            var startDate = moment(date).startOf('month').format();
            var endDate = moment(date).endOf('month').format();

            var paramsSubmitted = {
                startDate: startDate,
                endDate: endDate
            };


            return authentication.getHoursVol(paramsSubmitted).then(function (response) {
                var submittedSum = 0;
                var certifiedSum = 0;
                var rejectedSum = 0;

                angular.forEach(response.hours, function (timeInHours) {
                    switch (timeInHours.volunteerHoursStatus) {
                        case "Submitted":
                            submittedSum += timeInHours.hours;
                            break;
                        case "Certified":
                            certifiedSum += timeInHours.hours;
                            break;
                        case "Rejected":
                            rejectedSum += timeInHours.hours;
                            break;
                    }
                });

                return {
                    submittedHours: submittedSum,
                    certifiedHours: certifiedSum,
                    rejectedHours: rejectedSum
                };
            }).catch(function (error) {
                return $q.reject(error);
            });
        }

        // Check if user profile has AOS user object, VMIS user object, admin profile, and responsibilities
        function isBasicAdminUserDataComplete(userProfile) {
            return !(
                userProfile.aosUser === undefined ||
                userProfile.aosUser === null ||
                userProfile.aosUser.vmisUser === undefined ||
                userProfile.aosUser.vmisUser === null ||
                userProfile.aosUser.vmisUser.adminProfile === undefined ||
                userProfile.aosUser.vmisUser.adminProfile === null ||
                userProfile.aosUser.vmisUser.adminProfile.responsibilities === undefined ||
                userProfile.aosUser.vmisUser.adminProfile.responsibilities === null
            );
        }
    }
})();