(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('EditProgramManagerController', EditProgramManagerController);

    EditProgramManagerController.$inject = ['$modalInstance', '$modal', 'params', 'authentication'];

    // Note: not currently used for editing, only adding new PMs
    function EditProgramManagerController($modalInstance, $modal, params, authentication) {
        var vm = this;

        vm.isLoading = true;

        vm.programManager = params.programManager || {};
        vm.isNewProgramManager = params.programManager === null;
        vm.canRemove = !vm.isNewProgramManager && params.canRemove;
        if (vm.isNewProgramManager)
            vm.title = "Add New Program Manager";
        else
            vm.title = "Edit Program Manager - " + vm.programManager.aosUser.firstName + " " + vm.programManager.aosUser.lastName;

        vm.errorText = "";

        vm.onClickRemove = onClickRemove;
        vm.onSubmit = onSubmit;

        activate();

        function activate() {
            vm.isLoading = false;
        }

        function onClickRemove() {
            vm.isLoading = true;

            var modalInstance = $modal.open({
                templateUrl: 'app/components/administrator/people/actionDirectives/confirmRoleRemove.view.html',
                controller: 'ConfirmRoleRemoveController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    params: function () {
                        return {
                            userInfo: vm.programManager,
                            role: "AVC Program Manager"
                        };
                    }
                }
            });

            modalInstance.result
                .then(function () {
                    close('remove', vm.programManager.masterUserId);
                    vm.isLoading = false;
                })
                .catch(function (error) {
                    if (angular.isDefined(error))
                        setError(error);
                    vm.isLoading = false;
                });
        }

        function onSubmit() {
            vm.isLoading = true;
            authentication.getMasterUserIdFromEmail({
                email: vm.programManager.masterUserEmail
            })
                .then(function (response) {
                    if (response.success === true) {
                        clearError();

                        authentication.getUserProfile({masterUserId: response.masterUserId})
                            .then(function(userProfile) {
                                var isUserPM = userProfile.roles.vmisUserRoles.filter(function (role) {
                                    return role === "AVC Program Manager";
                                }).length === 1;

                                if (vm.isNewProgramManager && isUserPM) {
                                    setError("The account associated with the email address is already a PM. You must find and edit that user's roles in the table.");
                                    vm.isLoading = false;
                                    return;
                                }

                                if (vm.isNewProgramManager)
                                    close('save', response.masterUserId);
                                else
                                    close('edit', response.masterUserId);
                            })
                            .catch(function (error) {
                                setError(error);
                                vm.isLoading = false;
                            });
                    }
                })
                .catch(function (error) {
                    setError("The entered email address does not match any existing user.");
                    vm.isLoading = false;
                });
        }

        function setError(error) {
            var errorStr = error.error ? error.error : error;
            if (errorStr === 'cancel' || errorStr === 'backdrop click')
                return;
            vm.errorText = errorStr;
        }

        function clearError() {
            vm.errorText = "";
        }

        function close(action, masterUserId) {
            $modalInstance.close({
                action: action,
                masterUserId: masterUserId,
                responsibilities: {}
            });
        }
    }
})();