(function () {
    'use strict';

    angular
        .module('app')
        .directive('convertToNumber', ConvertToNumber);

    ConvertToNumber.$inject = [];

    function ConvertToNumber() {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                ngModel.$parsers.push(function (val) {
                    return parseInt(val, 10);
                });
                ngModel.$formatters.push(function (val) {
                    if (angular.isNumber(val) && isFinite(val)) {
                        return val.toString();
                    }
                });
            }
        };
    }
})();