(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .controller('BackgroundCheckDetailsController', BackgroundCheckDetailsController);

    BackgroundCheckDetailsController.$inject = ['$modalInstance', '$modal', 'moment', 'authentication',
        'backgroundCheck', 'id', 'viewOnly', 'adminInfo'];

    function BackgroundCheckDetailsController($modalInstance, $modal, moment, authentication,
                                              backgroundCheck, id, viewOnly, adminInfo) {
        var vm = this;

        vm.isLoading = true;

        vm.backgroundCheck = backgroundCheck;
        // Existence of master user ID indicates whether user is editing another user
        vm.masterUserId = parseInt(id) || null;
        vm.viewOnly = viewOnly;
        vm.title = vm.backgroundCheck.id ? 'Background Check Details' : 'Add Background Check';
        vm.currentDate = new Date();

        vm.isSavedDisabled = (!vm.viewOnly && vm.backgroundCheck.adminMasterUserId !== null && (vm.backgroundCheck.adminMasterUserId !== vm.backgroundCheck.checkerMasterUserId));

        vm.onClickSave = onClickSave;
        vm.onClickDelete = onClickDelete;
        vm.dateCheckedEndDate = dateCheckedEndDate;
        vm.formatDate = formatDate;

        activate();

        function activate() {
            // If viewing existing background check
            if (vm.backgroundCheck.checker) {
                vm.checkerFullName = vm.backgroundCheck.checker.lastName + ", " + vm.backgroundCheck.checker.firstName || "Checker name not found";
                vm.checkerEmail = vm.backgroundCheck.checker.workEmail || "Checker email not found";
            }
            // If new background check, the checker will be the current user
            else {
                vm.checkerFullName = adminInfo.checkerFullName || "Checker name not found";
                vm.checkerEmail = adminInfo.checkerEmail || "Checker email not found";
            }

            vm.backgroundCheck.date = vm.backgroundCheck.date ? vm.backgroundCheck.date : null;
            vm.backgroundCheck.validUntil = vm.backgroundCheck.validUntil ? vm.backgroundCheck.validUntil : null;

            vm.isLoading = false;
        }

        function onClickSave() {
            vm.isLoading = true;

            var params = {
                masterUserId: vm.masterUserId,
                volunteerBackgroundCheckId: vm.backgroundCheck.id,
                date: vm.backgroundCheck.date.toISOString(),
                dateValidUntil: vm.backgroundCheck.validUntil.toISOString(),
                note: vm.backgroundCheck.comment || ''
            };

            return authentication.manageVolunteerBackgroundChecks(params)
                .then(function (response) {
                    response.action = vm.backgroundCheck.id ? 'edit' : 'add';
                    return $modalInstance.close(response);
                })
                .catch(function (error) {
                    var params = {
                        error: error,
                        success: false,
                        action: vm.backgroundCheck.id ? 'edit' : 'add'
                    };
                    return $modalInstance.dismiss(params);
                });
        }

        // Deleting another user's trainings is not currently allowed
        function formatDate(theDateInMoment) {
            return theDateInMoment.format("M/D/YYYY");
        }

        // Deleting another user's trainings is not currently allowed
        function onClickDelete() {
            vm.isLoading = true;

            var options = {
                templateUrl: 'app/components/volunteer/records/backgroundChecks/delete/view.html',
                controller: 'BackgroundCheckDeleteController',
                controllerAs: 'vm',
                size: 'tiny',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    backgroundCheck: function () {
                        return vm.backgroundCheck;
                    }
                }
            };

            return $modal.open(options)
                .result
                .then(function (response) {
                    response.action = 'delete';
                    return $modalInstance.close(response);
                })
                .catch(function (error) {
                    var params = {
                        error: error,
                        success: false,
                        action: 'delete'
                    };
                    return $modalInstance.dismiss(params);
                });
        }

        function dateCheckedEndDate() {
            return moment(vm.currentDate).isAfter(vm.backgroundCheck.validUntil, 'day') ? vm.backgroundCheck.validUntil : vm.currentDate;
        }
    }
})();