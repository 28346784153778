(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('ConfirmOrganizationDeleteController', ConfirmOrganizationDeleteController);

    ConfirmOrganizationDeleteController.$inject = ['$modalInstance', 'organization'];

    function ConfirmOrganizationDeleteController($modalInstance, organization) {
        var vm = this;
        vm.title = "Delete Organization";

        vm.name = organization.name;

        vm.onAccept = onAccept;

        function onAccept() {
            $modalInstance.close();
        }
    }
})();