/**
 * Created by scuzzort3 on 7/13/16.
 */
(function () {
    'use strict';

    angular
        .module('app.hoursEntryFormatter', []);

    angular
        .module('app.hoursEntryFormatter')
        .directive('hoursEntryFormatter', hoursEntryFormatter);

    hoursEntryFormatter.$inject = [];

    function hoursEntryFormatter() {
        return {
            restrict: 'A',
            scope: {},
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                var min = attrs.min || 0;
                var max = attrs.max || 24;
                var pattern = new RegExp(attrs.pattern);
                ngModel.$parsers.push(function (val) {
                    if (val.match(pattern) !== null) {
                        if (parseFloat(val, 10) < min) {
                            val = min.toString();
                        } else if (parseFloat(val, 10) > max) {
                            val = max.toString();
                        }
                    }

                    ngModel.$setViewValue(val);
                    ngModel.$render();

                    return parseFloat(val, 10);
                });
                ngModel.$formatters.push(function (val) {
                    if(!angular.isNumber(val)){
                        return '';
                    }

                    return val.toFixed(2);
                });
            }
        };
    }
})();