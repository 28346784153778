(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('EditOrganizationsController', EditOrganizationsController);

    EditOrganizationsController.$inject = ['$q', '$modalInstance', '$modal', '_', 'params', 'authentication', 'loadStatus', 'config'];

    function EditOrganizationsController($q, $modalInstance, $modal, _, params, authentication, loadStatus, config) {
        var vm = this;

        vm.isLoading = true;
        vm.isProductionWebsite = null;
        vm.bannerMessage = null;
        vm.organization = angular.copy(params.organization);
        vm.title = params.title;
        vm.commArchived = params.organization.commArchived;
        vm.isDerivedFromStandardOrganization = (angular.isDefined(params.organization.standardOrganization) && params.organization.standardOrganization.name !== "NONE");
        vm.dropDowns = {
            communities: params.communities,
            statuses: [
                {
                    name: "Active",
                    value: 1
                },
                {
                    name: "Inactive",
                    value: 0
                }
            ]
        };

        // If only one community possible, go ahead and select that one
        if (vm.dropDowns.communities.length === 1)
            vm.organization.communityId = vm.dropDowns.communities[0].id;

        vm.loadStatus = loadStatus.init();

        vm.onChangeStandardOrganization = onChangeStandardOrganization;
        vm.onSubmit = onSubmit;
        vm.isDeletable = isDeletable;
        vm.onClickDelete = onClickDelete;

        activate();

        function activate() {
            var promises = [getDropDownData(), getOrganizationGroups()];
            if (vm.organization.id) {
                promises.push(isDeletable(vm.organization.id).then(function (isDeletable) {
                    vm.showDelete = isDeletable;
                }));
                promises = $q.all(promises);
            } else {
                promises = $q.all(promises).then(getStandardOrganizations);
            }
            promises.catch(displayFormError).finally(function () {
                vm.isLoading = false;
            });
            config.get().then(function (configData) {
                vm.isProductionWebsite = configData.isProductionWebsite;
                vm.bannerMessage = configData.bannerMessage;
            });
        }

        function onSubmit() {
            var apiParams = {
                volunteerOrganizationId: vm.organization.id || undefined,
                volunteerOrganizationFullName: vm.organization.name === params.organization.name ? undefined : vm.organization.name,
                communityAffiliationId: vm.organization.communityId,
                volunteerOrganizationDescription: vm.organization.description,
                isActive: vm.organization.status,
                volunteerOrganizationGroup: vm.organization.group.name,
                volunteerOrganizationType: vm.organization.id ? undefined : vm.organization.type
            };

            if (_.has(vm.standardOrganization, 'id')) {
                _.assign(apiParams, {
                    volunteerOrganizationTemplateId: vm.organization.id ? undefined : vm.standardOrganization.id,
                    deriveFromStandardOrganization: true
                });
            }

            if (params.organization.status === 1 && vm.organization.status === 0) {
                var modalInstance = $modal.open({
                    templateUrl: 'app/components/administrator/organizations/confirmOrganizationInactive.template.html',
                    controller: 'ConfirmOrganizationInactiveController',
                    controllerAs: 'vm',
                    size: 'tiny',
                    backdrop: 'static',
                    keyboard: false,
                    resolve: {
                        organization: function () {
                            return vm.organization;
                        },
                        community: function () {
                            return null;
                        },
                        title: function () {
                            return "Inactivate Organization";
                        }
                    }
                });
                // Close edit window if confirm delete modal returned
                modalInstance.result.then(function () {
                    return authentication.setOrganizationInfo(apiParams).then($modalInstance.close).catch(displayFormError);
                }).then($modalInstance.close).catch(displayFormError);
            }

            else {
                return authentication.setOrganizationInfo(apiParams).then($modalInstance.close).catch(displayFormError);
            }
        }

        function isDeletable(id) {
            return authentication.getOpportunitiesAdmin({
                startingRowIndex: 0,
                numberOfRows: 1,
                volunteerOrganizationId: id
            }).then(function (response) {
                return response.count === 0;
            });
        }

        function onClickDelete() {
            var modalInstance = $modal.open({
                templateUrl: 'app/components/administrator/organizations/confirmOrganizationDelete.template.html',
                controller: 'ConfirmOrganizationDeleteController',
                controllerAs: 'vm',
                size: 'tiny',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    organization: function () {
                        return vm.organization;
                    }
                }
            });

            // Close edit window if confirm delete modal returned
            modalInstance.result.then(function () {
                return authentication.deleteOrganization({
                    volunteerOrganizationId: vm.organization.id
                });
            }).then($modalInstance.close).catch(displayFormError);
        }

        function getDropDownData() {
            var elements = [
                'volunteerorganizationtype'
            ];
            return authentication.getUiData(elements)
                .then(function (response) {
                    vm.dropDowns.types = response.volunteerorganizationtype;
                    vm.organization.type = params.organization.type || vm.dropDowns.types[0];
                });
        }

        function getOrganizationGroups() {
            // Get number of org groups to load initially
            return loadStatus.setup(vm.loadStatus, "organizationGroups").then(function () {
                // Get those org groups
                return authentication.getOrganizationGroups({
                    startingRowIndex: 0,
                    numberOfRows: vm.loadStatus.defaultNumRows
                }).then(function (response) {
                    var organizationGroups = response.organizationGroups;

                    // Update load status object
                    vm.loadStatus.currentIndex = response.organizationGroups.length < response.count ?
                        vm.loadStatus.defaultNumRows : response.organizationGroups.length;
                    vm.loadStatus.numResultsNotLoaded = response.count - vm.loadStatus.currentIndex;
                    vm.loadStatus.nextResultLoadNum = vm.loadStatus.numResultsNotLoaded;

                    // If more org groups, get them
                    if (vm.loadStatus.nextResultLoadNum > 0) {
                        return authentication.getOrganizationGroups({
                            startingRowIndex: vm.loadStatus.currentIndex,
                            numberOfRows: vm.loadStatus.nextResultLoadNum
                        }).then(function (response) {
                            return organizationGroups.concat(response.organizationGroups);
                        });
                    }
                    return organizationGroups;
                }).then(function (organizationGroups) {
                    vm.dropDowns.groups = _.map(organizationGroups, function (organizationGroup) {
                        return {
                            id: organizationGroup.volunteerOrganizationGroupId,
                            name: organizationGroup.volunteerOrganizationGroupName
                        };
                    });
                    vm.organization.group = vm.organization.group || vm.dropDowns.groups[0];
                });
            });
        }

        function getStandardOrganizations() {
            return authentication.getStandardOrganizations({
                getAll: true,
                isActive: true
            }).then(function (response) {
                var standardOrganizations = response.standardOrganizations;
                return standardOrganizations;
            }).then(function (standardOrganizations) {
                vm.dropDowns.standardOrganizations = _.map(standardOrganizations, function (standardOrganization) {
                    return {
                        id: standardOrganization.volunteerOrganizationTemplateId,
                        name: standardOrganization.volunteerOrganizationTemplateName,
                        description: standardOrganization.volunteerOrganizationDescription,
                        group: {
                            id: standardOrganization.volunteerOrganizationGroupId,
                            name: standardOrganization.volunteerOrganizationGroup
                        },
                        type: standardOrganization.volunteerOrganizationType
                    };
                });
                vm.dropDowns.standardOrganizations.unshift({
                    name: 'None',
                    fullName: '',
                    description: '',
                    group: vm.dropDowns.groups[0],
                    type: vm.dropDowns.types[0]
                });
                vm.standardOrganization = vm.dropDowns.standardOrganizations[0];
            });
        }

        function onChangeStandardOrganization() {
            _.assign(vm.organization, {
                type: vm.standardOrganization.type,
                group: vm.standardOrganization.group,
                name: vm.standardOrganization.name,
                description: vm.standardOrganization.description
            });
        }

        function displayFormError(error) {
            if (error === 'cancel' || error === 'ok' || error === 'backdrop click')
                return;
            if (error)
                vm.formError = error;
        }
    }
})();