(function () {
    'use strict';

    angular
        .module('app.vmis')
        .config(routes);

    routes.$inject = ['$stateProvider'];

    function routes($stateProvider) {
        $stateProvider
            .state('vmis.loggedOut', {
                url: '/logged-out',
                templateUrl: 'app/components/loggedOut/loggedOut.view.html',
                controller: 'LoggedOutController',
                controllerAs: 'vm',
                title: 'Logged Out'
            });
    }
})();
