(function () {
    'use strict';

    angular
        .module('app')
        .factory('_', lodash);

    lodash.$inject = ['$window'];

    function lodash($window) {
        return $window._;
    }
})();