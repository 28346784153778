(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .config(routes);

    routes.$inject = ['$stateProvider'];

    function routes($stateProvider) {
        $stateProvider
            .state('vmis.administrator.people', {
                url: '/people/{role}',
                title: 'People',
                views: {
                    "@vmis.administrator": {
                        templateUrl: 'app/components/administrator/people/people.view.html',
                        controller: 'PeopleController',
                        controllerAs: 'vm'
                    },
                    "volunteers@vmis.administrator.people": {
                        templateUrl: 'app/components/administrator/people/volunteers/volunteers.view.html',
                        controller: 'VolunteersController',
                        controllerAs: 'vm'
                    },
                    "opocs@vmis.administrator.people": {
                        templateUrl: 'app/components/administrator/people/opocs/opocs.view.html',
                        controller: 'OPOCsController',
                        controllerAs: 'vm'
                    },
                    "coordinatorAssistants@vmis.administrator.people": {
                        templateUrl: 'app/components/administrator/people/coordinatorAssistants/coordinatorAssistants.view.html',
                        controller: 'CoordinatorAssistantsController',
                        controllerAs: 'vm'
                    },
                    "coordinators@vmis.administrator.people": {
                        templateUrl: 'app/components/administrator/people/coordinators/coordinators.view.html',
                        controller: 'CoordinatorsController',
                        controllerAs: 'vm'
                    },
                    "regionManagers@vmis.administrator.people": {
                        templateUrl: 'app/components/administrator/people/regionManagers/regionManagers.view.html',
                        controller: 'RegionManagersController',
                        controllerAs: 'vm'
                    },
                    "programManagers@vmis.administrator.people": {
                        templateUrl: 'app/components/administrator/people/programManagers/programManagers.view.html',
                        controller: 'ProgramManagersController',
                        controllerAs: 'vm'
                    },
                    "global@vmis.administrator.people": {
                        templateUrl: 'app/components/administrator/people/global/global.view.html',
                        controller: 'GlobalUserSearchController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    params: ['authentication', function (authentication) {
                        var params = {
                            roleObjectList: [],
                            responsibilities: {},
                            isProgramManager: false,
                            masterUserId: -1
                        };

                        // Used for creating isPMAndNotAVCC
                        var isAVCC = false;

                        var completeRoleObjectList = [
                            {
                                shortName: "Vol",
                                name: "Volunteer",
                                vmisUserRolesName: "AVC Volunteer"
                            },
                            {
                                shortName: "OPOC",
                                name: "OPOC",
                                responsibilitiesName: "opoc",
                                vmisUserRolesName: "AVC OPOC"
                            },
                            {
                                shortName: "AVCCAssistant",
                                name: "AVCC Assistant",
                                responsibilitiesName: "avccAssistant",
                                vmisUserRolesName: "AVC Coordinator Assistant"
                            },
                            {
                                shortName: "AVCC",
                                name: "AVCC",
                                responsibilitiesName: "avcc",
                                vmisUserRolesName: "AVC Coordinator"
                            },
                            {
                                shortName: "RM",
                                name: "Region Manager",
                                responsibilitiesName: "rm",
                                secondResponsibilitiesName: "rmManaged",
                                vmisUserRolesName: "AVC Region Manager"
                            },
                            {
                                shortName: "PM",
                                name: "Program Manager",
                                vmisUserRolesName: "AVC Program Manager"
                            },
                            {
                                shortName: "global",
                                name: "Global Lookup",
                                vmisUserRolesName: "Global User Lookup"
                            }
                        ];

                        return authentication.getUserProfile()
                            .then(function (response) {
                                params.masterUserId = response.masterUserId;
                                params.responsibilities = response.aosUser.vmisUser.adminProfile ?
                                    response.aosUser.vmisUser.adminProfile.responsibilities : null;

                                // Note: this detects what roles the ACTIVE USER has.
                                // Different roles are modifiable based on that.
                                for (var i = 0; i < response.roles.vmisUserRoles.length; i++) {
                                    // Depending on the active user role, save data about their responsibilities
                                    // to display in drop downs and populate the drop down for the types of roles
                                    // they can modify.
                                    switch (response.roles.vmisUserRoles[i]) {
                                        case "AVC Program Manager":
                                            addRoleAvoidDuplicates(getRoleObjectByVmisUserRolesName("Global User Lookup"));
                                            addRoleAvoidDuplicates(getRoleObjectByVmisUserRolesName("AVC Program Manager"));
                                            addRoleAvoidDuplicates(getRoleObjectByVmisUserRolesName("AVC Region Manager"));
                                            addRoleAvoidDuplicates(getRoleObjectByVmisUserRolesName("AVC Coordinator"));
                                            params.isProgramManager = true;
                                            break;
                                        case "AVC Region Manager":
                                            addRoleAvoidDuplicates(getRoleObjectByVmisUserRolesName("AVC Coordinator"));
                                            break;
                                        case "AVC Coordinator":
                                            addRoleAvoidDuplicates(getRoleObjectByVmisUserRolesName("AVC Volunteer"));
                                            addRoleAvoidDuplicates(getRoleObjectByVmisUserRolesName("AVC Coordinator Assistant"));
                                            addRoleAvoidDuplicates(getRoleObjectByVmisUserRolesName("AVC OPOC"));
                                            isAVCC = true;
                                            break;
                                        case "AVC Coordinator Assistant":
                                            addRoleAvoidDuplicates(getRoleObjectByVmisUserRolesName("AVC Volunteer"));
                                            break;
                                        case "AVC OPOC":
                                            addRoleAvoidDuplicates(getRoleObjectByVmisUserRolesName("AVC Volunteer"));
                                            break;
                                        default:
                                            break;
                                    }
                                }

                                params.isPMAndNotAVCC = params.isProgramManager && !isAVCC;

                                return params;
                            })
                            .catch(function (error) {
                                console.log(error); // TODO: Improve
                                return params;
                            });

                        // Return the role object that matches the "vmisUserRolesName" string
                        // Searches complete list because used for setup
                        function getRoleObjectByVmisUserRolesName(vmisUserRolesName) {
                            return completeRoleObjectList.filter(function (element) {
                                return (element.vmisUserRolesName === vmisUserRolesName);
                            })[0];
                        }

                        function addRoleAvoidDuplicates(role) {
                            // If current role cannot be found in list, add it
                            if (!getRoleObject(role.name)) {
                                params.roleObjectList.push(role);
                            }
                        }

                        // Return the role object in the current list that matches the "name" string
                        function getRoleObject(name) {
                            return params.roleObjectList.filter(function (element) {
                                return (element.name === name);
                            })[0];
                        }
                    }]
                }
            });
    }
})();
