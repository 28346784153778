(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('NameFilterController', NameFilterController);

    NameFilterController.$inject = ['$modalInstance', 'params'];

    function NameFilterController($modalInstance, params) {
        var vm = this;

        vm.isLoading = true;

        vm.filter = {};
        vm.filter.firstName = params.firstName || null;
        vm.filter.lastName = params.lastName || null;

        vm.onFilter = onFilter;

        activate();

        function activate() {
            vm.isLoading = false;
        }

        function onFilter() {
            vm.isLoading = true;
            var outputFilter = angular.copy(vm.filter);
            close('success', "Filter successfully created!", outputFilter);
        }

        function close(action, msg, filter) {
            $modalInstance.close({
                action: action,
                message: msg,
                filter: filter || null
            });
        }
    }
})();