(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('ProgramManagersController', ProgramManagersController);

    ProgramManagersController.$inject = ['$q', '$modal', '$scope', '$location', 'authentication', 'loadStatus', 'params', 'moment', '$timeout', '$window', '$state'];

    function ProgramManagersController($q, $modal, $scope, $location, authentication, loadStatus, params, moment, $timeout, $window, $state) {
        var vm = this;

        vm.isLoading = true;
        vm.roleObjectList = angular.copy(params.roleObjectList);

        var isProgramManager = params.isProgramManager; // Should always be true
        var masterUserId = params.masterUserId;

        vm.selectedRoleObject = vm.roleObjectList.filter(function (role) {
            return role.name === "Program Manager";
        })[0];
        vm.numUsersSelected = 0;
        vm.isExporting = false;
        vm.sortColumn = 'aosUser.lastName';
        vm.sortReverse = false;

        vm.filter = {
            lastName: $location.search().lastName || '',
            firstName: $location.search().firstName || '',
            startDate: isNaN(new Date($location.search().startDate)) ? undefined : new Date($location.search().startDate),
            endDate: isNaN(new Date($location.search().endDate)) ? undefined : new Date($location.search().endDate),
            workEmail: $location.search().workEmail || ''
        };

        vm.loadStatus = loadStatus.init();

        vm.usersWithRole = [];

        vm.onSelectRole = onSelectRole;
        vm.onClickFilterUsers = onClickFilterUsers;
        vm.onClickSearch = onClickSearch;
        vm.onClickAddNewProgramManager = onClickAddNewProgramManager;
        vm.onClickRemoveProgramManager = onClickRemoveProgramManager;
        vm.onClickOpenProgramManager = onClickOpenProgramManager;
        vm.onCheckAll = onCheckAll;
        vm.onCheckUser = onCheckUser;
        vm.onMassEmail = onMassEmail;
        vm.onExport = onExport;
        vm.onLoadMore = onLoadMore;
        vm.onLoadAll = onLoadAll;
        vm.massActionDisabled = massActionDisabled;

        activate();

        function activate() {
            vm.massActions = [
                {
                    name: "Export",
                    action: onExport
                },
                {
                    name: "Mass Email",
                    action: onMassEmail
                }
            ];

            loadStatus.setup(vm.loadStatus, "users")
                .then(function () {
                    getUsers(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows);
                });
        }

        function getUsers(index, num) {
            vm.isLoading = true;

            var paramsGetUsers = {
                vmisRole: "AVC Program Manager",
                startingRowIndex: index,
                numberOfRows: num,
                firstName: vm.filter.firstName ? vm.filter.firstName : undefined,
                lastName: vm.filter.lastName ? vm.filter.lastName : undefined,
                startDate: vm.filter.startDate ? moment(vm.filter.startDate).startOf('day').toISOString() : undefined,
                endDate: vm.filter.endDate ? moment(vm.filter.endDate).endOf('day').toISOString() : undefined,
                workEmail: vm.filter.workEmail ? vm.filter.workEmail : undefined
            };

            authentication.getManagersAdmin(paramsGetUsers)
                .then(function (response) {
                    getUsersSuccess(response, index, num);
                })
                .catch(function (error) {
                    getUsersError(error);
                });

            function getUsersSuccess(response, index, num) {
                if (index === 0) {
                    vm.usersWithRole = [];
                    vm.loadStatus.currentIndex = 0;
                }

                for (var i = 0; i < response.users.length; i++) {
                    // Create unique ID for user checkbox
                    response.users[i].checkboxId = "check-" + response.users[i].aosUser.lastName + response.users[i].aosUser.firstName;
                    // Add user to full list
                    vm.usersWithRole.push(response.users[i]);
                }

                updateCheckAllAndExport();

                // Either set current index to max if all users loaded or increment by rows loaded
                vm.loadStatus.currentIndex = (vm.loadStatus.currentIndex + num > response.count) ? response.count : (vm.loadStatus.currentIndex + num);
                // Track total number of users not loaded for "Load All" button
                vm.loadStatus.numResultsNotLoaded = response.count - vm.loadStatus.currentIndex;
                // Set text for "Load More" button
                vm.loadStatus.nextResultLoadNum = (response.count === vm.loadStatus.currentIndex) ? 0 :
                    (vm.loadStatus.numResultsNotLoaded > num) ? num : vm.loadStatus.numResultsNotLoaded;

                $timeout(function () {
                    vm.isLoading = false;
                }, 100);
            }

            function getUsersError(error) {
                if (angular.isDefined(error)) {
                    var errorStr = error.error ? error.error : error;
                    $scope.$emit('people:alert', errorStr);
                }
                else
                    $scope.$emit('people:alert', "Failed to load list! Please try again.");

                $timeout(function () {
                    vm.isLoading = false;
                }, 100);
            }
        }

        function onSelectRole() {
            $scope.$emit('people:role', vm.selectedRoleObject);
        }

        function onClickFilterUsers() {
            var modalInstance = $modal.open({
                templateUrl: 'app/components/administrator/people/actionDirectives/dateFilter.view.html',
                controller: 'DateFilterController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    params: function () {
                        return {
                            startDate: vm.filter.startDate || null,
                            endDate: vm.filter.endDate || null,
                            workEmail: vm.filter.workEmail || null
                        };
                    }
                }
            });

            modalInstance.result
                .then(function (result) {
                    vm.isLoading = true;
                    switch (result.action) {
                        case 'success':
                            // Apply results from filter modal
                            vm.filter.startDate = result.filter.startDate;
                            vm.filter.endDate = result.filter.endDate;
                            vm.filter.workEmail = result.filter.workEmail;

                            $location.search({
                                startDate: vm.filter.startDate || null,
                                endDate: vm.filter.endDate || null,
                                firstName: vm.filter.firstName || null,
                                lastName: vm.filter.lastName || null,
                                workEmail: vm.filter.workEmail || null
                            });

                            return getUsers(0, vm.loadStatus.defaultNumRows);
                        case 'error':
                            vm.isLoading = false;
                            return $scope.$emit('people:alert', result.message);
                        default:
                            vm.isLoading = false;
                            return $scope.$emit('people:alert', "Failed to create new filter!");
                    }
                })
                .catch(function (error) {
                    $scope.$emit('people:alert', error);
                    vm.isLoading = false;
                });
        }

        function onClickSearch() {
            $location.search({
                startDate: vm.filter.startDate || null,
                endDate: vm.filter.endDate || null,
                firstName: vm.filter.firstName || null,
                lastName: vm.filter.lastName || null,
                workEmail: vm.filter.workEmail || null
            });
            getUsers(0, vm.loadStatus.defaultNumRows);
            return updateCheckAllAndExport();
        }

        function onClickAddNewProgramManager() {
            openProgramManagerModal();
        }

        function onClickRemoveProgramManager(programManager) {
            var modalInstance = $modal.open({
                templateUrl: 'app/components/administrator/people/actionDirectives/confirmRoleRemove.view.html',
                controller: 'ConfirmRoleRemoveController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    params: function () {
                        return {
                            userInfo: programManager,
                            role: "AVC Program Manager"
                        };
                    }
                }
            });

            modalInstance.result
                .then(function () {
                    processModalResult({
                        action: 'remove',
                        masterUserId: programManager.masterUserId,
                        responsibilities: {}
                    },
                        programManager);
                })
                .catch(function (error) {
                    if (angular.isDefined(error))
                        $scope.$emit('people:alert', error);
                    vm.isLoading = false;
                });
        }

        function onClickOpenProgramManager(programManager) {
            var paramId = {
                id: programManager.masterUserId
            };
            $window.open($state.href('vmis.profileEdit', paramId), '_blank');
        }

        function openProgramManagerModal(programManager) {
            var modalInstance = $modal.open({
                templateUrl: 'app/components/administrator/people/programManagers/editProgramManager.view.html',
                controller: 'EditProgramManagerController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    params: function () {
                        return {
                            programManager: angular.copy(programManager) || null,
                            canRemove: isProgramManager
                        };
                    }
                }
            });

            // Process result from edit modal
            modalInstance.result
                .then(function (result) {
                    processModalResult(result, programManager);
                })
                .catch(function (error) {
                    $scope.$emit('people:alert', error);
                });
        }

        function processModalResult(result) {
            vm.isLoading = true;
            var promiseArray = [];
            switch (result.action) {
                case 'edit':
                    promiseArray.push(authentication.modifyUserRoles({
                        masterUserId: result.masterUserId,
                        aosUserRoles: ["AVC Program Manager"],
                        isAdding: true
                    }));
                    break;
                case 'save':
                    promiseArray.push(authentication.modifyUserRoles({
                        masterUserId: result.masterUserId,
                        aosUserRoles: ["AVC Program Manager"],
                        isAdding: true
                    }));
                    break;
                case 'remove':
                    if (result.masterUserId === masterUserId) {
                        $scope.$emit('people:alert', "You cannot remove your own program manager role.");
                        break;
                    }

                    promiseArray.push(authentication.modifyUserRoles({
                        masterUserId: result.masterUserId,
                        aosUserRoles: ["AVC Program Manager"],
                        isDeleting: true
                    }));
                    break;
                default:
                    break;
            }

            return $q.all(promiseArray).then(function () {
                $scope.$emit('profile:update');
                return getUsers(0, vm.loadStatus.defaultNumRows);
            }).catch(function (error) {
                $scope.$emit('people:alert', error);
                vm.isLoading = false;
            });
        }

        function onCheckAll() {
            // Check users in either complete or filtered list
            for (var i = 0; i < vm.usersWithRole.length; i++)
                vm.usersWithRole[i].isChecked = vm.checkAll;

            updateCheckAllAndExport();
        }

        function onCheckUser() {
            updateCheckAllAndExport();
        }

        function updateCheckAllAndExport() {
            vm.numUsersSelected = 0;
            for (var i = 0; i < vm.usersWithRole.length; i++) {
                if (vm.usersWithRole[i].isChecked)
                    vm.numUsersSelected++;
            }

            // If list empty, uncheck
            if (vm.usersWithRole.length === 0) {
                vm.checkAll = false;
            }
            // Otherwise look for a checked user
            else {
                vm.checkAll = vm.usersWithRole.every(function (user) {
                    return user.isChecked;
                });
            }
        }

        function onMassEmail() {
            var emails = vm.usersWithRole.filter(function (user) {
                return user.isChecked;
            }).map(function (user) {
                return (
                    (user.aosUser.vmisUser.adminProfile.workEmail) ?
                        user.aosUser.vmisUser.adminProfile.workEmail :
                        (user.masterUserEmail) ? user.masterUserEmail : null
                );
            });

            if (emails.length === 0)
                return;

            emails = Array.from(new Set(emails));
            emails = emails.filter(Boolean);

            var modalInstance = $modal.open({
                templateUrl: 'app/components/administrator/people/actionDirectives/massEmail.view.html',
                controller: 'MassEmailController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    params: function () {
                        return {
                            emails: emails
                        };
                    }
                }
            });

            // Process result from modal
            modalInstance.result
                .then(function (result) {
                    switch (result.action) {
                        case 'success':
                            var message = "";
                            if (emails.length !== 1)
                                message = "Mass email \"" + result.message + "\" successfully sent to " + emails.length + " users!";
                            else
                                message = "Mass email \"" + result.message + "\" successfully sent to 1 user!";

                            return $scope.$emit('people:success', message);
                        case 'error':
                            return $scope.$emit('people:alert', result.message);
                        default:
                            return $scope.$emit('people:alert', "Mass email failed to send!");
                    }
                })
                .catch(function (error) {
                    $scope.$emit('people:alert', error);
                });
        }

        // Export currently-checked users to spreadsheet format for download
        function onExport() {
            vm.isExporting = true;

            var exportingUsersHeader = [
                "First Name",
                "Last Name",
                "Last Login Date",
                "Email\r\n"
            ].toString();

            var exportingUsers = [exportingUsersHeader];
            exportingUsers = exportingUsers.concat(vm.usersWithRole.filter(function (user) {
                return user.isChecked;
            }).map(function (checkedUser) {
                var email = (checkedUser.aosUser.vmisUser.adminProfile.workEmail !== null) ?
                    checkedUser.aosUser.vmisUser.adminProfile.workEmail : "";

                return [
                    checkedUser.aosUser.firstName,
                    checkedUser.aosUser.lastName,
                    checkedUser.lastSignedIn,
                    email
                ].toString() + "\r\n";
            }));

            var blob = new Blob(exportingUsers, { type: "text/plain;charset=utf-8" });
            saveAs(blob, (moment().format('YYYYMMDD_HHmmss') + "_Report.csv"));

            vm.isExporting = false;
        }

        function onLoadMore() {
            getUsers(vm.loadStatus.currentIndex, vm.loadStatus.nextResultLoadNum);
            vm.checkAll = false;
        }

        function onLoadAll() {
            getUsers(vm.loadStatus.currentIndex, vm.loadStatus.numResultsNotLoaded);
            vm.checkAll = false;
        }

        function massActionDisabled() {
            return vm.numUsersSelected === 0 || vm.isExporting || vm.isLoading;
        }
    }
})();