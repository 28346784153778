(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .controller('ServiceLogController', ServiceLogController);

    ServiceLogController.$inject = ['$scope', '$modal', '$q', '$stateParams', 'authentication', 'moment'];

    function ServiceLogController($scope, $modal, $q, $stateParams, authentication, moment) {
        var vm = this;

        vm.isLoading = true;
        vm.serviceStatus = 'Active';

        var calendarMonth = moment().month();
        var calendarYear = moment().year();

        vm.positions = [];
        vm.position = null;
        vm.periodActivity = null;
        vm.certifiedHours = 0;
        vm.submittedHours = 0;
        vm.rejectedHours = 0;
        vm.posClosed = "";

        vm.targetUser = {
            masterUserId: parseInt($stateParams.id) || null,
            firstName: "",
            lastName: ""
        };

        vm.adminEditing = angular.isNumber(vm.targetUser.masterUserId);
        vm.isOnlyAVCCAssistant = false;

        // List of communities and organizations an AVCC/OPOC has control over
        var adminResponsibilities = {};
        // List of organizations an OPOC user on this page can see
        // Remains empty if user is AVCC+
        var opocResponsibilities = [];

        vm.onShowPeriodHoursModal = onShowPeriodHoursModal;
        vm.onClickAddPosition = onClickAddPosition;
        vm.onLoadActivities = onLoadActivities;
        vm.isInFiscalYear = isInFiscalYear;
        vm.onChangePosition = onChangePosition;
        vm.reloadPositions = reloadPositions;
        vm.checkClosedPosition = checkClosedPositions;

        $scope.$on('activity:success', successHandler);
        $scope.$on('activity:alert', alertHandler);

        activate();

        function activate() {
            var promiseArray = [];

            promiseArray.push(authentication.getUiData(['volunteerservicestatus']).then(function (response) {
                vm.serviceStatuses = [];
                for (var status in response.volunteerservicestatus) {
                    var newStatus = {
                        name: response.volunteerservicestatus[status],
                        value: response.volunteerservicestatus[status]
                    };
                    vm.serviceStatuses.push(newStatus);
                }
                var allStatus = {
                    name: 'All',
                    value: undefined
                };
                vm.serviceStatuses.splice(0, 0, allStatus);
            }));

            // If volunteer, get own master user ID
            // Also check if "adminEditing" own account, in which case turn off admin mode
            promiseArray.push(authentication.getUserProfile()
                .then(function (response) {
                    if (!response.masterUserEmail) {
                        filterError("Could not retrieve user!");
                        return;
                    }

                    if (vm.adminEditing) {
                        if (vm.targetUser.masterUserId === response.masterUserId)
                            vm.adminEditing = false;

                        adminResponsibilities = response.aosUser.vmisUser.adminProfile.responsibilities;

                        // Determine if admin is only OPOC, and if so, get list of responsible organizations
                        var isOPOC = false;
                        var isAVCC = false;
                        var isAVCCAssistant = false;

                        // Instead of looking at vmisRoles, could ensure user actually has responsibilities for the role
                        //var isOPOC = angular.isDefined(adminResponsibilities.opoc);
                        //var isAVCC = angular.isDefined(adminResponsibilities.avcc);
                        //var isAVCCAssistant = angular.isDefined(adminResponsibilities.avccAssistant);

                        response.roles.vmisUserRoles.forEach(function (role) {
                            if (role === "AVC Coordinator Assistant")
                                isAVCCAssistant = true;
                            else if (role === "AVC OPOC")
                                isOPOC = true;
                            else if (role === "AVC Coordinator")
                                isAVCC = true;
                        });

                        if (isOPOC && !isAVCC && !isAVCCAssistant)
                            response.aosUser.vmisUser.adminProfile.responsibilities.opoc.forEach(function (comm) {
                                comm.organizations.forEach(function (org) {
                                    opocResponsibilities.push(org);
                                });
                            });
                        // If AVCC Assistant, do not show "Add To Position" button
                        if (isAVCCAssistant && !isOPOC && !isAVCC)
                            vm.isOnlyAVCCAssistant = true;
                    }
                    else {
                        vm.targetUser.masterUserId = response.masterUserId;
                        vm.targetUser.firstName = response.aosUser.firstName;
                        vm.targetUser.lastName = response.aosUser.lastName;
                    }
                })
                .catch(function (error) {
                    filterError(error);
                    vm.isLoading = false;
                }));

            // If admin editing, get target user profile info
            if (vm.adminEditing) {
                var params = {
                    masterUserId: vm.targetUser.masterUserId
                };
                promiseArray.push(authentication.getUserProfile(params)
                    .then(function (response) {
                        if (!response.masterUserId) {
                            filterError("Could not retrieve user!");
                            return;
                        }

                        // For calendar
                        if (!response.aosUser){
                            vm.targetUser.firstName = "Unknown";
                            vm.targetUser.firstName = "User";
                        }
                        else {
                            vm.targetUser.firstName = response.aosUser.firstName;
                            vm.targetUser.lastName = response.aosUser.lastName;
                        }
                    })
                    .catch(function (error) {
                        filterError(error);
                        vm.isLoading = false;
                    }));
            }

            // Wait until getting one or two profiles complete
            // Setting of vm.position here triggers loading of calendar activities
            $q.all(promiseArray)
                .then(function () {
                    loadPositions()
                        .then(function () {
                            vm.position = vm.positions[0];
                            onChangePosition(vm.position);
                        });
                });
        }

        function checkClosedPositions() {
            if (vm.serviceStatus === 'Active' && vm.position.status === 'Closed') {
                vm.posClosed = "This position is closed. Contact your OPOC if you believe this is in error. If you are done with this position, go to your Service History to Finish it.";
            }
            else {
                vm.posClosed = "";
            }
        }

        function reloadPositions() {
            if (vm.adminEditing) {
                loadPositions(vm.targetUser.masterUserId)
                    .then(function () {
                        vm.position = vm.positions.length >= 1 ? vm.positions[0] : null;
                        onChangePosition(vm.position);
                    });
            }
            else {
                loadPositions()
                    .then(function () {
                        vm.position = vm.positions.length >= 1 ? vm.positions[0] : null;
                        onChangePosition(vm.position);
                    });
            }
        }

        function loadPositions() {
            var params = {};

            // If admin editing, get opportunities for target user
            if (vm.adminEditing) {
                // TODO: Hard-coded numerical value should be replaced
                params = {
                    startingRowIndex: 0,
                    numberOfRows: 1000,
                    masterUserId: vm.targetUser.masterUserId,
                    volunteerApplicationStatus: 'Approved',
                    volunteerServiceStatus: vm.serviceStatus ? vm.serviceStatus : undefined
                };
                return authentication.getApplicationsAdmin(params)
                    .then(function (response) {
                        // If specifically OPOC, filter this list of volunteer positions further
                        // to only show positions within organizations OPOC is responsible for
                        //opocResponsibilities[i].volunteerOrganizationName === app.volunteerOrganization
                        vm.positions = response.applications.map(function (app) {
                            return {
                                id: app.volunteerOrganizationOpportunityId,
                                title: app.volunteerOrganization + ": " + app.volunteerOpportunity,
                                organization: app.volunteerOrganization,
                                closedDate: app.closedDate,
                                status: app.opportunityStatus, // TODO: not present
                                serviceStatus: app.volunteerServiceStatus
                            };
                        });
                    })
                    .catch(function (error) {
                        filterError(error);
                        vm.isLoading = false;
                    });
            }
            // If volunteer, get own opportunities
            else {
                // TODO: Hard-coded numerical value should be replaced
                params = {
                    startingRowIndex: 0,
                    numberOfRows: 1000,
                    volunteerApplicationStatus: 'Approved',
                    volunteerServiceStatus: vm.serviceStatus ? vm.serviceStatus : undefined
                };
                return authentication.getOpportunitiesAppliedFor(params)
                    .then(function (response) {
                        vm.positions = response.opportunities.map(function (opportunity) {
                            return {
                                id: opportunity.opportunityId,
                                title: opportunity.opportunityStatus === "Closed" ?
                                    opportunity.organizationName + ": " + opportunity.title + " (Closed)" : 
                                    opportunity.organizationName + ": " + opportunity.title,
                                organization: opportunity.organizationName,
                                closedDate: opportunity.closedDate,
                                status: opportunity.opportunityStatus,
                                serviceStatus: opportunity.serviceStatus
                            };
                        });
                    })
                    .catch(function (error) {
                        filterError(error);
                        vm.isLoading = false;
                    });
            }
        }

        function onShowPeriodHoursModal() {
            var options = {};
            if (vm.adminEditing) {
                options = {
                    templateUrl: 'app/components/administrator/hours/manageActivity.view.html',
                    controller: 'ManageActivityController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    keyboard: false,
                    resolve: {
                        activity: function () {
                            // On creating new period hours as admin, provide field
                            // to indicate that type as well as additional needed data
                            var activity = {};
                            if (vm.periodActivity) {
                                activity = angular.copy(vm.periodActivity);
                                activity.position = vm.position;
                                activity.volunteer = {
                                    firstName: vm.targetUser.firstName,
                                    lastName: vm.targetUser.lastName,
                                    masterUserId: vm.targetUser.masterUserId
                                };
                                activity.organization = vm.position.organization;
                            }
                            else {
                                activity = {
                                    type: 'Period',
                                    date: moment().year(calendarYear).month(calendarMonth).startOf('month').toDate(),
                                    position: vm.position,
                                    volunteer: {
                                        firstName: vm.targetUser.firstName,
                                        lastName: vm.targetUser.lastName,
                                        masterUserId: vm.targetUser.masterUserId
                                    },
                                    organization: vm.position.organization
                                };
                            }
                            return activity;
                        },
                        rejectOnly: function () {
                            return false;
                        }
                    }
                };
            }
            else {
                options = {
                    templateUrl: 'app/components/volunteer/activityCalendar/enterHours.view.html',
                    controller: 'EnterHoursController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    keyboard: false,
                    resolve: {
                        activity: function () {
                            var newActivity = {
                                type: 'Period',
                                date: moment().year(calendarYear).month(calendarMonth).startOf('month').toDate()
                            };
                            return vm.periodActivity !== null ? angular.copy(vm.periodActivity) : newActivity;
                        },
                        positionId: function () {
                            return vm.position.id;
                        },
                        volunteerId: function () {
                            return vm.targetUser.masterUserId;
                        },
                        readOnly: function () {
                            return (vm.disabled || !isInFiscalYear(calendarYear, calendarMonth));
                        }
                    }
                };
            }

            $modal.open(options).result
                .catch(function (error) {
                    filterError(error);
                })
                // Even if user does not submit, could have deleted documents
                .finally(reloadCalendar);
        }

        function onClickAddPosition() {
            var options = {
                templateUrl: 'app/components/volunteer/serviceLog/addToPosition.view.html',
                controller: 'AddToPositionController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    params: function () {
                        return {
                            occupiedPositions: vm.positions,
                            targetUser: vm.targetUser,
                            responsibilities: adminResponsibilities
                        };
                    }
                }
            };

            $modal.open(options)
                .result
                .then(function (result) {
                    vm.isLoading = true;
                    if (result.action === "success") {
                        loadPositions().then(function () {
                            vm.position = vm.positions[0];
                            for (var i = 0; i < vm.positions.length; i++) {
                                if (vm.positions[i].id === result.position.volunteerOpportunityId) {
                                    vm.position = vm.positions[i];
                                    break;
                                }
                            }
                            onChangePosition(vm.position);
                        });
                        displayAlert('success', result.message);
                    }
                    else {
                        filterError(result.message);
                        vm.isLoading = false;
                    }
                })
                .catch(function (error) {
                    filterError(error);
                });
        }

        function isInFiscalYear(year, month) {
            if (angular.isUndefined(year) || year === null) {
                year = calendarYear;
            }
            if (angular.isUndefined(month) || month === null) {
                month = calendarMonth;
            }
            var start = moment().month('October').subtract(1, 'years').startOf('month');
            var end = moment();
            if (moment().month() >= 9) {
                if (moment().month() === 9 && moment().date() < 15) {
                    start = moment().month('September').startOf('month');
                } else {
                    start = moment().month('October').startOf('month');
                }
            }
            var date = {
                year: year,
                month: month
            };
            return moment(date).isSameOrAfter(start, 'month') && moment(date).isSameOrBefore(end, 'month');
        }

        function onLoadActivities(year, month, dailyActivities, periodActivities) {
            calendarYear = year;
            calendarMonth = month;
            vm.periodActivity = periodActivities[0] || null;

            vm.certifiedHours = 0;
            vm.submittedHours = 0;
            vm.rejectedHours = 0;
            dailyActivities.forEach(updateHours);
            periodActivities.forEach(updateHours);

            function updateHours (activity) {
                switch (activity.status) {
                    case 'Certified':
                        vm.certifiedHours += activity.hours;
                        break;
                    case 'Submitted':
                        vm.submittedHours += activity.hours;
                        break;
                    case 'Rejected':
                        vm.rejectedHours += activity.hours;
                        break;
                    default:
                        break;
                }
            }
        }

        function reloadCalendar() {
            vm.reloadCalendar = true;
        }

        function onChangePosition(position) {
            if ((angular.isUndefined(position)) || (position === null)){
                vm.disabled = true;
                vm.isLoading = false;
                vm.reloadCalendar = true;
                return;
            }

            var onChangePromise = {
                application: authentication.getOpportunitiesAppliedFor({
                    volunteerOpportunityId: position.id
                }),
                position: authentication.getOpportunityDetails({
                    volunteerOpportunityId: position.id
                })
            };

            if(vm.adminEditing) {
                onChangePromise = {
                    application: authentication.getApplicationsAdmin({
                        volunteerOpportunityId: position.id,
                        masterUserId: parseInt($stateParams.id)
                    }),
                    position: authentication.getOpportunityDetails({
                        volunteerOpportunityId: position.id
                    })
                };
            }

            // TODO: Could be simplified with different/previous API calls?
            $q.all(onChangePromise)
                .then(function (responses) {
                vm.disabled = vm.adminEditing ? false : (responses.position.volunteerPositionStatus === 'Closed' ||
                    ((responses.application.opportunities.length >= 1)) &&
                    (responses.application.opportunities[0].serviceStatus !== 'Active'));
                vm.isLoading = false;
                checkClosedPositions();
            }).catch(function (error) {
                filterError(error);
                vm.isLoading = false;
            });
        }

        function successHandler(event, message) {
            event.stopPropagation();

            displayAlert('success', message);
        }

        function alertHandler(event, message) {
            event.stopPropagation();

            filterError(message);
        }

        function filterError(error) {
            if (angular.isDefined(error)) {
                var errorStr = error.error ? error.error : error;
                if (errorStr === 'ok' || errorStr === 'cancel' || errorStr === 'backdrop click')
                    displayAlert('clear');
                else
                    displayAlert('error', errorStr);
            }
        }

        function displayAlert(type, message) {
            switch (type) {
                case 'success':
                    vm.formSuccess = message;
                    vm.formError = "";
                    break;
                case 'error':
                    vm.formError = message;
                    vm.formSuccess = "";
                    break;
                case 'clear':
                    vm.formError = "";
                    vm.formSuccess = "";
                    break;
                default:
                    vm.formError = message;
                    vm.formSuccess = "";
                    break;
            }
        }
    }
})();
