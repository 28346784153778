(function () {
    'use strict';

    angular
        .module('vmis.activityCalendar')
        .controller('DeleteHoursDocumentController', DeleteHoursDocumentController);

        DeleteHoursDocumentController.$inject = ['$modalInstance', 'authentication', 'document'];

    function DeleteHoursDocumentController($modalInstance, authentication, document) {
        var vm = this;

        vm.document = document;

        vm.onDeleteUploadedDoc = onClickDelete;

        function onClickDelete() {
            vm.processing = true;

            var params = {
                hoursDocumentId: vm.document.hoursDocumentId,
            };

            return authentication.deleteHoursDocument(params)
                .then($modalInstance.close)
                .catch($modalInstance.dismiss);

        }
    }
})();