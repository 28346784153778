(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .controller('RecordsController', RecordsController);

    RecordsController.$inject = ['$scope', '$stateParams', 'authentication', 'adminInfo'];

    function RecordsController($scope, $stateParams, authentication, adminInfo) {
        var vm = this;

        vm.isLoading = true;

        vm.masterUserId = parseInt($stateParams.id) || null;
        // Use existence of adminMasterUserId (not undefined) as the official check
        // of whether admin is editing another user
        vm.adminEditing = angular.isNumber(adminInfo.adminMasterUserId);

        vm.alerts = [];

        vm.onCloseAlert = onCloseAlert;

        $scope.$on('records:success', successHandler);
        $scope.$on('records:alert', alertHandler);

        activate();

        function activate() {
            if (vm.adminEditing)
                getUserProfile(vm.masterUserId);
            else
                vm.isLoading = false;
        }

        function getUserProfile(id) {
            var params = null;
            if (angular.isNumber(id)) {
                params = {
                    masterUserId: id
                };
            }

            return authentication.getUserProfile(params)
                .then(function (response) {
                    if (!response.masterUserId) {
                        displayError("Could not retrieve user!");
                        vm.isLoading = false;
                        return;
                    }
                    if (!response.aosUser) {
                        vm.fullName = "Unknown User";
                    } else {
                        vm.fullName = response.aosUser.firstName + " " + response.aosUser.lastName;
                    }
                    vm.isLoading = false;
                })
                .catch(function (error) {
                    displayError(error);
                    vm.isLoading = false;
                });
        }

        function successHandler(event, message) {
            event.stopPropagation();

            displayAlert('success', message);
        }

        function alertHandler(event, message) {
            event.stopPropagation();

            displayError(message);
        }

        function onCloseAlert(index) {
            vm.alerts.splice(index, 1);
        }

        function displayAlert(type, message) {
            vm.alerts.push({
                type: type,
                message: message
            });
        }

        function displayError(error) {
            if (error === 'cancel' || error === 'ok' || error === 'backdrop click')
                return;
            if (error)
                displayAlert('alert', error);
        }
    }
})();