(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('OrganizationGroupDetailsController', OrganizationGroupDetailsController);

    OrganizationGroupDetailsController.$inject = ['$modalInstance', '$modal', 'authentication', 'title', 'organizationGroup', 'config'];

    function OrganizationGroupDetailsController($modalInstance, $modal, authentication, title, organizationGroup, config) {
        var vm = this;
        var originalOrganizationGroupName = organizationGroup.name;

        vm.title = title;
        vm.organizationGroup = organizationGroup;

        vm.isProductionWebsite = null;
        vm.bannerMessage = null;

        vm.onSave = onSave;
        vm.onClickDelete = onClickDelete;

        activate();

        function activate() {
            config.get().then(function (configData) {
                vm.isProductionWebsite = configData.isProductionWebsite;
                vm.bannerMessage = configData.bannerMessage;
            });
        }

        function onSave() {
            vm.isLoading = true;
            return authentication.manageOrganizationGroup({
                volunteerOrganizationGroupId: organizationGroup.id || undefined,
                volunteerOrganizationGroupName: organizationGroup.name
            }).then(function () {
                $modalInstance.close();
            }).catch(function (error) {
                vm.formError = error;
            }).finally(function () {
                vm.isLoading = false;
            });
        }

        function onClickDelete() {
            $modal.open({
                templateUrl: 'app/components/administrator/organizationGroups/organizationGroupDelete.view.html',
                controller: 'OrganizationGroupDeleteController',
                controllerAs: 'vm',
                size: 'tiny',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    organizationGroupName: function () {
                        return originalOrganizationGroupName;
                    }
                }
            }).result.then(function () {
                vm.isLoading = true;
                return authentication.manageOrganizationGroup({
                    volunteerOrganizationGroupId: organizationGroup.id || undefined,
                    isDelete: true
                }).then(function () {
                    $modalInstance.close();
                });
            }, function () {
                //dismiss
            }).catch(function (error) {
                vm.formError = error;
            }).finally(function () {
                vm.isLoading = false;
            });
        }
    }
})();