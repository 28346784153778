(function () {
    'use strict';

    angular
        .module('app')
        .controller('ProfileController', ProfileController);

    ProfileController.$inject = ['$anchorScroll', '$q', '$state', '$stateParams', '$modal', '$window', '$scope',
        'authentication', 'moment', '_', 'config'];

    function ProfileController($anchorScroll, $q, $state, $stateParams, $modal, $window, $scope,
        authentication, moment, _, config) {
        var vm = this;
        var oldCommunity = {
            communityAffiliationId: null,
            communityAffiliationName: null
        };
        var testString = "";

        vm.isUatWebsite = null;
        vm.isTrainingWebsite = null;
        vm.isLoading = true;

        vm.dispositions = {};
        vm.dispositionsSelected = 0;
        vm.masterUserId = parseInt($stateParams.id) || null; // If exists, using page as admin
        vm.nonUserEmail = $stateParams.email || null; // If exists, using page to create non-user
        vm.fullName = vm.nonUserEmail || "";
        vm.userName = "";

        vm.noProfile = false;

        vm.onSubmit = onSubmit;
        vm.onChangeCountry = onChangeCountry;
        vm.onCancel = onCancel;
        vm.onChangeDisposition = onChangeDisposition;
        vm.onChangeBirthday = onChangeBirthday;

        activate();

        function activate() {
            config.get().then(function (configData) {
                vm.isUatWebsite = configData.isUatWebsite;
                vm.isTrainingWebsite = configData.isTrainingWebsite;
            });
            fillDropDowns().then(function () {
                if (vm.masterUserId) {
                    getUserProfile(vm.masterUserId);
                }
                else if (vm.nonUserEmail) {
                    vm.adminEditing = true;
                    vm.noProfile = true;

                    // For non-users, the volunteer email should match the master email
                    // Pre-fill that field out
                    vm.volunteerProfile = {
                        volunteerEmail: vm.nonUserEmail
                    };

                    // Only allow assigning of a non-user to a community the admin is responsible for (and is active)
                    // Slightly inefficient since the admin's profile is obtained elsewhere on page load
                    authentication.getUserProfile()
                        .then(function (response) {
                            if (response.aosUser.vmisUser.adminProfile && response.aosUser.vmisUser.adminProfile.responsibilities) {
                                var responsibilities = response.aosUser.vmisUser.adminProfile.responsibilities;
                                var workingCommIdsList = [];
                                // Note: according to people.routes.js:112, only AVCC and OPOC can access this functionality
                                // (In combination with the exclusion of AVCC Assistants in volunteers.controller.js.)
                                if (responsibilities.avcc) {
                                    responsibilities.avcc.forEach(function (comm) {
                                        workingCommIdsList.push(comm.communityAffiliationId);
                                    });
                                }
                                if (responsibilities.opoc) {
                                    responsibilities.opoc.forEach(function (comm) {
                                        workingCommIdsList.push(comm.communityAffiliationId);
                                    });
                                }

                                var newCommsList = [];
                                workingCommIdsList.forEach(function (respCommId) {
                                    var match = _.find(vm.dropDowns.communities, { 'communityAffiliationId': respCommId });
                                    if (match !== undefined) {
                                        newCommsList.push(match);
                                    }
                                });
                                vm.dropDowns.communities = newCommsList;
                            }
                            else {
                                vm.formSuccess = '';
                                vm.formError = "Error: insufficient admin responsibilities to create a non-user";
                            }
                            vm.isLoading = false;
                        })
                        .catch(function (error) {
                            vm.formSuccess = '';
                            vm.formError = "Error: " + error;
                            vm.isLoading = false;
                        });
                }
                else {
                    vm.adminEditing = false;
                    getUserProfile();
                }
            });
        }

        function fillDropDowns() {
            var promiseArray = [];

            var elements = [
                'usstate',
                'stateabbrev',
                'country',
                'branchofservice',
                'component',
                'disposition',
                'paygrade',
                'civilianpaygrade',
                'preferredcontactmethod'
            ];

            vm.dropDowns = {};

            promiseArray.push(authentication.getUiData(elements).then(function (response) {
                vm.dropDowns.states = authentication.createStateList(response.usstate,response.stateabbrev);
                vm.dropDowns.countries = response.country;
                vm.dropDowns.branches = response.branchofservice;
                vm.dropDowns.components = response.component;
                vm.dropDowns.ranks = response.paygrade;
                vm.dropDowns.civilianRanks = response.civilianpaygrade;
                vm.dropDowns.preferredContactMethods = response.preferredcontactmethod;

                response.disposition.forEach(function (disposition) {
                    vm.dispositions[disposition] = {
                        name: disposition,
                        selected: false,
                        disabled: false
                    };
                });
            }));

            promiseArray.push(authentication.getCommunities({
                getAll: true,
                isArchived: false
            }).then(function (response) {
                vm.dropDowns.communities = response.communityAffiliations;
            }));

            return $q.all(promiseArray).catch(function (error) {
                vm.formSuccess = '';
                vm.formError = "Error: " + error;
            });
        }

        function getUserProfile(id) {
            var getUserProfilePromiseArray = [];
            var commCheckPromiseArray = [];
            vm.isLoading = true;

            var params = null;
            if (angular.isNumber(id)) {
                params = {
                    masterUserId: id
                };

                getUserProfilePromiseArray.push(authentication.getUsernameFromMasterUserId(params).then(function(response){
                    vm.username = response.username;
                }));
            }



            getUserProfilePromiseArray.push(authentication.getUserProfile(params)
                .then(function (response) {
                    // If editing own profile and a part is incomplete (not checking admin profile)
                    if (!id && (!authentication.isAosProfileValid(response.aosUser) ||
                        (response.aosUser.vmisUser && !authentication.isVmisProfileValid(response.aosUser.vmisUser.volunteerProfile)))) {
                        vm.noProfile = true;
                        vm.formSuccess = '';
                        vm.formError = "You must fill out your User profile before proceeding.";

                        vm.initialDate = moment().format('MM/DD/YYYY');
                    }

                    vm.email = response.masterUserEmail || response.aosUser.email;

                    var aosProfile = response.aosUser;

                    vm.fullName = aosProfile.firstName + " " + aosProfile.lastName;

                    vm.aosProfile = {};
                    vm.aosProfile.firstName = aosProfile.firstName || '';
                    vm.aosProfile.middleInitial = aosProfile.middleInitial ? aosProfile.middleInitial : '';
                    vm.aosProfile.lastName = aosProfile.lastName || '';
                    vm.aosProfile.dateOfBirth = aosProfile.dateOfBirth ? moment(aosProfile.dateOfBirth, 'YYYY-MM-DD').toDate() : undefined;
                    if (vm.aosProfile.dateOfBirth !== undefined) {
                        var birthday = new Date(vm.aosProfile.dateOfBirth).toISOString();
                        var age = moment().startOf('day').diff(birthday, 'years');
                        vm.isMinor = (age < 18);
                    }
                    else {
                        vm.isMinor = false;
                    }

                    if (aosProfile.communityAffiliationId) {
                        commCheckPromiseArray.push(authentication.isCommunityArchived(aosProfile.communityAffiliationId)
                            .then(function (isArchived) {
                                if (isArchived) {
                                    vm.formSuccess = '';
                                    vm.formError = "The Military Community you were a part of has been inactivated. Please select a new one.";
                                }
                            })
                        );

                        oldCommunity = vm.aosProfile.militaryCommunity = vm.dropDowns.communities.filter(function (comm) {
                            return comm.communityAffiliationId === aosProfile.communityAffiliationId;
                        })[0];
                    }
                    else {
                        vm.aosProfile.militaryCommunity = oldCommunity = undefined;
                    }

                    vm.aosProfile.branch = aosProfile.branchOfService || '';
                    vm.aosProfile.component = aosProfile.armyComponent || '';

                    // Minor fields
                    vm.aosProfile.isMarried = aosProfile.isMarried || false;
                    if (!aosProfile.isMarried) {
                        vm.aosProfile.parentGuardian = {
                            firstName: aosProfile.parentGuardianFirstName,
                            lastName: aosProfile.parentGuardianLastName,
                            phone: aosProfile.parentGuardianPhone,
                            email: aosProfile.parentGuardianEmail,
                            address: {
                                country: aosProfile.parentGuardianCountry,
                                line1: aosProfile.parentGuardianAddress1,
                                line2: aosProfile.parentGuardianAddress2 || '',
                                city: aosProfile.parentGuardianCity,
                                state: aosProfile.parentGuardianUsState,
                                zipCode: aosProfile.parentGuardianZipCode
                            }
                        };
                        onChangeCountry(vm.aosProfile.parentGuardian.address);
                    }

                    if (aosProfile.dispositions) {
                        aosProfile.dispositions.forEach(function (disposition) {
                            vm.dispositions[disposition].selected = true;
                            onChangeDisposition(vm.dispositions[disposition]);
                        });

                        if (vm.dispositions['Service Member'].selected) {
                            vm.aosProfile.rank = aosProfile.payGrade;
                        }
                        if (vm.dispositions['Family Member'].selected) {
                            vm.aosProfile.sponsorRank = aosProfile.sponsorPayGrade;
                        }
                        if (vm.dispositions['DoD Civilian'].selected) {
                            vm.aosProfile.civilianRank = aosProfile.civilianPayGrade;
                        }
                    }

                    if ('vmisUser' in response.aosUser && response.aosUser.vmisUser !== null) {
                        var vmisUser = response.aosUser.vmisUser;

                        // Edge case that should not happen in normal usage
                        // Can occur if, for example, in Postman only part of the profile is set
                        if (vmisUser === null)
                            vm.noProfile = true;

                        if ('adminProfile' in vmisUser && vmisUser.adminProfile !== null) {
                            var adminProfile = vmisUser.adminProfile;

                            if (!id && !authentication.isAdminProfileValid(adminProfile)) {
                                vm.formSuccess = '';
                                vm.formError = "You have been granted a higher level of access within VMIS. Please update your administrator profile before continuing.";
                            }

                            var isAfwpAdmin = false;
                            var isProgramManager = false;

                            // Find out if AFWP admin or PM
                            response.roles.vmisUserRoles.forEach(function (role) {
                                switch (role) {
                                    case "AFWP Administrator":
                                        isAfwpAdmin = true;
                                        break;
                                    case "AVC Program Manager":
                                        isProgramManager = true;
                                        break;
                                }
                            });

                            vm.adminProfile = {
                                additionalRoles: {
                                    afwpAdmin: isAfwpAdmin,
                                    programManager: isProgramManager
                                },
                                responsibilities: {
                                    opoc: _.map(adminProfile.responsibilities.opoc, function (community) {
                                        return {
                                            name: community.communityAffiliationName,
                                            organizations: _.map(community.organizations, function (organization) {
                                                return organization.volunteerOrganizationName;
                                            })
                                        };
                                    }),
                                    avccAssistant: _.map(adminProfile.responsibilities.avccAssistant, function (community) {
                                        return community.communityAffiliationName;
                                    }),
                                    coordinator: _.map(adminProfile.responsibilities.avcc, function (community) {
                                        return community.communityAffiliationName;
                                    }),
                                    rm: _.map(adminProfile.responsibilities.rm, function (community) {
                                        return community.communityAffiliationName;
                                    })
                                },
                                note: adminProfile.note || '',
                                workEmail: adminProfile.workEmail,
                                commercialWorkPhone: adminProfile.commercialWorkPhone,
                                dsnPhone: adminProfile.dsnPhone || '',
                                address: {
                                    country: adminProfile.country,
                                    line1: adminProfile.address1,
                                    line2: adminProfile.address2 || '',
                                    city: adminProfile.city,
                                    state: adminProfile.usState,
                                    zipCode: adminProfile.zipCode
                                }
                            };
                            onChangeCountry(vm.adminProfile.address);
                        }

                        if ('volunteerProfile' in vmisUser && vmisUser.volunteerProfile !== null) {
                            var volunteerProfile = vmisUser.volunteerProfile;
                            vm.volunteerProfile = {};
                            vm.volunteerProfile.volunteerEmail = volunteerProfile.volunteerEmail;
                            vm.volunteerProfile.preferredContactMethod = volunteerProfile.preferredContactMethod;
                            vm.volunteerProfile.workExperience = volunteerProfile.workExperience ? volunteerProfile.workExperience : '';
                            vm.volunteerProfile.volunteerExperience = volunteerProfile.volunteerExperience ? volunteerProfile.volunteerExperience : '';
                            vm.volunteerProfile.specialSkillsInterestsAndHobbies = volunteerProfile.specialSIH ? volunteerProfile.specialSIH : '';
                            vm.volunteerProfile.primaryPhone = volunteerProfile.primaryPhone;
                            vm.volunteerProfile.secondaryPhone = volunteerProfile.secondaryPhone ? volunteerProfile.secondaryPhone : '';
                        }
                    }
                })
                .catch(function (error) {
                    vm.formSuccess = '';
                    vm.formError = "Error: " + error;
                    vm.isLoading = false;
                }));

            if(vm.masterUserId) {
                getUserProfilePromiseArray.push(authentication.getUserNotificationEmailSettings({masterUserId: vm.masterUserId})
                    .then(function (response) {
                        vm.gettingNotificationEmails = response.currentNotificationEmails.count > 0;
                        vm.initalNotificationEmails = vm.gettingNotificationEmails;
                        vm.isLoading = false;
                    })
                    .catch(function (error) {
                        vm.formSuccess = '';
                        vm.formError = "Error: " + error;
                        vm.isLoading = false;
                    }));
            }
            else {
                getUserProfilePromiseArray.push(authentication.getUserNotificationEmailSettings()
                    .then(function (response) {
                        vm.gettingNotificationEmails = response.currentNotificationEmails.count > 0;
                        vm.initalNotificationEmails = vm.gettingNotificationEmails;
                        vm.isLoading = false;
                    })
                    .catch(function (error) {
                        vm.formSuccess = '';
                        vm.formError = "Error: " + error;
                        vm.isLoading = false;
                    }));
            }

            // Check if "adminEditing" own account, otherwise set flag to true
            if (angular.isNumber(id)) {
                getUserProfilePromiseArray.push(authentication.getUserProfile()
                    .then(function (response) {
                        vm.adminEditing = id !== response.masterUserId;
                    })
                    .catch(function (error) {
                        vm.formSuccess = '';
                        vm.formError = "Error: " + error;
                        vm.isLoading = false;
                    }));
            }

            return $q.all(getUserProfilePromiseArray)
                .then(function () {
                    return $q.all(commCheckPromiseArray)
                        .then(function () {
                            vm.isLoading = false;
                        });

                });
        }

        function onSubmit() {
            // If editing another user but not creating non-user
            if (vm.adminEditing && !vm.nonUserEmail)
                setUserProfile(vm.masterUserId);
            else
                setUserProfile();
        }

        function setUserProfile(id) {
            var aosUser = {
                firstName: vm.aosProfile.firstName,
                middleInitial: vm.aosProfile.middleInitial,
                lastName: vm.aosProfile.lastName,
                dateOfBirth: moment(vm.aosProfile.dateOfBirth).format('YYYY-MM-DD'),
                communityAffiliationId: vm.aosProfile.militaryCommunity.communityAffiliationId,
                branchOfService: vm.aosProfile.branch,
                armyComponent: vm.aosProfile.component,
                dispositions: []
            };

            // TODO: Should we check the time again or just follow what the checkbox indicates?
            if (moment().diff(vm.aosProfile.dateOfBirth, 'years') < 18) {
                aosUser.isMarried = vm.aosProfile.isMarried;
                if (!aosUser.isMarried) {
                    aosUser.parentGuardianFirstName = vm.aosProfile.parentGuardian.firstName;
                    aosUser.parentGuardianLastName = vm.aosProfile.parentGuardian.lastName;
                    aosUser.parentGuardianPhone = vm.aosProfile.parentGuardian.phone;
                    aosUser.parentGuardianEmail = vm.aosProfile.parentGuardian.email;
                    aosUser.parentGuardianAddress1 = vm.aosProfile.parentGuardian.address.line1;
                    aosUser.parentGuardianAddress2 = vm.aosProfile.parentGuardian.address.line2;
                    aosUser.parentGuardianCity = vm.aosProfile.parentGuardian.address.city;
                    aosUser.parentGuardianCountry = vm.aosProfile.parentGuardian.address.country;
                    aosUser.parentGuardianZipCode = vm.aosProfile.parentGuardian.address.zipCode;
                    if(angular.isDefined(vm.aosProfile.parentGuardian.address.state) && vm.aosProfile.parentGuardian.address.state !== null){
                        testString = vm.aosProfile.parentGuardian.address.state.substring(vm.aosProfile.parentGuardian.address.state.length-5);

                        if(testString.substring(0,2) === ' (' && testString.substring(testString.length-1) === ')'){
                            vm.aosProfile.parentGuardian.address.state = vm.aosProfile.parentGuardian.address.state.substring(0,vm.aosProfile.parentGuardian.address.state.length-5);
                        }
                    }
                    aosUser.parentGuardianUsState = vm.aosProfile.parentGuardian.address.isStateRequired ? vm.aosProfile.parentGuardian.address.state : undefined;
                }
            }

            angular.forEach(vm.dispositions, function (disposition) {
                if (disposition.selected) aosUser.dispositions.push(disposition.name);
            });

            if (vm.dispositions['Service Member'].selected) {
                aosUser.payGrade = vm.aosProfile.rank;
            }
            if (vm.dispositions['Family Member'].selected) {
                aosUser.sponsorPayGrade = vm.aosProfile.sponsorRank;
            }
            if (vm.dispositions['DoD Civilian'].selected) {
                aosUser.civilianPayGrade = vm.aosProfile.civilianRank;
            }
            
            if (vm.adminProfile || vm.volunteerProfile) {
                aosUser.vmisUser = {};
            }

            if (vm.adminProfile) {

                if(angular.isDefined(vm.adminProfile.address.state) && vm.adminProfile.address.state !== null){
                    testString = vm.adminProfile.address.state.substring(vm.adminProfile.address.state.length-5);

                    if(testString.substring(0,2) === ' (' && testString.substring(testString.length-1) === ')'){
                        vm.adminProfile.address.state = vm.adminProfile.address.state.substring(0,vm.adminProfile.address.state.length-5);
                    }
                }

                aosUser.vmisUser.adminProfile = {
                    note: vm.adminProfile.note,
                    workEmail: vm.adminProfile.workEmail,
                    commercialWorkPhone: vm.adminProfile.commercialWorkPhone,
                    dsnPhone: vm.adminProfile.dsnPhone,
                    country: vm.adminProfile.address.country,
                    address1: vm.adminProfile.address.line1,
                    address2: vm.adminProfile.address.line2,
                    city: vm.adminProfile.address.city,
                    usState: vm.adminProfile.address.isStateRequired ? vm.adminProfile.address.state : undefined,
                    zipCode: vm.adminProfile.address.zipCode
                };
            }

            if (vm.volunteerProfile) {
                aosUser.vmisUser.volunteerProfile = {
                    volunteerEmail: vm.volunteerProfile.volunteerEmail,
                    preferredContactMethod: vm.volunteerProfile.preferredContactMethod,
                    workExperience: vm.volunteerProfile.workExperience,
                    volunteerExperience: vm.volunteerProfile.volunteerExperience,
                    specialSIH: vm.volunteerProfile.specialSkillsInterestsAndHobbies,
                    primaryPhone: vm.volunteerProfile.primaryPhone,
                    secondaryPhone: vm.volunteerProfile.secondaryPhone
                };
            }

            if (vm.nonUserEmail)
                completeNonUserCreation(aosUser);
            else {
                var promise = $q.resolve();
                if (oldCommunity && oldCommunity.communityAffiliationId && oldCommunity.communityAffiliationId !== aosUser.communityAffiliationId) {
                    promise = $modal.open({
                        templateUrl: 'app/components/profile/changeCommunity.view.html',
                        controller: 'ChangeCommunityController',
                        controllerAs: 'vm',
                        size: 'tiny',
                        backdrop: 'static',
                        keyboard: false,
                        resolve: {
                            params: function () {
                                return {
                                    community: oldCommunity.communityAffiliationName,
                                    name: vm.adminEditing ? aosUser.firstName : null
                                };
                            }
                        }
                    }).result.then(function () {
                        return authentication.finishOpportunityStatusForUser({
                            communityAffiliationId: oldCommunity.communityAffiliationId,
                            userId: vm.adminEditing ? id : undefined
                        });
                    }, function () {
                    });
                }

                promise.then(function () {
                    return authentication.setUserProfile(aosUser, vm.adminEditing ? id : undefined);
                }).then(function () {
                    oldCommunity = vm.aosProfile.militaryCommunity;
                    var fullName = aosUser.firstName + " " + aosUser.lastName;
                    if(vm.gettingNotificationEmails !== vm.initalNotificationEmails){
                        var params = vm.gettingNotificationEmails ? {} : {isRemoving: true};
                        return authentication.setUserNotificationEmailSettings(params).then(function () {
                            profileUpdatedSuccessfully(fullName);
                            activate();
                        });
                    }
                    else {
                        profileUpdatedSuccessfully(fullName);
                    }

                }).catch(function (error) {
                    vm.formSuccess = '';
                    vm.formError = "Error: " + error;
                }).finally(function () {
                    $anchorScroll('anchor-top');
                });
            }
        }

        function profileUpdatedSuccessfully(fullName) {
            vm.formError = '';
            vm.formSuccess = 'Profile updated successfully!';
            // Alert header and side nav to update for any possible changes if not admin editing
            if (!vm.masterUserId)
                $scope.$emit('profile:update');
            // Just update profile page header if admin editing
            else
                vm.fullName = fullName;
        }

        function onChangeCountry(profileAddress) {
            profileAddress.isStateRequired = profileAddress.country === 'United States';
            if (!profileAddress.isStateRequired) {
                profileAddress.state = undefined;
            }
        }

        function onChangeBirthday(date) {
            if (!date) return;
            var birthday = new Date(date).toISOString();
            var age = moment().startOf('day').diff(birthday, 'years');
            vm.isMinor = (age < 18);
            vm.aosProfile.isMarried = 0;
        }

        function onCancel() {
            $state.go('vmis', null, { reload: true });
        }

        function onChangeDisposition(disposition) {
            vm.dispositionsSelected += disposition.selected ? 1 : -1;
        }

        function completeNonUserCreation(aosUser) {
            // Non-user-specific check
            if (vm.nonUserEmail && (vm.nonUserEmail !== aosUser.vmisUser.volunteerProfile.volunteerEmail)) {
                vm.formError = "Volunteer email must match master user email for non-users.";
                vm.isLoading = false;
                return;
            }

            var params = {
                aosUser: aosUser,
                email: vm.nonUserEmail
            };

            authentication.setNonUser(params)
                .then(function () {
                    // Inform user of success with modal
                    var options = {
                        templateUrl: 'app/components/profile/infoModal.view.html',
                        controller: 'InfoModalController',
                        controllerAs: 'vm',
                        size: 'tiny',
                        backdrop: 'static',
                        keyboard: false,
                        resolve: {
                            title: function () {
                                return "User Successfully Created";
                            },
                            firstName: function () {
                                return params.aosUser.firstName;
                            },
                            lastName: function () {
                                return params.aosUser.lastName;
                            },
                            email: function () {
                                return params.email;
                            },
                            error: function () {
                                return null;
                            }
                        }
                    };

                    $modal.open(options)
                        .result.finally(function () {
                            infoModalResponse();
                        });
                })
                .catch(function (error) {
                    // Inform user of failure with modal
                    var options = {
                        templateUrl: 'app/components/profile/infoModal.view.html',
                        controller: 'InfoModalController',
                        controllerAs: 'vm',
                        size: 'tiny',
                        backdrop: 'static',
                        keyboard: false,
                        resolve: {
                            title: function () {
                                return "User Creation Failed";
                            },
                            firstName: function () {
                                return params.aosUser.firstName;
                            },
                            lastName: function () {
                                return params.aosUser.lastName;
                            },
                            email: function () {
                                return params.email;
                            },
                            error: function () {
                                return error;
                            }
                        }
                    };

                    $modal.open(options)
                        .result.finally(function () {
                            vm.formError = error;
                            vm.isLoading = false;
                        });
                });

            function infoModalResponse() {
                // Go to another page
                if ($stateParams['return-to'])
                    redirect.returnTo($stateParams['return-to']);
                else
                    $window.history.back();

                vm.isLoading = false;
            }
        }
    }
})();