(function () {
    'use strict';

    angular
        .module('app.vmis')
        .controller('OpocDetailsController', OpocDetailsController);

    OpocDetailsController.$inject = ['$q', 'authentication', 'opocs'];

    function OpocDetailsController($q, authentication, opocs) {
        var vm = this;

        activate();

        function activate() {
            getProfile();
        }

        function getProfile() {
            var promises = opocs.map(function (opoc) {
                return authentication.getUserProfile({
                    masterUserId: opoc.masterUserId
                });
            });
            return $q.all(promises).then(function (responses) {
                vm.opocs = responses.map(function (response) {
                    var profile = response.aosUser;
                    var adminProfile = response.aosUser.vmisUser.adminProfile;
                    return {
                        name: profile.firstName + ' ' + profile.lastName,
                        phone: adminProfile.commercialWorkPhone,
                        dsnPhone: adminProfile.dsnPhone ? adminProfile.dsnPhone : 'N/A',
                        email: adminProfile.workEmail
                    };
                });
            });
        }
    }
})();