(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .config(routes);

    routes.$inject = ['$stateProvider'];

    function routes($stateProvider) {
        $stateProvider
            .state('vmis.volunteer.avccLocator', {
                url: '/avcc-locator',
                title: 'AVCC Locator',
                views: {
                    "@vmis.volunteer": {
                        templateUrl: 'app/components/volunteer/avccLocator/avccLocator.view.html',
                        controller: 'AvccLocatorController',
                        controllerAs: 'vm'
                    },
                    "avccList@vmis.volunteer.avccLocator": {
                        templateUrl: 'app/components/volunteer/avccLocator/avccList/avccList.view.html',
                        controller: 'AvccListController',
                        controllerAs: 'vm'
                    }
                }
            });
    }
})();