(function () {
    'use strict';

    angular
        .module('app')
        .directive('passwordValidator', PasswordValidator);

    PasswordValidator.$inject = ['config'];

    // Provide validators for each aspect of a password:
    // Min 15 characters
    // 2 uppercase letters
    // 2 lowercase letters
    // 2 numbers
    // 2 special characters
    // 0 spaces

    // Example: https://docs.angularjs.org/guide/forms - "Custom Validation"
    function PasswordValidator(config) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, elm, attrs, ctrl) {
                return config.get().then(function (configData) {
                    var lengthRegex = RegExp(configData.passwordRequirements.requirementChecks.lengthRegex);
                    var upperRegex = RegExp(configData.passwordRequirements.requirementChecks.upperRegex);
                    var lowerRegex = RegExp(configData.passwordRequirements.requirementChecks.lowerRegex);
                    var numRegex = RegExp(configData.passwordRequirements.requirementChecks.numRegex);
                    var specialRegex = RegExp(configData.passwordRequirements.requirementChecks.specialRegex);
                    var spaceRegex = RegExp(configData.passwordRequirements.requirementChecks.spaceRegex);

                    ctrl.$validators.passwordLength = function(modelValue, viewValue) {
                        return lengthRegex.test(modelValue);
                    };
                    ctrl.$validators.passwordUpper = function(modelValue, viewValue) {
                        return upperRegex.test(modelValue);
                    };
                    ctrl.$validators.passwordLower = function(modelValue, viewValue) {
                        return lowerRegex.test(modelValue);
                    };
                    ctrl.$validators.passwordNum = function(modelValue, viewValue) {
                        return numRegex.test(modelValue);
                    };
                    ctrl.$validators.passwordSpecial = function(modelValue, viewValue) {
                        return specialRegex.test(modelValue);
                    };
                    ctrl.$validators.passwordSpace = function(modelValue, viewValue) {
                        // When empty, it technically doesn't have spaces but we want to
                        // keep the UI element red.
                        if (ctrl.$isEmpty(modelValue)) return false;

                        return spaceRegex.test(modelValue);
                    };
                });
            }
        };
    }
})();