(function () {
    'use strict';

    angular
        .module('app.vmis', [
            'angularMoment', // angular-moment, requires moment
            'chart.js', // angular-chart.js, requires chart.js

            'vmis.activityCalendar',
            'app.auth',

            'vmis.administrator',
            'vmis.volunteer',

            'app.hoursEntryFormatter',
            'app.learnAboutThisPage',
            'app.lite'
        ]);
})();