(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .controller('TrainingDeleteController', TrainingDeleteController);

    TrainingDeleteController.$inject = ['$modalInstance', 'authentication', 'training'];

    function TrainingDeleteController($modalInstance, authentication, training) {
        var vm = this;

        vm.training = training;

        vm.onClickDelete = onClickDelete;

        function onClickDelete() {
            vm.processing = true;

            var params = {
                volunteerTrainingIds: [vm.training.id]
            };

            return authentication.deleteVolunteerTrainings(params)
                .then($modalInstance.close)
                .catch($modalInstance.dismiss);
        }
    }
})();