(function () {
    'use strict';

    angular
        .module('app.auth')
        .factory('loadStatus', loadStatus);

    loadStatus.$inject = ['config', 'lite'];

    // TODO: This service is not used to its fullest extent
    // It could be expanded to handle a number of situations that are currently
    // addressed repeatedly in an individual manner throughout the app.
    // Reset, for example, could be modified to handle the many other
    // reset-like behaviors, and then applied everywhere.
    // Additionally, this service holds the defaultNumRows value from the config,
    // so it could serve as the reference point for that to cut down on config.get()s.
    function loadStatus(config, lite) {
        return {
            init: init,
            setup: setup,
            reset: reset
        };

        function init() {
            return {
                currentIndex: 0,
                numResultsNotLoaded: 0,
                nextResultLoadNum: null,
                defaultNumRows: 0,
                maxLoadAll: 0
            };
        }

        // One loadStatus per object type
        function setup(loadStatusObj, type) {
            return config.get().then(function (configData) {
                loadStatusObj.defaultNumRows = lite.getNumRows(
                    configData.defaultNumRows[type], configData.liteModeReductionFactor);
                loadStatusObj.nextResultLoadNum = loadStatusObj.defaultNumRows;
                loadStatusObj.maxLoadAll = configData.defaultNumRows.maxLoadAll;
            }).catch(function (error) {
                console.error(error);
            });
        }

        function reset(loadStatusObj) {
            loadStatusObj.currentIndex = 0;
            loadStatusObj.numResultsNotLoaded = 0;
            loadStatusObj.nextResultLoadNum = loadStatusObj.defaultNumRows;
        }
    }
})();
