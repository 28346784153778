(function () {
    'use strict';

    angular
        .module('app.auth')
        .factory('records', records);

    records.$inject = ['authentication'];

    function records(authentication) {

        var myMasterUserId = null;

        activate();

        return {
            getMyMasterUserId: getMyMasterUserId
        };

        function activate() {
            return authentication.getUserProfile()
                .then(function (response) {
                    if (response.masterUserId) {
                        myMasterUserId = response.masterUserId;
                    }
                });
        }

        /**
         * Returns user's own masterUserId, if it's obtained on controller load using this service, above.
         * Otherwise null.
         * @returns myMasterUserId user's own masterUserId, unless null
         */
        function getMyMasterUserId() {
            return myMasterUserId;
        }
    }
})();