(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .controller('AvccListController', AvccListController);

    AvccListController.$inject = ['$q', '$scope', 'authentication', 'loadStatus'];

    function AvccListController($q, $scope, authentication, loadStatus) {
        var vm = this;

        vm.isLoading = true;

        vm.coordinators = [];
        vm.loadStatus = loadStatus.init();

        vm.onLoadMore = onLoadMore;
        vm.onLoadAll = onLoadAll;
        vm.sortColumn = "lastName";
        vm.sortReverse = false;

        // If not logged in, there will be no
        // filter update event to do this at start.
        if (!authentication.isLoggedIn())
            vm.isLoading = false;

        $scope.$on('avccLocatorFilters:update', onFiltersUpdate);

        activate();

        function activate() {
            loadStatus.setup(vm.loadStatus, "users");
        }

        function onFiltersUpdate(event, filters) {
            vm.coordinators = [];
            vm.isLoading = true;
            vm.currentCommunity = filters.communityId;

            // Reset load status object
            vm.loadStatus.currentIndex = 0;
            vm.loadStatus.nextResultLoadNum = vm.loadStatus.defaultNumRows;
            return getCoordinators(filters.communityId, vm.loadStatus.currentIndex, vm.loadStatus.nextResultLoadNum)
                .then(function (coordinators) {
                    vm.isLoading = false;
                    vm.coordinators = coordinators;
                    $scope.$emit('avccLocatorList:update');
                });
        }

        function getCoordinators(communityId, index, num) {
            return communityId ? authentication.getAvccsForCommunity({
                communityAffiliationId: communityId,
                startingRowIndex: index,
                numberOfRows: num
            }).then(function (response) {
                return getCoordinatorsSuccess(response, index, num);
            }).catch(function (error) {
                getCoordinatorsError(error);
            }) : $q.resolve([]);

        }

        function getCoordinatorsSuccess(response, index, num) {
            if (index === 0) {
                vm.loadStatus.currentIndex = 0;
            }

            for (var i = 0; i < response.avccs.length; i++) {
                // Create secret field for filtering on first and last name simultaneously
                response.avccs[i].fullName = response.avccs[i].firstName + " " + response.avccs[i].lastName;
                // Add user to full list
                vm.coordinators.push(response.avccs[i]);
            }

            // Either set current index to max if all users loaded or increment by rows loaded
            vm.loadStatus.currentIndex = (vm.loadStatus.currentIndex + num > response.count) ? response.count : (vm.loadStatus.currentIndex + num);
            // Track total number of users not loaded for "Load All" button
            vm.loadStatus.numResultsNotLoaded = response.count - vm.loadStatus.currentIndex;
            // Set text for "Load More" button
            vm.loadStatus.nextResultLoadNum = (response.count === vm.loadStatus.currentIndex) ? 0 :
                (vm.loadStatus.numResultsNotLoaded > num) ? num : vm.loadStatus.numResultsNotLoaded;

            vm.isLoading = false;
            return response.avccs;
        }

        function getCoordinatorsError(error) {
            if (angular.isDefined(error)) {
                var errorStr = error.error ? error.error : error;
                $scope.$emit('avccLocator:alert', errorStr);
            }
            else
                $scope.$emit('avccLocator:alert', "Failed to load list! Please try again.");

            vm.isLoading = false;
        }

        function onLoadMore() {
            getCoordinators(vm.currentCommunity, vm.loadStatus.currentIndex, vm.loadStatus.nextResultLoadNum);
        }

        function onLoadAll() {
            getCoordinators(vm.currentCommunity, vm.loadStatus.currentIndex, vm.loadStatus.numResultsNotLoaded);
        }
    }
})();