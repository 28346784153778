(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .config(routes);

    routes.$inject = ['$stateProvider'];

    function routes($stateProvider) {
        $stateProvider
            .state('vmis.administrator.standardOrganizations', {
                url: '/standard-organizations',
                templateUrl: 'app/components/administrator/standardOrganizations/standardOrganizations.view.html',
                controller: 'StandardOrganizationsController',
                controllerAs: 'vm',
                title: 'Standard Organizations'
            });
    }
})();