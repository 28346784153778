(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .controller('VolunteerApplicationsController', VolunteerApplicationsController);

    VolunteerApplicationsController.$inject = ['$state', 'authentication', 'loadStatus', '$scope'];

    function VolunteerApplicationsController($state, authentication, loadStatus, $scope) {
        var vm = this;

        vm.isLoading = true;

        vm.activities = [];
        vm.applicationId = -1;
        vm.sortReverse = true;
        vm.sortColumn = 'submittedDate';
        vm.statusTypes = [
            'All',
            'Submitted',
            'Approved',
            'Declined'
        ];
        vm.status = vm.statusTypes[0];
        vm.applications = [];
        vm.loadStatus = loadStatus.init();

        vm.onClickApplication = onClickApplication;
        vm.onChangeStatus = onChangeStatus;
        vm.onLoadMore = onLoadMore;
        vm.onLoadAll = onLoadAll;

        activate();

        function activate() {
            loadStatus.setup(vm.loadStatus, "applications")
                .then(function () {
                    loadApplications(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows, vm.status);
                });
        }

        function loadApplications(index, num, status) {
            vm.isLoading = true;

            var params = {
                startingRowIndex: index,
                numberOfRows: num
            };
            if (status !== "All")
                params.volunteerApplicationStatus = status;

            authentication.getOpportunitiesAppliedFor(params)
                .then(function (response) {
                    getApplicationsSuccess(response, index, num);
                }).catch(function (error) {
                    getApplicationsError(error);
                });
        }

        function onClickApplication(application) {
            var params = {
                opportunityId: application.opportunityId,
                applicationId: application.applicationId,
                communityVolunteerOrganizationApplicationId: application.communityVolunteerOrganizationApplicationId
            };
            $state.go('vmis.volunteer.applications.details', params);
        }

        function onChangeStatus(status) {
            loadApplications(0, vm.loadStatus.defaultNumRows, status);
        }

        function getApplicationsSuccess(response, index, num) {
            if (index === 0) {
                vm.loadStatus.currentIndex = 0;
                vm.applications = [];
            }

            // Either set current index to max if all users loaded or increment by rows loaded
            vm.loadStatus.currentIndex = (vm.loadStatus.currentIndex + num > response.count) ? response.count : (vm.loadStatus.currentIndex + num);
            // Track total number of users not loaded for "Load All" button
            vm.loadStatus.numResultsNotLoaded = response.count - vm.loadStatus.currentIndex;
            // Set text for "Load More" button
            vm.loadStatus.nextResultLoadNum = (response.count === vm.loadStatus.currentIndex) ? 0 :
                (vm.loadStatus.numResultsNotLoaded > num) ? num : vm.loadStatus.numResultsNotLoaded;

            vm.applications = vm.applications.concat(response.opportunities);
            vm.isLoading = false;
        }

        function getApplicationsError(error) {
            if (angular.isDefined(error)) {
                var errorStr = error.error ? error.error : error;
                $scope.$emit('applications:alert', errorStr);
            }
            else
                $scope.$emit('applications:alert', "Failed to load list! Please try again.");

            vm.isLoading = false;
        }

        function onLoadMore() {
            loadApplications(vm.loadStatus.currentIndex, vm.loadStatus.nextResultLoadNum, vm.volunteerApplicationStatus);
        }

        function onLoadAll() {
            loadApplications(vm.loadStatus.currentIndex, vm.loadStatus.numResultsNotLoaded, vm.volunteerApplicationStatus);
        }
    }
})();
