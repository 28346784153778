(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .factory('opportunitiesService', opportunitiesService);

    opportunitiesService.$inject = ['$q', 'authentication'];

    function opportunitiesService($q, authentication) {
        return {
            isLoggedIn: isLoggedIn,
            getOpportunities: getOpportunities,
            getOpportunityDetails: getOpportunityDetails,
            applyForOpportunity: applyForOpportunity,
            getDropDownData: getDropDownData,
            getUserProfile: getUserProfile,
            setVolunteerProfile: setVolunteerProfile,
            getCurrentUser: getCurrentUser
        };

        function isLoggedIn() {
            return authentication.isLoggedIn();
        }

        function getOpportunities(startIndex, length, postalCode, state, country, community, organizationGroup, organization, title) {
            var request = {
                startingRowIndex: startIndex,
                numberOfRows: length
            };

            var isAdmin = false;
            var promiseArray = [];

            if (isLoggedIn()) {
                promiseArray.push(authentication.getUserProfile()
                    .then(function (response) {
                        if (response.aosUser && response.aosUser.vmisUser && response.aosUser.vmisUser.adminProfile)
                            isAdmin = !!response.aosUser.vmisUser.adminProfile;
                    })
                    .catch(function (error) {
                        return $q.reject(error);
                    }));
            }

            return $q.all(promiseArray)
                .then(function () {
                    if (isAdmin) request.volunteerPositionStatus = "Open, searchable";
                    if (postalCode) request.postalCode = postalCode;
                    if (title) request.title = title;
                    if (state) request.usstate = state;
                    if (country) request.country = country;
                    if (community) request.communityAffiliation = community;
                    if (organization) request.volunteerOrganizationId = organization;
                    if (organizationGroup) request.volunteerOrganizationGroup = organizationGroup;

                    return authentication
                        .getOpportunities(request)
                        .then(function (response) {
                            for (var i in response.opportunities) {
                                var opp = response.opportunities[i];
                                if (opp.usState !== undefined) {
                                    opp.region = opp.usState + ", " + opp.country;
                                }
                                else {
                                    opp.region = opp.country;
                                }
                                if(!opp.userHasApplied){
                                    opp.applicationStatus = "None";
                                }
                                else {
                                    opp.applicationStatus = opp.volunteerApplications[0].volunteerApplicationStatus;
                                }
                            }
                            return {
                                opportunities: response.opportunities,
                                count: response.count
                            };
                        })
                        .catch(function (error) {
                            return $q.reject(error);
                        });
                })
                .catch(function (error) {
                    return $q.reject(error);
                });
        }

        function getOpportunityDetails(id) {
            var request = {
                volunteerOpportunityId: id
            };

            return authentication
                .getOpportunityDetails(request)
                .then(function (response) {
                    return response;
                })
                .catch(function (error) {
                    return $q.reject(error);
                });
        }

        function applyForOpportunity(id) {
            var request = {
                volunteerOpportunityId: id
            };

            return authentication
                .applyForOpportunity(request)
                .then(function (response) {
                    return response;
                });
        }

        function getDropDownData(dropDowns) {
            return authentication
                .getUiData(dropDowns)
                .then(function (response) {
                    return response;
                })
                .catch(function (error) {
                    return $q.reject(error);
                });
        }
        
        function getCommunities() {
            return authentication.
            getCommunities({getAll:true})
                .then(function (response){
                    return response;
                })
                .catch(function(error){
                    return $q.reject(error);
                });
        }

        function getUserProfile() {
            return authentication
                .getUserProfile()
                .then(function (response) {
                    return response;
                })
                .catch(function (error) {
                    return $q.reject(error);
                });
        }

        function setVolunteerProfile(profile) {
            var request = {
                vmisUser: {
                    volunteerProfile: profile
                }
            };

            return authentication
                .setUserProfile(request)
                .then(function (response) {
                    return response;
                })
                .catch(function (error) {
                    return $q.reject(error);
                });
        }
        
        function getCurrentUser() {
            return authentication.currentUser().email;
        }
    }
})();