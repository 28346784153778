(function () {
    'use strict';

    angular
        .module('app.learnAboutThisPage')
        .controller('LearnAboutThisPageController', LearnAboutThisPageController);

    LearnAboutThisPageController.$inject = ['$scope', '$modal'];

    function LearnAboutThisPageController($scope, $modal) {
        var vmWidget = this;

        vmWidget.learnTitle = $scope.learnTitle;
        vmWidget.textBody = $scope.textBody;

        vmWidget.openModal = openModal;

        function openModal() {
            var modalInstance = $modal.open({
                templateUrl: 'app/common/directives/learnAboutThisPage/learnAboutModal.template.html',
                controller: 'LearnAboutModalController',
                controllerAs: 'vmWidgetModal',
                backdrop: 'static',
                keyboard: false
            });
            modalInstance.learnTitle = vmWidget.learnTitle;
            modalInstance.textBody = vmWidget.textBody;
            // modalInstance.result
            // To Do: add error catching

        }
    }
})();