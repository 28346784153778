(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('VolunteersController', VolunteersController);

    VolunteersController.$inject = ['$q', '$modal', '$state', '$window', '$scope', '$location', '$timeout', 'authentication', 'loadStatus', 'params', 'moment', '_'];

    function VolunteersController($q, $modal, $state, $window, $scope, $location, $timeout, authentication, loadStatus, params, moment, _) {
        var vm = this;

        vm.isLoading = true;
        vm.roleObjectList = angular.copy(params.roleObjectList);
        vm.responsibilities = angular.copy(params.responsibilities);
        vm.includeFloatingUsers = false;
        vm.isFloatingUsersEnabled = false;

        vm.excludeNonAppliedVolunteers = true;

        // Ideally should compare with roles object in profile, but only passed in responsibilities.
        var isOPOC = angular.isDefined(vm.responsibilities.opoc);
        var isAVCCAssistant = angular.isDefined(vm.responsibilities.avccAssistant);
        vm.isAVCC = angular.isDefined(vm.responsibilities.avcc);
        // If viewing volunteers and not AVCC/Assistant, only show users related to organizations responsible for
        var isOnlyOPOC = isOPOC && !isAVCCAssistant && !vm.isAVCC;
        vm.isOnlyAVCCAssistant = !isOPOC && isAVCCAssistant && !vm.isAVCC;

        vm.selectedRoleObject = vm.roleObjectList.filter(function (role) {
            return role.name === "Volunteer";
        })[0];
        vm.numUsersSelected = 0;
        vm.searchFilter = {
            fullName: $location.search().name || ''
        };
        vm.isExporting = false;

        vm.filter = {
            selectedCommunity: _.parseInt($location.search().community) || undefined,
            selectedOrganizations: $location.search().organization || undefined,
            lastName: $location.search().lastName || '',
            firstName: $location.search().firstName || '',
            isActive: $location.search().isActive ? $location.search().isActive !== 'false' : undefined,
            startDate: isNaN(new Date($location.search().startDate)) ? undefined : new Date($location.search().startDate),
            endDate: isNaN(new Date($location.search().endDate)) ? undefined : new Date($location.search().endDate),
            floatingUsers: $location.search().floatingUsers || '',
            volunteerEmail: $location.search().volunteerEmail || undefined,
            excludeNonAppliedVolunteers: $location.search().excludeNonAppliedVolunteers || '',
            startingRowIndex: _.parseInt($location.search().startingRowIndex) || undefined,
            numberOfRows: _.parseInt($location.search().numberOfRows) || undefined
        };

        if(!vm.filter.startingRowIndex || !vm.filter.numberOfRows){
            vm.filter.startingRowIndex=undefined;
            vm.filter.numberOfRows=undefined;
        }

        vm.isCustomPaging = angular.isDefined(vm.filter.startingRowIndex) && angular.isDefined(vm.filter.numberOfRows) && vm.filter.startingRowIndex !== null && vm.filter.numberOfRows !== null;

        if(vm.filter.selectedOrganizations !== undefined){
            if(Array.isArray(vm.filter.selectedOrganizations)){
                vm.filter.selectedOrganizations.forEach(function(value,index){
                    vm.filter.selectedOrganizations[index] = _.parseInt(value);
                });
            }
            else {
                vm.filter.selectedOrganizations = [_.parseInt(vm.filter.selectedOrganizations)];
            }
        }

        if(!vm.isCustomPaging) {
            vm.loadStatus = loadStatus.init();
        }
        else {
            vm.totalResults = 0;
        }

        vm.sortColumn = 'aosUser.lastName';
        vm.sortReverse = false;

        vm.usersWithRole = [];

        vm.onSelectRole = onSelectRole;
        vm.onClickFilterUsers = onClickFilterUsers;
        vm.onClickSearch = onClickSearch;
        vm.onClickAddNewNonUser = onClickAddNewNonUser;
        vm.onCheckAll = onCheckAll;
        vm.onCheckUser = onCheckUser;
        vm.onClickEditVol = onClickEditVol;
        vm.onLoadMore = onLoadMore;
        vm.onLoadAll = onLoadAll;
        vm.massActionDisabled = massActionDisabled;
        vm.onCheckIncludeFloatingUsers = onCheckIncludeFloatingUsers;
        vm.onCheckExcludeNonAppliedVolunteers = onCheckExcludeAppliedVolunteers;

        activate();

        function activate() {
            determineStateOfFloatingUserCheckBox();
            determineStateOfExcludeNonAppliedVolunteers ();

            vm.massActions = [
                {
                    name: "Export",
                    action: onExport
                },
                {
                    name: "Mass Email",
                    action: onMassEmail
                }
            ];

            if(!vm.isCustomPaging) {
                loadStatus.setup(vm.loadStatus, "users")
                    .then(function () {
                        fillCommunities();
                    });
            }

            else {
                fillCommunities();
            }
        }

        function determineStateOfFloatingUserCheckBox() {
            // if we're only an opoc, then you will always see floating volunteers
            // because you're filtering on organization, not community
            if (isOnlyOPOC) {
                vm.includeFloatingUsers = true;
                vm.isFloatingUsersEnabled = false;
            }
            else {
                if (vm.filter.selectedOrganizations !== null && vm.filter.selectedOrganizations !== undefined) {
                    vm.includeFloatingUsers = true;
                    vm.isFloatingUsersEnabled = false;
                }
                else {
                    vm.isFloatingUsersEnabled = true;
                    vm.includeFloatingUsers = vm.filter.floatingUsers === 'true';
                }
            }
        }

        function onCheckIncludeFloatingUsers() {
            if (vm.includeFloatingUsers === true) {
                vm.filter.floatingUsers = 'true';
            }
            else {
                vm.filter.floatingUsers = '';
            }


            $location.search('floatingUsers', vm.filter.floatingUsers || null);


            if(!vm.isCustomPaging) {
                vm.loadStatus.currentIndex = 0;
                getUsers(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows);
            }
            else {
                getUsers(vm.filter.startingRowIndex, vm.filter.numberOfRows);
            }
        }

        function determineStateOfExcludeNonAppliedVolunteers () {
            vm.excludeNonAppliedVolunteers = vm.filter.excludeNonAppliedVolunteers !== 'false';

            if(!(vm.filter.excludeNonAppliedVolunteers === 'false' || vm.filter.excludeNonAppliedVolunteers === 'true')){
                vm.filter.excludeNonAppliedVolunteers = '';
                $location.search('excludeNonAppliedVolunteers', null);
            }
        }

        function onCheckExcludeAppliedVolunteers() {
            if(vm.excludeNonAppliedVolunteers){
                vm.filter.excludeNonAppliedVolunteers = 'true';
            }

            else {
                vm.filter.excludeNonAppliedVolunteers = 'false';
            }

            $location.search('excludeNonAppliedVolunteers', vm.filter.excludeNonAppliedVolunteers || null);


            if(!vm.isCustomPaging) {
                vm.loadStatus.currentIndex = 0;
                loadStatus.setup(vm.loadStatus, "users")
                    .then(function () {
                        getUsers(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows);
                    });
            }
            else {
                getUsers(vm.filter.startingRowIndex, vm.filter.numberOfRows);
            }
        }

        function communityAlreadyExistsInArray(array,community){
            var isFound = false;
            array.forEach(function(item){
                if(item.communityAffiliationId === community.communityAffiliationId){
                    isFound = true;
                }
            });
            return isFound;
        }

        function fillCommunities() {
            vm.communities = [];
            var promiseArray = [];
            if (!isOnlyOPOC) {
                if (vm.isAVCC) {
                    vm.communities = vm.responsibilities.avcc;
                    // If AVCC, communities specified but need organizations arrays
                    vm.communities.forEach(function (comm) {
                        // Empty array is required for filter modal to add orgs when needed
                        comm.organizations = [];
                    });
                    if (isOPOC) {
                        vm.responsibilities.opoc.forEach(function (comm) {
                            comm.organizations.forEach(function (org) {
                                org.organizationName = org.volunteerOrganizationName;
                            });
                            if(!communityAlreadyExistsInArray(vm.communities,comm)){
                                vm.communities.push(comm);
                            }
                        });
                    }
                }
                else if (isAVCCAssistant) {
                    vm.communities = vm.responsibilities.avccAssistant;
                    // If AVCC Assistant, communities specified but need organizations arrays
                    vm.communities.forEach(function (comm) {
                        // Empty array is required for filter modal to add orgs when needed
                        comm.organizations = [];
                    });
                    if (isOPOC) {
                        vm.responsibilities.opoc.forEach(function (comm) {
                            comm.organizations.forEach(function (org) {
                                org.organizationName = org.volunteerOrganizationName;
                            });
                            if(!communityAlreadyExistsInArray(vm.communities,comm)){
                                vm.communities.push(comm);
                            }
                        });
                    }
                }
            }
            else {
                vm.includeFloatingUsers = true;
                vm.isFloatingUsersEnabled = false;

                vm.communities = vm.responsibilities.opoc;

                // Not calling fillResponsibilities, so initialize "All" entry
                vm.communities.forEach(function (comm) {
                    // Responsibilities object uses different field name than getOrganizations API call
                    comm.organizations.forEach(function (org) {
                        org.organizationName = org.volunteerOrganizationName;
                    });
                    comm.organizations.unshift({
                        volunteerOrganizationId: -1,
                        organizationName: "All"
                    });
                });
            }

            return $q.all(promiseArray).then(function () {
                vm.communities.unshift({
                    communityAffiliationId: -1,
                    communityAffiliationName: "All",
                    organizations: [{
                        volunteerOrganizationId: -1,
                        organizationName: "All"
                    }]
                });
                vm.isLoading = false;
            }).catch(function (error) {
                $scope.$emit('people:alert', error);
                vm.isLoading = false;
            });
        }

        function getUsers(index, num) {
            vm.isLoading = true;

            var allCommunities = !vm.filter.selectedCommunity;
            var allOrganizations = !vm.filter.selectedOrganizations;

            updateCheckAllAndExport();

            var paramsGetUsers = {
                isRegUser: true,
                isNonUser: true,
                startingRowIndex: index,
                numberOfRows: num,
                isActive: vm.filter.isActive,
                firstName: vm.filter.firstName ? vm.filter.firstName : undefined,
                lastName: vm.filter.lastName ? vm.filter.lastName : undefined,
                startDate: vm.filter.startDate ? moment(vm.filter.startDate).startOf('day').toISOString() : undefined,
                endDate: vm.filter.endDate ? moment(vm.filter.endDate).endOf('day').toISOString() : undefined,
                includeFloatingVolunteers: vm.includeFloatingUsers,
                volunteerEmail: vm.filter.volunteerEmail ? vm.filter.volunteerEmail : undefined,
                excludeVolunteersWithoutApplications: vm.excludeNonAppliedVolunteers
            };

            if (!allCommunities) {
                paramsGetUsers.communityAffiliationIds = [vm.filter.selectedCommunity];
            }

            // If specific organization filter is selected, include in parameters
            if (!allOrganizations) {
                paramsGetUsers.volunteerOrganizationIds = vm.filter.selectedOrganizations;
                paramsGetUsers.communityAffiliationIds = undefined;
            }

            // Make call for these communities and/or organizations with parameters
            authentication.getVolunteersAdmin(paramsGetUsers)
                .then(function (response) {
                    getUsersSuccess(response, index, num);
                })
                .catch(function (error) {
                    getUsersError(error);
                });

            function getUsersSuccess(response, index, num) {
                if (index === 0 && !vm.isCustomPaging) {
                    vm.usersWithRole = [];
                    vm.loadStatus.currentIndex = 0;
                }

                if(vm.isCustomPaging){
                    vm.usersWithRole=[];
                    vm.totalResults = response.count;
                }

                for (var i = 0; i < response.users.length; i++) {
                    // If current user is AVCC, any variables set here are ignored in view.
                    if (isOPOC) {
                        if (isAVCCAssistant) {
                            // If the current user is OPOC + AVCC Assistant and the target user is non-user,
                            // first check if the user has any activity. If not, they are an inactive non-user,
                            // which is a special case with fewer drop downs. It is also known then that the user
                            // is visible through the OPOC role. If the non-user has activity,
                            // check for a match between the target user's activity and the OPOC responsibilities.
                            // If no match, the user is visible through the AVCC Assistant role; otherwise,
                            // the OPOC role. These situations will have different drop down options.
                            if (response.users[i].isNonUser) {
                                response.users[i].isInactiveNonUser =
                                    (response.users[i].activePositionOrganizations.length === 0);

                                response.users[i].fromAvccAssistPurview = response.users[i].isInactiveNonUser ?
                                    false : !isUserInNormalOPOCPurview(response.users[i]);
                            }
                            // If the current user is OPOC + AVCC Assistant and the target user is normal,
                            // check for a match between the target user's activity and the OPOC responsibilities.
                            // If no match, the user is visible through the AVCC Assistant role; otherwise,
                            // the OPOC role. These situations will have different drop down options.
                            else
                                response.users[i].fromAvccAssistPurview = !isUserInNormalOPOCPurview(response.users[i]);
                        }
                        // If current user is OPOC and target user is non-user, check for a match between
                        // the target user's activity and the OPOC responsibilities. If no match,
                        // the target user is a special case with fewer drop down options.
                        else if (response.users[i].isNonUser) {
                            response.users[i].isInactiveNonUser = !isUserInNormalOPOCPurview(response.users[i]);
                        }
                    }

                    response.users[i].latestHoursActivityDate = response.users[i].latestHoursActivityDate || "";

                    // Create unique ID for user checkbox
                    // TODO: for volunteer lists especially this may not be unique enough (add email?)
                    response.users[i].checkboxId = "check-" + response.users[i].aosUser.lastName + response.users[i].aosUser.firstName;

                    // Add user to full list
                    vm.usersWithRole.push(response.users[i]);
                }

                updateCheckAllAndExport();

                if(!vm.isCustomPaging) {
                    // Either set current index to max if all users loaded or increment by rows loaded
                    vm.loadStatus.currentIndex = (vm.loadStatus.currentIndex + num > response.count) ?
                        response.count : (vm.loadStatus.currentIndex + num);
                    // Track total number of users not loaded for "Load All" button
                    vm.loadStatus.numResultsNotLoaded = response.count - vm.loadStatus.currentIndex;
                    // Set text for "Load More" button
                    vm.loadStatus.nextResultLoadNum = (response.count === vm.loadStatus.currentIndex) ? 0 :
                        (vm.loadStatus.numResultsNotLoaded > num) ? num : vm.loadStatus.numResultsNotLoaded;
                }

                $timeout(function () {
                    vm.isLoading = false;
                }, 100);

                function isUserInNormalOPOCPurview(user) {
                    // For each active position the user is in
                    for (var p = 0; p < user.activePositionOrganizations.length; p++) {
                        // For each community OPOC has responsibilities in
                        for (var c = 0; c < vm.responsibilities.opoc.length; c++) {
                            // For each organization in responsible community
                            for (var o = 0; o < vm.responsibilities.opoc[c].organizations.length; o++) {
                                // Check if non-user's activity matches responsibility
                                if (user.activePositionOrganizations[p] ===
                                    vm.responsibilities.opoc[c].organizations[o].volunteerOrganizationId)
                                    return true;
                            }
                        }
                    }
                    return false;
                }
            }

            function getUsersError(error) {
                if (angular.isDefined(error)) {
                    var errorStr = error.error ? error.error : error;
                    $scope.$emit('people:alert', errorStr);
                }
                else
                    $scope.$emit('people:alert', "Failed to load list! Please try again.");

                $timeout(function () {
                    vm.isLoading = false;
                }, 100);
            }
        }

        function onSelectRole() {
            $scope.$emit('people:role', vm.selectedRoleObject);
        }

        function onClickFilterUsers() {
            var modalInstance = $modal.open({
                templateUrl: 'app/components/administrator/people/actionDirectives/orgCommFilter.view.html',
                controller: 'OrgCommFilterController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    params: function () {
                        return {
                            communities: vm.communities,
                            selectedCommunity: vm.filter.selectedCommunity,
                            selectedOrganizations: vm.filter.selectedOrganizations,
                            isActive: vm.filter.isActive,
                            startDate: vm.filter.startDate || null,
                            endDate: vm.filter.endDate || null,
                            role: "Volunteer",
                            volunteerEmail: vm.filter.volunteerEmail || null,
                            startingRowIndex: vm.filter.startingRowIndex || null,
                            numberOfRows: vm.filter.numberOfRows || null
                        };
                    }
                }
            });

            modalInstance.result
                .then(function (result) {
                    vm.isLoading = true;

                    switch (result.action) {
                        case 'success':
                            // Apply results from filter modal
                            vm.filter.selectedCommunity = result.filter.selectedCommunity.communityAffiliationId !== -1 ?
                                result.filter.selectedCommunity.communityAffiliationId : null;
                            if(result.filter.selectedOrganizations.length === 0){
                                vm.filter.selectedOrganizations = null;
                            }
                            else {
                                vm.filter.selectedOrganizations = [];
                                result.filter.selectedOrganizations.forEach(function(selectedOrg){
                                   vm.org = selectedOrg.volunteerOrganizationId !== -1 ? vm.filter.selectedOrganizations.push(selectedOrg.volunteerOrganizationId):null;
                                });
                            }

                            vm.filter.isActive = result.filter.isActive.value;
                            vm.filter.startDate = result.filter.startDate;
                            vm.filter.endDate = result.filter.endDate;
                            vm.filter.volunteerEmail = result.filter.volunteerEmail;
                            vm.filter.startingRowIndex = result.filter.startingRowIndex;
                            vm.filter.numberOfRows = result.filter.numberOfRows;

                            $location.search({
                                community: vm.filter.selectedCommunity,
                                organization: vm.filter.selectedOrganizations,
                                isActive: vm.filter.isActive,
                                firstName: vm.filter.firstName || null,
                                lastName: vm.filter.lastName || null,
                                startDate: vm.filter.startDate || null,
                                endDate: vm.filter.endDate || null,
                                name: vm.searchFilter.fullName || null,
                                floatingUsers: vm.filter.floatingUsers || null,
                                volunteerEmail: vm.filter.volunteerEmail || null,
                                excludeNonAppliedVolunteers: vm.filter.excludeNonAppliedVolunteers || null,
                                startingRowIndex: vm.filter.startingRowIndex || null,
                                numberOfRows: vm.filter.numberOfRows || null
                            });

                            vm.isCustomPaging = angular.isDefined(vm.filter.startingRowIndex) && angular.isDefined(vm.filter.numberOfRows) && vm.filter.startingRowIndex !== null && vm.filter.numberOfRows !== null;

                            determineStateOfFloatingUserCheckBox();
                            determineStateOfExcludeNonAppliedVolunteers ();

                            if(!vm.isCustomPaging) {
                                if(!vm.loadStatus) {
                                    vm.loadStatus = loadStatus.init();
                                }

                                return getUsers(0, vm.loadStatus.defaultNumRows);
                            }

                            else {
                                return getUsers(vm.filter.startingRowIndex, vm.filter.numberOfRows);
                            }
                        break;
                        case 'error':
                            vm.isLoading = false;
                            return $scope.$emit('people:alert', result.message);
                        default:
                            vm.isLoading = false;
                            return $scope.$emit('people:alert', "Failed to create new filter!");
                    }
                })
                .catch(function (error) {
                    $scope.$emit('people:alert', error);
                    vm.isLoading = false;
                });
        }

        function onClickSearch() {
            $location.search({
                community: vm.filter.selectedCommunity,
                organization: vm.filter.selectedOrganizations,
                isActive: vm.filter.isActive,
                firstName: vm.filter.firstName || null,
                lastName: vm.filter.lastName || null,
                startDate: vm.filter.startDate || null,
                endDate: vm.filter.endDate || null,
                volunteerEmail: vm.filter.volunteerEmail || null,
                startingRowIndex: vm.filter.startingRowIndex || null,
                numberOfRows: vm.filter.numberOfRows || null
            });
            if(!vm.isCustomPaging) {
                getUsers(0, vm.loadStatus.defaultNumRows);
            }
            else {
                return getUsers(vm.filter.startingRowIndex, vm.filter.numberOfRows);
            }
            return updateCheckAllAndExport();
        }

        function onClickAddNewNonUser() {
            var modalInstance = $modal.open({
                templateUrl: 'app/components/administrator/people/volunteers/addNonUser.view.html',
                controller: 'AddNonUserController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false
            });

            modalInstance.result
                .then(function (result) {
                    vm.isLoading = true;

                    switch (result.action) {
                        case 'success':
                            return $state.go('vmis.profileNonUser', { "email": result.email });
                        // These states do not seem to occur
                        case 'error':
                            vm.isLoading = false;

                            return $scope.$emit('people:alert', result.message);
                        default:
                            vm.isLoading = false;

                            return $scope.$emit('people:alert', "Failed to create new non-user!");
                    }
                })
                .catch(function (error) {
                    $scope.$emit('people:alert', error);
                    vm.isLoading = false;
                });
        }

        function onCheckAll() {
            // Check users in either complete or filtered list
            for (var i = 0; i < vm.usersWithRole.length; i++)
                vm.usersWithRole[i].isChecked = vm.checkAll;

            updateCheckAllAndExport();
        }

        function onCheckUser() {
            updateCheckAllAndExport();
        }

        function updateCheckAllAndExport() {
            vm.numUsersSelected = 0;
            for (var i = 0; i < vm.usersWithRole.length; i++) {
                if (vm.usersWithRole[i].isChecked)
                    vm.numUsersSelected++;
            }

            // If list empty, uncheck
            if (vm.usersWithRole.length === 0) {
                vm.checkAll = false;
            }
            // Otherwise look for a checked user
            else {
                vm.checkAll = vm.usersWithRole.every(function (user) {
                    return user.isChecked;
                });
            }
        }

        function onClickEditVol(vol, action) {
            var paramId = {
                id: vol.masterUserId
            };
            switch (action) {
                case "Profile":
                    // Open new page in new tab
                    $window.open($state.href('vmis.profileEdit', paramId), '_blank');
                    break;
                case "Service Log":
                    $window.open($state.href('vmis.volunteer.serviceLogEdit', paramId), '_blank');
                    break;
                case "Records":
                    $window.open($state.href('vmis.volunteer.recordsEdit', paramId), '_blank');
                    break;
                case "Service History":
                    $window.open($state.href('vmis.volunteer.serviceHistoryEdit', paramId), '_blank');
                    break;
                case "Convert to Normal User":
                    convertToNormalUser(vol);
                    break;
                default:
                    console.log(page);
                    break;
            }
        }

        function convertToNormalUser(vol) {
            // Confirm conversion with modal
            return authentication.getNonUserEmail(vol.masterUserId).then(function (response) {
                var options = {
                    templateUrl: 'app/components/administrator/people/volunteers/confirmConvert.view.html',
                    controller: 'ConfirmConvertController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    keyboard: false,
                    resolve: {
                        modalParams: function () {
                            return {
                                firstName: vol.aosUser.firstName,
                                lastName: vol.aosUser.lastName,
                                email: response.email,
                                masterUserId: vol.masterUserId
                            };
                        }
                    }
                };

                return $modal.open(options).result;
            }).then(function () {
                var msg = "Invitation email sent to " + vol.aosUser.firstName + " " + vol.aosUser.lastName + "!";
                $scope.$emit('people:success', msg);
                return getUsers(0, vm.loadStatus.defaultNumRows);
            }).catch(function (error) {
                $scope.$emit('people:alert', error);
            });
        }

        function onMassEmail() {
            var emails = vm.usersWithRole.filter(function (user) {
                return user.isChecked && (user.aosUser !== null);
            }).map(function (user) {
                return (
                    (user.aosUser.vmisUser) ?
                        user.aosUser.vmisUser.volunteerProfile.volunteerEmail :
                        (user.email) ? user.email : null
                );
            });

            if (emails.length === 0)
                return;

            emails = Array.from(new Set(emails));
            // From: https://stackoverflow.com/a/281335/1634403
            emails = emails.filter(Boolean);

            var modalInstance = $modal.open({
                templateUrl: 'app/components/administrator/people/actionDirectives/massEmail.view.html',
                controller: 'MassEmailController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    params: function () {
                        return {
                            emails: emails
                        };
                    }
                }
            });

            // Process result from modal
            modalInstance.result
                .then(function (result) {
                    switch (result.action) {
                        case 'success':
                            var message = "";
                            if (emails.length !== 1)
                                message = "Mass email \"" + result.message + "\" successfully sent to " + emails.length + " users!";
                            else
                                message = "Mass email \"" + result.message + "\" successfully sent to 1 user!";

                            return $scope.$emit('people:success', message);
                        case 'error':
                            return $scope.$emit('people:alert', result.message);
                        default:
                            return $scope.$emit('people:alert', "Mass email failed to send!");
                    }
                })
                .catch(function (error) {
                    $scope.$emit('people:alert', error);
                });
        }

        // Export currently-checked users to spreadsheet format for download
        function onExport() {
            vm.isExporting = true;

            var exportingUsersHeader = [
                "First Name",
                "Last Name",
                "Last Login Date",
                "Most Recent Hours",
                "Email",
                "Background Check\r\n"
            ].toString();

            var exportingUsers = [exportingUsersHeader];
            exportingUsers = exportingUsers.concat(vm.usersWithRole.filter(function (user) {
                return user.isChecked;
            }).map(function (checkedUser) {
                var email = (checkedUser.aosUser.vmisUser !== null) ?
                    checkedUser.aosUser.vmisUser.volunteerProfile.volunteerEmail : "";

                return [
                    checkedUser.aosUser.firstName,
                    checkedUser.aosUser.lastName,
                    checkedUser.lastSignedIn,
                    checkedUser.latestHoursActivityDate,
                    email,
                    checkedUser.aosUser.backgroundCheckStatus
                ].toString() + "\r\n";
            }));

            var blob = new Blob(exportingUsers, { type: "text/plain;charset=utf-8" });
            saveAs(blob, (moment().format('YYYYMMDD_HHmmss') + "_Report.csv"));

            vm.isExporting = false;
        }

        function onLoadMore() {
            if(!vm.isCustomPaging) {
                getUsers(vm.loadStatus.currentIndex, vm.loadStatus.nextResultLoadNum);
                vm.checkAll = false;
            }
        }

        function onLoadAll() {
            if(!vm.isCustomPaging) {
                getUsers(vm.loadStatus.currentIndex, vm.loadStatus.numResultsNotLoaded);
                vm.checkAll = false;
            }
        }

        function massActionDisabled() {
            return vm.numUsersSelected === 0 || vm.isExporting || vm.isLoading;
        }
    }
})();
