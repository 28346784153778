(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .controller('ServiceHistoryController', ServiceHistoryController);

    ServiceHistoryController.$inject = ['$scope', 'defaultFilters', 'volunteer', '$modal', '$stateParams', 'authentication'];

    function ServiceHistoryController($scope, defaultFilters, volunteer, $modal, $stateParams, authentication) {
        var vm = this;

        vm.years=[];
        vm.alerts=[];
        vm.isLoading = true;

        var year = moment().year();
        vm.years.push(year.toString());
        vm.selectedYear = year.toString();
        for(var i=0; i<9; i++){
            vm.years.push((year-(i+1)).toString());
        }

        vm.volunteer = volunteer;
        vm.startDate = moment(defaultFilters.startDate).format('MM/DD/YYYY');
        vm.endDate = moment(defaultFilters.endDate).format('MM/DD/YYYY');

        $scope.$on('serviceHistory:alert', alertHandler);
        vm.onCloseAlert = onCloseAlert;

        activate();
        vm.generateDA4713 = generateDA4713;
        vm.generateDA4162 = generateDA4162;

        vm.isLoading = false;

        function activate() {
        }

        function updateFilters() {
            $scope.$broadcast('filters', {
                startDate: moment(vm.startDate, 'MM/DD/YYYY').toDate(),
                endDate: moment(vm.endDate, 'MM/DD/YYYY').toDate()
            });
        }
        
        function generateDA4713() {
            $modal.open({
                templateUrl: 'app/components/volunteer/serviceHistory/da4713YearSelector/view.html',
                controller: 'DA4713YearSelector',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    masterUserId: function () {
                        return $stateParams.id || undefined;
                    }
                }
            });
        }

        function generateDA4162() {
            vm.isLoading = true;
            return authentication.getDA4162({
                masterUserId: $stateParams.id || undefined
            })
                .then(function(){
                    vm.isLoading = false;
                })
                .catch(function(err){
                    vm.isLoading = false;
                    generateDAFormError(err);
                });
        }

        function generateDAFormError(error) {
            if (angular.isDefined(error)) {
                var errorStr = error.error ? error.error : error;
                $scope.$emit('serviceHistory:alert', errorStr);
            }
            else
                $scope.$emit('serviceHistory:alert', "Failed to generate form, please try again");

            vm.isLoading = false;
        }

        function alertHandler(event, message) {
            event.stopPropagation();

            displayError(message);
        }

        function onCloseAlert(index) {
            vm.alerts.splice(index, 1);
        }

        function displayAlert(type, message) {
            vm.alerts.push({
                type: type,
                message: message
            });
        }

        function displayError(error) {
            if (error === 'cancel' || error === 'backdrop click')
                return;
            if (error)
                displayAlert('alert', error);
        }
    }
})();