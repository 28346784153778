(function () {
    'use strict';

    angular
        .module('app.lite', []);

    angular
        .module('app.lite')
        .factory('lite', lite);

    lite.$inject = ['$cookies', 'config'];

    function lite($cookies, config) {

        return {
            isLiteMode: isLiteMode,
            setLiteMode: setLiteMode,
            getNumRows: getNumRows,
        };

        function isLiteMode() {
            var cookieData = $cookies.get('vmis-lite');
            // result will be undefined if it hasn't been explicitly set yet
            return !!cookieData ? JSON.parse(cookieData) : false;
        }

        function setLiteMode(isLiteMode) {
            $cookies.put('vmis-lite', isLiteMode);
        }

        // Input is default number of rows for a single object type and the reduction factor
        function getNumRows(defaultNumRows, liteModeReductionFactor) {
            if (!isLiteMode()) return defaultNumRows;

            var reducedValue = Math.ceil(
                defaultNumRows / (liteModeReductionFactor > 0 ? liteModeReductionFactor : 1));

            return reducedValue > 0 ? reducedValue : 1;
        }
    }
})();