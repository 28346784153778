(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('MergeAccountsController', MergeAccountsController);

    MergeAccountsController.$inject = ['authentication', '$scope', '$modal'];

    function MergeAccountsController(authentication, $scope, $modal) {
        var vm = this;
        
        vm.isLoading = false;
        
        vm.selectedUsernames = [];
        vm.selectedEmails = [];
        vm.selectedNonUsers = [];

        vm.alerts = [];

        vm.mergeToUser = "";
        vm.selectedUsernameList = "";
        vm.selectedEmailList = "";
        vm.selectedNonUserList = "";
        
        vm.onClickUsername = onClickUsername;
        vm.onClickEmail = onClickEmail;
        vm.onClickNonUser = onClickNonUser;

        vm.onClickMergeStats = onClickMergeStats;
        vm.onClickMerge = onClickMerge;
        vm.onClearData = onClearData;

        $scope.$on('mergeAccounts:alert', alertHandler);
        vm.onCloseAlert = onCloseAlert;
        
        activate();

        function activate() {

        }

        function onClearData(items) {
            if(items === "all") {
                vm.mergeToUser = "";
                vm.selectedUsernameList = "";
                vm.selectedEmailList = "";

                vm.selectedUsernames = [];
                vm.selectedEmails = [];

                vm.selectedNonUserList = "";
                vm.selectedNonUsers = [];
            }
            
            if(items === "email"){
                vm.selectedEmailList = "";
                vm.selectedEmails = [];
            }
            
            if(items === "username"){
                vm.selectedUsernameList = "";
                vm.selectedUsernames = [];
            }

            if(items === "nonuser"){
                vm.selectedNonUserList = "";
                vm.selectedNonUsers = [];
            }
        }
        
        function onClickUsername() {
            $modal.open({
                templateUrl: 'app/components/administrator/mergeAccounts/addEmailUsername.view.html',
                controller: 'AddEmailUsernameController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    title: function () {
                        return "Add Username";
                    }
                },
                size: 'tiny'
            }).result.then(function (result) {
                if(vm.selectedUsernames.some(function(element){
                        return element === result.item;
                    })){
                    displayError("This username already exists in this Merge From Email Addresses");
                }
                else {
                    vm.selectedUsernames.push(result.item);
                    if (vm.selectedUsernameList !== "") {
                        vm.selectedUsernameList = vm.selectedUsernameList + ", " + result.item;
                    }
                    else {
                        vm.selectedUsernameList = result.item;
                    }
                }
            });
        }

        function onClickEmail() {
            $modal.open({
                templateUrl: 'app/components/administrator/mergeAccounts/addEmailUsername.view.html',
                controller: 'AddEmailUsernameController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    title: function () {
                        return "Add Email";
                    }
                }
            }).result.then(function (result) {
                if(vm.selectedEmails.some(function(element){
                        return element === result.item;
                    })){
                    displayError("This email already exists in this Merge From Email Addresses");
                }
                else {
                    if(result.item === vm.mergeToUser){
                        displayError("You cannot add the Merge To Email Address to the Merge From Email Address list");
                    }
                    else {
                        vm.selectedEmails.push(result.item);
                        if (vm.selectedEmailList !== "") {
                            vm.selectedEmailList = vm.selectedEmailList + ", " + result.item;
                        }
                        else {
                            vm.selectedEmailList = result.item;
                        }
                    }
                }
            });
        }

        function onClickNonUser() {
            $modal.open({
                templateUrl: 'app/components/administrator/mergeAccounts/addEmailUsername.view.html',
                controller: 'AddEmailUsernameController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    title: function () {
                        return "Add Non-User";
                    }
                }
            }).result.then(function (result) {
                if(vm.selectedNonUsers.some(function(element){
                    return element === result.item;
                })){
                    displayError("This non-user already exists in this Merge From Non-Users");
                }
                else {
                    if(result.item === vm.mergeToUser){
                        displayError("You cannot add the Merge To Email Address to the Merge From Non-Users list");
                    }
                    else {
                        vm.selectedNonUsers.push(result.item);
                        if (vm.selectedNonUserList !== "") {
                            vm.selectedNonUserList = vm.selectedNonUserList + ", " + result.item;
                        }
                        else {
                            vm.selectedNonUserList = result.item;
                        }
                    }
                }
            });
        }

        function onClickMergeStats() {
            vm.isLoading = true;
            authentication.getMasterUserIdFromEmail({email: vm.mergeToUser})
                .then(function(user){
                    if(user.success){
                        return user.masterUserId;
                    }
                })
                .then(function(masterUserId){
                    var params = {getMergeStatsOnly: true};
                    params.masterUserId = masterUserId;

                    if(vm.selectedEmails.length !== 0){
                        params.emails = vm.selectedEmails;
                    }
                    if(vm.selectedUsernames.length !== 0){
                        params.usernames = vm.selectedUsernames;
                    }
                    if(vm.selectedNonUsers.length !== 0){
                        params.nonUsers = vm.selectedNonUsers;
                    }
                    return authentication.mergeVMISdata(params);
                })
                .then(function(results){
                    if(angular.isDefined(results)) {
                        $modal.open({
                            templateUrl: 'app/components/administrator/mergeAccounts/mergeCompleted.view.html',
                            controller: 'MergeCompletedController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            keyboard: false,
                            resolve: {
                                title: function () {
                                    return "Merge Information";
                                },
                                results: function () {
                                    return results;
                                }
                            }
                        });
                    }
                })
                .catch(function(error){
                    if(error === "email not found") {
                        displayError("This user does not exist in the system");
                    }
                    else {
                        displayError(error);
                    }
                })
                .finally(function(){
                    vm.isLoading = false;
                });
        }

        function onClickMerge() {
            $modal.open({
                templateUrl: 'app/components/administrator/mergeAccounts/confirmMerge.view.html',
                controller: 'ConfirmMergeController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    toEmail:  function () {return vm.mergeToUser;},
                    fromEmails: function () {return vm.selectedEmails;},
                    fromUsernames: function () {return vm.selectedUsernames;},
                    fromNonUsers: function () {return vm.selectedNonUsers;}
                }
            }).result.then(function (error) {
                if(angular.isDefined(error)){
                    displayError(error.error);
                }
                else {
                    onClearData("all");
                }
            });
        }

        function alertHandler(event, message) {
            event.stopPropagation();

            displayError(message);
        }

        function onCloseAlert(index) {
            vm.alerts.splice(index, 1);
        }

        function displayAlert(type, message) {
            vm.alerts.push({
                type: type,
                message: message
            });
        }

        function displayError(error) {
            if (error === 'cancel' || error === 'backdrop click')
                return;
            if (error)
                displayAlert('alert', error);
        }
    }
})();
    