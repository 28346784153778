(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .controller('OrientationDetailsController', OrientationDetailsController);

    OrientationDetailsController.$inject = ['$modalInstance', '$modal', '$timeout', '$q', 'moment', 'authentication',
        'utilsServices', 'records', 'orientation', 'id', 'viewOnly', 'config'];

    function OrientationDetailsController($modalInstance, $modal, $timeout, $q, moment, authentication,
                                          utilsServices, records, orientation, id, viewOnly, config) {
        var vm = this;

        vm.isLoading = true;
        vm.isAttachmentUploading = false;

        vm.newUpload = false;

        vm.orientation = orientation;

        // This Boolean checks whether the modal was opened
        // to view/delete an existing attachment or to upload new ones.
        if (vm.orientation.attachments && vm.orientation.attachments.length > 0) {
            vm.attachmentPresent = true;
            vm.attachments = vm.orientation.attachments;
        } else {
            vm.attachmentPresent = false;
            vm.attachments = [];
        }

        vm.masterUserId = parseInt(id) || null;
        // Existence of master user ID indicates whether user is editing another user
        vm.adminEditing = angular.isNumber(vm.masterUserId);
        vm.viewOnly = viewOnly;
        vm.title = vm.orientation.id ? 'Orientation Details' : 'Add Orientation';

        vm.allowedFileTypesForUpload = null;
        vm.maxFileSizeInMegabytes = null;
        vm.formError = "";

        vm.onSelectAttachments = onSelectAttachments;
        vm.onDeleteSelectedAttachment = onDeleteSelectedAttachment;
        vm.onClickSave = onClickSave;
        vm.onClickDelete = onClickDelete;

        activate();

        function activate() {
            config.get().then(function (configData) {
                vm.allowedFileTypesForUpload = configData.allowedFileTypesForUpload;
                vm.maxFileSizeInMegabytes = configData.maxFileSizeInMegabytes;
            });

            vm.orientation.date = vm.orientation.date ? vm.orientation.date : null;
            // If new orientation, set Certified to true if admin or false if self
            if (!vm.orientation.id)
                vm.orientation.certified = vm.adminEditing;
            // If existing orientation, convert 0/1 API return to true/false for checkbox
            else
                vm.orientation.certified = vm.orientation.certified === 1;

            vm.isLoading = false;
        }

        function onSelectAttachments(attachments, errAttachments) {
            if (errAttachments.length > 0) {
                utilsServices.displayError("Wrong file type or file size is larger than " + vm.maxFileSizeInMegabytes + ".", vm);
                updateAttachmentPresent();
                return;
            } else {
                vm.formError = "";
            }

            if (attachments.length > 0) {
                angular.forEach(attachments, function (a) {
                    vm.attachments.push({
                        theFile: a,
                        name: a.name,
                        isSelected: true
                    });
                });
            }

            // Update flag for whether new attachments to upload were selected
            updateAttachmentPresent();
        }

        function onDeleteSelectedAttachment(attachment) {
            var index = vm.attachments.indexOf(attachment);
            if (index > -1) {
                if (attachment.isSelected) {
                    vm.attachments.splice(index, 1);
                    updateAttachmentPresent();
                } else {
                    var params = {
                        volunteerOrientationIds: [vm.orientation.id],
                        atoAttachmentId: attachment.id
                    };
                    authentication.deleteVolunteerOrientations(params)
                        .then(function (response) {
                            vm.attachments.splice(index, 1);
                            updateAttachmentPresent();
                        })
                        .catch(function (error) {
                            utilsServices.displayError(error, vm);
                        });
                }
            }
        }

        function onClickSave() {
            vm.isLoading = true;

            var params = {
                volunteerOrientationId: vm.orientation.id || undefined,
                masterUserId: vm.adminEditing && !vm.orientation.id ? vm.masterUserId : undefined, // Only do this on admin create, not admin edit
                orientationName: vm.orientation.name,
                orientationDate: moment(vm.orientation.date).format('YYYY-MM-DD[T00:00:00Z]'),
                isCertified: vm.adminEditing ? vm.orientation.certified : undefined
            };

            return authentication.setVolunteerOrientations(params)
                .then(function (response) {
                    response.action = vm.orientation.id ? 'edit' : 'add';

                    var selectedAttachments = [];
                    vm.attachments.forEach(function (a) {
                        if (a.isSelected) {
                            selectedAttachments.push(a);
                        }
                    });

                    if (selectedAttachments.length > 0) {
                        uploadAttachments(selectedAttachments,
                            vm.orientation.id ? vm.orientation.id : response.volunteerOrientationId);
                    } else {
                        return $modalInstance.close(response);
                    }
                })
                .catch(function (error) {
                    var params = {
                        error: error,
                        success: false,
                        action: vm.orientation.id ? 'edit' : 'add'
                    };
                    return $modalInstance.dismiss(params);
                });
        }

        function onClickDelete() {
            vm.isLoading = true;

            var options = {
                templateUrl: 'app/components/volunteer/records/orientations/delete/view.html',
                controller: 'OrientationDeleteController',
                controllerAs: 'vm',
                size: 'tiny',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    orientation: function () {
                        return vm.orientation;
                    }
                }
            };

            return $modal.open(options)
                .result
                .then(function (response) {
                    response.action = 'delete';
                    return $modalInstance.close(response);
                })
                .catch(function (error) {
                    var params = {
                        error: error,
                        success: false,
                        action: 'delete'
                    };
                    return $modalInstance.dismiss(params);
                });
        }

        function uploadAttachments(attachments, orientationId) {
            vm.isAttachmentUploading = true;

            var promiseArray = [];

            attachments.forEach(function (a) {
                var suffix = a.name.split(".").pop();
                // If user-created attachment name does not have a suffix
                if (suffix === a.name)
                    suffix = "";

                var uploadAttachmentParams = {
                    masterUserId: vm.adminEditing ? vm.masterUserId : records.getMyMasterUserId(),
                    documentName: a.name,
                    fileSuffix: suffix,
                    volunteerOrientationId: orientationId,
                    documentDescription: a.name,
                    theFile: a.theFile
                };

                promiseArray.push(authentication.uploadVolunteerDocument(uploadAttachmentParams)
                    .then(function (response) {
                        a.uploaded = true;
                    }));
            });

            $q.all(promiseArray)
                .then(function (response) {
                    return $timeout(function () {
                        vm.isAttachmentUploading = false;
                        response.action = 'add';
                        return $modalInstance.close(response);
                    }, 1000);
                })
                .catch(function (error) {
                    return $timeout(function () {
                        vm.isAttachmentUploading = false;
                        var params = {
                            error: error,
                            success: false,
                            action: 'add'
                        };
                        return $modalInstance.dismiss(params);
                    }, 1000);
                });
        }

        function updateAttachmentPresent() {
            vm.attachmentPresent = (vm.attachments.length > 0);
        }
    }
})();