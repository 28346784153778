(function () {
    'use strict';

    angular
        .module('app.auth')
        .factory('utilsServices', utils);

    utils.$inject = ['$q', '_', 'authentication'];

    function utils($q, _, authentication) {
        return {
            combineCommunityResponsibilities: combineCommunityResponsibilities,
            combineStandardOrganizationResponsibilities: combineStandardOrganizationResponsibilities,
            combineResponsibilities: combineResponsibilities,
            parseErrorString: parseErrorString,
            displayError: displayError
        };

        /**
         * Combines the community responsibilities of an admin.
         * @param profile The admin's profile
         * @param roles The roles to require
         * @returns {*} Returns a promise of an array of communities or null if admin does not have any
         * responsibilities. Communities are returned in the following format:
         *     {
         *         id, // Community ID
         *         name, // Community name
         *         organizations: [{
         *             id, // Organization ID
         *             name // Organization name
         *         }, ...]
         *     }
         */
        function combineCommunityResponsibilities(profile, roles) {
            var responsibilities = getResponsibilities(profile);
            if (!responsibilities) return null;

            var communities = [];

            // Coordinator
            if (shouldInclude(responsibilities, roles, 'avcc', 'AVC Coordinator')) {
                communities = getUniques(communities, mapCommunities(responsibilities.avcc));
            }

            // Coordinator Assistant
            if (shouldInclude(responsibilities, roles, 'avccAssistant', 'AVC Coordinator Assistant')) {
                communities = getUniques(communities, mapCommunities(responsibilities.avccAssistant));
            }

            // OPOC
            if (shouldInclude(responsibilities, roles, 'opoc', 'AVC OPOC')) {
                communities = getUniques(communities, mapCommunities(responsibilities.opoc));
            }

            return $q.when(communities);

            function mapCommunities(communities) {
                return _.map(communities, function (community) {
                    return {
                        id: community.communityAffiliationId,
                        name: community.communityAffiliationName,
                        organizations: community.organizations ? _.map(community.organizations, function (organization) {
                                return {
                                    id: organization.volunteerOrganizationId,
                                    name: organization.volunteerOrganizationName
                                };
                            }) : null
                    };
                });
            }
        }

        /**
         * Combines the standard organization responsibilities of an admin.
         * @param profile The admin's profile
         * @param roles The roles to require
         * @returns {*} Returns a promise of an array of standard organizations or null if admin does not have any
         * responsibilities. Standard organizations are returned in the following format:
         *     {
         *         id, // Standard organization ID
         *         name // Standard organization name
         *     }
         */
        function combineStandardOrganizationResponsibilities(profile, roles) {
            return authentication.getStandardOrganizations({
                getAll: true
            }).then(function(response) {
                return _.map(response.standardOrganizations, function(standardOrganization) {
                    return {
                        id: standardOrganization.volunteerOrganizationTemplateId,
                        name: standardOrganization.volunteerOrganizationTemplateName
                    };
                });
            });
        }

        /**
         * Combines all the responsibilities of an admin.
         * @param profile The admin's profile
         * @param roles The roles to require
         * @returns {*} Returns a promise of a map of the admin's responsibilities or null if admin does not have any
         * responsibilities.
         */
        function combineResponsibilities(profile, roles) {
            if (!getResponsibilities(profile)) return null;

            return $q.all({
                standardOrganizations: combineStandardOrganizationResponsibilities(profile, roles),
                communities: combineCommunityResponsibilities(profile, roles)
            });
        }

        function getResponsibilities(profile) {
            return _.has(profile, 'aosUser.vmisUser.adminProfile.responsibilities') ? profile.aosUser.vmisUser.adminProfile.responsibilities : null;
        }

        function shouldInclude(responsibilities, roles, responsibilityRole, requiredRole) {
            return _.has(responsibilities, responsibilityRole) && (!roles || _.includes(roles, requiredRole));
        }

        function getUniques(array1, array2) {
            return array1 ? _.unionBy(array1, array2, 'id') : array2;
        }

        function parseErrorString(error) {
            var errorStr = "";
            if (error.error)
                errorStr = error.error;
            else
                errorStr = error;
            return errorStr;
        }

        /**
         * Displays error string in vm.formError and sets vm.isLoading flag to false.
         * Ignores multiple common strings that shouldn't be displayed.
         * Note: there are multiple ways we use formError, and this doesn't work for all of them.
         * @param error The error string
         * @param vm The object to place the string in (formError) and set loading flag to false in
         */
        function displayError(error, vm) {
            if (_.isUndefined(error)) return;
            var errorStr = parseErrorString(error);

            vm.loading = false;

            if (errorStr === 'cancel' || errorStr === 'backdrop click' || errorStr === 'ok')
                return;
            if (errorStr)
                vm.formError = errorStr;
        }
    }
})();