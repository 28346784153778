(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('OrganizationGroupDeleteController', OrganizationGroupDeleteController);

    OrganizationGroupDeleteController.$inject = ['organizationGroupName'];

    function OrganizationGroupDeleteController(organizationGroupName) {
        var vm = this;

        vm.organizationGroupName = organizationGroupName;
    }
})();