(function () {
    'use strict';

    angular
        .module('administrator.hours')
        .config(routes);

    routes.$inject = ['$stateProvider'];

    function routes($stateProvider) {
        $stateProvider
            .state('vmis.administrator.hours', {
                url: '/hours',
                templateUrl: 'app/components/administrator/hours/hours.view.html',
                controller: 'HoursController',
                controllerAs: 'vm',
                title: 'Hours'
            });
    }
})();