(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('EditCoordinatorController', EditCoordinatorController);

    EditCoordinatorController.$inject = ['$modalInstance', '$modal', '$q', 'params', 'authentication', 'usersServices'];

    function EditCoordinatorController($modalInstance, $modal, $q, params, authentication, usersServices) {
        var vm = this;

        vm.isLoading = true;

        vm.coordinator = params.coordinator || {};
        vm.isNewAVCC = params.coordinator === null;
        vm.canRemove = !vm.isNewAVCC && params.canRemove;
        if (vm.isNewAVCC)
            vm.title = "Add New AVCC";
        else
            vm.title = "Edit AVCC - " + vm.coordinator.aosUser.firstName + " " + vm.coordinator.aosUser.lastName;

        vm.errorText = "";

        vm.communities = params.activeUserResponsibilities;
        vm.avccResponsibilities = [];
        var originalResponsibilities = [];

        vm.onClickRemove = onClickRemove;
        vm.onSubmit = onSubmit;

        activate();

        function activate() {
            if (!vm.isNewAVCC && vm.coordinator.aosUser.vmisUser.adminProfile.responsibilities.avcc) {
                // If existing role with responsibilities, fill ui-select
                vm.coordinator.aosUser.vmisUser.adminProfile.responsibilities.avcc.forEach(function (resp) {
                    // If AVCC responsibility is under active user's purview
                    if (isInActiveUserResponsibilities(resp)) {
                        vm.avccResponsibilities.push(_.find(vm.communities, function (comm) {
                            return comm.communityAffiliationId === resp.communityAffiliationId;
                        }));
                    }
                    // If AVCC responsibility is outside active user's purview
                    else {
                        resp.readOnly = true;
                        vm.avccResponsibilities.push(resp);
                    }
                });

                originalResponsibilities = angular.copy(vm.avccResponsibilities);
            }

            vm.isLoading = false;
        }

        function onClickRemove() {
            vm.isLoading = true;

            var modalInstance = $modal.open({
                templateUrl: 'app/components/administrator/people/actionDirectives/confirmRoleRemove.view.html',
                controller: 'ConfirmRoleRemoveController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    params: function () {
                        return {
                            userInfo: vm.coordinator,
                            role: "AVC Coordinator"
                        };
                    }
                }
            });

            modalInstance.result
                .then(function () {
                    close('remove', vm.coordinator.masterUserId, originalResponsibilities);
                    vm.isLoading = false;
                })
                .catch(function (error) {
                    if (angular.isDefined(error))
                        setError(error);
                    vm.isLoading = false;
                });
        }

        function onSubmit() {
            vm.isLoading = true;

            var getProfilePromise = Promise.resolve(null);
            if (vm.isNewAVCC){
                getProfilePromise = authentication.getMasterUserIdFromEmail({email: vm.coordinator.masterUserEmail})
                    .then(function(response){
                        if(response.success === true){
                            clearError();
                            vm.coordinator.masterUserId = response.masterUserId;
                            return authentication.getUserProfile({masterUserId: response.masterUserId});
                        }
                        else {
                            setError("The entered email address does not match any existing user");
                            vm.isLoading = false;
                        }
                    });
            }
            else {
                getProfilePromise = authentication.getUserProfile({masterUserId: vm.coordinator.masterUserId});
            }

            getProfilePromise
                .then(function(userProfile) {
                    var isUserAVCC = userProfile.roles.vmisUserRoles.filter(function (role) {
                        return role === "AVC Coordinator";
                    }).length === 1;
                    var isUserAssistant = userProfile.roles.vmisUserRoles.filter(function (role) {
                        return role === "AVC Coordinator Assistant";
                    }).length === 1;

                    // Ensure that if modal is opened or not, later code is only run afterward
                    var confirmationPromise = $q.resolve();

                    // Check if user is an AVCC Assistant. If so, show confirmation modal,
                    // because assistant must be removed to add AVCC.
                    if (isUserAssistant) {
                        confirmationPromise = $modal.open({
                            templateUrl: 'app/components/administrator/people/actionDirectives/confirmAvccAssistReplace.view.html',
                            controller: 'ConfirmAvccAssistReplaceController',
                            controllerAs: 'vm',
                            backdrop: 'static',
                            keyboard: false,
                            resolve: {
                                params: function () {
                                    return {
                                        userInfo: userProfile,
                                        avccResponsibilities: vm.avccResponsibilities
                                    };
                                }
                            }
                        }).result; // Simply continue along normal path if "Ok"
                    }

                    confirmationPromise.then(function () {
                        if (vm.isNewAVCC && isUserAVCC) {
                            if (!usersServices.isBasicAdminUserDataComplete(userProfile))
                            {
                                setError("This user cannot be added due to incomplete data");
                                vm.isLoading = false;
                                return;
                            }

                            var newResponsibilities = vm.communities.map(function (comm) {
                                return comm.communityAffiliationId;
                            });
                            var oldResponsibilities = userProfile.aosUser.vmisUser.adminProfile.responsibilities.avcc.map(function(comm){
                                return comm.communityAffiliationId;
                            });

                            if(_.intersection(newResponsibilities,oldResponsibilities).length > 0) {
                                setError("The account associated with the email address is already an AVCC. You must find and edit that user's roles in the table.");
                                vm.isLoading = false;
                                return;
                            }
                            else {
                                close('edit', response.masterUserId, vm.avccResponsibilities);
                                return;
                            }
                        }

                        if (vm.isNewAVCC)
                            close('save', vm.coordinator.masterUserId, vm.avccResponsibilities);
                        else
                            close('edit', vm.coordinator.masterUserId, vm.avccResponsibilities);
                    }).catch(function (error) {
                        setError(error);
                        vm.isLoading = false;
                    });
                }).catch(function (error) {
                    setError(error);
                    vm.isLoading = false;
                });
        }

        function isInActiveUserResponsibilities(resp) {
            for (var i = 0; i < vm.communities.length; i++) {
                if (resp.communityAffiliationId === vm.communities[i].communityAffiliationId)
                    return true;
            }

            return false;
        }

        function setError(error) {
            if (error === undefined) return;
            var errorStr = error.error ? error.error : error;
            if (errorStr === 'cancel' || errorStr === 'backdrop click')
                return;
            vm.errorText = errorStr;
        }

        function clearError() {
            vm.errorText = "";
        }

        function close(action, masterUserId, responsibilities) {
            $modalInstance.close({
                action: action,
                masterUserId: masterUserId,
                responsibilities: filterResponsibilities(responsibilities)
            });
        }

        // Ensure only new target user responsibilities
        // that the active user can edit are present
        function filterResponsibilities(resp) {
            return resp.filter(function (comm) {
                return isInActiveUserResponsibilities(comm);
            });
        }
    }
})();