(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .controller('DeleteApplicationConfirmationController', DeleteApplicationConfirmationController);

    DeleteApplicationConfirmationController.$inject = ['title'];

    function DeleteApplicationConfirmationController(title) {
        var vm = this;

        vm.title = title;
    }
})();