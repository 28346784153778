(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('StandardOrganizationsController', StandardOrganizationsController);

    StandardOrganizationsController.$inject = ['$q', '$modal', '_', 'authentication', 'loadStatus'];

    function StandardOrganizationsController($q, $modal, _, authentication, loadStatus) {
        var vm = this;

        vm.sortColumn = 'name';
        vm.loadStatus = loadStatus.init();
        vm.isLoading = true;

        vm.onChangeFilter = onChangeFilter;
        vm.onClickStandardOrganization = onClickStandardOrganization;
        vm.onLoadAll = onLoadAll;
        vm.onLoadMore = onLoadMore;

        activate();

        function activate() {
            loadStatus.setup(vm.loadStatus, "standardOrganizations")
                .then(function () {
                    getDropDownData().then(function () {
                        return getStandardOrganizations(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows);
                    });
                });
        }

        function getDropDownData() {
            return $q.all({
                groups: authentication.getOrganizationGroups().then(function (response) {
                    return _.concat([{ label: 'All' }], _.map(response.organizationGroups, function (group) {
                        return {
                            label: group.volunteerOrganizationGroupName,
                            value: group.volunteerOrganizationGroupId
                        };
                    }));
                }),
                types: authentication.getUiData(['volunteerorganizationtype']).then(function (response) {
                    return _.concat([{ label: 'All' }], _.map(response.volunteerorganizationtype, function (type) {
                        return {
                            label: type,
                            value: type
                        };
                    }));
                }),
                statuses: $q.when([{ label: 'All' }, {
                    label: 'Active',
                    value: 1
                }, {
                    label: 'Inactive',
                    value: 0
                }])
            }).then(function (responses) {
                vm.dropDowns = {
                    groups: responses.groups,
                    types: responses.types,
                    statuses: responses.statuses
                };

                vm.filters = {
                    group: vm.dropDowns.groups[0],
                    type: vm.dropDowns.types[0],
                    status: vm.dropDowns.statuses[0]
                };
            });
        }

        function getStandardOrganizations(index, num) {
            vm.isLoading = true;

            return authentication.getStandardOrganizations({
                startingRowIndex: index,
                numberOfRows: num,
                volunteerOrganizationGroupId: vm.filters.group.value,
                volunteerOrganizationType: vm.filters.type.value,
                isActive: vm.filters.status.value
            }).then(function (response) {
                if (index === 0) {
                    vm.loadStatus.currentIndex = 0;
                    vm.standardOrganizations = [];
                }

                // Either set current index to max if all users loaded or increment by rows loaded
                vm.loadStatus.currentIndex = (vm.loadStatus.currentIndex + num > response.count) ? response.count : (vm.loadStatus.currentIndex + num);
                // Track total number of users not loaded for "Load All" button
                vm.loadStatus.numResultsNotLoaded = response.count - vm.loadStatus.currentIndex;
                // Set text for "Load More" button
                vm.loadStatus.nextResultLoadNum = (response.count === vm.loadStatus.currentIndex) ? 0 :
                    (vm.loadStatus.numResultsNotLoaded > num) ? num : vm.loadStatus.numResultsNotLoaded;

                vm.standardOrganizations = _.concat(vm.standardOrganizations, _.map(response.standardOrganizations, function (standardOrganization) {
                    return {
                        id: standardOrganization.volunteerOrganizationTemplateId,
                        name: standardOrganization.volunteerOrganizationTemplateName,
                        group: {
                            id: standardOrganization.volunteerOrganizationGroupId,
                            name: standardOrganization.volunteerOrganizationGroup
                        },
                        type: standardOrganization.volunteerOrganizationType,
                        status: standardOrganization.isActive,
                        standardPositionCount: standardOrganization.standardPositionCount,
                        description: standardOrganization.volunteerOrganizationDescription
                    };
                })
                );
                vm.formError = '';
            }).catch(function (error) {
                if (angular.isDefined(error)) {
                    vm.formError = error.error ? error.error : error;
                }
                else
                    vm.formError = 'Failed to load list! Please try again.';
            }).finally(function () {
                vm.isLoading = false;
            });
        }

        function onClickStandardOrganization(standardOrganization) {
            $modal.open({
                templateUrl: 'app/components/administrator/standardOrganizations/standardOrganizationDetails.view.html',
                controller: 'StandardOrganizationDetailsController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    title: function () {
                        return standardOrganization ? 'Standard Organization Details' : 'Add New Standard Organization';
                    },
                    standardOrganization: function () {
                        return standardOrganization ? _.clone(standardOrganization) : {};
                    },
                    dropDowns: function () {
                        return _.forEach(_.clone(vm.dropDowns), function (list, key, dropDowns) {
                            dropDowns[key] = _.tail(list);
                        });
                    }
                }
            }).result.then(function () {
                vm.loadStatus.currentIndex = 0;
                return getStandardOrganizations(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows);
            });
        }

        function onChangeFilter() {
            vm.loadStatus.currentIndex = 0;
            getStandardOrganizations(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows);
        }

        function onLoadMore() {
            getStandardOrganizations(vm.loadStatus.currentIndex, vm.loadStatus.nextResultLoadNum);
        }

        function onLoadAll() {
            getStandardOrganizations(vm.loadStatus.currentIndex, vm.loadStatus.numResultsNotLoaded);
        }
    }
})();