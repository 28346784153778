(function () {
    'use strict';

    angular
        .module('app')
        .directive('loadingIcon', LoadingIcon);

    LoadingIcon.$inject = [];

    function LoadingIcon() {
        return {
            restrict: 'E',
            templateUrl: 'app/common/directives/loadingIcon/loadingIcon.template.html',
            controller: function () {},
            controllerAs: 'loadingIcon',
            scope: {
                show: '=',
                // TODO: Make size parameter so these two cannot conflict if both used
                little: '=?',
                littlest: '=?',
                pushDown: '=?'
            },
            bindToController: true
        };
    }
})();
