(function () {
    'use strict';

    angular
        .module('app.vmis')
        .controller('PasswordExpirationModalController', PasswordExpirationModalController);

    PasswordExpirationModalController.$inject = ['daysRemaining', 'config'];

    function PasswordExpirationModalController(daysRemaining, config) {
        var vm = this;

        vm.daysRemaining = daysRemaining;
        vm.accountSettingsURL = null;

        activate();

        function activate() {
            config.get().then(function (configData) {
                vm.accountSettingsURL = configData.accountSettingsURL;
            });
        }
    }
})();