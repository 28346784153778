(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('InvitationsController', InvitationsController);

    InvitationsController.$inject = ['$modal', '$scope', 'authentication', 'loadStatus', 'title'];

    function InvitationsController($modal, $scope, authentication, loadStatus, title) {
        var vm = this;

        vm.isLoading = true;

        vm.sortReverse = true;
        vm.sortColumn = 'invitationDate';
        vm.title = title;
        vm.status = [
            'All',
            'Sent',
            'Accepted',
            'Expired'
        ];
        vm.invites = [];
        vm.alerts = [];
        vm.currentStatus = vm.status[1];
        vm.loadStatus = loadStatus.init();

        var completeRoleObjectList = [
            {
                shortName: "Vol",
                name: "Volunteer",
                vmisUserRolesName: "AVC Volunteer"
            },
            {
                shortName: "OPOC",
                name: "OPOC",
                responsibilitiesName: "opoc",
                vmisUserRolesName: "AVC OPOC"
            },
            {
                shortName: "AVCCAssistant",
                name: "AVCC Assistant",
                responsibilitiesName: "avccAssistant",
                vmisUserRolesName: "AVC Coordinator Assistant"
            },
            {
                shortName: "AVCC",
                name: "AVCC",
                responsibilitiesName: "avcc",
                vmisUserRolesName: "AVC Coordinator"
            },
            {
                shortName: "RM",
                name: "Region Manager",
                responsibilitiesName: "rm",
                secondResponsibilitiesName: "rmManaged",
                vmisUserRolesName: "AVC Region Manager"
            },
            {
                shortName: "PM",
                name: "Program Manager",
                vmisUserRolesName: "AVC Program Manager"
            }
        ];

        vm.getInvites = getInvites;
        vm.sendInvite = sendInvite;
        vm.onLoadMore = onLoadMore;
        vm.onLoadAll = onLoadAll;
        vm.onCloseAlert = onCloseAlert;

        activate();

        function activate() {
            vm.role = "AVC OPOC";

            loadStatus.setup(vm.loadStatus, "invitations")
                .then(function () {
                    getInvites(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows);
                });

            // From people.routes.js, for future handling of different
            // invitation options for different user responsibilities
            /*
            vm.roles = [];

            return authentication.getUserProfile()
                .then(function (response) {
                    // Note: this detects what roles the ACTIVE USER has.
                    // Different roles are invitable based on that.
                    for (var i = 0; i < response.roles.vmisUserRoles.length; i++) {
                        // Depending on the active user role, save data about their responsibilities
                        // to display in drop downs and populate the drop down for the types of roles
                        // they can invite.
                        switch (response.roles.vmisUserRoles[i]) {
                            case "AVC Program Manager":
                                addRoleAvoidDuplicates(getRoleObjectByVmisUserRolesName("AVC Program Manager"));
                                addRoleAvoidDuplicates(getRoleObjectByVmisUserRolesName("AVC Region Manager"));
                                addRoleAvoidDuplicates(getRoleObjectByVmisUserRolesName("AVC Coordinator"));
                                addRoleAvoidDuplicates(getRoleObjectByVmisUserRolesName("AVC Coordinator Assistant"));
                                addRoleAvoidDuplicates(getRoleObjectByVmisUserRolesName("AVC OPOC"));
                                break;
                            case "AVC Region Manager":
                                addRoleAvoidDuplicates(getRoleObjectByVmisUserRolesName("AVC Coordinator"));
                                break;
                            case "AVC Coordinator":
                                addRoleAvoidDuplicates(getRoleObjectByVmisUserRolesName("AVC Coordinator Assistant"));
                                addRoleAvoidDuplicates(getRoleObjectByVmisUserRolesName("AVC OPOC"));
                                break;
                            case "AVC Coordinator Assistant":
                                addRoleAvoidDuplicates(getRoleObjectByVmisUserRolesName("AVC OPOC"));
                                break;
                            case "AVC OPOC":
                                addRoleAvoidDuplicates(getRoleObjectByVmisUserRolesName("AVC OPOC"));
                                break;
                            default:
                                break;
                        }
                    }
                }
                .catch(function (error) {
                    if (error) {
                        var errorStr = error.error ? error.error : error;
                        displayError(errorStr);
                    }
                }));

            // Return the role object that matches the "vmisUserRolesName" string
            // Searches complete list because used for setup
            function getRoleObjectByVmisUserRolesName(vmisUserRolesName) {
                return completeRoleObjectList.filter(function (element) {
                    return (element.vmisUserRolesName === vmisUserRolesName);
                })[0];
            }

            function addRoleAvoidDuplicates(role) {
                // If current role cannot be found in list, add it
                if (!getRoleObject(role.name)) {
                    vm.roles.push(role);
                }
            }

            // Return the role object in the current list that matches the "name" string
            function getRoleObject(name) {
                return vm.roles.filter(function (element) {
                    return (element.name === name);
                })[0];
            }
            */
        }

        function getInvites(index, num) {
            vm.isLoading = true;
            var params = {
                startingRowIndex: index,
                numberOfRows: num,
                invitationStatus: vm.currentStatus !== vm.status[0] ? vm.currentStatus : undefined
            };
            if (vm.role) {
                params.aosRoles = [vm.role];
            }

            authentication.getInvites(params)
                .then(function (response) {
                    return getInvitesSuccess(response, index, num);
                }).catch(function (err) {
                    getInvitesError(err);
                });
        }

        function sendInvite(invite) {
            var options = {
                templateUrl: 'app/components/administrator/invitations/sendInvite.view.html',
                controller: 'SendInviteController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    roles: function () {
                        return vm.roles ? vm.roles : null;
                    },
                    selectedRole: function () {
                        return vm.role;
                    },
                    title: function () {
                        var role;
                        switch (vm.role) {
                            case "AVC OPOC":
                                role = "OPOC";
                                break;
                            case "AVC Coordinator":
                                role = "AVCC";
                                break;
                            case "AVC Region Manager":
                                role = "Region Manager";
                                break;
                            default:
                                role = "";
                                break;
                        }

                        return angular.isDefined(invite) ? 'Edit ' + role + ' Invitation' : 'Invite New ' + role;
                    },
                    invite: function () {
                        return angular.isDefined(invite) ? invite : null;
                    }
                }
            };

            $modal.open(options).result.then(function () {
                vm.loadStatus.currentIndex = 0;
                return getInvites(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows);
            }).catch(function (error) {
                // If modal is left by pressing "Escape", error is undefined.
                if (error) {
                    var errorStr = error.error ? error.error : error;
                    if (errorStr === 'ok' || errorStr === 'cancel' || errorStr === 'backdrop click')
                        return;

                    displayError(errorStr);
                }
            });
        }

        function getInvitesSuccess(response, index, num) {
            if (index === 0) {
                vm.loadStatus.currentIndex = 0;
                vm.invites = [];
            }

            // Either set current index to max if all users loaded or increment by rows loaded
            vm.loadStatus.currentIndex = (vm.loadStatus.currentIndex + num > response.count) ? response.count : (vm.loadStatus.currentIndex + num);
            // Track total number of users not loaded for "Load All" button
            vm.loadStatus.numResultsNotLoaded = response.count - vm.loadStatus.currentIndex;
            // Set text for "Load More" button
            vm.loadStatus.nextResultLoadNum = (response.count === vm.loadStatus.currentIndex) ? 0 :
                (vm.loadStatus.numResultsNotLoaded > num) ? num : vm.loadStatus.numResultsNotLoaded;

            vm.invites = vm.invites.concat(response.invitations);
            vm.isLoading = false;
        }

        function getInvitesError(error) {
            if (angular.isDefined(error)) {
                var errorStr = error.error ? error.error : error;
                $scope.$emit('invites:alert', errorStr);
            }
            else
                $scope.$emit('invites:alert', "Failed to load list! Please try again.");

            vm.isLoading = false;
        }

        function onLoadMore() {
            getInvites(vm.loadStatus.currentIndex, vm.loadStatus.nextResultLoadNum);
        }

        function onLoadAll() {
            getInvites(vm.loadStatus.currentIndex, vm.loadStatus.numResultsNotLoaded);
        }

        function displayAlert(type, message) {
            vm.alerts.push({
                type: type,
                message: message
            });
        }

        function onCloseAlert(index) {
            vm.alerts.splice(index, 1);
        }

        function displayError(error) {
            if (error === 'cancel' || error === 'ok' || error === 'backdrop click')
                return;
            if (error)
                displayAlert('alert', error);
        }
    }
})();
