(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('EditOpocController', EditOpocController);

    EditOpocController.$inject = ['$modalInstance', '$modal', '$q', 'params', 'authentication', 'usersServices'];

    function EditOpocController($modalInstance, $modal, $q, params, authentication, usersServices) {
        var vm = this;

        vm.isLoading = true;

        vm.opoc = params.opoc || {};
        vm.isNewOPOC = params.opoc === null;
        vm.canRemove = !vm.isNewOPOC && params.canRemove;
        vm.canEdit = params.canEdit;

        if (vm.isNewOPOC)
            vm.title = "Add New OPOC";
        else
            vm.title = "Edit OPOC - " + vm.opoc.aosUser.firstName + " " + vm.opoc.aosUser.lastName;

        vm.errorText = "";

        vm.communities = params.activeUserResponsibilities;
        vm.selectedCommunity = vm.communities[0];
        vm.opocResponsibilities = [];
        vm.initFillCommsCount = 0;
        var originalResponsibilities = [];

        vm.onCommunityChange = onCommunityChange;
        vm.onSelectOrg = onSelectOrg;
        vm.onClickRemove = onClickRemove;
        vm.onSubmit = onSubmit;

        activate();

        function activate() {
            if (!vm.isNewOPOC && vm.opoc.aosUser.vmisUser.adminProfile.responsibilities.opoc) {
                var promiseArray = [];

                // Get all communities' orgs before building flat responsibilities list
                // Implemented similarly to manageActivity.controller.js:233
                vm.opoc.aosUser.vmisUser.adminProfile.responsibilities.opoc.forEach(function (respComm) {
                    var fillComm = findCommInFullList(respComm);

                    // If target user has responsibilities outside of active user's purview,
                    // do not attempt to fill drop down for those responsibilities.
                    if (fillComm) {
                        promiseArray.push(authentication.getOrganizations({
                            communityAffiliationId: respComm.communityAffiliationId,
                            getAll: true
                        })
                            .then(function (response) {
                                // Populate this particular vm.communities entry with its organizations
                                fillComm.organizations = [];
                                // This fills the object for future uses of the modal
                                response.organizations.forEach(function (org) {
                                    fillComm.organizations.push(org);
                                });
                            })
                            .catch(function (error) {
                                setError(error);
                            }));
                    }
                });

                $q.all(promiseArray)
                    .then(function () {
                        fillTargetUserResponsibilities();
                    })
                    .catch(function (error) {
                        setError(error);
                    });
            }
            else {
                vm.selectedCommunity = vm.communities[0];
                onCommunityChange();
            }
        }

        function onCommunityChange() {
            return getCommOrgs();
        }

        function onSelectOrg(item, model) {
            addCommDataToOrg(vm.opocResponsibilities.length - 1, vm.selectedCommunity);
        }

        function onClickRemove() {
            vm.isLoading = true;

            var modalInstance = $modal.open({
                templateUrl: 'app/components/administrator/people/actionDirectives/confirmRoleRemove.view.html',
                controller: 'ConfirmRoleRemoveController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    params: function () {
                        return {
                            userInfo: vm.opoc,
                            role: "Organization Point Of Contact"
                        };
                    }
                }
            });

            modalInstance.result
                .then(function () {
                    close('remove', vm.opoc.masterUserId, originalResponsibilities);
                    vm.isLoading = false;
                })
                .catch(function (error) {
                    if (angular.isDefined(error))
                        setError(error);
                    vm.isLoading = false;
                });
        }

        function onSubmit() {
            var promiseArray = [];
            vm.isLoading = true;
            if (!vm.opoc.masterUserId) {
                promiseArray.push(authentication.getMasterUserIdFromEmail({
                    email: vm.opoc.masterUserEmail
                })
                    .then(function (response) {
                        if (response.success === true) {
                            clearError();
                            vm.opoc.masterUserId = response.masterUserId;
                            authentication.getUserProfile({masterUserId: vm.opoc.masterUserId})
                                .then(function (userProfile) {
                                    var isUserOPOC = userProfile.roles.vmisUserRoles.filter(function (role) {
                                        return role === "AVC OPOC";
                                    }).length === 1;

                                    if (vm.isNewOPOC && isUserOPOC) {
                                        if (!usersServices.isBasicAdminUserDataComplete(userProfile))
                                        {
                                            setError("This user cannot be added due to incomplete data");
                                            vm.isLoading = false;
                                            return;
                                        }

                                        var isUserInOPOCCommunity = userProfile.aosUser.vmisUser.adminProfile.responsibilities.opoc.filter(function (opocResp) {
                                            return opocResp.communityAffiliationId === vm.selectedCommunity.communityAffiliationId;
                                        }).length === 1;

                                        if (isUserInOPOCCommunity) {
                                            setError("The account associated with the email address is already an OPOC. You must find and edit that user's roles in the table.");
                                            vm.isLoading = false;
                                            return;
                                        } else {
                                            close('edit', vm.opoc.masterUserId, vm.opocResponsibilities);
                                            return;
                                        }
                                    }

                                    if (vm.isNewOPOC)
                                        close('save', vm.opoc.masterUserId, vm.opocResponsibilities);
                                    else
                                        close('edit', vm.opoc.masterUserId, vm.opocResponsibilities);
                                })
                                .catch(function (error) {
                                    setError(error);
                                    vm.isLoading = false;
                                });
                        }
                    })
                    .catch(function (error) {
                        setError(error);
                        vm.isLoading = false;
                    }));
            }

            else {
                promiseArray.push(authentication.getUserProfile({masterUserId: vm.opoc.masterUserId})
                    .then(function (userProfile) {
                        var isUserOPOC = userProfile.roles.vmisUserRoles.filter(function (role) {
                            return role === "AVC OPOC";
                        }).length === 1;

                        if (vm.isNewOPOC && isUserOPOC) {
                            if (!usersServices.isBasicAdminUserDataComplete(userProfile))
                            {
                                setError("This user cannot be added due to incomplete data");
                                vm.isLoading = false;
                                return;
                            }

                            var isUserInOPOCCommunity = userProfile.aosUser.vmisUser.adminProfile.responsibilities.opoc.filter(function (opocResp) {
                                return opocResp.communityAffiliationId === vm.selectedCommunity.communityAffiliationId;
                            }).length === 1;

                            if (isUserInOPOCCommunity) {
                                setError("The account associated with the email address is already an OPOC. You must find and edit that user's roles in the table.");
                                vm.isLoading = false;
                                return;
                            } else {
                                close('edit', vm.opoc.masterUserId, vm.opocResponsibilities);
                                return;
                            }
                        }

                        if (vm.isNewOPOC)
                            close('save', vm.opoc.masterUserId, vm.opocResponsibilities);
                        else
                            close('edit', vm.opoc.masterUserId, vm.opocResponsibilities);
                    })
                    .catch(function (error) {
                        setError(error);
                        vm.isLoading = false;
                    }));
            }

            $q.all(promiseArray).catch(function (error) {
                    setError(error);
                });
        }

        function isInActiveUserResponsibilities(resp) {
            for (var i = 0; i < vm.communities.length; i++) {
                if (resp.communityAffiliationId === vm.communities[i].communityAffiliationId)
                    return true;
            }

            return false;
        }

        // Called once all communities have organizations loaded
        function fillTargetUserResponsibilities() {
            // If existing role with responsibilities, fill ui-select
            vm.opoc.aosUser.vmisUser.adminProfile.responsibilities.opoc.forEach(function (respComm) {
                // Find community to process
                var curComm = findCommInFullList(respComm);

                // If target user's responsibility is outside of active user's purview, just add
                // info without connecting it to the existing lists (that do not exist for it, anyway).
                if (curComm) {
                    // Add each responsible organization by finding its entry in the full list
                    respComm.organizations.forEach(function (respOrg) {
                        vm.opocResponsibilities.push(_.find(curComm.organizations, function (org) {
                            return org.volunteerOrganizationId === respOrg.volunteerOrganizationId;
                        }));
                        // Add parent community for display purposes
                        addCommDataToOrg(vm.opocResponsibilities.length - 1, curComm);
                    });
                }
                else {
                    respComm.organizations.forEach(function (respOrg) {
                        respOrg.organizationName = respOrg.volunteerOrganizationName;
                        respOrg.readOnly = true;
                        vm.opocResponsibilities.push(respOrg);
                        addCommDataToOrg(vm.opocResponsibilities.length - 1, respComm);
                    });
                }
            });
            originalResponsibilities = angular.copy(vm.opocResponsibilities);

            vm.selectedCommunity = vm.communities[0];
            onCommunityChange();
        }

        function findCommInFullList(inComm) {
            // Accept both community object with ID and ID itself
            if (inComm.communityAffiliationId)
                inComm = inComm.communityAffiliationId;

            return _.find(vm.communities, function (comm) {
                return inComm === comm.communityAffiliationId;
            });
        }

        function addCommDataToOrg(index, curComm) {
            if (!vm.opocResponsibilities[index].communityAffiliationId) {
                vm.opocResponsibilities[index].communityAffiliationName = curComm.communityAffiliationName;
                vm.opocResponsibilities[index].communityAffiliationId = curComm.communityAffiliationId;
            }
        }

        function getCommOrgs() {
            vm.isLoading = true;
            var comm = vm.selectedCommunity;
            if (comm.organizations.length === 0) {
                return authentication.getOrganizations({
                    communityAffiliationId: comm.communityAffiliationId,
                    getAll: true
                })
                    .then(function (response) {
                        comm.organizations = [];
                        // This fills the object for future uses of the modal
                        response.organizations.forEach(function (org) {
                            comm.organizations.push(org);
                        });
                    })
                    .catch(function (error) {
                        setError(error);
                    })
                    .finally(function () {
                        vm.isLoading = false;
                    });
            }
            else {
                vm.isLoading = false;
                return $q.when();
            }
        }

        function setError(error) {
            var errorStr = error.error ? error.error : (error.message ? error.message : error);
            if (errorStr === 'cancel' || errorStr === 'backdrop click')
                return;
            vm.errorText = errorStr;
        }

        function clearError() {
            vm.errorText = "";
        }

        function close(action, masterUserId, responsibilities) {
            $modalInstance.close({
                action: action,
                masterUserId: masterUserId,
                responsibilities: reformatResponsibilities(responsibilities)
            });
        }

        function reformatResponsibilities(resp) {
            var outResp = [];
            resp.forEach(function (org) {
                // Check if community for org exists
                var outComm = _.find(outResp, function (comm) {
                    return org.communityAffiliationId === comm.communityAffiliationId;
                });

                // If new community
                if (!outComm) {
                    var newComm = findCommInFullList(org.communityAffiliationId);
                    // If not found, responsibility is outside of active user's purview
                    // and will not be included in API call.
                    if (newComm) {
                        newComm.organizations = [org];
                        outResp.push(newComm);
                    }
                }
                else {
                    outComm.organizations.push(org);
                }
            });

            return outResp;
        }
    }
})();