(function () {
    'use strict';

    angular
        .module('app.vmis')
        .config(routes);

    routes.$inject = ['$stateProvider'];

    function routes($stateProvider) {
        $stateProvider
            .state('underConstruction', {
                url: '/',
                templateUrl: 'app/components/underConstructionPage/underConstructionPage.view.html',
                controller: 'UnderConstructionController',
                controllerAs: 'vm',
                title: 'Under Construction'
            });
    }
})();