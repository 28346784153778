(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .controller('AddToPositionController', AddToPositionController);

    AddToPositionController.$inject = ['$modalInstance', 'authentication', 'params'];

    function AddToPositionController($modalInstance, authentication, params) {
        var vm = this;

        var occupiedPositions = params.occupiedPositions;
        vm.targetUser = params.targetUser;
        var responsibilities = params.responsibilities;

        vm.title = "Add To Position - ";

        vm.title += vm.targetUser.firstName + " " + vm.targetUser.lastName;

        vm.selectedCommunity = null;
        vm.selectedOrganization = null;
        vm.selectedPosition = null;
        vm.isLoading = true;

        var isAVCC = angular.isDefined(responsibilities.avcc);
        var isOPOC = angular.isDefined(responsibilities.opoc);

        if (isAVCC)
        {
            vm.communities = responsibilities.avcc;

            // If AVCC, communities specified but need organizations arrays
            vm.communities.forEach(function (comm) {
                comm.organizations = [];
            });

            if (isOPOC)
            {
                for (var i = 0, len = responsibilities.opoc.length; i < len; i++) {
                    var comm = responsibilities.opoc[i];
                    var duplicateComm = (responsibilities.avcc.indexOf(comm) !== -1);
                    if (!duplicateComm)
                    {
                        comm.organizations.forEach(function (org) {
                            org.positions = [];
                        });
                        vm.communities.push(comm);
                    }
                }
            }
        }
        else
        {
            vm.communities = responsibilities.opoc;

            // If OPOC, organizations specified but need positions arrays
            vm.communities.forEach(function (comm) {
                comm.organizations.forEach(function (org) {
                    org.positions = [];
                });
            });
        }

        vm.onSelectCommunity = onSelectCommunity;
        vm.onSelectOrganization = onSelectOrganization;
        vm.onSubmit = onSubmit;

        activate();

        function activate() {
            if(!angular.isDefined(vm.communities) || vm.communities === null || vm.communities.length === 0){
                vm.formError = "This user is not allowed to add other users to positions";
                vm.isLoading = false;
                close('error', "This user is not allowed to add other users to positions");
            }
            vm.selectedCommunity = vm.communities[0];
            var params = {
                community: vm.communities[0]
            };

            selectFirstOrgFromCommunity(params).then(function(selectedVolOrg) {
                getOpportunities(selectedVolOrg);
            });
        }

        function getOpportunities(selectedVolOrg){

        // Volunteers can be added to positions in other communities or organizations
        // regardless of what their affiliated community is
        authentication.getOpportunitiesAdmin({volunteerOrganizationId: selectedVolOrg, getAll: true})
            .then(function (response) {
                // For each opportunity, place in hierarchical list
                response.opportunities.forEach(function (opp) {
                    var opportunityPlacementFound = false;
                    // Find existing community to place opportunity in
                    for (var i = 0; i < vm.communities.length; i++) {
                        if (opp.communityAffiliation === vm.communities[i].communityAffiliationName) {
                            // Find existing organization to place opportunity in
                            for (var j = 0; j < vm.communities[i].organizations.length; j++) {
                                if (opp.volunteerOrganizationId === vm.communities[i].organizations[j].volunteerOrganizationId) {
                                    addNewPosition(opp, j, i);
                                    opportunityPlacementFound = true;
                                    break; // Stop searching through organizations
                                }
                            }
                            // If new or first organization
                            if (!opportunityPlacementFound) {
                                addNewPosition(opp, opp.organization, i);
                                opportunityPlacementFound = true;
                            }
                            break; // Stop searching through communities
                        }
                    }
                    // If new or first community
                    if (!opportunityPlacementFound) {
                        addNewPosition(opp, opp.organization, opp.communityAffiliation);
                    }
                });

                if(vm.selectedOrganization.positions.length > 0){
                    vm.selectedPosition = vm.selectedOrganization.positions[0];
                }

                vm.isLoading = false;

            })
            .catch(function (error) {
                vm.formError = error;
                vm.isLoading = false;
            });

        }

        // "position" is new object to add
        // "org" and "comm" are objects if they are new or indices if already exist
        function addNewPosition(position, org, comm) {
            // If volunteer has an application for this position,
            // this modal will return an error

            // Check if user is already accepted into position
            var positionAvailable = position.volunteerPositionStatus !== "Closed";
            if (positionAvailable) {
                occupiedPositions.forEach(function (occPos) {
                    if (occPos.id === position.volunteerOrganizationOpportunityId)
                        positionAvailable = false;
                });
            }

            var commIndex = null;
            var orgIndex = null;

            if (angular.isNumber(comm))
                commIndex = comm;
            // If community object passed in, it is new
            else {
                var newComm = {
                    communityAffiliationId: 0,
                    communityAffiliationName: "",
                    organizations: []
                };
                vm.communities.push(newComm);
                commIndex = vm.communities.length - 1;
            }

            if (angular.isNumber(org))
                orgIndex = org;
            // If organization object passed in, it is new
            else {
                // Use format from OPOC responsibilities object (Name, not FullName)
                var newOrg = {
                    volunteerOrganizationId: position.volunteerOrganizationId,
                    volunteerOrganizationName: position.volunteerOrganization,
                    positions: []
                };
                vm.communities[commIndex].organizations.push(newOrg);
                orgIndex = vm.communities[commIndex].organizations.length - 1;
            }

            if (positionAvailable)
                vm.communities[commIndex].organizations[orgIndex].positions.push(position);

            return true;
        }

        function onSelectCommunity() {
            vm.selectedPosition = null;
            selectFirstOrgFromCommunity({community: vm.selectedCommunity})
                .then(function(selectedVolOrg){
                    getOpportunities(selectedVolOrg);
                });
        }

        function onSelectOrganization() {
            vm.selectedOrganization.positions = [];
            vm.selectedPosition = null;
            getOpportunities(vm.selectedOrganization.volunteerOrganizationId);

        }

        function selectFirstOrgFromCommunity(params) {
            var selectedVolOrgPromise = Promise.resolve(null);

            // return the first organization of the community otherwise get a list of orgs and then return the first one
            if(params.community.organizations.length > 0){
                vm.selectedOrganization = params.community.organizations[0];
                vm.selectedOrganization.positions = [];
                selectedVolOrgPromise = Promise.resolve(params.community.organizations[0].volunteerOrganizationId);
            }

            else {
                selectedVolOrgPromise = authentication.getOrganizations({communityAffiliationId: params.community.communityAffiliationId, isActive: true, getAll: true})
                    .then(function(orgs){
                        orgs.organizations.forEach(function(org){
                            if(org.organizationName != "Volunteer Submitted") {
                                var newOrg = {
                                    volunteerOrganizationId: org.volunteerOrganizationId,
                                    volunteerOrganizationName: org.organizationName,
                                    positions: []
                                };
                                params.community.organizations.push(newOrg);
                            }
                        });
                        if(params.community.organizations.length > 0) {
                            vm.selectedOrganization = params.community.organizations[0];
                            return params.community.organizations[0].volunteerOrganizationId;
                        }
                        else {
                            return null;
                        }
                    });
            }

            return selectedVolOrgPromise;
        }

        function onSubmit() {
            vm.isLoading = true;

            var params = {
                volunteerOpportunityId: vm.selectedPosition.volunteerOrganizationOpportunityId,
                masterUserId: vm.targetUser.masterUserId
            };

            authentication.setVolunteerForOpportunity(params)
                .then(function (response) {
                    close('success', "Volunteer successfully added to position!", vm.selectedPosition);
                })
                .catch(function (error) {
                    close('error', "Failed to add volunteer to position: " + error);
                });
        }

        function close(action, msg, position) {
            $modalInstance.close({
                action: action,
                message: msg,
                position: position ? position : null
            });
        }
    }
})();