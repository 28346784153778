(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('StandardPositionDetailsController', StandardPositionDetailsController);

    StandardPositionDetailsController.$inject = ['$modalInstance', '$modal', 'authentication', 'title', 'standardPosition', 'standardOrganizations', 'config'];

    function StandardPositionDetailsController($modalInstance, $modal, authentication, title, standardPosition, standardOrganizations, config) {
        var vm = this;
        var originalStandardPositionTitle = standardPosition.title;

        vm.title = title;
        vm.standardPosition = standardPosition;
        vm.standardOrganizations = standardOrganizations;

        vm.isProductionWebsite = null;
        vm.bannerMessage = null;

        vm.onSave = onSave;
        vm.onClickDelete = onClickDelete;

        activate();

        function activate() {
            if (standardOrganizations.length === 1) {
                vm.standardPosition.standardOrganization = standardOrganizations[0];
            }
            config.get().then(function (configData) {
                vm.isProductionWebsite = configData.isProductionWebsite;
                vm.bannerMessage = configData.bannerMessage;
            });
        }

        function onSave() {
            vm.isLoading = true;
            return authentication.setOpportunity({
                createOrUpdateStandardPosition: true,
                volunteerOrganizationTemplateId: vm.standardPosition.standardOrganization.id,
                volunteerOrganizationsOpportunitiesTemplateId: vm.standardPosition.id || undefined,
                volunteerOrganizationsOpportunitiesTemplateName: vm.standardPosition.title,
                title: vm.standardPosition.title,
                duties: vm.standardPosition.duties || '',
                qualifications: vm.standardPosition.qualifications || '',
                training: vm.standardPosition.training || ''
            }).then(function () {
                $modalInstance.close();
            }).catch(function (error) {
                vm.formError = error;
            }).finally(function () {
                vm.isLoading = false;
            });
        }

        function onClickDelete() {
            return $modal.open({
                templateUrl: 'app/components/administrator/standardPositions/standardPositionDelete.view.html',
                controller: 'StandardPositionDeleteController',
                controllerAs: 'vm',
                size: 'tiny',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    standardPositionName: function () {
                        return originalStandardPositionTitle;
                    }
                }
            }).result.then(function () {
                vm.isLoading = true;
                return authentication.deleteOpportunity({
                    volunteerOrganizationsOpportunitiesTemplateId: standardPosition.id
                }).then(function () {
                    $modalInstance.close();
                });
            }, function () {
                //dismiss
            }).catch(function (error) {
                vm.formError = error;
            }).finally(function () {
                vm.isLoading = false;
            });
        }
    }
})();