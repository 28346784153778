(function () {
    'use strict';

    angular
        .module('app')
        .directive('textAreaWithCount', textAreaWithCount);

    textAreaWithCount.$inject = [];

    function textAreaWithCount() {
        return {
            restrict: 'E',
            templateUrl: 'app/common/directives/textAreaWithCount/textAreaWithCount.template.html',
            controller: function() {},
            controllerAs: 'vm',
            bindToController: true,
            scope: {
                defaultShownText: '@',
                maxGivenLength: '@',
                isRequired: '<',
                isDisabled: '<',
                isReadOnly: '@',
                numberRows: '@',
                modelName: '=',
                isHidden: '<'
            }
        };
    }
    
})();