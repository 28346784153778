(function () {
    'use strict';

    angular
        .module('app')
        .directive('vmisHoursStatus', VmisHoursStatus);

    VmisHoursStatus.$inject = [];

    function VmisHoursStatus() {
        return {
            restrict: 'E',
            templateUrl: 'app/common/directives/vmisHoursStatus/vmisHoursStatus.template.html',
            controller: function () {},
            controllerAs: 'vm',
            scope: {
                status: '<'
            },
            bindToController: true
        };
    }
})();