(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('ConfirmationController', ConfirmationController);

    ConfirmationController.$inject = ['$modalInstance', 'title', 'body'];

    function ConfirmationController($modalInstance, title, body) {
        var vm = this;

        vm.body = body;
        vm.title = title;

        vm.onCancel = onCancel;
        vm.onConfirm = onConfirm;

        activate();

        function activate() {
        }

        function onConfirm() {
            $modalInstance.close();
        }

        function onCancel() {
            $modalInstance.dismiss('cancel');
        }
    }
})();