(function () {
    'use strict';

    angular
        .module('app')
        .directive('vmisDropdown', vmisDropdown);

    vmisDropdown.$inject = [];

    function vmisDropdown() {
        return {
            restrict: 'E',
            transclude: {
                'toggle': 'vmisDropdownToggle',
                'pane': 'vmisDropdownPane'
            },
            templateUrl: 'app/common/directives/dropdown/dropdown.view.html',
            controller: 'DropdownController',
            controllerAs: 'vm',
            scope: {
                active: '=?isOpen'
            },
            bindToController: true
        };
    }
})();