(function () {
    'use strict';

    angular
        .module('app.vmisHoursEntry')
        .controller('HoursErrorController', HoursErrorController);

    HoursErrorController.$inject = ['error'];

    function HoursErrorController(error) {
        var vm = this;
        vm.error = error;
    }
})();