(function () {
    'use strict';

    angular
        .module('administrator.hours')
        .controller('HoursController', HoursController);

    HoursController.$inject = ['$q', '$modal', '$scope', 'moment', 'authentication', 'utilsServices', 'loadStatus', '_'];

    function HoursController($q, $modal, $scope, moment, authentication, utilsServices, loadStatus, _) {
        var vm = this;

        vm.isLoading = true;

        vm.formError = "";

        vm.statusTypes = [
            'All',
            'Submitted',
            'Certified',
            'Rejected',
            'Deleted'
        ];
        vm.activities = [];
        vm.loadStatus = loadStatus.init();

        vm.communities = [];
        vm.currentDate = new Date();
        vm.filter = {
            endDate: moment().toDate(),
            startDate: moment().date() < 15 ?
                moment().subtract(1, 'months').startOf('month').toDate() :
                moment().startOf('month').toDate(),
            status: 'Submitted',
            firstName: "",
            lastName: "",
            selectedCommunity: null,
            selectedOrganization: null
        };
        vm.sortReverse = true;
        vm.sortColumn = 'date';

        vm.disableOrgDropDown = disableOrgDropDown;
        vm.onSelectCommunity = onSelectCommunity;
        vm.onSelectOrganization = onSelectOrganization;
        vm.onActivityClick = onActivityClick;
        vm.onStatusChange = onStatusChange;
        vm.onChangeDates = onChangeDates;
        vm.onCheckAll = onCheckAll;
        vm.onCheckActivity = onCheckActivity;
        vm.onClickBatch = onClickBatch;
        vm.onLoadMore = onLoadMore;
        vm.onLoadAll = onLoadAll;
        vm.onClickSearch = onClickSearch;

        activate();

        function activate() {
            loadStatus.setup(vm.loadStatus, "hours")
                .then(function () {
                    getCommunities(); // Calls getActivities()
                });
        }

        function getCommunities() {
            return authentication.getUserProfile().then(function (response) {
                // Set up communities and organizations that the
                // active user is responsible for for the drop downs
                return utilsServices.combineCommunityResponsibilities(response);
            }).then(function (communities) {
                if (_.isArray(communities) && communities.length !== 1) {
                    communities.unshift({
                        name: 'All',
                        organizations: []
                    });
                }
                _.forEach(communities, function (community) {
                    if (_.isArray(community.organizations) && community.organizations.length !== 1) {
                        community.organizations.unshift({
                            name: 'All'
                        });
                    }
                });
                vm.communities = communities;
                // Use lack of ID as cue for this entry
                vm.filter.selectedCommunity = vm.communities[0];
                return onSelectCommunity();
            }).catch(displayError);
        }

        function getActivities(index, num) {
            vm.isLoading = true;

            var params = {
                startingRowIndex: index,
                numberOfRows: num,
                // If input box has invalid date format and a drop down is changed,
                // the date here will be undefined
                startDate: vm.filter.startDate ? new Date(vm.filter.startDate).toISOString() : undefined,
                endDate: vm.filter.endDate ? new Date(vm.filter.endDate).toISOString() : undefined,
                volunteerHoursStatus: vm.filter.status !== "All" ? vm.filter.status : undefined,
                communityAffiliationId: vm.filter.selectedCommunity.id || undefined,
                volunteerOrganizationId: vm.filter.selectedOrganization.id || undefined,
                firstName: vm.filter.firstName || undefined,
                lastName: vm.filter.lastName || undefined
            };

            return authentication.getHoursAdmin(params)
                .then(function (response) {
                    getActivitiesSuccess(response, index, num);
                }).catch(displayError).finally(function (err) {
                    vm.isLoading = false;
                    getActivitiesError(err);
                });
        }

        function onClickSearch () {
            vm.loadStatus.currentIndex = 0;
            getActivities(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows);
        }

        function fillCommOrgs() {
            return authentication.getOrganizations({
                communityAffiliationId: vm.filter.selectedCommunity.id,
                getAll: true
            }).then(function (response) {
                var organizations = _.map(response.organizations, function (organization) {
                    return {
                        id: organization.volunteerOrganizationId,
                        name: organization.organizationName
                    };
                });
                if (_.isArray(organizations) && organizations.length !== 1) {
                    organizations.unshift({
                        name: "All"
                    });
                }
                vm.filter.selectedCommunity.organizations = organizations;
            }).catch(function (error) {
                $scope.$emit('people:alert', error);
                vm.isLoading = false;
            });
        }

        function disableOrgDropDown() {
            return vm.isLoading || !angular.isDefined(vm.filter.selectedCommunity.id);
        }

        function onSelectCommunity() {
            var promise = vm.filter.selectedCommunity.organizations === null ? fillCommOrgs() : $q.when();
            return promise
                .then(function () {
                    vm.loadStatus.currentIndex = 0;
                    vm.filter.selectedOrganization = vm.filter.selectedCommunity.organizations[0];
                    return getActivities(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows);
                });
        }

        function onSelectOrganization() {
            vm.loadStatus.currentIndex = 0;
            // This function is called on community change, which is unnecessary
            if (!vm.isLoading)
                getActivities(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows);
        }

        function onActivityClick(activity) {
            var modalInstance = $modal.open({
                templateUrl: 'app/components/administrator/hours/manageActivity.view.html',
                controller: 'ManageActivityController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    activity: function () {
                        return angular.copy(activity);
                    },
                    rejectOnly: function () {
                        return false;
                    }
                }
            });

            modalInstance.result
                .then(function () {
                    vm.loadStatus.currentIndex = 0;
                    return getActivities(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows);
                }).catch(displayError);
        }

        function onStatusChange() {
            vm.loadStatus.currentIndex = 0;
            getActivities(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows);
        }

        function onChangeDates(startDate, endDate) {
            if (angular.isDefined(startDate) && angular.isDefined(endDate)) {
                vm.loadStatus.currentIndex = 0;
                getActivities(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows);
            }
        }

        function onCheckAll() {
            vm.activities.forEach(function (activity) {
                activity.isChecked = activity.status !== 'Deleted' ? vm.checkAll : false;
            });
            vm.activityChecked = vm.checkAll;
        }

        function onCheckActivity() {
            vm.activityChecked = vm.activities.some(function (activity) {
                return activity.isChecked;
            });

            var activities = _.filter(vm.activities, function (activity) {
                return activity.status !== 'Deleted';
            });
            vm.checkAll = activities.length ? _.every(activities, function (activity) {
                return activity.isChecked;
            }) : false;
        }

        function onClickBatch(status) {
            $modal.open({
                templateUrl: 'app/components/administrator/hours/batchConfirmation.view.html',
                controller: 'BatchConfirmationController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    status: function () {
                        return status;
                    }
                }
            }).result
                .then(function (reviewerNote) {
                    switch (status) {
                        case 'Certify':
                            status = 'Certified';
                            break;
                        case 'Reject':
                            status = 'Rejected';
                            break;
                    }
                    var params = {
                        volunteerHoursStatus: status,
                        hoursIds: vm.activities.filter(function (activity) {
                            return activity.isChecked;
                        }).map(function (activity) {
                            return activity.id;
                        }),
                        reviewerNotes: reviewerNote || ''
                    };

                    return authentication.manageVolunteerHours(params);
                })
                .then(function () {
                    vm.loadStatus.currentIndex = 0;
                    return getActivities(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows);
                }).catch(function (error) {
                    displayError(error);
                });
        }

        function displayError(error) {
            if (_.isUndefined(error)) return;
            var errorStr = utilsServices.parseErrorString(error);

            vm.isLoading = false;

            if (errorStr === 'cancel' || errorStr === 'backdrop click' || errorStr === 'ok')
                return;
            if (errorStr)
                vm.formError = errorStr;
        }

        function getActivitiesSuccess(response, index, num) {
            if (index === 0) {
                vm.loadStatus.currentIndex = 0;
                vm.activities = [];
            }
            // Either set current index to max if all users loaded or increment by rows loaded
            vm.loadStatus.currentIndex = (vm.loadStatus.currentIndex + num > response.count) ? response.count : (vm.loadStatus.currentIndex + num);
            // Track total number of users not loaded for "Load All" button
            vm.loadStatus.numResultsNotLoaded = response.count - vm.loadStatus.currentIndex;
            // Set text for "Load More" button
            vm.loadStatus.nextResultLoadNum = (response.count === vm.loadStatus.currentIndex) ? 0 :
                (vm.loadStatus.numResultsNotLoaded > num) ? num : vm.loadStatus.numResultsNotLoaded;

            vm.activities = vm.activities.concat(_.map(response.hours, function (activity) {
                return {
                    id: activity.volunteerHoursId,
                    position: {
                        id: null,
                        title: activity.volunteerOpportunity
                    },
                    organization: activity.orgActive ? activity.volunteerOrganization : activity.volunteerOrganization + " (inactive)",
                    community: activity.communityAffiliation,
                    volunteer: {
                        firstName: activity.firstName,
                        lastName: activity.lastName
                    },
                    documents: activity.documents,
                    hours: activity.hours,
                    status: activity.volunteerHoursStatus,
                    type: activity.dayOfService ? 'Daily' : 'Period',
                    date: activity.dayOfService || activity.periodOfService,
                    volunteerNote: activity.userNotes || '',
                    reviewerNote: activity.reviewerNotes || ''
                };
            }));

            onCheckActivity();
            vm.sortReverse = true;
            vm.sortColumn = 'date';
        }

        function getActivitiesError(error) {
            if (angular.isDefined(error)) {
                var errorStr = error.error ? error.error : error;
                $scope.$emit('hours:alert', errorStr);
            }
            else
                $scope.$emit('hours:alert', "Failed to load list! Please try again.");

            vm.isLoading = false;
        }

        function onLoadMore() {
            getActivities(vm.loadStatus.currentIndex, vm.loadStatus.nextResultLoadNum);
            vm.checkAll = false;
        }

        function onLoadAll() {
            getActivities(vm.loadStatus.currentIndex, vm.loadStatus.numResultsNotLoaded);
            vm.checkAll = false;
        }
    }
})();
