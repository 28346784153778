(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('StandardOrganizationDeleteController', StandardOrganizationDeleteController);

    StandardOrganizationDeleteController.$inject = ['standardOrganizationName'];

    function StandardOrganizationDeleteController(standardOrganizationName) {
        var vm = this;

        vm.standardOrganizationName = standardOrganizationName;
    }
})();