(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('DateFilterController', DateFilterController);

    DateFilterController.$inject = ['$modalInstance', 'moment', 'params'];

    function DateFilterController($modalInstance, moment, params) {
        var vm = this;

        vm.isLoading = true;

        vm.filter = {};
        vm.filter.startDate = params.startDate || null;
        vm.filter.endDate = params.endDate || null;
        vm.currentDate = new Date();
        vm.filter.workEmail = params.workEmail || null;

        vm.onClear = onClear;
        vm.onFilter = onFilter;

        activate();

        function activate() {
            vm.isLoading = false;
        }

        function onClear() {
            vm.isLoading = true;

            vm.filter.startDate = null;
            vm.filter.endDate = null;
            vm.filter.workEmail = null;

            onFilter();
        }

        function onFilter() {
            vm.isLoading = true;

            var outputFilter = angular.copy(vm.filter);

            // If both fields have data, confirm valid before continuing
            if (outputFilter.startDate && outputFilter.endDate && moment(outputFilter.startDate).isAfter(outputFilter.endDate, 'day')) {
                close('error', "Start Date must be before End Date when filtering.");
            }
            close('success', "Filter successfully created!", outputFilter);
        }

        function close(action, msg, filter) {
            $modalInstance.close({
                action: action,
                message: msg,
                filter: filter || null
            });
        }
    }
})();