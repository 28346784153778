(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('DeleteConfirmationController', DeleteConfirmationController);

    DeleteConfirmationController.$inject = ['$modalInstance', 'firstName', 'lastName', 'positionTitle'];

    function DeleteConfirmationController($modalInstance, firstName, lastName, positionTitle) {
        var vm = this;

        vm.firstName = firstName;
        vm.lastName = lastName;
        vm.positionTitle = positionTitle;

        vm.onDelete = onDelete;
        vm.onCancel = onCancel;

        activate();

        function activate() {
        }

        function onDelete() {
            $modalInstance.close();
        }

        function onCancel() {
            $modalInstance.dismiss();
        }
    }
})();