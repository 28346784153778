(function () {
    'use strict';

    angular
        .module('app.vmis')
        .controller('BeginMigrationController', BeginMigrationController);

    BeginMigrationController.$inject = ['$modalInstance'];

    function BeginMigrationController($modalInstance) {
        var vm = this;

        vm.onClickOk = onClickOk;

        function onClickOk() {
            $modalInstance.dismiss({});
        }
    }
})();