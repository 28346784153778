(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('OrgCommFilterController', OrgCommFilterController);

    OrgCommFilterController.$inject = ['$modalInstance', 'moment', 'authentication', 'params', '_', 'config'];

    function OrgCommFilterController($modalInstance, moment, authentication, params, _, config) {
        var vm = this;

        vm.isLoading = true;

        vm.filteringVol = params.role === "Volunteer";

        config.get().then(function (configData) {
            vm.defaultNumRows = configData.defaultNumRows.users;
            vm.maxNumRows = configData.defaultNumRows.maxLoadAll;
        });

        vm.defaultStartingIndex = 0;

        vm.filter = {
            communities: [],
            selectedCommunity: {},
            selectedOrganizations: [],
        };

        // Only allow filtering on activity for volunteers
        if (vm.filteringVol) {
            vm.filter.activities = [
                {
                    name: "All",
                    value: undefined
                },
                {
                    name: "At least 1 Active position",
                    value: true
                },
                {
                    name: "No Active positions",
                    value: false
                }];
            vm.filter.isActive = vm.filter.activities[0];
        }

        if (params.communities)
            vm.filter.communities = params.communities;
        
        vm.filter.selectedCommunity = params.selectedCommunity ? vm.filter.communities.filter(function (comm) {
                return comm.communityAffiliationId === params.selectedCommunity;
            })[0] : vm.filter.communities[0];

        if(params.selectedOrganizations)
        {
            params.selectedOrganizations.forEach(function(selectedOrg){
                vm.selectedOrg = vm.filter.selectedCommunity.organizations.filter(function (org){
                    return org.volunteerOrganizationId == selectedOrg;
                });
                vm.resultAddOrg = vm.selectedOrg[0]?vm.filter.selectedOrganizations.push(vm.selectedOrg[0]):null;
            });
        }

        if (vm.filteringVol && (params.isActive !== undefined))
            vm.filter.isActive = vm.filter.activities.filter(function (activity) {
                return activity.value === params.isActive;
            })[0];

        vm.filter.startDate = params.startDate || null;
        vm.filter.endDate = params.endDate || null;
        vm.currentDate = new Date();

        vm.filter.workEmail = params.workEmail || null;
        vm.filter.volunteerEmail = params.volunteerEmail || null;

        vm.filter.startingRowIndex = params.startingRowIndex || null;
        vm.filter.numberOfRows = params.numberOfRows || null;

        vm.organizationText = "Organization";
        vm.searchEmailText = "Work Email";
        if (vm.filteringVol) {
            vm.organizationText += " of Activity";
            vm.searchEmailText = "Volunteer Email";
        }
        else // Only other role for this modal are OPOCs
            vm.organizationText += " of Responsibility";

        vm.disableOrgDropDown = disableOrgDropDown;
        vm.disableCommunity = disableCommunity;
        vm.onSelectCommunity = onSelectCommunity;
        vm.onSelectOrg = onSelectOrg;
        vm.onClear = onClear;
        vm.onFilter = onFilter;

        activate();

        function activate() {
            // Should only occur if program manager or AVCC/Assistant,
            // since loading all orgs on People page takes too long for PM
            // and AVCC/Assistant responsibilities are on community level.
            if (vm.filter.selectedCommunity.organizations.length === 0)
                fillCommOrgs().then(function(_){
                    if(params.selectedOrganizations)
                    {
                        params.selectedOrganizations.forEach(function(selectedOrg){
                            vm.selectedOrg = vm.filter.selectedCommunity.organizations.filter(function (org){
                                if(org.volunteerOrganizationId !== -1) {
                                    return org.volunteerOrganizationId == selectedOrg;
                                }
                            });
                            vm.resultAddOrg = vm.selectedOrg[0]?vm.filter.selectedOrganizations.push(vm.selectedOrg[0]):null;
                        });
                    }
                });
            else
                vm.isLoading = false;
        }

        function fillCommOrgs() {
            // Do nothing with "All" community placeholder
            if (vm.filter.selectedCommunity.communityAffiliationId === -1) {
                vm.isLoading = false;
                return;
            }
            else
                vm.isLoading = true;

            return authentication.getOrganizations({
                // TODO: Label inactives
                communityAffiliationId: vm.filter.selectedCommunity.communityAffiliationId,
                getAll: true
            })
                .then(function (response) {
                    vm.filter.selectedCommunity.organizations = [];
                    response.organizations.forEach(function (org) {
                        vm.filter.selectedCommunity.organizations.push(org);
                    });
                    vm.filter.selectedOrganizations = [];
                    vm.isLoading = false;
                })
                .catch(function (error) {
                    $scope.$emit('people:alert', error);
                    vm.isLoading = false;
                });
        }

        function disableOrgDropDown() {
            return vm.isLoading || (vm.filter.selectedCommunity.communityAffiliationId === -1);
        }

        function disableCommunity () {
            return vm.filter.selectedOrganizations.length > 0;
        }

        function onSelectCommunity() {
            // Should only occur if program manager or AVCC/Assistant,
            // since loading all orgs on People page takes too long for PM
            // and AVCC/Assistant responsibilities are on community level.
            if (vm.filter.selectedCommunity.organizations.length === 0)
                fillCommOrgs();
            else
                vm.filter.selectedOrganizations = [];
        }

        function onSelectOrg(item, model) {

        }

        function onClear() {
            vm.isLoading = true;

            vm.filter.startDate = null;
            vm.filter.endDate = null;

            vm.filter.workEmail = null;
            vm.filter.volunteerEmail = null;

            vm.filter.startingRowIndex = null;
            vm.filter.numberOfRows = null;

            if (vm.filteringVol)
                vm.filter.isActive = vm.filter.activities[0];
            vm.filter.selectedCommunity = vm.filter.communities[0];
            onSelectCommunity(vm.filter.selectedCommunity);

            onFilter();
        }

        function onFilter() {
            vm.isLoading = true;

            if(vm.filter.startingRowIndex && !vm.filter.numberOfRows){
                vm.filter.numberOfRows = vm.defaultNumRows;
            }

            if(!vm.filter.startingRowIndex && vm.filter.numberOfRows){
                vm.filter.startingRowIndex = vm.defaultStartingIndex;
            }

            var outputFilter = angular.copy(vm.filter);

            // If both fields have data, confirm valid before continuing
            if (outputFilter.startDate && outputFilter.endDate && moment(outputFilter.startDate).isAfter(outputFilter.endDate, 'day')) {
                close('error', "Start Date must be before End Date when filtering.");
            }
            close('success', "Filter successfully created!", outputFilter);
        }

        function close(action, msg, filter) {
            $modalInstance.close({
                action: action,
                message: msg,
                filter: filter || null
            });
        }
    }
})();