/**
 * Created by tlester3 on 4/17/16.
 */
(function () {
    'use strict';

    angular
        .module('app.auth')
        .factory('authentication', authentication);

    authentication.$inject = ['$http', '$cookies', '$q', '$log', '$window', '$location', '_', 'moment', 'config'];

    function authentication($http, $cookies, $q, $log, $window, $location, _, moment, config) {
        var host = $window.location.protocol + "//" + $window.location.host;

        return {
            destroyToken: destroyToken,
            isLoggedIn: isLoggedIn,

            setRedirect: setRedirect,
            getRedirect: getRedirect,
            clearRedirect: clearRedirect,

            isUserInVMIS: isUserInVMIS,
            logIn: logIn,
            getLastLoginDate: getLastLoginDate,
            logOut: logOut,
            logOutOfAppOnly: logOutOfAppOnly,
            keepAlive: keepAlive,
            isAosProfileValid: isAosProfileValid,
            isCommunityArchived: isCommunityArchived,
            isVmisProfileValid: isVmisProfileValid,
            isAdminProfileValid: isAdminProfileValid,

            findByUsername: findByUsername,
            sendMigrationEmailByUsername: sendMigrationEmailByUsername,
            findUsernameByKey: findUsernameByKey,
            completeUserMigration: completeUserMigration,

            getUiData: getUiData,
            createStateList: createStateList,
            setUserProfile: setUserProfile,
            getUserProfile: getUserProfile,
            getNonUserEmail: getNonUserEmail,
            setNonUser: setNonUser,
            setNonUserToUser: setNonUserToUser,

            getOpportunities: getOpportunities,
            getOpportunitiesAdmin: getOpportunitiesAdmin,
            getOpportunityDetails: getOpportunityDetails,
            applyForOpportunity: applyForOpportunity,
            setVolunteerForOpportunity: setVolunteerForOpportunity,

            getOpportunitiesAppliedFor: getOpportunitiesAppliedFor,
            getApplicationsAdmin: getApplicationsAdmin,
            getVolunteerHoursForOpportunity: getVolunteerHoursForOpportunity,
            getHoursVol: getHoursVol,
            getHoursAdmin: getHoursAdmin,
            setVolunteerHoursForOpportunity: setVolunteerHoursForOpportunity,
            getHoursDetails: getHoursDetails,
            deleteHours: deleteHours,

            getUserRoles: getUserRoles,
            modifyUserRoles: modifyUserRoles,

            getOpportunityVolunteers: getOpportunityVolunteers,
            getApplicationDetails: getApplicationDetails,
            getCommunityApplicationDetails: getCommunityApplicationDetails,

            deleteApplication: deleteApplication,

            manageVolunteerHours: manageVolunteerHours,
            setOpportunity: setOpportunity,
            deleteOpportunity: deleteOpportunity,
            setOpportunityStatusForUser: setOpportunityStatusForUser,
            finishOpportunityStatusForUser: finishOpportunityStatusForUser,

            sendInvite: sendInvite,
            acceptInvite: acceptInvite,
            getInvites: getInvites,
            getInviteStatus: getInviteStatus,
            updateInvite: updateInvite,
            deleteInvite: deleteInvite,

            getNotifications: getNotifications,
            setNotificationRead: setNotificationRead,
            deleteNotifications: deleteNotifications,
            getUserNotificationEmailSettings: getUserNotificationEmailSettings,
            setUserNotificationEmailSettings: setUserNotificationEmailSettings,

            getVolunteersAdmin: getVolunteersAdmin,
            getManagersAdmin: getManagersAdmin,
            getAllUsers: getAllUsers,
            getCommunities: getCommunities,
            getCommunityInfo: getCommunityInfo,
            getCommunitiesIntermediateNodes: getCommunitiesIntermediateNodes,
            getOrganizations: getOrganizations,
            getOrganizationsAdmin: getOrganizationsAdmin,
            setOrganizationInfo: setOrganizationInfo,
            deleteOrganization: deleteOrganization,
            getStandardOrganizations: getStandardOrganizations,
            getStandardPositionsAdmin: getStandardPositionsAdmin,
            getOrganizationGroups: getOrganizationGroups,
            manageOrganizationGroup: manageOrganizationGroup,
            getOpocsForOpportunity: getOpocsForOpportunity,

            sendEmailToList: sendEmailToList,
            getTroubledOrganizationsForCommunity: getTroubledOrganizationsForCommunity,

            uploadHoursDocument: uploadHoursDocument,
            deleteHoursDocument: deleteHoursDocument,
            uploadVolunteerDocument: uploadVolunteerDocument,
            deleteVolunteerDocument: deleteVolunteerDocument,
            uploadMassEmailAttachment: uploadMassEmailAttachment,

            getVolunteerServiceRecord: getVolunteerServiceRecord,
            getVolunteerNotes: getVolunteerNotes,
            manageVolunteerNotes: manageVolunteerNotes,
            getVolunteerBackgroundChecks: getVolunteerBackgroundChecks,
            manageVolunteerBackgroundChecks: manageVolunteerBackgroundChecks,
            getVolunteerDocuments: getVolunteerDocuments,
            getVolunteerAwards: getVolunteerAwards,
            setVolunteerAwards: setVolunteerAwards,
            deleteVolunteerAwards: deleteVolunteerAwards,
            getVolunteerTrainings: getVolunteerTrainings,
            setVolunteerTrainings: setVolunteerTrainings,
            deleteVolunteerTrainings: deleteVolunteerTrainings,
            getVolunteerOrientations: getVolunteerOrientations,
            setVolunteerOrientations: setVolunteerOrientations,
            deleteVolunteerOrientations: deleteVolunteerOrientations,

            getAvccsForCommunity: getAvccsForCommunity,
            getDA4713: getDA4713,
            getDA4162: getDA4162,
            mergeVMISdata: mergeVMISdata,
            getMasterUserIdFromEmail: getMasterUserIdFromEmail,
            getUsernameFromMasterUserId: getUsernameFromMasterUserId,

            checkVmisApiHealth: checkVmisApiHealth,
            checkCoreApiHealth: checkCoreApiHealth
        };

        function saveToken(token, expiration) {
            $cookies.put('cas-ticket', token, {
                expires: expiration
            });
        }

        function getToken() {
            return $cookies.get('cas-ticket');
        }

        function destroyToken() {
            $cookies.remove('cas-ticket');
        }

        function setRedirect(state, params) {
            $cookies.putObject('redirect', { state: state, params: params });
        }

        function getRedirect() {
            return $cookies.getObject('redirect');
        }

        function clearRedirect() {
            $cookies.remove('redirect');
        }

        function isLoggedIn() {
            return !!getToken();
        }

        function isUserInVMIS(params) {
            var data = {
                email: params.email
            };

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/public_api/is_user_in_VMIS', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function logIn(ticket, service) {
            if (service === null || service === undefined) {
                service = encodeURIComponent(host + ($location.path() === '/' ? '' : $location.path()));
            }

            var data = {
                ticket: ticket,
                service: service
            };

            return config.get().then(function (configData) {
                return $http.post(configData.coreServer + '/login/sign_in_with_ticket', data);
            }).then(httpSuccess).then(onSuccess).catch(httpFailure);

            function onSuccess(response) {
                saveToken(response.access_token, moment().add(response.expires_in, 'minutes').toDate());
                return response;
            }
        }

        function getLastLoginDate() {
            return config.get().then(function (configData) {
                var service = encodeURIComponent($window.location.protocol + "//" + $window.location.host);
                return $http.post(configData.coreServer + '/authorized_api/users/get_last_login', {authorization: getToken(), service: service});
            }).then(httpSuccess).catch(httpFailure);
        }

        function logOutOfAppOnly () {
            return config.get().then(function (configData) {
                var service = encodeURIComponent($window.location.protocol + "//" + $window.location.host);
                return $http.post(configData.coreServer + '/authorized_api/users/sign_out', {authorization: getToken(), service: service});
            }).then(httpSuccess).catch(httpFailure);
        }

        function logOut() {
            var casServer = "";
            return config.get().then(function (configData) {
                casServer = configData.casServer; // Store value while config data is in-scope
                return $http.post(configData.coreServer + '/authorized_api/users/sign_out', { authorization: getToken() });
            }).then(httpSuccess).then(function (response) {
                destroyToken();
                // See loggedOut.routes.js
                window.location.href = casServer + 'logout?service=' +
                    encodeURI($window.location.protocol + "//" + $window.location.host + "/logged-out");
            }).catch(httpFailure);
        }

        function keepAlive() {
            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/keep_alive', { authorization: getToken() });
            }).then(httpSuccess).then(function (response) {
                saveToken(getToken(), new Date(response.expiresOnDate));
                return response;
            }).catch(httpFailure).catch(function (error) {
                destroyToken();
                return $q.reject(error);
            });
        }

        function findByUsername(username) {
            return config.get().then(function (configData) {
                return $http.get(configData.coreServer + '/public_api/migrate/' + username);
            }).then(httpSuccess).catch(httpFailure);
        }

        function findUsernameByKey(key) {
            return config.get().then(function (configData) {
                return $http.get(configData.coreServer + '/public_api/migrate/userwithhash/' + key);
            }).then(httpSuccess).catch(httpFailure);
        }

        function sendMigrationEmailByUsername(username) {
            return config.get().then(function (configData) {
                return $http.post(configData.coreServer + '/public_api/migrate/' + username);
            }).then(httpSuccess).catch(httpFailure);
        }

        function completeUserMigration(key, username, email, password) {
            var params = {
                hash: key,
                password: password,
                newEmail: email
            };
            return config.get().then(function (configData) {
                return $http.put(configData.coreServer + '/public_api/migrate/claim/' + username, params);
            }).then(httpSuccess).catch(httpFailure);
        }

        function isAosProfileValid(aosProfile) {
            if (!isLoggedIn()) return false;

            // List of required AFWP Profile fields
            var isProfileValid = aosProfile.firstName &&
                aosProfile.lastName &&
                aosProfile.dateOfBirth &&
                aosProfile.communityAffiliationId &&
                aosProfile.branchOfService &&
                aosProfile.armyComponent &&
                aosProfile.dispositions.length > 0;

            //check dispositions
            if (_.indexOf(aosProfile.dispositions, 'Service Member') >= 0) isProfileValid = isProfileValid && aosProfile.payGrade;
            if (_.indexOf(aosProfile.dispositions, 'Family Member') >= 0) isProfileValid = isProfileValid && aosProfile.sponsorPayGrade;
            if (_.indexOf(aosProfile.dispositions, 'DoD Civilian') >= 0) isProfileValid = isProfileValid && aosProfile.civilianPayGrade;

            //check minor
            if (moment().diff(new Date(aosProfile.dateOfBirth), 'years') < 18) {
                if (!aosProfile.isMarried) {
                    isProfileValid = isProfileValid &&
                        aosProfile.parentGuardianFirstName &&
                        aosProfile.parentGuardianLastName &&
                        aosProfile.parentGuardianPhone &&
                        aosProfile.parentGuardianEmail &&
                        aosProfile.parentGuardianCountry &&
                        aosProfile.parentGuardianAddress1 &&
                        aosProfile.parentGuardianCity &&
                        aosProfile.parentGuardianZipCode;

                    if (aosProfile.parentGuardianCountry === 'United States') isProfileValid = isProfileValid && aosProfile.parentGuardianUsState;
                }
            }

            return !!isProfileValid;
        }

        function isCommunityArchived(communityAffiliationId) {
            return getCommunityInfo({ communityAffiliationId: communityAffiliationId })
                .then(function (comm) {
                    return comm.communityAffiliations.isArchived;
                });
        }

        // Assuming aosUser.vmisUser.volunteerProfile as input
        function isVmisProfileValid(vmisProfile) {
            if (!vmisProfile) return false;

            // List of required Volunteer Profile fields
            var isProfileValid =
                vmisProfile.preferredContactMethodId &&
                vmisProfile.volunteerEmail &&
                vmisProfile.primaryPhone;

            return !!isProfileValid;
        }

        function isAdminProfileValid(adminProfile) {
            if (!adminProfile) return false;

            // List of required Administrator Profile fields
            var isProfileValid = adminProfile.workEmail &&
                adminProfile.commercialWorkPhone &&
                adminProfile.country &&
                adminProfile.address1 &&
                adminProfile.city &&
                adminProfile.zipCode;
            if (adminProfile.country === 'United States') isProfileValid = isProfileValid && adminProfile.usState;

            return !!isProfileValid;
        }

        function getUiData(elements) {
            var data = {
                elements: elements
            };

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/public_api/get_ui_data', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function createStateList(states,abbreviations) {
            var resultArray = [];

            for (var i = 0; i < states.length ; i++) {
                resultArray.push(states[i] + " ("+abbreviations[i]+")");
            }

            return resultArray;
        }

        function getUserProfile(optional) {
            //if (!isLoggedIn())
            //    return $q.reject("Logged out");

            var data = {
                authorization: getToken()
            };

            data.masterUserId = optional?optional.masterUserId?optional.masterUserId:undefined:undefined;

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/get_user_profile', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function setUserProfile(profile, id) {
            var profileCopy = angular.copy(profile, {});
            var data = {
                authorization: getToken(),
                aosUser: profileCopy
            };

            if (angular.isNumber(id)) {
                data.masterUserId = angular.copy(id);
            }

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/set_user_profile', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getNonUserEmail(nonUserMasterId) {
            var data = {
                authorization: getToken(),
                masterUserId: nonUserMasterId
            };
            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/get_nonuser_email', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function setNonUser(profile) {
            var data = {
                authorization: getToken(),
                email: profile.email,
                aosUser: profile.aosUser
            };

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/set_non_user', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function setNonUserToUser(params) {
            var data = {
                authorization: getToken(),
                masterUserId: params.masterUserId,
                email: params.email
            };

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/set_non_user_to_user', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getOpportunities(params) {
            var data = params;

            var url = '/public_api';
            if (isLoggedIn()) {
                url = '/authorized_api';
                data.authorization = getToken();
            }

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + url + '/get_opportunities', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getOpportunitiesAdmin(params) {
            var data = angular.copy(params);
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/get_opportunities_admin', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getOpportunityDetails(params) {
            var data = params;
            var url = '/public_api';

            if (isLoggedIn()) {
                url = '/authorized_api';
                data.authorization = getToken();
            }

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + url + '/get_opportunity_details', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function applyForOpportunity(params) {
            var data = params;
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/apply_for_opportunity', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function setVolunteerForOpportunity(params) {
            var data = angular.copy(params, {});
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/set_volunteer_for_opportunity', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getOpportunitiesAppliedFor(params) {
            var data = angular.copy(params, {});
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/get_opportunities_applied_for', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getApplicationsAdmin(params) {
            var data = angular.copy(params, {});
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/get_applications_admin', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getVolunteerHoursForOpportunity(params) {
            var data = params;
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/get_volunteer_hours_for_opportunity', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getDA4713(params) {
            var data = angular.copy(params);
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/get_DA4713_form', data, { responseType: 'arraybuffer' });
            }).then(httpSuccess).then(function (response) {
                var file = new Blob([response], { type: 'application/pdf' });
                saveAs(file, moment().format('YYYYMMDD_HHmmss') + '_DA4713.pdf');
            }).catch(httpFailure);
        }

        function getDA4162(params) {
            var data = angular.copy(params);
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/get_DA4162_form', data, { responseType: 'arraybuffer' });
            }).then(httpSuccess).then(function (response) {
                var file = new Blob([response], { type: 'application/pdf' });
                saveAs(file, moment().format('YYYYMMDD_HHmmss') + '_DA4162.pdf');
            }).catch(httpFailure);
        }

        function getHoursVol(params) {
            var data = angular.copy(params);
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/get_hours_vol', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getHoursAdmin(params) {
            var data = angular.copy(params);
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/get_hours_admin', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function setVolunteerHoursForOpportunity(params) {
            var data = params;
            data.authorization = getToken();
            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/set_volunteer_hours_for_opportunity', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getUserRoles() {
            var data = {
                authorization: getToken()
            };

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/get_user_roles', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function modifyUserRoles(params) {
            var data = params;
            data.authorization = getToken();
            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/modify_user_roles', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getOpportunityVolunteers(opportunityId, status) {
            var data = {
                authorization: getToken(),
                volunteerOpportunityId: opportunityId
            };

            if (status) data.volunteerApplicationStatus = status;

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/get_opportunity_volunteers', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function manageVolunteerHours(params) {
            var data = {
                authorization: getToken(),
                volunteerHoursStatus: params.volunteerHoursStatus,
                hoursIds: params.hoursIds
            };

            // Fill in optional parameters
            if ('reviewerNotes' in params)
                data.reviewerNotes = params.reviewerNotes;
            if ('hours' in params)
                data.hours = params.hours;

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/manage_volunteer_hours', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function setOpportunity(opportunity) {
            var data = opportunity;
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/set_opportunity', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function deleteOpportunity(params) {
            var data = angular.copy(params, {});
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/delete_opportunity', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function setOpportunityStatusForUser(params) {
            var data = angular.copy(params, {});
            data.authorization = getToken();
            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/set_opportunity_status_for_user', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function finishOpportunityStatusForUser(params) {
            var data = angular.copy(params);
            data.authorization = getToken();
            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/finish_opportunity_status_for_user', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function sendInvite(params) {
            var data = angular.copy(params, {});
            data.authorization = getToken();
            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/send_invite', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function acceptInvite(params) {
            var data = {
                authorization: getToken(),
                hash: params.hash
            };

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/accept_invite', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getInvites(params) {
            var data = angular.copy(params, {});
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/get_invites', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getInviteStatus(hash) {
            var data = {
                hash: hash
            };

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/public_api/get_invite_status', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function updateInvite(params) {
            var data = angular.copy(params, {});
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/update_invite', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function deleteInvite(hash) {
            var data = {
                authorization: getToken(),
                hash: hash
            };

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/delete_invite', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getNotifications(startingRowIndex, numberOfRows) {
            var data = {
                authorization: getToken(),
                startingRowIndex: startingRowIndex,
                numberOfRows: numberOfRows
            };

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/get_notifications', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function setNotificationRead(notificationIds) {
            var data = {
                authorization: getToken(),
                notificationIds: notificationIds
            };

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/set_notification_read', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getApplicationDetails(params) {
            var data = {
                authorization: getToken(),
                volunteerApplicationId: params.volunteerApplicationId
            };

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/get_application_details', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getCommunityApplicationDetails(params) {
            var data = {
                authorization: getToken(),
                communityVolunteerOrganizationApplicationId: params.communityVolunteerOrganizationApplicationId
            };

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/get_application_details', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function deleteApplication(params) {
            var data = angular.copy(params, {});
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/delete_application', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getHoursDetails(id) {
            var data = {
                authorization: getToken(),
                hoursId: id
            };

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/get_hours_details', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function deleteHours(params) {
            var data = angular.copy(params);
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/delete_hours', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function deleteNotifications(notificationIds) {
            var data = {
                authorization: getToken(),
                notificationIds: notificationIds
            };

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/delete_notification', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getUserNotificationEmailSettings(params) {
            var data = angular.copy(params, {});
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/get_user_notification_email_settings', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function setUserNotificationEmailSettings(params) {
            var data = angular.copy(params);
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/set_user_notification_email_settings', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getVolunteersAdmin(params) {
            var data = angular.copy(params, {});
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/get_volunteers_admin', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getManagersAdmin(params) {
            var data = angular.copy(params, {});
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/get_managers_admin', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getAllUsers(params) {
            var data = angular.copy(params, {});
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/get_all_users', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getCommunities(params) {
            var data = angular.copy(params);

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/public_api/get_communities', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getCommunityInfo(params) {
            var data = angular.copy(params);

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/public_api/get_community_info', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getCommunitiesIntermediateNodes(params) {
            var data = angular.copy(params);

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/public_api/get_communities_intermediate_nodes', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getOrganizations(params) {
            var data = angular.copy(params) || {};
            data.authorization = getToken();

            if (typeof params !== 'undefined') {
                if ('isActive' in params)
                    data.isActive = params.isActive;
            }

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/public_api/get_organizations_for_community', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getOrganizationsAdmin(params) {
            var data = angular.copy(params);
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/get_organizations_admin', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function setOrganizationInfo(params) {
            params.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/set_organization_info', params);
            }).then(httpSuccess).catch(httpFailure);
        }

        function deleteOrganization(params) {
            params.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/delete_organization', params);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getStandardOrganizations(params) {
            var data = angular.copy(params, {});
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/get_standard_organizations', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getStandardPositionsAdmin(params) {
            var data = angular.copy(params, {});
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/get_standard_positions_admin', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getOrganizationGroups(params) {
            var data = angular.copy(params, {});
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/get_organization_groups', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function manageOrganizationGroup(params) {
            var data = angular.copy(params, {});
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/manage_organization_group', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getOpocsForOpportunity(params) {
            var data = angular.copy(params);
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/public_api/get_opocs_for_opportunity', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function sendEmailToList(params) {
            var data = angular.copy(params);
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/send_email_to_list', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getTroubledOrganizationsForCommunity(params) {
            var data = angular.copy(params, {});
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/get_troubled_organizations_for_community', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        // Uploading documents requires different method
        // (multi-part form-data request)
        function uploadHoursDocument(params) {
            var fd = new FormData();
            fd.append('theFile', params.theFile);
            fd.append('fileSuffix', params.fileSuffix);
            // TODO: Use "documentName" to allow user to specify what document is, not just the filename
            fd.append('documentName', params.documentName);
            fd.append('volunteerHoursId', params.volunteerHoursId);
            fd.append('authorization', getToken());

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/hours_document', fd, {
                    transformRequest: angular.identity,
                    headers: { 'Content-Type': undefined }
                });
            }).then(httpSuccess).catch(httpFailure);
        }

        function deleteHoursDocument(params) {
            params.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/delete_documents_for_hours', params);
            }).then(httpSuccess).catch(httpFailure);
        }

        // Uploading documents requires different method
        // (multi-part form-data request)
        function uploadVolunteerDocument(params) {
            var fd = new FormData();
            fd.append('theFile', params.theFile);
            fd.append('fileSuffix', params.fileSuffix);
            // TODO: Use "documentName" to allow user to specify what document is, not just the filename
            fd.append('documentName', params.documentName);
            fd.append('masterUserId', params.masterUserId);
            fd.append('documentDescription', params.documentDescription);
            fd.append('authorization', getToken());

            // Award, Training, Orientation-specific fields for their document upload
            if (params.volunteerAwardId) {
                fd.append('volunteerAwardId', params.volunteerAwardId);
            } else if (params.volunteerTrainingId) {
                fd.append('volunteerTrainingId', params.volunteerTrainingId);
            } else if (params.volunteerOrientationId) {
                fd.append('volunteerOrientationId', params.volunteerOrientationId);
            }

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/volunteer_document', fd, {
                    transformRequest: angular.identity,
                    headers: { 'Content-Type': undefined }
                });
            }).then(httpSuccess).catch(httpFailure);
        }

        function deleteVolunteerDocument(params) {
            params.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/delete_documents_for_user', params);
            }).then(httpSuccess).catch(httpFailure);
        }

        // Uploading documents requires different method
        // (multi-part form-data request)
        function uploadMassEmailAttachment(params) {
            var fd = new FormData();
            fd.append('theFile', params.theFile);
            fd.append('fileSuffix', params.fileSuffix);
            fd.append('sentEmailUUID', params.sentEmailUUID);
            fd.append('documentName', params.documentName);
            fd.append('authorization', getToken());

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/upload_attachment', fd, {
                    transformRequest: angular.identity,
                    headers: { 'Content-Type': undefined }
                });
            }).then(httpSuccess).catch(httpFailure);
        }

        function getVolunteerServiceRecord(params) {
            var data = angular.copy(params, {});
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/get_volunteer_service_record', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getVolunteerNotes(params) {
            var data = angular.copy(params, {});
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/get_volunteer_notes', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function manageVolunteerNotes(params) {
            var data = angular.copy(params, {});
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/manage_volunteer_notes', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getVolunteerBackgroundChecks(params) {
            var data = angular.copy(params, {});
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/get_volunteer_background_checks', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function manageVolunteerBackgroundChecks(params) {
            var data = angular.copy(params, {});
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/manage_volunteer_background_checks', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getVolunteerDocuments(params) {
            var data = angular.copy(params, {});
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/get_volunteer_documents', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getVolunteerAwards(params) {
            var data = angular.copy(params, {});
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/get_volunteer_awards', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function setVolunteerAwards(params) {
            var data = angular.copy(params, {});
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/set_volunteer_awards', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function deleteVolunteerAwards(params) {
            var data = angular.copy(params, {});
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/delete_volunteer_awards', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getVolunteerTrainings(params) {
            var data = angular.copy(params, {});
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/get_volunteer_trainings', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function setVolunteerTrainings(params) {
            var data = angular.copy(params, {});
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/set_volunteer_trainings', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function deleteVolunteerTrainings(params) {
            var data = angular.copy(params, {});
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/delete_volunteer_trainings', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getVolunteerOrientations(params) {
            var data = angular.copy(params, {});
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/get_volunteer_orientations', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function setVolunteerOrientations(params) {
            var data = angular.copy(params, {});
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/set_volunteer_orientations', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function deleteVolunteerOrientations(params) {
            var data = angular.copy(params, {});
            data.authorization = getToken();

            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/delete_volunteer_orientations', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getAvccsForCommunity(params) {
            var data = angular.copy(params);
            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/public_api/get_avccs_for_community', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function mergeVMISdata(params) {
            var data = angular.copy(params);
            data.authorization = getToken();
            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/merge_VMIS_data', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getMasterUserIdFromEmail(params) {
            var data = angular.copy(params);
            data.authorization = getToken();
            return config.get().then(function (configData) {
                return $http.post(configData.apiServer + '/authorized_api/get_masterUserId_from_email', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        function getUsernameFromMasterUserId(params) {
            var data = angular.copy(params);
            data.authorization = getToken();
            return config.get().then(function (configData) {
                return $http.post(configData.coreServer + '/authorized_api/users/get_username_from_masterUserId', data);
            }).then(httpSuccess).catch(httpFailure);
        }

        /**
         * Gets information from VMIS API health check.
         * @param params Any of acceptable parameters to check aspects of API health (unacceptable ones are ignored) (optional)
         * @returns {*} Returns a promise of the data from the API.
         */
        function checkVmisApiHealth(params) {
            return config.get().then(function (configData) {
                return checkHealth(configData.apiServer, params);
            });
        }

        /**
         * Gets information from Core API health check.
         * @param params Any of acceptable parameters to check aspects of API health (unacceptable ones are ignored) (optional)
         * @returns {*} Returns a promise of the data from the API.
         */
        function checkCoreApiHealth(params) {
            return config.get().then(function (configData) {
                return checkHealth(configData.coreServer, params);
            });
        }

        function checkHealth(api, params) {
            if (params !== undefined && !Array.isArray(params)) {
                console.warn("Attempted to pass non-array into API health check!");
                return;
            }
            var paramsStr = "";

            // "params" is optional
            if (params) {
                paramsStr += "?test=";
                params.forEach(function (param) {
                    paramsStr += param.toString() + ",";
                });
            }

            return $http.get(api + '/check_health' + paramsStr).then(httpSuccess).catch(httpFailure);
        }

        function httpSuccess(response) {
            if (response.status === 200) {
                var data = response.data;
                if (data &&
                    (data.success === true ||
                        (data instanceof ArrayBuffer && data.byteLength !== undefined))) {
                    return response.data;
                }
            }
            return $q.reject(response);
        }

        function httpFailure(error) {
            if (error.status === 200) {
                $log.error(error.config.method, error.config.url, error.data.error);
                return $q.reject(error.data.error);
            }
            var errorText = 'Error: ' + error.status + ', ' + error.statusText;
            $log.error(error.config.method, error.config.url, errorText);
            return $q.reject(errorText);
        }
    }
})();
