(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .config(routes);

    routes.$inject = ['$stateProvider'];

    function routes($stateProvider) {
        $stateProvider
            .state('vmis.volunteer', {
                url: '/volunteer',
                abstract: true,
                templateUrl: 'app/components/volunteer/volunteer.view.html'
            })
            .state('vmis.volunteer.serviceLog', {
                url: '/service-log',
                templateUrl: 'app/components/volunteer/serviceLog/serviceLog.view.html',
                controller: 'ServiceLogController',
                controllerAs: 'vm',
                title: 'Service Log'
            })
            .state('vmis.volunteer.serviceLogEdit', {
                url: '/service-log/{id}',
                templateUrl: 'app/components/volunteer/serviceLog/serviceLog.view.html',
                controller: 'ServiceLogController',
                controllerAs: 'vm',
                title: 'Service Log'
            })
            .state('vmis.volunteer.applications', {
                url: '/applications',
                templateUrl: 'app/components/volunteer/applications/applications.view.html',
                controller: 'VolunteerApplicationsController',
                controllerAs: 'vm',
                title: 'Applications'
            })
            .state('vmis.volunteer.applications.details', {
                url: '/details?opportunityId&applicationId&communityVolunteerOrganizationApplicationId',
                templateUrl: 'app/components/volunteer/applications/applicationDetails.view.html',
                controller: 'VolunteerApplicationDetailsController',
                controllerAs: 'vm',
                title: 'Application Details'
            })
            .state('vmis.volunteer.applications.createNonAvcPosition', {
                url: '/create-non-avc-position',
                templateUrl: 'app/components/volunteer/createNonAvcPosition/createNonAvcPosition.view.html',
                controller: 'CreateNonAvcPositionController',
                controllerAs: 'vm',
                title: 'Create Position'
            })
            .state('vmis.volunteer.opportunities', {
                url: '/opportunities',
                templateUrl: 'app/components/volunteer/opportunities/opportunities.view.html',
                controller: 'OpportunitiesController',
                controllerAs: 'vm',
                title: 'Opportunities'
            })
            .state('vmis.volunteer.opportunities.apply', {
                url: '/apply/{opportunityId:int}',
                templateUrl: 'app/components/volunteer/opportunities/apply.view.html',
                controller: 'ApplyController',
                controllerAs: 'vm',
                title: 'Volunteer Application'
            });
    }
})();