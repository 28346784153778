(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('ApplicationsController', ApplicationsController);

    ApplicationsController.$inject = ['$modal', '$scope', '$q', '$timeout', '_', 'authentication', 'utilsServices', 'loadStatus'];

    function ApplicationsController($modal, $scope, $q, $timeout, _, authentication, utilsServices, loadStatus) {
        var vm = this;

        vm.isLoading = true;
        vm.applications = [];
        vm.alerts = [];

        vm.sortReverse = true;
        vm.sortColumn = 'submittedDate';
        vm.statusTypes = [
            {
                status: "All",
                value: ""
            },
            {
                status: "Submitted",
                value: "Submitted"
            },
            {
                status: "Approved",
                value: "Approved"
            },
            {
                status: "Declined",
                value: "Declined"
            }
        ];

        vm.positionTitles = [
            {
                title: "All",
                value: ""
            }
        ];

        var uniqueTitles = []; // Track unique positions for filtering

        vm.filter = {
            selectedCommunity: {},
            selectedOrganization: {},
            volunteerOpportunity: vm.positionTitles[0].value,
            communityVolunteeringApplication: vm.positionTitles[0].commId,
            volunteerApplicationStatus: vm.statusTypes[1].value,
            firstName: null,
            lastName: null
        };

        vm.communities = [];

        vm.loadStatus = loadStatus.init();

        vm.getApplications = getApplications;
        vm.onClickApplication = onClickApplication;
        vm.onClickSearch = onClickSearch;
        vm.onLoadMore = onLoadMore;
        vm.onLoadAll = onLoadAll;
        vm.disableOrgDropDown = disableOrgDropDown;
        vm.onSelectCommunity = onSelectCommunity;
        vm.onSelectOrganization = onSelectOrganization;
        $scope.$on('applications:alert', alertHandler);
        vm.onCloseAlert = onCloseAlert;


        activate();

        function activate() {
            loadStatus.setup(vm.loadStatus, "applications")
                .then(function () {
                    getCommunities(); // Calls getApplications()
                });
        }

        function getApplications(index, num) {
            vm.isLoading = true;

            var params = {
                startingRowIndex: index,
                numberOfRows: num,
                volunteerApplicationStatus: vm.filter.volunteerApplicationStatus ? vm.filter.volunteerApplicationStatus : undefined,
                volunteerOpportunityId: vm.filter.volunteerOpportunity ? (vm.filter.volunteerOpportunity.opportunityId ? vm.filter.volunteerOpportunity.opportunityId : undefined) : undefined,
                communityVolunteerOrganizationApplicationId: vm.filter.volunteerOpportunity ? (vm.filter.volunteerOpportunity.commId ? vm.filter.volunteerOpportunity.commId : undefined) : undefined,
                communityAffiliationId: (vm.filter.selectedCommunity.id !== -1) ? vm.filter.selectedCommunity.id : undefined,
                volunteerOrganizationId: (vm.filter.selectedOrganization.id !== -1) ? vm.filter.selectedOrganization.id : undefined,
                firstName: vm.filter.firstName ? vm.filter.firstName : undefined,
                lastName: vm.filter.lastName ? vm.filter.lastName : undefined
            };

            return authentication.getApplicationsAdmin(params)
                .then(function (response) {
                    return getApplicationsSuccess(response, index, num);
                }).catch(function (error) {
                    getApplicationsError(error);
                });

            function getApplicationsSuccess(response, index, num) {
                // Create "masterUserFullName" field for filtering
                function processApplications(applications) {
                    applications.forEach(function (application) {
                        if (angular.isUndefined(application.submittedDate)) {
                            application.submittedDate = application.createdDate;
                        }
                        //application.masterUserFullName = application.firstName + " " + application.lastName;

                        // Get unique titles for drop down
                        if (uniqueTitles.indexOf(application.volunteerOpportunity) === -1) {
                            uniqueTitles.push(application.volunteerOpportunity);
                            vm.positionTitles.push({
                                title: application.volunteerOpportunity,
                                value: { opportunityId: application.volunteerOrganizationOpportunityId, commId: application.communityVolunteerOrganizationApplicationId }

                            });
                        }
                    });
                }

                if (index === 0) {
                    vm.loadStatus.currentIndex = 0;
                    vm.applications = [];
                }

                // Either set current index to max if all users loaded or increment by rows loaded
                vm.loadStatus.currentIndex = (vm.loadStatus.currentIndex + num > response.count) ? response.count : (vm.loadStatus.currentIndex + num);
                // Track total number of users not loaded for "Load All" button
                vm.loadStatus.numResultsNotLoaded = response.count - vm.loadStatus.currentIndex;
                // Set text for "Load More" button
                vm.loadStatus.nextResultLoadNum = (response.count === vm.loadStatus.currentIndex) ? 0 :
                    (vm.loadStatus.numResultsNotLoaded > num) ? num : vm.loadStatus.numResultsNotLoaded;

                // Only operate on new applications
                response.applications.forEach(function (application) {
                    if (angular.isDefined(application.volunteerOrganizationOpportunityId)) {
                        application.volunteerOrganization = application.orgActive ?
                            application.volunteerOrganization : application.volunteerOrganization + " (inactive)";
                    }
                });

                vm.applications = vm.applications.concat(response.applications);
                processApplications(vm.applications); // TODO: Should only operate on new applications?

                $timeout(function () {
                    vm.isLoading = false;
                }, 100);

                return vm.applications;
            }

            function getApplicationsError(error) {
                if (angular.isDefined(error)) {
                    var errorStr = error.error ? error.error : error;
                    displayError(errorStr);
                }
                else
                    displayError("Failed to load list! Please try again");

                $timeout(function () {
                    vm.isLoading = false;
                }, 100);
            }
        }

        function getCommunities() {
            return authentication.getUserProfile().then(function (response) {
                return utilsServices.combineCommunityResponsibilities(response, ['AVC OPOC', 'AVC Coordinator']);
            }).then(function (communities) {
                if (_.isArray(communities) && communities.length !== 1) {
                    communities.unshift({
                        name: "All",
                        organizations: []
                    });
                }
                _.forEach(communities, function (community) {
                    if (_.isArray(community.organizations) && community.organizations.length !== 1) {
                        community.organizations.unshift({
                            name: 'All'
                        });
                    }
                });
                vm.communities = communities;
                vm.filter.selectedCommunity = vm.communities[0];

                return onSelectCommunity();
            }).catch(displayError);
        }

        function onClickApplication(application) {
            var options = {
                templateUrl: 'app/components/administrator/applications/applicationDetails.view.html',
                controller: 'ApplicationDetailsController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    params: function () {
                        if (application.volunteerApplicationId) {
                            return {
                                title: 'Application Details',
                                volunteerApplicationId: application.volunteerApplicationId,
                                status: application.volunteerApplications
                            };
                        }
                        else {
                            return {
                                title: 'Application Details',
                                communityVolunteerOrganizationApplicationId: application.communityVolunteerOrganizationApplicationId,
                                status: application.volunteerApplications
                            };
                        }
                    }
                }
            };

            $modal.open(options).result.then(function (result) {
                if (angular.isDefined(result) && result.error !== "") {
                    displayError(result.error);
                }
                vm.loadStatus.currentIndex = 0;
                getApplications(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows);
            });
        }

        function disableOrgDropDown() {
            return vm.isLoading || (vm.filter.selectedCommunity.id === -1);
        }

        function onSelectCommunity() {
            // Should only occur if program manager or AVCC/Assistant,
            // since loading all orgs on People page takes too long for PM
            // and AVCC/Assistant responsibilities are on community level.
            var promise = vm.filter.selectedCommunity.organizations === null ? fillCommOrgs() : $q.when();
            return promise.then(function () {
                vm.loadStatus.currentIndex = 0;
                vm.filter.selectedOrganization = vm.filter.selectedCommunity.organizations[0];
                return getApplications(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows);
            });
        }

        function fillCommOrgs() {
            return authentication.getOrganizations({
                communityAffiliationId: vm.filter.selectedCommunity.id,
                getAll: true
            }).then(function (response) {
                var organizations = _.map(response.organizations, function (organization) {
                    return {
                        id: organization.volunteerOrganizationId,
                        name: organization.organizationName
                    };
                });
                if (_.isArray(organizations) && organizations.length !== 1) {
                    organizations.unshift({
                        name: "All"
                    });
                }
                vm.filter.selectedCommunity.organizations = organizations;
            }).catch(function (error) {
                $scope.$emit('people:alert', error);
                vm.isLoading = false;
            });
        }

        function onSelectOrganization() {
            vm.loadStatus.currentIndex = 0;
            return getApplications(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows);
        }

        function onClickSearch() {
            return getApplications(0, vm.loadStatus.defaultNumRows);
        }

        function alertHandler(event, message) {
            event.stopPropagation();

            displayError(message);
        }

        function onCloseAlert(index) {
            vm.alerts.splice(index, 1);
        }

        function displayAlert(type, message) {
            vm.alerts.push({
                type: type,
                message: message
            });
        }

        function displayError(error) {
            if (error === 'cancel' || error === 'backdrop click')
                return;
            if (error)
                displayAlert('alert', error);
        }

        function onLoadMore() {
            getApplications(vm.loadStatus.currentIndex, vm.loadStatus.nextResultLoadNum);
        }

        function onLoadAll() {
            getApplications(vm.loadStatus.currentIndex, vm.loadStatus.numResultsNotLoaded);
        }
    }
})();
