(function () {
    'use strict';

    angular
        .module('app.vmis')
        .controller('VmisController', VmisController);

    VmisController.$inject = ['$cookies', '$scope', '$state', '$modal', '$window', 'Idle', 'authentication', 'moment', 'config', 'loadStatus', 'lite'];

    function VmisController($cookies, $scope, $state, $modal, $window, Idle, authentication, moment,
                            config, loadStatus, lite) {
        var vm = this;

        vm.toggleLiteMode = toggleLiteMode;
        vm.vmisLiteText = lite.isLiteMode() ? "Switch to standard VMIS" : "Try VMIS Lite";

        vm.websiteFamilyList = null;
        vm.reportsUrl = null;
        vm.faqUrl = null;
        vm.contactHelpDeskUrl = null;

        vm.footerMessage = null;
        vm.showMyLiveChat = null;

        vm.showMenu = false;
        vm.colors = [{ name: "Red", id: 1 }, { name: "Green", id: 2 }, { name: "Blue", id: 3 }];

        vm.navClosed = false;

        vm.isLoggedIn = null;
        vm.lastLoginDate = null;

        vm.isRole = {
            volunteer: false,
            opoc: false,
            coorAssistant: false,
            coordinator: false,
            regionManager: false,
            programManager: false,
            afwpAdmin: false
        };

        vm.loadStatus = loadStatus.init();

        $scope.notificationObject = {
            notifications: 0,
            unreadNotifications: 0,
            totalUnreadNotifications: 0,
            setNotifications: function (inNotifications, unreadCount) {
                $scope.notificationObject.notifications = inNotifications;
                if (unreadCount !== null && unreadCount !== undefined)
                    $scope.notificationObject.updateUnreadNotificationsCount(unreadCount);
                else
                    $scope.notificationObject.updateUnreadNotificationsCount(
                        $scope.notificationObject.notifications.reduce(function (count, notif) {
                            if (!notif.read)
                                count++;
                            return count;
                        }, 0)
                    );
            },
            updateUnreadNotificationsCount: function (numUnread) {
                $scope.notificationObject.totalUnreadNotifications = numUnread;
                if (numUnread > 99)
                    $scope.notificationObject.unreadNotifications = "99+";
                else
                    $scope.notificationObject.unreadNotifications = numUnread;
            }
        };

        vm.logIn = logIn;
        vm.onClickLogOut = onClickLogOut;
        vm.onClickViewProfile = onClickViewProfile;
        vm.onClickViewAccountSettings = onClickViewAccountSettings;
        vm.goHome = goHome;
        vm.hasAdditionalRole = hasAdditionalRole;
        vm.redirectToDashboard = redirectToDashboard;
        vm.openAboutModal = openAboutModal;
        vm.toggleNav = toggleNav;

        $scope.$on('IdleStart', function () {
            // the user appears to have gone idle
            return $modal.open({
                templateUrl: 'app/components/idle/idle.view.html',
                controller: 'IdleController',
                controllerAs: 'vm',
                size: 'tiny',
                closeOnClick: false,
                backdrop: 'static',
                keyboard: false
            }).result.then(function () {
                Idle.watch();
                $cookies.remove('logged-out-reason');
            });
        });

        $scope.$on('IdleTimeout', function () {
            // the user has timed out (meaning idleDuration + timeout has passed without any activity)
            // this is where you'd log them
            config.get().then(function (configData) {
                $cookies.put('logged-out-reason', configData.loggedOutReasons.idle);
                logOutOfAppOnly();
                $state.go('vmis.loggedOut', null, { reload: true });
            });
        });

        $scope.$on('Keepalive', function () {
            // do something to keep the user's session alive
            authentication.keepAlive().catch(function (err) {
                console.error(err);
                return config.get().then(function (configData) {
                    $cookies.put('logged-out-reason', configData.loggedOutReasons.ticketError);
                    logOut();
                });
            });
        });

        $scope.$on('profile:update', function () {
            // Update the name and side nav options
            getProfile();
        });

        // Close the drop down menu if the user clicks outside of it
        window.onclick = function (event) {
            if (!$(event.target).hasClass("header-icon-menu")) {
                vm.showMenu = false;
                $scope.$apply();
            }
        };

        activate();

        function activate() {
            config.get().then(function (configData) {
                vm.websiteFamilyList = configData.afwpWebsiteFamilyList;
                vm.reportsUrl = configData.reportsUrl;
                vm.faqUrl = configData.faqUrl;
                vm.footerMessage = configData.footerMessage || "";
                vm.showMyLiveChat = !configData.isUatWebsite;

                let options = vm.websiteFamilyList
                    .filter(e => e.name === 'AFWP Resource Center')
                    .map(e => e.url);

                if (options.length !== 0) {
                    vm.contactHelpDeskUrl = options[0] + 'contact/';
                }

                // If not logged in, do not ping API without ticket/token and cause an error
                if (configData.siteUnderConstruction) {
                    $state.go('underConstruction', null);
                }
                // If "/about", open About modal on blank page that can't be closed.
                else if ($state.current.name === 'about') {
                    openAboutModal(true);
                }
                else if (authentication.isLoggedIn() && $state.current.name !== 'vmis.loggedOut') {
                    loadStatus.setup(vm.loadStatus, "notifications")
                        .then(function () {
                            getProfile().then(loadNotifications).catch(function (error) {
                                console.log(error);
                                authentication.destroyToken();
                                vm.isLoggedIn = false;
                            });
                        });
                    getLastLoginDate().catch(function (error) {
                        console.log(error);
                    });
                }
                else {
                    vm.isLoggedIn = false;

                    if ($state.current.name !== 'vmis.logIn' &&
                        $state.current.name !== 'vmis.register' &&
                        $state.current.name !== 'vmis.claimUser' &&
                        $state.current.name !== 'vmis.migrateUser' &&
                        $state.current.name !== 'vmis.volunteer.avccLocator' &&
                        $state.current.name !== 'vmis.loggedOut') {
                        $state.go('vmis.volunteer.opportunities', null);
                    }
                }
            });
        }

        function getLastLoginDate() {
            return authentication.getLastLoginDate()
                .then(function (response) {
                    if (response.lastLoginDate == null){
                        vm.lastLoginDate = null;
                    } else {
                        vm.lastLoginDate = moment(response.lastLoginDate).local().format('MM-DD-YYYY, h:mm A');
                    }
                }).catch(function (err) {
                    throw(err);
                })
        }

        function getProfile() {
            return authentication.getUserProfile().then(function (response) {
                vm.isLoggedIn = true;
                vm.firstName = response.aosUser.firstName;

                //need to concat name display otherwise name scrolls across the top of the time
                if (angular.isDefined(vm.firstName) && vm.firstName !== null) {
                    if (vm.firstName.length > 30) {
                        vm.firstName = vm.firstName.substring(0, 30);
                        vm.firstName = vm.firstName + "...";
                    }
                }

                var vmisRoles = response.roles.vmisUserRoles;
                if (vmisRoles !== null) {
                    // Clear roles for if refreshing from updated profile
                    vm.isRole = {
                        volunteer: false,
                        opoc: false,
                        coorAssistant: false,
                        coordinator: false,
                        regionManager: false,
                        programManager: false,
                        afwpAdmin: false
                    };

                    for (var i = 0; i < vmisRoles.length; i++) {
                        switch (vmisRoles[i]) {
                            case 'AVC Volunteer':
                                vm.isRole.volunteer = true;
                                break;
                            case 'AVC OPOC':
                                vm.isRole.opoc = true;
                                break;
                            case 'AVC Coordinator Assistant':
                                vm.isRole.coorAssistant = true;
                                break;
                            case 'AVC Coordinator':
                                vm.isRole.coordinator = true;
                                break;
                            case 'AVC Region Manager':
                                vm.isRole.regionManager = true;
                                break;
                            case 'AVC Program Manager':
                                vm.isRole.programManager = true;
                                break;
                            case 'AFWP Administrator':
                                vm.isRole.afwpAdmin = true;
                                break;
                        }
                    }
                    if ($state.is('vmis')) {
                        if (hasAdditionalRole() || vm.isRole.volunteer) {
                            $state.go('.dashboard', null, { reload: true });
                        } else {
                            $state.go('.volunteer.opportunities');
                        }
                    }
                }
            });
        }

        function loadNotifications() {
            return authentication.getNotifications(0, vm.loadStatus.defaultNumRows)
                .then(function (response) {
                    // TODO: Update vm.loadStatus
                    $scope.notificationObject.setNotifications(response.notifications, response.unread);
                    return $scope.notificationObject.notifications;
                });
        }

        function onClickLogOut() {
            vm.isDropdownOpen = false;
            logOut();
        }

        function logOutOfAppOnly() {
            Idle.unwatch();
            authentication.logOutOfAppOnly().catch(function (err) {
                console.error(err);
                return config.get().then(function (configData) {
                    $cookies.put('logged-out-reason', configData.loggedOutReasons.idle);
                });
            });
        }

        function logOut() {
            Idle.unwatch();
            authentication.logOut().catch(function (err) {
                console.error(err);
                return config.get().then(function (configData) {
                    $cookies.put('logged-out-reason', configData.loggedOutReasons.ticketError);
                });
            });
        }

        function onClickViewProfile() {
            vm.isDropdownOpen = false;
            $state.go('vmis.profile');
        }

        function onClickViewAccountSettings() {
            vm.isDropdownOpen = false;
            // Open link in new tab
            config.get().then(function (configData) {
                $window.open(configData.accountSettingsUrl + "log-in", '_blank');
            });
        }

        function goHome() {
            if (vm.isLoggedIn) $state.go('vmis.dashboard');
        }

        function logIn() {
            $state.go('vmis.logIn');
        }

        function hasAdditionalRole() {
            return (vm.isRole.opoc || vm.isRole.coorAssistant ||
                vm.isRole.coordinator || vm.isRole.regionManager ||
                vm.isRole.programManager || vm.isRole.afwpAdmin);
        }

        function redirectToDashboard() {
            $state.go('vmis.dashboard', null);
        }

        function toggleLiteMode() {
            lite.setLiteMode(!lite.isLiteMode());
            $state.reload();
        }

        function openAboutModal(stayOpen) {
            var options = {
                templateUrl: "app/components/about/about.modal.view.html",
                controller: 'AboutModalController',
                controllerAs: 'aboutModal',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    // Tell modal to hide close buttons
                    stayOpen: function () {
                        return stayOpen;
                    }
                }
            };
            
            return $modal.open(options)
                .result.catch(function (error) {
                    if (error === 'cancel' || error === 'ok' || error === 'backdrop click')
                        return;
                    if (error)
                        console.error(error);
                });
        }

        /**
         * This function is accessible by a round blue button when the side nav is closed
         * and the last side nav entry when it is open.
         * The side nav entry only shows when on medium or small screens, and the side nav
         * always starts open. So this functionality isn't accessible on large screens.
         * (This can be changed! Just remove "hide-for-large" for that entry in vmis.view.html.) */
        function toggleNav() {
            vm.navClosed = !vm.navClosed;
        }
    }
})();