(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .config(routes);

    routes.$inject = ['$stateProvider'];

    function routes($stateProvider) {
        $stateProvider
            .state('vmis.administrator', {
                url: '/administrator',
                abstract: true,
                template: '<div ui-view></div>'
            })
            .state('vmis.administrator.organizations', {
                url: '/organizations',
                templateUrl: 'app/components/administrator/organizations/organizations.view.html',
                controller: 'OrganizationsController',
                controllerAs: 'vm',
                title: 'Organizations'
            })
            .state('vmis.administrator.invitations', {
                url: '/invitations',
                templateUrl: 'app/components/administrator/invitations/invitations.view.html',
                controller: 'InvitationsController',
                controllerAs: 'vm',
                resolve: {
                    title: function () {
                        return 'Invitations';
                    }
                },
                title: "Invitations"
            })
            .state('vmis.administrator.positions', {
                url: '/positions',
                templateUrl: 'app/components/administrator/positions/positions.view.html',
                controller: 'PositionsController',
                controllerAs: 'vm',
                title: 'Positions'
            })
            .state('vmis.administrator.applications', {
                url: '/applications',
                templateUrl: 'app/components/administrator/applications/applications.view.html',
                controller: 'ApplicationsController',
                controllerAs: 'vm',
                title: 'Applications'
            })
            .state('vmis.administrator.applications.details', {
                url: '/details?applicationId&communityVolunteerOrganizationApplicationId',
                templateUrl: 'app/components/administrator/applications/applicationDetails.view.html',
                controller: 'ApplicationDetailsController',
                controllerAs: 'vm',
                params: {
                    nextState: {
                        name: '',
                        params: {},
                        options: {}
                    }
                }
            });
    }
})();