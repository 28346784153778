(function () {
    'use strict';

    angular
        .module('app')
        .directive('confirmDataError', ConfirmDataError);

    ConfirmDataError.$inject = [];

    function ConfirmDataError() {
        return {
            require: 'ngModel',
            link: link
        };

        function link(scope, elm, attrs, ctrl) {
            scope.$watch(attrs.confirmDataError, function (error) {
                elm[0].setCustomValidity(error);
                ctrl.$setValidity('confirmDataError', error ? false : true);
            });
        }
    }
})();
