(function () {
    'use strict';

    angular
        .module('app.vmis')
        .controller('UnderConstructionController', UnderConstructionController);

    UnderConstructionController.$inject = ['$scope', '$window', 'config'];

    function UnderConstructionController($scope, $window, config) {
        var vm = this;
        vm.websiteFamilyList = null;

        // Close the drop down menu if the user clicks outside of it
        window.onclick = function (event) {
            if (!$(event.target).hasClass("header-icon-menu")) {
                vm.showMenu = false;
                $scope.$apply();
            }
        };

        activate();

        function activate() {
            config.get().then(function (configData) {
                vm.websiteFamilyList = configData.afwpWebsiteFamilyList;
            });
        }
    }
})();