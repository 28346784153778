(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('RejectConfirmationController', RejectConfirmationController);

    RejectConfirmationController.$inject = ['firstName', 'lastName', 'positionTitle'];

    function RejectConfirmationController(firstName, lastName, positionTitle) {
        var vm = this;

        vm.firstName = firstName;
        vm.lastName = lastName;
        vm.positionTitle = positionTitle;
    }
})();