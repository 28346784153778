(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .controller('ApplicationConfirmationController', ApplicationConfirmationController);

    ApplicationConfirmationController.$inject = ['$modalInstance', 'name', 'positionTitle'];

    function ApplicationConfirmationController($modalInstance, name, positionTitle) {
        var vm = this;

        vm.name = name;
        vm.positionTitle = positionTitle;

        vm.onOk = onOk;
        vm.onDismiss = onDismiss;

        activate();

        function activate() {
        }

        function onOk() {
            $modalInstance.close();
        }

        function onDismiss() {
            $modalInstance.dismiss();
        }
    }
})();