(function () {
    'use strict';

    angular
        .module('app')
        .directive('liveChat', LiveChat);

    LiveChat.$inject = [];

    function LiveChat() {
        return {
            restrict: 'E',
            link: link
        };

        function link() {
            // Requires JQuery
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = "async";
            script.defer = "defer";
            script['data-cfasync'] = "false";
            script.src = "https://mylivechat.com/chatinline.aspx?hccid=44878937";
            document.body.appendChild(script);
        }
    }
})();