(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('CommFilterController', CommFilterController);

    CommFilterController.$inject = ['$modalInstance', 'moment', 'params'];

    function CommFilterController($modalInstance, moment, params) {
        var vm = this;

        vm.isLoading = true;

        vm.filter = {
            communities: [],
            selectedCommunities: []
        };
        if (params.communities)
            vm.filter.communities = params.communities;

        if(params.selectedCommunities){
            params.selectedCommunities.forEach(function(selectedCommunity){
                vm.selectedCommunity = vm.filter.communities.filter(function(comm){
                   return comm.communityAffiliationId === selectedCommunity;
                });

                vm.resultAddComm = vm.selectedCommunity[0]?vm.filter.selectedCommunities.push(vm.selectedCommunity[0]):null;
            });
        }

        vm.filter.startDate = params.startDate || null;
        vm.filter.endDate = params.endDate || null;
        vm.currentDate = new Date();

        vm.filter.workEmail = params.workEmail || null;

        vm.onClear = onClear;
        vm.onFilter = onFilter;
        vm.onSelectCommunity = onSelectCommunity;

        activate();

        function activate() {
            vm.isLoading = false;
        }

        function onClear() {
            vm.isLoading = true;

            vm.filter.workEmail = null;
            vm.filter.startDate = null;
            vm.filter.endDate = null;
            vm.filter.selectedCommunities = [vm.filter.communities[0]];

            onFilter();
        }

        function onFilter() {
            vm.isLoading = true;

            var outputFilter = angular.copy(vm.filter);

            // If both fields have data, confirm valid before continuing
            if (outputFilter.startDate && outputFilter.endDate && moment(outputFilter.startDate).isAfter(outputFilter.endDate, 'day')) {
                close('error', "Start Date must be before End Date when filtering.");
            }
            close('success', "Filter successfully created!", outputFilter);
        }

        function onSelectCommunity($item, $model) {
            // Array containing just "All" item
            var allCommunity = vm.filter.communities.filter(function(comm){
                return comm.communityAffiliationId === -1;
            });
            // Bool whether new list has "All"
            var containsAll = vm.filter.selectedCommunities.filter(function(comm){
                return comm.communityAffiliationId === -1;
            }).length > 0;
            // Set new list to just "All" if contains that
            if (containsAll) {
                vm.filter.selectedCommunities = allCommunity;
            }
        }

        function close(action, msg, filter) {
            $modalInstance.close({
                action: action,
                message: msg,
                filter: filter || null
            });
        }
    }
})();