(function () {
    'use strict';

    angular
        .module('vmis.administrator', [
            'ui.router',
            'angularMoment',

            'administrator.hours'
        ]);
})();