/**
 * Created by tlester3 on 4/16/16.
 */
(function () {
    'use strict';

    angular
        .module('app')
        .config(config);

    config.$inject = ['$urlRouterProvider'];

    function config($urlRouterProvider) {
        $urlRouterProvider
            .otherwise('/');
    }
})();