(function () {
    'use strict';

    angular
        .module('vmis.activityCalendar')
        .directive('calendar', ActivityCalendar);

    ActivityCalendar.$inject = [];

    function ActivityCalendar() {
        return {
            restrict: 'E',
            templateUrl: 'app/components/volunteer/activityCalendar/activityCalendar.view.html',
            scope: {
                date: '=?',
                position: '=',
                reload: '=?'
            },
            controller: 'ActivityCalendarController',
            controllerAs: 'vm',
            backdrop: 'static',
            keyboard: false,
            bindToController: {
                onLoadActivities: '&?',
                disabled: '=?',
                targetUser: '=',
                adminEditing: '='
            },
            link: link
        };

        function link(scope) {
            scope.$watch('date', dateListener);
            scope.$watch('position', positionListener);
            scope.$watch('reload', reloadListener);

            function dateListener() {
            }

            function positionListener(newValue, oldValue, scope) {
                if (angular.isDefined(newValue) && (newValue !== oldValue)) {
                    scope.vm.onSetPosition(newValue);
                }
            }

            function reloadListener(newValue, oldValue, scope) {
                if(angular.isDefined(newValue) && (newValue !== oldValue) && newValue === true) {
                    scope.reload = false;
                    scope.vm.onReload();
                }
            }
        }
    }
})();