(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('ConfirmConvertController', ConfirmConvertController);

    ConfirmConvertController.$inject = ['$modalInstance', 'authentication', 'modalParams'];

    function ConfirmConvertController($modalInstance, authentication, modalParams) {
        var vm = this;

        vm.formError = '';
        vm.isLoading = false;
        vm.fullName = modalParams.firstName + " " + modalParams.lastName;
        vm.email = modalParams.email;

        vm.onOk = onOk;

        function onOk() {
            vm.isLoading = true;
            var params = {
                masterUserId: modalParams.masterUserId,
                email: vm.email
            };

            return authentication.setNonUserToUser(params).then(function () {
                return $modalInstance.close();
            }).catch(function (error) {
                vm.formError = error;
            }).finally(function() {
                vm.isLoading = false;
            });
        }
    }
})();