(function () {
    'use strict';

    angular
        .module('app.vmis')
        .controller('InfoModalController', InfoModalController);

    InfoModalController.$inject = ['$modalInstance', 'title', 'firstName', 'lastName', 'email', 'error'];

    function InfoModalController($modalInstance, title, firstName, lastName, email, error) {
        var vm = this;

        vm.title = title;
        vm.fullName = firstName + " " + lastName;
        vm.email = email;

        vm.error = error;

        vm.onOk = onOk;

        activate();

        function activate() {
            $modalInstance.close();
        }

        function onOk() {
            $modalInstance.close();
        }
    }
})();