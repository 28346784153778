(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('StandardPositionsController', StandardPositionsController);

    StandardPositionsController.$inject = ['$modal', 'authentication', 'utilsServices', 'loadStatus', '_'];

    function StandardPositionsController($modal, authentication, utilsServices, loadStatus, _) {
        var vm = this;

        vm.sortColumn = 'title';
        vm.loadStatus = loadStatus.init();

        vm.onChangeStandardOrganization = onChangeStandardOrganization;
        vm.onClickStandardPosition = onClickStandardPosition;
        vm.onLoadAll = onLoadAll;
        vm.onLoadMore = onLoadMore;

        activate();

        function activate() {
            loadStatus.setup(vm.loadStatus, "standardPositions")
                .then(function () {
                    getStandardOrganizations();

                    getStandardPositions(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows);
                });
        }

        function getStandardOrganizations() {
            return authentication.getUserProfile().then(function (response) {
                return utilsServices.combineStandardOrganizationResponsibilities(response).then(function (standardOrganizations) {
                    standardOrganizations.unshift({
                        id: null,
                        name: 'All'
                    });
                    vm.standardOrganizations = standardOrganizations;
                    vm.standardOrganization = standardOrganizations[0];
                });
            });
        }

        function getStandardPositions(index, num, standardOrganizationId) {
            vm.isLoading = true;

            return authentication.getStandardPositionsAdmin({
                startingRowIndex: index,
                numberOfRows: num,
                volunteerOrganizationTemplateId: standardOrganizationId || undefined
            }).then(function (response) {
                if (index === 0) {
                    vm.loadStatus.currentIndex = 0;
                    vm.standardPositions = [];
                }

                // Either set current index to max if all users loaded or increment by rows loaded
                vm.loadStatus.currentIndex = (vm.loadStatus.currentIndex + num > response.count) ? response.count : (vm.loadStatus.currentIndex + num);
                // Track total number of users not loaded for "Load All" button
                vm.loadStatus.numResultsNotLoaded = response.count - vm.loadStatus.currentIndex;
                // Set text for "Load More" button
                vm.loadStatus.nextResultLoadNum = (response.count === vm.loadStatus.currentIndex) ? 0 :
                    (vm.loadStatus.numResultsNotLoaded > num) ? num : vm.loadStatus.numResultsNotLoaded;

                vm.standardPositions = _.concat(vm.standardPositions, _.map(response.standardPositions, function (standardPosition) {
                    return {
                        id: standardPosition.volunteerOrganizationsOpportunitiesTemplateId,
                        title: standardPosition.title,
                        standardOrganization: {
                            id: standardPosition.volunteerOrganizationTemplateId,
                            name: standardPosition.VolunteerOrganizationTemplate
                        },
                        duties: standardPosition.duties,
                        qualifications: standardPosition.qualifications,
                        training: standardPosition.training
                    };
                })
                );
                vm.formError = '';
            }).catch(function (error) {
                if (angular.isDefined(error)) {
                    vm.formError = error.error ? error.error : error;
                }
                else
                    vm.formError = 'Failed to load list! Please try again.';
            }).finally(function () {
                vm.isLoading = false;
            });
        }

        function onChangeStandardOrganization(standardOrganization) {
            vm.loadStatus.currentIndex = 0;
            return getStandardPositions(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows, standardOrganization.id);
        }

        function onClickStandardPosition(standardPosition) {
            $modal.open({
                templateUrl: 'app/components/administrator/standardPositions/standardPositionDetails.view.html',
                controller: 'StandardPositionDetailsController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    title: function () {
                        return standardPosition ? 'Standard Position Details' : 'Add New Standard Position';
                    },
                    standardPosition: function () {
                        return standardPosition ? _.clone(standardPosition) : {};
                    },
                    standardOrganizations: function () {
                        return _.tail(vm.standardOrganizations);
                    }
                }
            }).result.then(function () {
                vm.loadStatus.currentIndex = 0;
                return getStandardPositions(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows);
            });
        }

        function onLoadMore() {
            getStandardPositions(vm.loadStatus.currentIndex, vm.loadStatus.nextResultLoadNum, vm.standardOrganization.id);
        }

        function onLoadAll() {
            getStandardPositions(vm.loadStatus.currentIndex, vm.loadStatus.numResultsNotLoaded, vm.standardOrganization.id);
        }
    }
})();