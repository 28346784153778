(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('AcceptInviteController', AcceptInviteController);

    AcceptInviteController.$inject = ['$state', '$stateParams', 'authentication'];

    function AcceptInviteController($state, $stateParams, authentication) {
        var vm = this;

        vm.onSubmit = onSubmit;

        activate();

        function activate() {
            getInvite();
        }

        function getInvite() {
            return authentication.getInviteStatus($stateParams.hash)
                .then(function (response) {
                    vm.role = response.aosRoles[0];
                    vm.firstName = response.firstName;
                    vm.lastName = response.lastName;
                    var additionalInfo = JSON.parse(response.additionalInfo);
                    vm.communities = additionalInfo.communityAffiliations;
                    vm.standardOrganizations = additionalInfo.volunteerOrganizationTemplates;
                    vm.organizations = additionalInfo.volunteerOrganizations;
                    vm.community = response.community;
                    switch (response.invitationStatusId) {
                        case 'Expired':
                            vm.expired = true;
                            break;
                        case 'Accepted':
                            vm.accepted = true;
                            break;
                    }
                    vm.showInvite = !(vm.expired || vm.accepted);
                })
                .catch(displayError);
        }

        function onSubmit() {
            return authentication.acceptInvite({
                hash: $stateParams.hash
            }).then(function () {
                $state.go('vmis.inviteAccepted');
            }).catch(displayError);
        }

        function displayError(error) {
            vm.formError = error;
        }
    }
})();