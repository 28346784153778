(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .controller('OpportunityDetailsController', OpportunityDetailsController);

    OpportunityDetailsController.$inject = ['$q', 'params', '$modalInstance', '$state', '$window', 'opportunitiesService', 'authentication'];

    function OpportunityDetailsController($q, params, $modalInstance, $state, $window, opportunitiesService, authentication) {
        var vm = this;

        vm.opportunityId = params.opportunityId;
        vm.onSubmit = onSubmit;
        vm.closeModal = closeModal;
        vm.onPrint = onPrint;

        activate();

        function activate() {
            getOpportunityDetails();
        }

        function getOpportunityDetails() {
            return $q.all({
                details: opportunitiesService.getOpportunityDetails(vm.opportunityId),
                opocs: authentication.getOpocsForOpportunity({volunteerOpportunityId: vm.opportunityId})
            })
                .then(function (results) {
                    vm.details = results.details;
                    vm.details.isBackgroundCheckRequired = vm.details.isBackgroundCheckRequired ? 'Yes' : 'No';
                    vm.details.supervisionConditionFlag = vm.details.supervisionConditionFlag ? 'Yes' : 'No';

                    vm.opocs = results.opocs.opocs;
                });
        }

        function onSubmit() {
            //$modalInstance.close(); // This was causing a console error: "TypeError: Cannot read property 'value' of undefined"
            if (opportunitiesService.isLoggedIn()) {
                $state.go('vmis.volunteer.opportunities.apply', {opportunityId: vm.opportunityId});
            }
            else {
                authentication.setRedirect('vmis.volunteer.opportunities.apply', {opportunityId: vm.opportunityId});
                $state.go('vmis.logIn');
            }
        }

        function onPrint() {
            var printHref = $state.href("print.position", { id: vm.opportunityId }, {absolute: true});
            $window.open(printHref.replace("#", "print.html#"), "Print");
        }
        
        function closeModal() {
            $modalInstance.close();
        }
    }
})();