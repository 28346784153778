(function () {
    'use strict';

    angular
        .module('app.vmisHoursEntry')
        .controller('HoursEntryController', HoursEntryController);

    HoursEntryController.$inject = ['$scope', '$q', '$timeout', 'moment', 'authentication', '$modal', '_'];

    function HoursEntryController($scope, $q, $timeout, moment, authentication, $modal, _) {
        var vm = this;

        vm.dateRange = {
            start: new Date().getDate() < 15 ? moment().subtract(1, 'months').startOf('month').toDate() : moment().startOf('month').toDate(),
            end: new Date()
        };
        vm.tooltipIsOpen = false;
        vm.autoSubmitTimeOut = null;

        vm.autoSubmitHours = autoSubmitHours;
        vm.onHourBlur = setHour;

        $scope.$watch('vmWidget.date', dateListener, true);

        function getOpportunities() {
            return authentication
                .getOpportunitiesAppliedFor({
                    startingRowIndex: 0,
                    numberOfRows: 100,
                    volunteerApplicationStatus: 'Approved',
                    volunteerServiceStatus: 'Active',
                    volunteerPositionStatus: ['Open, searchable','Open, not searchable']
                })
                .then(function (response) {
                    return response.opportunities.map(function (position) {
                        return {
                            id: position.opportunityId,
                            title: position.title
                        };
                    });
                });
        }

        function getAllHours(opportunities) {
            return $q.all(opportunities.map(getHours));
        }

        function getHours(opportunity) {
            var request = {
                volunteerOpportunityId: opportunity.id,
                // Just send date, as timezones would cause an extra Sunday to be included
                startDate: moment(vm.date).startOf('week').format('YYYY-MM-DD'),
                endDate: moment(vm.date).endOf('week').format('YYYY-MM-DD')
            };

            return authentication.getHoursVol(request)
                .then(function (response) {
                    return _.map(_.filter(response.hours, 'dayOfService'), function (activity) {
                        return {
                            date: moment(activity.dayOfService),
                            hours: activity.hours,
                            status: activity.volunteerHoursStatus,
                            reviewerNote: activity.reviewerNotes,
                            userNote: activity.userNotes
                        };
                    });
                });
        }

        /**
         * This function creates a timeout that, when expired, submits the hours being entered in
         * the Log Hours card. It should be set on the "keyup" event.
         * Adapted from emailValidator.directive.js in Account Management.
         */
        function autoSubmitHours(position, activity) {
            //cancel the last timeout
            clearTimeout(vm.autoSubmitTimeOut);

            // wait for user to stop typing before processing validity
            vm.autoSubmitTimeOut = setTimeout(function(){
                setHour(position, activity);
            }, 1500);
        }

        function setHour(position, activity) {
            if (angular.isUndefined(activity.hours) || activity.hours === null) {
                return;
            }
            var hours = activity.hours || 0;
            var request = {
                volunteerOpportunityId: position.id,
                hours: {
                    dateEntered: moment().toISOString(),
                    dayOfService: moment(activity.date).startOf('day').toISOString(),
                    hours: hours
                }
            };

            authentication
                .setVolunteerHoursForOpportunity(request)
                .then(function (response) {
                    vm.tooltipIsOpen = true;
                    if (typeof vm.setHourCallback === 'function') {
                        vm.setHourCallback();
                    }
                    $timeout(function () {
                        vm.tooltipIsOpen = false;
                    }, 2000);
                    return response;
                })
                .then(displayHours)
                .catch(function (error) {
                    var templateUrl = "app/common/directives/vmisHoursEntry/error.html";
                    var options = {
                        controller: 'HoursErrorController',
                        controllerAs: 'modal',
                        templateUrl: templateUrl,
                        size: 'tiny',
                        backdrop: 'static',
                        keyboard: false,
                        resolve: {
                            error: function () {
                                return error;
                            }
                        }
                    };
                    $modal.open(options);
                });
        }

        function dateListener() {
            displayHours();
        }

        function displayHours() {
            vm.weekday = moment(vm.date).weekday();
            var deferred = $q.defer();
            var promise = deferred.promise;

            promise
                .then(getOpportunities)
                .then(function (positions) {
                    vm.positions = positions;
                    return positions;
                })
                .then(getAllHours)
                .then(function (result) {

                    for (var i = 0; i < vm.positions.length; i++) {
                        var position = vm.positions[i];
                        position.activities = [];
                        // TODO: could be more robust by checking if full date matches, not just weekday
                        for (var d = 0; d < 7; d++) {
                            var foundIndex = -1;
                            for (var j = 0; j < result[i].length; j++) {
                                foundIndex = result[i][j].date.weekday() === d ? j : foundIndex;
                            }
                            var activity = {
                                hours: null,
                                date: moment(vm.date).weekday(d).toDate()
                            };
                            // Element IDs must be unique
                            activity.id = "hours-" +
                                position.title.split(" ").join("") +
                                "-" +
                                activity.date.toDateString().split(" ").join("");

                            if (foundIndex > -1) {
                                activity.hours = result[i][foundIndex].hours ? result[i][foundIndex].hours : null;
                                activity.status = result[i][foundIndex].status;
                            }

                            position.activities.push(activity);
                        }
                    }

                    return result;
                })
                .catch(function (error) {
                    var templateUrl = "app/common/directives/vmisHoursEntry/error.html";
                    var options = {
                        controller: 'HoursErrorController',
                        controllerAs: 'modal',
                        templateUrl: templateUrl,
                        size: 'tiny',
                        backdrop: 'static',
                        keyboard: false,
                        resolve: {
                            error: function () {
                                return error;
                            }
                        }
                    };
                    $modal.open(options);
                });

            deferred.resolve();
        }
    }
})();