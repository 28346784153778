/**
 * Created by nbhadsavle3 on 11/3/2017.
 */

(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('AddEmailUsernameController', AddEmailUsernameController);

    AddEmailUsernameController.$inject = ['$modalInstance', 'title'];

    function AddEmailUsernameController($modalInstance, title) {
        var vm = this;
        
        vm.title = title;

        vm.onCancel = onCancel;
        vm.onConfirm = onConfirm;

        activate();

        function activate() {
        }

        function onConfirm() {
            $modalInstance.close({item: vm.item});
        }

        function onCancel() {
            $modalInstance.dismiss('cancel');
        }
    }
})();