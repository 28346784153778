(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .controller('OpportunitiesController', OpportunitiesController);

    OpportunitiesController.$inject = ['$modal', 'opportunitiesService', 'loadStatus', 'config', '$scope', '$timeout'];

    function OpportunitiesController($modal, opportunitiesService, loadStatus, config, $scope, $timeout) {
        var vm = this;

        vm.isLoading = true;

        vm.opportunities = [];
        vm.sortReverse = true;
        vm.sortColumn = 'postedDate';
        vm.title = '';
        vm.filters = {};
        vm.alerts = [];

        vm.loadStatus = loadStatus.init();

        vm.onFiltersClick = onFiltersClick;
        vm.onClickSearch = onClickSearch;
        vm.onSelect = onSelect;
        vm.onLoadMore = onLoadMore;
        vm.onLoadAll = onLoadAll;
        vm.onCloseAlert = onCloseAlert;

        $scope.$on('opportunities:alert', alertHandler);

        activate();

        function activate() {
            vm.isLoggedIn = opportunitiesService.isLoggedIn();

            loadStatus.setup(vm.loadStatus, "opportunities")
                .then(function () {
                    // If user is logged in, filter the Opportunities page based on their home community.
                    // (This was recommended by Gary Ducharme of Three Wire Systems.)
                    if (vm.isLoggedIn) {
                        opportunitiesService.getUserProfile().then(function (response) {
                            // Note: when filter is selected from modal, more data is present than these fields.
                            // Filter modal setup fills in remaining information if opened.
                            // See opportunitiesFilter.controller.js:99
                            vm.filters.community = {
                                "communityAffiliationId": response.aosUser.communityAffiliationId,
                                "communityAffiliationName": response.aosUser.communityAffiliation
                            };
                            // Matches line ~129
                            vm.selectedCommunity = vm.filters.community.communityAffiliationName;

                            loadOpportunities(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows,
                                undefined, undefined, undefined, vm.selectedCommunity,
                                undefined, undefined, undefined);
                        }).catch(function (error) {
                            console.error(error);
                        });
                    } else {
                        // Otherwise, load opportunities normally
                        loadOpportunities(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows);
                    }
                });
        }

        function loadOpportunities(index, num, postalCode, state, country, community, organizationGroup, organization, title) {
            vm.isLoading = true;

            opportunitiesService
                .getOpportunities(index, num, postalCode, state, country, community, organizationGroup, organization, title)
                .then(function (result) {
                    // TODO: communityAffiliationId, usStateId, countryId replace
                    // communityAffiliation, usState, country - table columns empty
                    getOpportunitiesSuccess(result, index, num);
                }).catch(function (error) {
                    getOpportunitiesError(error);
                });
        }

        function onSelect(opportunity) {
            openModal(opportunity.volunteerOpportunityId);
        }

        function openModal(id) {
            $modal.open({
                templateUrl: 'app/components/volunteer/opportunities/opportunityDetails.template.html',
                controller: 'OpportunityDetailsController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    params: function () {
                        return {
                            opportunityId: id
                        };
                    }
                }
            });

            // modalInstance.result
            // To Do: add error catching

        }

        function onClickSearch() {
            vm.loadStatus.currentIndex = 0;
            return loadOpportunities(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows,
                vm.filters.postalCode, vm.filters.state, vm.filters.country,
                vm.selectedCommunity, vm.filters.organizationGroup, vm.selectedOrganization, vm.title);
        }

        function onFiltersClick() {
            var modalInstance = $modal.open({
                templateUrl: 'app/components/volunteer/opportunities/opportunitiesFilter.template.html',
                controller: 'OpportunitiesFilterController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    filters: function () {
                        return vm.filters;
                    }
                }
            });

            modalInstance.result
                .then(function (filters) {
                    vm.filters = filters;

                    // These set to undefined in opportunities filter if not selected,
                    // which is copied out into the vm.filters object above.
                    // (The vm.filters fields for comm and org are not used?)
                    if (angular.isDefined(filters.community) &&
                        angular.isDefined(filters.community.communityAffiliationName)) {
                        vm.selectedCommunity = filters.community.communityAffiliationName;
                    }
                    else {
                        vm.selectedCommunity = undefined;
                    }
                    if (angular.isDefined(filters.organization) &&
                        angular.isDefined(filters.organization.volunteerOrganizationId)) {
                        vm.selectedOrganization = filters.organization.volunteerOrganizationId;
                    }
                    else {
                        vm.selectedOrganization = undefined;
                    }

                    if(angular.isDefined(filters.state)){
                        filters.state = filters.state.substring(0,filters.state.length-5);
                    }
                    else {
                        filters.state = undefined;
                    }

                    vm.loadStatus.currentIndex = 0;
                    loadOpportunities(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows,
                        filters.postalCode, filters.state, filters.country,
                        vm.selectedCommunity, filters.organizationGroup, vm.selectedOrganization, vm.title);
                }, function (error) {
                    if (angular.isDefined(error)) {
                        var errorStr = error.error ? error.error : error;
                        $scope.$emit('opportunities:alert', errorStr);
                    }

                    vm.isLoading = false;
                });
        }

        function getOpportunitiesSuccess(response, index, num) {
            if (index === 0) {
                vm.loadStatus.currentIndex = 0;
                vm.opportunities = [];
            }

            // Either set current index to max if all users loaded or increment by rows loaded
            vm.loadStatus.currentIndex = (vm.loadStatus.currentIndex + num > response.count) ? response.count : (vm.loadStatus.currentIndex + num);
            // Track total number of users not loaded for "Load All" button
            vm.loadStatus.numResultsNotLoaded = response.count - vm.loadStatus.currentIndex;
            // Set text for "Load More" button
            vm.loadStatus.nextResultLoadNum = (response.count === vm.loadStatus.currentIndex) ? 0 :
                (vm.loadStatus.numResultsNotLoaded > num) ? num : vm.loadStatus.numResultsNotLoaded;

            vm.opportunities = vm.opportunities.concat(response.opportunities);
            $timeout(function () {
                vm.isLoading = false;
            }, 100);
        }

        function getOpportunitiesError(error) {
            if (angular.isDefined(error)) {
                var errorStr = error.error ? error.error : error;
                $scope.$emit('opportunities:alert', errorStr);
            }
            else
                $scope.$emit('opportunities:alert', "Failed to load list! Please try again.");

            $timeout(function () {
                vm.isLoading = false;
            }, 100);
        }

        function onLoadMore() {
            loadOpportunities(vm.loadStatus.currentIndex, vm.loadStatus.nextResultLoadNum,
                vm.filters.postalCode, vm.filters.state, vm.filters.country,
                vm.selectedCommunity, vm.filters.organizationGroup, vm.selectedOrganization, vm.title);
        }
        function onLoadAll() {
            loadOpportunities(vm.loadStatus.currentIndex, vm.loadStatus.numResultsNotLoaded,
                vm.filters.postalCode, vm.filters.state, vm.filters.country,
                vm.selectedCommunity, vm.filters.organizationGroup, vm.selectedOrganization, vm.title);
        }

        function alertHandler(event, message) {
            event.stopPropagation();

            displayError(message);
        }

        function onCloseAlert(index) {
            vm.alerts.splice(index, 1);
        }

        function displayAlert(type, message) {
            vm.alerts.push({
                type: type,
                message: message
            });
        }

        function displayError(error) {
            if (error === 'cancel' || error === 'backdrop click')
                return;
            if (error)
                displayAlert('alert', error);
        }

    }
})();
