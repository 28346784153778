(function () {
    'use strict';

    angular
        .module('app.auth')
        .factory('config', config);

    config.$inject = ['$http'];

    function config($http) {
        var $config;

        return { get: get };

        function get() {
            if (!$config) {
                $config = $http.get('/config.json').then(function (response) {
                    if (typeof response.data !== 'object') {
                        response.status = 404;
                        response.statusText = 'Not Found';
                        throw response;
                    }
                    return response.data;
                });
            }
            return $config;
        }
    }
})();
