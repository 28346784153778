(function () {
    'use strict';

    angular
        .module('app.vmis')
        .config(routes);

    routes.$inject = ['$stateProvider'];

    function routes($stateProvider) {
        $stateProvider
            .state('vmis.dashboard', {
                url: '/dashboard',
                title: 'Dashboard',
                views: {
                    '@vmis': {
                        templateUrl: 'app/components/dashboard/dashboard.view.html',
                        controller: 'DashboardController',
                        controllerAs: 'vm'
                    },
                    'coordinator@vmis.dashboard': {
                        templateUrl: 'app/components/dashboard/coordinator/coordinatorDashboard.view.html',
                        controller: 'CoordinatorDashboardController',
                        controllerAs: 'vm'
                    },
                    'opocAvccAssistant@vmis.dashboard': {
                        templateUrl: 'app/components/dashboard/opocAvccAssistant/opocAvccAssistantDashboard.view.html',
                        controller: 'OpocAvccAssistantDashboardController',
                        controllerAs: 'vm'
                    },
                    'volunteer@vmis.dashboard': {
                        templateUrl: 'app/components/dashboard/volunteer/volunteerDashboard.view.html',
                        controller: 'VolunteerDashboardController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    isAdmin: ['$stateParams', 'authentication', function ($stateParams, authentication) {
                        return authentication.getUserProfile().then(function (response) {
                            if (response.aosUser && response.aosUser.vmisUser && response.aosUser.vmisUser.adminProfile)
                                return !!response.aosUser.vmisUser.adminProfile;
                            else
                                return false;
                        }).catch(function () {
                            return false;
                        });
                    }]
                }
            });
    }
})();