/**
 * Created by nbhadsavle3 on 10/18/2017.
 */
(function () {
    'use strict';

    angular
        .module('app')
        .controller('DA4713YearSelector', DA4713YearSelector);

    DA4713YearSelector.$inject = ['$scope', 'masterUserId','$modalInstance', 'authentication'];

    function DA4713YearSelector($scope, masterUserId, $modalInstance, authentication) {
        var vm = this;
        vm.isLoading = true;

        vm.years=[];
        vm.alerts=[];

        var year = moment().year();
        vm.years.push(year.toString());
        vm.selectedYear = year.toString();
        for(var i=0; i<9; i++){
            vm.years.push((year-(i+1)).toString());
        }

        $scope.$on('da4713YearSelector:alert', alertHandler);
        vm.onCloseAlert = onCloseAlert;

        activate();
        vm.generateDA4713Form = generateDA4713Form;

        vm.isLoading = false;
        function activate() {
        }

        function generateDA4713Form() {
            vm.isLoading = true;
            return authentication.getDA4713({
                year: vm.selectedYear,
                masterUserId: masterUserId
            })
            .then($modalInstance.close).catch(function (error) {
                vm.isLoading = false;
            })
            .catch(function(error){
                vm.isLoading = false;
               generateDAFormError(error);
            });
        }

        function generateDAFormError(error) {
            if (angular.isDefined(error)) {
                var errorStr = error.error ? error.error : error;
                $scope.$emit('serviceHistory:alert', errorStr);
            }
            else
                $scope.$emit('serviceHistory:alert', "Failed to generate form, please try again");

            vm.isLoading = false;
        }

        function alertHandler(event, message) {
            event.stopPropagation();

            displayError(message);
        }

        function onCloseAlert(index) {
            vm.alerts.splice(index, 1);
        }

        function displayAlert(type, message) {
            vm.alerts.push({
                type: type,
                message: message
            });
        }

        function displayError(error) {
            if (error === 'cancel' || error === 'backdrop click')
                return;
            if (error)
                displayAlert('alert', error);
        }
    }
})();