(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('ConfirmOrganizationInactiveController', ConfirmOrganizationInactiveController);

    ConfirmOrganizationInactiveController.$inject = ['$modalInstance', 'title', 'organization', 'community'];

    function ConfirmOrganizationInactiveController($modalInstance,title, organization, community) {
        var vm = this;
        vm.title = title;

        if(organization !== null) {
            vm.name = organization.name;
        }

        if(community !== null) {
            vm.name = community;
        }

        vm.onAccept = onAccept;

        function onAccept() {
            $modalInstance.close();
        }
    }
})();