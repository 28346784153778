(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .controller('DocumentsController', DocumentsController);

    DocumentsController.$inject = ['$scope', '$modal', '$stateParams', 'authentication', 'adminInfo'];

    function DocumentsController($scope, $modal, $stateParams, authentication, adminInfo) {
        var vm = this;

        vm.isLoading = true;
        vm.sortColumn = 'date';
        vm.sortReverse = true;

        vm.maxDocumentsUploaded = false;

        var index = 0;
        var limit = 100;

        vm.documents = [];
        vm.masterUserId = parseInt($stateParams.id) || null;
        // Admin master user ID is used to check whether user can delete individual documents they uploaded
        vm.adminMasterUserId = parseInt(adminInfo.adminMasterUserId) || null;
        // Use existence of adminMasterUserId (not undefined) as the official check
        // of whether admin is editing another user
        vm.adminEditing = angular.isNumber(vm.adminMasterUserId);

        vm.onClickDetails = onClickDetails;
        vm.onClickAddDoc = onClickAddDoc;
        vm.canUserDeleteDoc = canUserDeleteDoc;

        activate();

        function activate() {
            getDocuments(vm.masterUserId);
        }

        function getDocuments(id) {
            vm.isLoading = true;

            var params = {
                startingRowIndex: index,
                numberOfRows: limit
            };

            // Only add this field if valid parameter is passed in
            if (angular.isNumber(id))
                params.masterUserId = id;

            return authentication.getVolunteerDocuments(params)
                .then(function (response) {
                    vm.documents = response.documents.map(function (doc) {
                        return {
                            id: doc.userFileId,
                            name: doc.documentName,
                            url: doc.fileUrl,
                            date: new Date(doc.modifiedDate),
                            description: doc.documentDescription,
                            uploaderFullName: doc.uploaderLastName + ', ' + doc.uploaderFirstName,
                            uploadersId: doc.uploadersId,
                            masterUserId: doc.masterUserId
                        };
                    });

                    vm.isLoading = false;
                })
                .catch(function (error) {
                    if (error) {
                        error = error.error ? error.error : error;
                        $scope.$emit('records:alert', error);
                    }
                    vm.isLoading = false;
                });
        }

        function openDetailsModal(document, id) {
            var options = {
                templateUrl: 'app/components/volunteer/records/documents/details/view.html',
                controller: 'DocumentDetailsController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    document: function () {
                        return document ? angular.copy(document) : {};
                    },
                    id: function () {
                        return id ? angular.copy(id) : null;
                    },
                    // If document already exists, it cannot be edited. If not, making new one.
                    viewOnly: function () {
                        return document ? !canUserDeleteDoc(document) : false;
                    }
                }
            };
            $modal.open(options)
                .result
                .then(function (result) {
                    vm.isLoading = true;

                    var verb = "";
                    switch (result.action) {
                        case 'add':
                            verb = "added";
                            break;
                        case 'edit':
                            verb = "updated";
                            break;
                        case 'delete':
                            verb = "deleted";
                            break;
                        default:
                            break;
                    }

                    var msg = "";
                    if (verb)
                        msg = "Document(s) successfully " + verb + "!";
                    else
                        msg = "Unrecognized action!";
                    $scope.$emit('records:success', msg);

                    if (vm.adminEditing)
                        getDocuments(id);
                    else
                        getDocuments();
                })
                .catch(function (error) {
                    // If modal is left by pressing "Escape", error is undefined.
                    if (error) {
                        error = error.error ? error.error : error;
                        $scope.$emit('records:alert', error);
                    }
                });
        }

        function onClickDetails(document) {
            if (vm.adminEditing)
                openDetailsModal(document, vm.masterUserId);
            else
                openDetailsModal(document);
        }

        function onClickAddDoc() {
            if (vm.adminEditing)
                openDetailsModal(null, vm.masterUserId);
        }

        // Documents can only be deleted by their uploader
        function canUserDeleteDoc(document) {
            return (document.uploadersId === vm.adminMasterUserId) && vm.adminEditing;
        }
    }
})();