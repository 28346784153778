(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .controller('AwardDeleteController', AwardDeleteController);

    AwardDeleteController.$inject = ['$modalInstance', 'authentication', 'award'];

    function AwardDeleteController($modalInstance, authentication, award) {
        var vm = this;

        vm.award = award;

        vm.onClickDelete = onClickDelete;

        function onClickDelete() {
            vm.processing = true;

            var params = {
                volunteerAwardIds: [vm.award.id]
            };

            return authentication.deleteVolunteerAwards(params)
                .then($modalInstance.close)
                .catch($modalInstance.dismiss);
        }
    }
})();