(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .controller('PositionListController', PositionListController);

    PositionListController.$inject = ['$scope', '$stateParams', '$modal', '_', 'authentication', 'defaultFilters'];

    function PositionListController($scope, $stateParams, $modal, _, authentication, defaultFilters) {
        var vm = this;
        var index = 0;
        var limit = 100;
        var filters = defaultFilters;

        vm.positions = null;
        vm.isLoading = true;

        vm.getDetails = getDetails;
        vm.getHours = getHours;

        $scope.$on('filters', onChangeFilters);

        activate();

        function activate() {
            getPositions();
        }

        function getPositions() {
            vm.isLoading = true;
            return authentication.getVolunteerServiceRecord({
                onlyAVCInfo: defaultFilters.onlyAVCInfo,
                masterUserId: $stateParams.id || undefined
            }).then(function (result) {
                vm.positions = result.positions.map(function (position) {
                    return {
                        id: position.id,
                        name: position.name,
                        startDate: (position.startDate === "N/A")?position.startDate:moment.utc(position.startDate).format('MM/DD/YYYY'),
                        endDate: (position.endDate === "N/A")?position.endDate:moment.utc(position.endDate).format('MM/DD/YYYY'),
                        status: position.serviceStatus,
                        organization: position.organization.name,
                        community: position.organization.community,
                        summary: position.summary,
                        hours: position.totalHoursCertified,
                        isReadonly: _.isUndefined($stateParams.id) && position.serviceStatus !== 'Active' && position.serviceStatus !== 'Finished'
                    };
                });
            }).finally(function () {
                vm.isLoading = false;
            });
        }

        function getDetails(position) {
            var options = {
                templateUrl: 'app/components/volunteer/serviceHistory/avcPositions/serviceDetailsModal/view.html',
                controller: 'PositionServiceDetailsModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    params: function () {
                        return {
                            positionId: position.id,
                            userId: $stateParams.id,
                            startDate: position.startDate,
                            endDate: position.endDate,
                            status: position.status,
                            hours: position.hours
                        };
                    }
                }
            };
            $modal.open(options).result.then(getPositions);
        }

        function getHours(id) {

        }

        function onChangeFilters(event, newFilters) {
            filters = newFilters;
            getPositions();
        }
    }
})();