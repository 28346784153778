(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('DeleteInviteController', DeleteInviteController);

    DeleteInviteController.$inject = ['$modalInstance', 'fullName', 'email', 'role'];

    function DeleteInviteController($modalInstance, fullName, email, role) {
        var vm = this;
        vm.title = "Delete Invitation";

        vm.fullName = fullName;
        vm.email = email;
        vm.role = role;

        vm.title = "Confirm Delete Invitation - " + vm.fullName;

        vm.onAccept = onAccept;

        function onAccept() {
            $modalInstance.close();
        }
    }
})();