(function () {
    'use strict';

    angular
        .module('app.vmis')
        .config(config);

    config.$inject = ['$stateProvider', '$urlRouterProvider'];

    function config($stateProvider, $urlRouterProvider) {
        $urlRouterProvider
            // Check if a service ticket is present in the current url's search parameters.
            .when(/\\*/, ticketHandler)
            // Determine where to reroute the user when they navigate to '/'.
            .when('/', defaultPathRedirect);

        $stateProvider
            .state('print', {
                url: "/print",
                abstract: true,
                template: '<div class="print-page" ui-view></div>'
            })
            .state('vmis', {
                templateUrl: 'app/components/vmis.view.html',
                controller: 'VmisController',
                controllerAs: 'vm'
            })
            .state('print.position', {
                url: '/position/{id:int}',
                templateUrl: 'app/components/vmisPrint.view.html',
                controller: 'VmisPrintController',
                controllerAs: 'vm'
            })
            .state('about', {
                url: '/about',
                templateUrl: 'app/components/vmis.view.html',
                controller: 'VmisController',
                controllerAs: 'vm',
                title: 'About'
            });

        ticketHandler.$inject = ['$cookies', '$state', '$modal', 'authentication', '$location', '$window', 'Idle', 'config'];

        function ticketHandler($cookies, $state, $modal, authentication, $location, $window, Idle, config) {
            var searchParms = $location.search();

            // Check if a service ticket is present in the current url's search parameters. If not, continue handling route as normal.
            if (angular.isDefined(searchParms.ticket)) {
                var ticket = searchParms.ticket;
                return config.get().then(function (configData) {
                    return authentication.logIn(ticket).then(function (response) {
                        Idle.watch();
                        $cookies.remove('logged-out-reason');

                        var options = {
                            controllerAs: 'vm',
                            backdrop: 'static',
                            keyboard: false
                        };

                        // This is handled in Account Management?
                        // Check if the user logged in with a one-time password or a password that is soon to expire. If so, display a modal with the appropriate warning.
                        // if (response.isOneTimeUsePassword) {
                        //     options.templateUrl = 'app/components/accountSettings/editPassword.view.html';
                        //     options.controller = 'EditPasswordController';
                        //     options.resolve = {
                        //         isDismissible: function () {
                        //             return false;
                        //         }
                        //     };
                        //     options.id = 1;
                        //     $modal.open(options);
                        // } else

                        // If password is near expiration, alert user. If using Common Access Card (CAC), don't bother.
                        // Days to expire field should also not be null if using the modal. (Previously this was the only way to determine CAC use.)
                        if (!response.cac_in_use && (response.daysToExpire !== null) && (response.daysToExpire < configData.passwordExpirationWarningDays)) {
                            options.templateUrl = 'app/components/logIn/passwordExpirationModal/passwordExpirationModal.view.html';
                            options.controller = 'PasswordExpirationModalController';
                            options.resolve = {
                                daysRemaining: function () {
                                    return response.daysToExpire;
                                }
                            };
                            options.size = 'tiny';
                            $modal.open(options);
                        }

                        // Reroute the user to a previously defined path, the state that represents the '/' path, or remove the service ticket from url's search parameters.
                        if (authentication.getRedirect()) {
                            var redirect = authentication.getRedirect();
                            authentication.clearRedirect();
                            $state.go(redirect.state, redirect.params);
                        } else if ($location.path() === '/') {
                            $state.go('vmis.dashboard', null, { reload: true });
                        } else {
                            $location.search('ticket', null);
                        }
                    }).catch(function (err) {
                        console.error(err);
                        // Reroute the user to the logged out page and display the appropriate error.
                        $cookies.put('logged-out-reason', configData.loggedOutReasons.ticketError);
                        $state.go('vmis.loggedOut', null, { reload: true });
                    });
                });
            }
            return false;
        }

        defaultPathRedirect.$inject = ['$cookies', '$state', 'authentication', 'config'];

        function defaultPathRedirect($cookies, $state, authentication, config) {
            var isVolunteer, isOpoc, isAvcc, isProgramManager, isAfwpAdmin;

            return config.get().then(function (configData) {
                if (configData.siteUnderConstruction) {
                    // If the site is under construction, redirect the user to '/underConstruction'.
                    $state.go('underConstruction', null, { reload: true });
                }
                else if (authentication.isLoggedIn()) {
                    // Check the current user's profile and determine where to reroute the user depending on their role(s).
                    return authentication.getUserRoles()
                        .then(function (response) {
                            if (response.vmisUserRoles !== null) {
                                for (var i = 0; i < response.vmisUserRoles.length; i++) {
                                    switch (response.vmisUserRoles[i]) {
                                        case 'AVC Volunteer':
                                            isVolunteer = true;
                                            break;
                                        case 'AVC OPOC':
                                            isOpoc = true;
                                            break;
                                        case 'AVC Coordinator':
                                            isAvcc = true;
                                            break;
                                        case 'AVC Program Manager':
                                            isProgramManager = true;
                                            break;
                                        case 'AFWP Administrator':
                                            isAfwpAdmin = true;
                                            break;
                                    }
                                }

                                if (isProgramManager || isAvcc || isOpoc || isVolunteer || isAfwpAdmin) {
                                    $state.go('vmis.dashboard', null, { reload: true });
                                } else {
                                    $state.go('vmis.volunteer.opportunities', null, { reload: true });
                                }
                            }
                        })
                        .catch(function (error) {
                            // Remove the current user's cookies and redirect them to the landing page.
                            console.log(error);
                            authentication.destroyToken();
                            $state.go('landing', null, { reload: true });
                        });
                }
                else {
                    // Redirect unauthenticated users to landing page.
                    $state.go('landing', null, { reload: true });
                }
            });
        }
    }
})();