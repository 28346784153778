(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('OPOCsController', OPOCsController);

    OPOCsController.$inject = ['$q', '$modal', '$scope', '$location', '_', 'authentication', 'loadStatus', 'params', 'moment', '$timeout', '$window', '$state'];

    function OPOCsController($q, $modal, $scope, $location, _, authentication, loadStatus, params, moment, $timeout, $window, $state) {
        var vm = this;

        vm.isLoading = true;
        vm.roleObjectList = angular.copy(params.roleObjectList);
        vm.responsibilities = angular.copy(params.responsibilities);

        var isProgramManager = params.isProgramManager;
        // If user is PM but not AVCC, cannot add roles
        vm.isPMAndNotAVCC = params.isPMAndNotAVCC;

        vm.selectedRoleObject = vm.roleObjectList.filter(function (role) {
            return role.name === "OPOC";
        })[0];
        vm.numUsersSelected = 0;
        vm.isExporting = false;
        vm.sortColumn = 'aosUser.lastName';
        vm.sortReverse = false;

        vm.filter = {
            selectedCommunity: _.parseInt($location.search().community) || undefined,
            selectedOrganizations: $location.search().organization || undefined,
            lastName: $location.search().lastName || '',
            firstName: $location.search().firstName || '',
            startDate: isNaN(new Date($location.search().startDate)) ? undefined : new Date($location.search().startDate),
            endDate: isNaN(new Date($location.search().endDate)) ? undefined : new Date($location.search().endDate),
            workEmail: $location.search().workEmail || ''
        };

        if(vm.filter.selectedOrganizations !== undefined){
            if(Array.isArray(vm.filter.selectedOrganizations)){
                vm.filter.selectedOrganizations.forEach(function(value,index){
                    vm.filter.selectedOrganizations[index] = _.parseInt(value);
                });
            }
            else {
                vm.filter.selectedOrganizations = [_.parseInt(vm.filter.selectedOrganizations)];
            }
        }

        vm.loadStatus = loadStatus.init();

        vm.usersWithRole = [];

        vm.onSelectRole = onSelectRole;
        vm.onClickFilterUsers = onClickFilterUsers;
        vm.onClickSearch = onClickSearch;
        vm.onClickAddNewOPOC = onClickAddNewOPOC;
        vm.onClickEditOPOC = onClickEditOPOC;
        vm.onClickOpenOPOC = onClickOpenOPOC;
        vm.onCheckAll = onCheckAll;
        vm.onCheckUser = onCheckUser;
        vm.onMassEmail = onMassEmail;
        vm.onExport = onExport;
        vm.onLoadMore = onLoadMore;
        vm.onLoadAll = onLoadAll;
        vm.massActionDisabled = massActionDisabled;

        activate();

        function activate() {
            vm.massActions = [
                {
                    name: "Export",
                    action: onExport
                },
                {
                    name: "Mass Email",
                    action: onMassEmail
                }
            ];

            loadStatus.setup(vm.loadStatus, "users")
                .then(function () {
                    fillCommunities(); // Calls getUsers
                });
        }

        function fillCommunities() {
            vm.communities = [];
            var promiseArray = [];
            vm.communities = vm.responsibilities.avcc;

            // If AVCC, communities specified but need organizations arrays
            vm.communities.forEach(function (comm) {
                // Empty array is required for filter modal to add orgs when needed
                comm.organizations = [];
            });

            return $q.all(promiseArray).then(function () {
                vm.communities.unshift({
                    communityAffiliationId: -1,
                    communityAffiliationName: "All",
                    organizations: [{
                        volunteerOrganizationId: -1,
                        organizationName: "All"
                    }]
                });
                return getUsers(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows);
            }).catch(function (error) {
                $scope.$emit('people:alert', error);
                vm.isLoading = false;
            });
        }

        function getUsers(index, num) {
            vm.isLoading = true;
            var allCommunities = !vm.filter.selectedCommunity;
            var allOrganizations = !vm.filter.selectedOrganizations;

            var paramsGetUsers = {
                vmisRole: "AVC OPOC",
                startingRowIndex: index,
                numberOfRows: num,
                firstName: vm.filter.firstName ? vm.filter.firstName : undefined,
                lastName: vm.filter.lastName ? vm.filter.lastName : undefined,
                startDate: vm.filter.startDate ? moment(vm.filter.startDate).startOf('day').toISOString() : undefined,
                endDate: vm.filter.endDate ? moment(vm.filter.endDate).endOf('day').toISOString() : undefined,
                workEmail: vm.filter.workEmail ? vm.filter.workEmail : undefined
            };

            if (!allCommunities) {
                paramsGetUsers.communityAffiliationIds = [vm.filter.selectedCommunity];
            }

            // If specific organization filter is selected, include in parameters
            if (!allOrganizations) {
                paramsGetUsers.volunteerOrganizationIds = vm.filter.selectedOrganizations;
                paramsGetUsers.communityAffiliationIds = undefined;
            }

            authentication.getManagersAdmin(paramsGetUsers)
                .then(function (response) {
                    getUsersSuccess(response, index, num);
                })
                .catch(function (error) {
                    getUsersError(error);
                });

            function getUsersSuccess(response, index, num) {
                if (index === 0) {
                    vm.usersWithRole = [];
                    vm.loadStatus.currentIndex = 0;
                }

                for (var i = 0; i < response.users.length; i++) {
                    // Create unique ID for user checkbox
                    response.users[i].checkboxId = "check-" + response.users[i].aosUser.lastName + response.users[i].aosUser.firstName;
                    // Add user to full list
                    vm.usersWithRole.push(response.users[i]);
                }

                updateCheckAllAndExport();

                // Either set current index to max if all users loaded or increment by rows loaded
                vm.loadStatus.currentIndex = (vm.loadStatus.currentIndex + num > response.count) ? response.count : (vm.loadStatus.currentIndex + num);
                // Track total number of users not loaded for "Load All" button
                vm.loadStatus.numResultsNotLoaded = response.count - vm.loadStatus.currentIndex;
                // Set text for "Load More" button
                vm.loadStatus.nextResultLoadNum = (response.count === vm.loadStatus.currentIndex) ? 0 :
                    (vm.loadStatus.numResultsNotLoaded > num) ? num : vm.loadStatus.numResultsNotLoaded;

                $timeout(function () {
                    vm.isLoading = false;
                }, 100);
            }

            function getUsersError(error) {
                if (angular.isDefined(error)) {
                    var errorStr = error.error ? error.error : error;
                    $scope.$emit('people:alert', errorStr);
                }
                else
                    $scope.$emit('people:alert', "Failed to load list! Please try again.");

                $timeout(function () {
                    vm.isLoading = false;
                }, 100);
            }
        }

        function onSelectRole() {
            $scope.$emit('people:role', vm.selectedRoleObject);
        }

        function onClickFilterUsers() {
            var modalInstance = $modal.open({
                templateUrl: 'app/components/administrator/people/actionDirectives/orgCommFilter.view.html',
                controller: 'OrgCommFilterController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    params: function () {
                        return {
                            communities: angular.copy(vm.communities),
                            selectedCommunity: vm.filter.selectedCommunity,
                            selectedOrganizations: vm.filter.selectedOrganizations,
                            startDate: vm.filter.startDate || null,
                            endDate: vm.filter.endDate || null,
                            workEmail: vm.filter.workEmail || null,
                            role: "AVC OPOC"
                        };
                    }
                }
            });

            modalInstance.result.then(function (result) {
                vm.isLoading = true;
                switch (result.action) {
                    case 'success':
                        // Apply results from filter modal
                        vm.filter.selectedCommunity = result.filter.selectedCommunity.communityAffiliationId !== -1 ? result.filter.selectedCommunity.communityAffiliationId : null;
                        if(result.filter.selectedOrganizations.length === 0){
                            vm.filter.selectedOrganizations = null;
                        }
                        else {
                            vm.filter.selectedOrganizations = [];
                            result.filter.selectedOrganizations.forEach(function(selectedOrg){
                                // If not "All", save
                                if (selectedOrg.volunteerOrganizationId !== -1) {
                                    vm.filter.selectedOrganizations.push(selectedOrg.volunteerOrganizationId);
                                }
                            });
                            // If working list is empty, set org filter back to empty
                            vm.filter.selectedOrganizations = vm.filter.selectedOrganizations.length === 0 ? null : vm.filter.selectedOrganizations;
                        }
                        vm.filter.startDate = result.filter.startDate;
                        vm.filter.endDate = result.filter.endDate;
                        vm.filter.workEmail = result.filter.workEmail;

                        $location.search({
                            community: vm.filter.selectedCommunity,
                            organization: vm.filter.selectedOrganizations,
                            startDate: vm.filter.startDate || null,
                            endDate: vm.filter.endDate || null,
                            firstName: vm.filter.firstName || null,
                            lastName: vm.filter.lastName || null,
                            workEmail: vm.filter.workEmail || null
                        });

                        return getUsers(0, vm.loadStatus.defaultNumRows);
                    case 'error':
                        vm.isLoading = false;
                        return $scope.$emit('people:alert', result.message);
                    default:
                        vm.isLoading = false;
                        return $scope.$emit('people:alert', "Failed to create new filter!");
                }
            }).catch(function (error) {
                $scope.$emit('people:alert', error);
                vm.isLoading = false;
            });
        }

        function onClickSearch() {
            $location.search({
                community: vm.filter.selectedCommunity,
                organization: vm.filter.selectedOrganizations,
                startDate: vm.filter.startDate || null,
                endDate: vm.filter.endDate || null,
                firstName: vm.filter.firstName || null,
                lastName: vm.filter.lastName || null,
                workEmail: vm.filter.workEmail || null
            });
            getUsers(0, vm.loadStatus.defaultNumRows);
            return updateCheckAllAndExport();
        }

        function onClickAddNewOPOC() {
            openOpocModal();
        }

        function onClickEditOPOC(opoc) {
            openOpocModal(opoc);
        }

        function onClickOpenOPOC(opoc) {
            var paramId = {
                id: opoc.masterUserId
            };
            $window.open($state.href('vmis.profileEdit', paramId), '_blank');
        }

        function openOpocModal(opoc) {
            var modalResponsibilities = angular.copy(vm.communities.filter(function (comm) {
                return comm.communityAffiliationId !== -1;
            }).map(function (comm) {
                var retComm = comm;
                retComm.organizations = retComm.organizations.filter(function (org) {
                    return org.volunteerOrganizationId !== -1;
                });
                return retComm;
            }));

            var modalInstance = $modal.open({
                templateUrl: 'app/components/administrator/people/opocs/editOpoc.view.html',
                controller: 'EditOpocController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    params: function () {
                        return {
                            activeUserResponsibilities: modalResponsibilities,
                            opoc: angular.copy(opoc) || null,
                            // If user has certain higher roles, they can remove OPOC role entirely
                            canRemove: isProgramManager,
                            // If user is PM but not AVCC, cannot edit roles
                            canEdit: !vm.isPMAndNotAVCC
                        };
                    }
                }
            });

            // Process result from edit modal
            modalInstance.result
                .then(function (result) {
                    processModalResult(result, opoc, modalResponsibilities);
                })
                .catch(function (error) {
                    $scope.$emit('people:alert', error);
                });
        }

        function processModalResult(result, opoc, modalResponsibilities) {
            vm.isLoading = true;
            var promiseArray = [];
            switch (result.action) {
                case 'edit':
                    result.responsibilities.forEach(function (comm) {
                        promiseArray.push(authentication.modifyUserRoles({
                            masterUserId: result.masterUserId,
                            aosUserRoles: ["AVC OPOC"],
                            communityAffiliationIds: [comm.communityAffiliationId],
                            volunteerOrganizationIds: comm.organizations.map(function (org) {
                                return org.volunteerOrganizationId;
                            }),
                            isAddingOrRemovingResponsibilities: true
                        }));
                    });

                    // Check if an entire community's worth of organizations was removed.
                    if (angular.isDefined(opoc)) {
                        var removedCommunityArray = [];

                        // Filter list to just communities not in new responsibilities list
                        var missingResponsibilities = opoc.aosUser.vmisUser.adminProfile.responsibilities.opoc
                            .filter(function (origResp) {
                                // Return true and keep community if not found in new list by ID
                                return undefined === _.find(result.responsibilities, function (resp) {
                                    return resp.communityAffiliationId === origResp.communityAffiliationId;
                                });
                            });

                        // For each missing community with responsibilities, check if missing because
                        // active user doesn't have purview or if because being entirely removed.
                        missingResponsibilities.forEach(function (missingResp) {
                            for (var i = 0; i < modalResponsibilities.length; i++) {
                                // If in active user responsibilities, take action
                                if (modalResponsibilities[i].communityAffiliationId === missingResp.communityAffiliationId) {
                                    removedCommunityArray.push(missingResp.communityAffiliationId);
                                    break;
                                }
                            }
                        });

                        // API needs the last OPOC community deletion to be done after all others are resolved
                        if (removedCommunityArray.length === 1) {
                            promiseArray.push(authentication.modifyUserRoles({
                                masterUserId: result.masterUserId,
                                aosUserRoles: ["AVC OPOC"],
                                communityAffiliationIds: [removedCommunityArray[0]],
                                volunteerOrganizationIds: [],
                                isAddingOrRemovingResponsibilities: true
                            }));
                        }
                        else if (removedCommunityArray.length > 1) {
                            for (var i = 0; i < removedCommunityArray.length - 1; i++) {
                                promiseArray.push(authentication.modifyUserRoles({
                                    masterUserId: result.masterUserId,
                                    aosUserRoles: ["AVC OPOC"],
                                    communityAffiliationIds: [removedCommunityArray[i]],
                                    volunteerOrganizationIds: [],
                                    isAddingOrRemovingResponsibilities: true
                                }));
                            }
                            return $q.all(promiseArray).then(function () {
                                return authentication.modifyUserRoles({
                                    masterUserId: result.masterUserId,
                                    aosUserRoles: ["AVC OPOC"],
                                    communityAffiliationIds: [removedCommunityArray[removedCommunityArray.length - 1]],
                                    volunteerOrganizationIds: [],
                                    isAddingOrRemovingResponsibilities: true
                                });
                            }).then(function () {
                                $scope.$emit('profile:update');
                                getUsers(0, vm.loadStatus.defaultNumRows);
                            }).catch(function (error) {
                                $scope.$emit('people:alert', error);
                                vm.isLoading = false;
                            });
                        }
                    }
                    break;
                case 'save':
                    if (result.responsibilities.length === 0) {
                        promiseArray.push(authentication.modifyUserRoles({
                            masterUserId: result.masterUserId,
                            aosUserRoles: ["AVC OPOC"],
                            isAdding: true
                        }));
                    }
                    else {
                        var firstComm = result.responsibilities.pop();
                        promiseArray.push(authentication.modifyUserRoles({
                            masterUserId: result.masterUserId,
                            aosUserRoles: ["AVC OPOC"],
                            communityAffiliationIds: [firstComm.communityAffiliationId],
                            volunteerOrganizationIds: firstComm.organizations.map(function (org) {
                                return org.volunteerOrganizationId;
                            }),
                            isAdding: true
                        }));
                    }

                    if (result.responsibilities.length > 0) {
                        return $q.all(promiseArray)
                            .then(function () {
                                result.action = 'edit';
                                return processModalResult(result, opoc, modalResponsibilities);
                            })
                            .catch(function (error) {
                                $scope.$emit('people:alert', error);
                                vm.isLoading = false;
                            });
                    }
                    break;
                case 'remove':
                    promiseArray.push(authentication.modifyUserRoles({
                        masterUserId: result.masterUserId,
                        aosUserRoles: ["AVC OPOC"],
                        isDeleting: true
                    }));
                    break;
                default:
                    break;
            }

            return $q.all(promiseArray).then(function () {
                $scope.$emit('profile:update');
                return getUsers(0, vm.loadStatus.defaultNumRows);
            }).catch(function (error) {
                $scope.$emit('people:alert', error);
                vm.isLoading = false;
            });
        }

        function onCheckAll() {
            // Check users in either complete or filtered list
            for (var i = 0; i < vm.usersWithRole.length; i++)
                vm.usersWithRole[i].isChecked = vm.checkAll;

            updateCheckAllAndExport();
        }

        function onCheckUser() {
            updateCheckAllAndExport();
        }

        function updateCheckAllAndExport() {
            vm.numUsersSelected = 0;
            for (var i = 0; i < vm.usersWithRole.length; i++) {
                if (vm.usersWithRole[i].isChecked)
                    vm.numUsersSelected++;
            }

            // If list empty, uncheck
            if (vm.usersWithRole.length === 0) {
                vm.checkAll = false;
            }
            // Otherwise look for a checked user
            else {
                vm.checkAll = vm.usersWithRole.every(function (user) {
                    return user.isChecked;
                });
            }
        }

        function onMassEmail() {
            var emails = vm.usersWithRole.filter(function (user) {
                return user.isChecked;
            }).map(function (user) {
                return (
                    (user.aosUser.vmisUser.adminProfile.workEmail) ?
                        user.aosUser.vmisUser.adminProfile.workEmail :
                        (user.masterUserEmail) ? user.masterUserEmail : null
                );
            });

            if (emails.length === 0)
                return;

            emails = Array.from(new Set(emails));
            emails = emails.filter(Boolean);

            var modalInstance = $modal.open({
                templateUrl: 'app/components/administrator/people/actionDirectives/massEmail.view.html',
                controller: 'MassEmailController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    params: function () {
                        return {
                            emails: emails
                        };
                    }
                }
            });

            // Process result from modal
            modalInstance.result
                .then(function (result) {
                    switch (result.action) {
                        case 'success':
                            var message = "";
                            if (emails.length !== 1)
                                message = "Mass email \"" + result.message + "\" successfully sent to " + emails.length + " users!";
                            else
                                message = "Mass email \"" + result.message + "\" successfully sent to 1 user!";

                            return $scope.$emit('people:success', message);
                        case 'error':
                            return $scope.$emit('people:alert', result.message);
                        default:
                            return $scope.$emit('people:alert', "Mass email failed to send!");
                    }
                })
                .catch(function (error) {
                    $scope.$emit('people:alert', error);
                });
        }

        // Export currently-checked users to spreadsheet format for download
        function onExport() {
            vm.isExporting = true;

            var exportingUsersHeader = [
                "First Name",
                "Last Name",
                "Last Login Date",
                "Responsibilities",
                "Email\r\n"
            ].toString();

            var exportingUsers = [exportingUsersHeader];
            exportingUsers = exportingUsers.concat(vm.usersWithRole.filter(function (user) {
                return user.isChecked;
            }).map(function (checkedUser) {
                var responsibilitiesStr = "\"";
                if (angular.isDefined(checkedUser.aosUser.vmisUser.adminProfile.responsibilities.opoc)) {
                    checkedUser.aosUser.vmisUser.adminProfile.responsibilities.opoc.forEach(function (comm) {
                        responsibilitiesStr += comm.communityAffiliationName + ": ";
                        comm.organizations.forEach(function (org) {
                            responsibilitiesStr += org.volunteerOrganizationName + "; ";
                        });
                        responsibilitiesStr = responsibilitiesStr.slice(0, -2);
                        responsibilitiesStr += ". ";
                    });
                    responsibilitiesStr = responsibilitiesStr.slice(0, -1);
                    responsibilitiesStr += "\"";
                }

                var email = (checkedUser.aosUser.vmisUser.adminProfile.workEmail !== null) ?
                    checkedUser.aosUser.vmisUser.adminProfile.workEmail : "";

                return [
                    checkedUser.aosUser.firstName,
                    checkedUser.aosUser.lastName,
                    checkedUser.lastSignedIn,
                    responsibilitiesStr,
                    email
                ].toString() + "\r\n";
            }));

            var blob = new Blob(exportingUsers, { type: "text/plain;charset=utf-8" });
            saveAs(blob, (moment().format('YYYYMMDD_HHmmss') + "_Report.csv"));

            vm.isExporting = false;
        }

        function onLoadMore() {
            getUsers(vm.loadStatus.currentIndex, vm.loadStatus.nextResultLoadNum);
            vm.checkAll = false;
        }

        function onLoadAll() {
            getUsers(vm.loadStatus.currentIndex, vm.loadStatus.numResultsNotLoaded);
            vm.checkAll = false;
        }

        function massActionDisabled() {
            return vm.numUsersSelected === 0 || vm.isExporting || vm.isLoading;
        }
    }
})();