(function() {
    'use strict';

    angular
        .module('administrator.hours')
        .controller('BatchConfirmationController', BatchConfirmationController);

    BatchConfirmationController.$inject = ['status'];

    function BatchConfirmationController(status) {
        var vm = this;

        vm.status = status;
    }
})();