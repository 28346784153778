(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .controller('OrientationsController', OrientationsController);

    OrientationsController.$inject = ['$scope', '$modal', '$stateParams', 'authentication', 'adminInfo', 'moment'];

    function OrientationsController($scope, $modal, $stateParams, authentication, adminInfo, moment) {
        var vm = this;

        vm.isLoading = true;
        vm.sortColumn = 'date';
        vm.sortReverse = true;

        var index = 0;
        var limit = 100;

        vm.orientations = [];
        vm.masterUserId = parseInt($stateParams.id) || null;
        // Use existence of adminMasterUserId (not undefined) as the official check
        // of whether admin is editing another user
        vm.adminEditing = angular.isNumber(adminInfo.adminMasterUserId);

        vm.onClickDetails = onClickDetails;
        vm.onClickAddOrientation = onClickAddOrientation;
        vm.canUserEditOrientation = canUserEditOrientation;

        activate();

        function activate() {
            refreshOrientations();
        }

        function getOrientations(id) {
            vm.isLoading = true;

            var params = {
                startingRowIndex: index,
                numberOfRows: limit
            };

            if (angular.isNumber(id)) {
                params.masterUserId = id;
            }

            return authentication.getVolunteerOrientations(params)
                .then(function (response) {
                    vm.orientations = response.result.map(function (orientation) {
                        return {
                            id: orientation.volunteerOrientationId,
                            date: moment(orientation.orientationDate, 'YYYY-MM-DD').toDate(),
                            name: orientation.orientationName,
                            certified: orientation.isCertified,
                            // Note: API currently designed for 1 attachment
                            attachments: orientation.atoAttachmentId ?
                                [{
                                    id: orientation.atoAttachmentId,
                                    url: orientation.fileUrl,
                                    name: orientation.documentName,
                                    description: orientation.documentDescription
                                }] : []
                        };
                    });

                    vm.isLoading = false;
                })
                .catch(function (error) {
                    error = error.error ? error.error : error;
                    $scope.$emit('records:alert', error);
                    vm.isLoading = false;
                });
        }

        function openDetailsModal(orientation, id) {
            var options = {
                templateUrl: 'app/components/volunteer/records/orientations/details/view.html',
                controller: 'OrientationDetailsController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    orientation: function () {
                        return orientation ? angular.copy(orientation) : {};
                    },
                    id: function () {
                        return id ? angular.copy(id) : null;
                    },
                    // If orientation exists, editing and must check further;
                    // if not, adding and not view only
                    viewOnly: function () {
                        return orientation ? !canUserEditOrientation(orientation) : false;
                    }
                }
            };
            $modal.open(options)
                .result
                .then(function (result) {
                    vm.isLoading = true;

                    var verb = "";
                    switch (result.action) {
                        case 'add':
                            verb = "added";
                            break;
                        case 'edit':
                            verb = "updated";
                            break;
                        case 'delete':
                            verb = "deleted";
                            break;
                        default:
                            break;
                    }

                    var msg = "";
                    if (verb)
                        msg = "Orientation successfully " + verb + "!";
                    else
                        msg = "Unrecognized action!";
                    $scope.$emit('records:success', msg);
                })
                .catch(function (error) {
                    // If modal is left by pressing "Escape", error is undefined.
                    if (error) {
                        error = error.error ? error.error : error;
                        $scope.$emit('records:alert', error);
                    }
                })
                .finally(function () {
                    refreshOrientations();
                });
        }

        function onClickDetails(orientation) {
            if (vm.adminEditing)
                openDetailsModal(orientation, vm.masterUserId);
            else
                openDetailsModal(orientation);
        }

        function onClickAddOrientation() {
            if (vm.adminEditing)
                openDetailsModal(null, vm.masterUserId);
            else
                openDetailsModal();
        }

        function refreshOrientations() {
            if (vm.adminEditing)
                getOrientations(vm.masterUserId);
            else
                getOrientations();
        }

        // If orientation is uncertified, it can be edited by user themselves
        function canUserEditOrientation(orientation) {
            return vm.adminEditing || !orientation.certified;
        }
    }
})();