/**
 * Created by scuzzort3 on 8/10/17.
 */
(function () {
    'use strict';

    angular
        .module('app.learnAboutThisPage')
        .directive('learnAboutThisPage', learnAboutThisPage);

    learnAboutThisPage.$inject = [];

    function learnAboutThisPage() {
        return {
            restrict: 'E',
            templateUrl: 'app/common/directives/learnAboutThisPage/learnAboutThisPage.view.html',
            controller: 'LearnAboutThisPageController',
            controllerAs: 'vmWidget',
            scope: {
                learnTitle: '@',
                textBody: '@'
            }
        };
    }
})();