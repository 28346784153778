/**
 * Created by nbhadsavle3 on 11/3/2017.
 */

(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('ConfirmMergeController', ConfirmMergeController);

    ConfirmMergeController.$inject = ['$modalInstance', 'authentication','toEmail','fromEmails','fromUsernames', 'fromNonUsers', '$modal'];

    function ConfirmMergeController($modalInstance, authentication, toEmail, fromEmails, fromUsernames, fromNonUsers, $modal) {
        var vm = this;
        
        vm.isLoading = false;
        vm.toEmail = toEmail;
        vm.fromEmails = fromEmails;
        vm.fromUsernames = fromUsernames;
        vm.fromNonUsers = fromNonUsers;

        vm.onCancel = onCancel;
        vm.onConfirm = onConfirm;

        activate();

        function activate() {
        }

        function onConfirm() {
            vm.isLoading = true;
            authentication.getMasterUserIdFromEmail({email: toEmail})
                .then(function(user){
                    if(user.success){
                        return user.masterUserId;
                    }
                })
                .then(function(masterUserId){
                    if(angular.isDefined(masterUserId)) {
                        var params = {};
                        params.masterUserId = masterUserId;

                        if (fromEmails.length !== 0) {
                            params.emails = fromEmails;
                        }
                        if (fromUsernames.length !== 0) {
                            params.usernames = fromUsernames;
                        }
                        if(fromNonUsers.length !== 0){
                            params.nonUsers = fromNonUsers;
                        }

                        return authentication.mergeVMISdata(params);
                    }
                })
                .then(function(results){
                    vm.isLoading = false;
                    if(angular.isDefined(results)) {
                        $modal.open({
                            templateUrl: 'app/components/administrator/mergeAccounts/mergeCompleted.view.html',
                            controller: 'MergeCompletedController',
                            controllerAs: 'vm',
                            backdrop : 'static',
                            keyboard: false,
                            resolve: {
                                title: function () {
                                    return "Merge Results";
                                },
                                results: function () {
                                    return results;
                                }
                            }
                        }).result.then(function () {
                            $modalInstance.close();
                        });
                    }
                })
                .catch(function(error){
                    if(error === "email not found") {
                        return $modalInstance.close({error: "This user does not exist in the system"});
                    }
                    else {
                        return $modalInstance.close({error: error});
                    }
                })
                .finally(function(){
                    vm.isLoading = false;
                });
        }

        function onCancel() {
            $modalInstance.dismiss('cancel');
        }
    }
})();