(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .controller('OrientationDeleteController', OrientationDeleteController);

    OrientationDeleteController.$inject = ['$modalInstance', 'authentication', 'orientation'];

    function OrientationDeleteController($modalInstance, authentication, orientation) {
        var vm = this;

        vm.orientation = orientation;

        vm.onClickDelete = onClickDelete;

        function onClickDelete() {
            vm.processing = true;

            var params = {
                volunteerOrientationIds: [vm.orientation.id]
            };

            return authentication.deleteVolunteerOrientations(params)
                .then($modalInstance.close)
                .catch($modalInstance.dismiss);
        }
    }
})();