(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .controller('BackgroundCheckDeleteController', BackgroundCheckDeleteController);

    BackgroundCheckDeleteController.$inject = ['$modalInstance', 'authentication', 'backgroundCheck'];

    function BackgroundCheckDeleteController($modalInstance, authentication, backgroundCheck) {
        var vm = this;

        vm.backgroundCheck = backgroundCheck;

        vm.onClickDelete = onClickDelete;

        function onClickDelete() {
            vm.processing = true;

            var params = {
                volunteerBackgroundCheckId: vm.backgroundCheck.id,
                isDelete: true
            };

            return authentication.manageVolunteerBackgroundChecks(params)
                .then($modalInstance.close)
                .catch($modalInstance.dismiss);
        }
    }
})();