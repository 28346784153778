(function () {
    'use strict';

    angular
        .module('app.vmis')
        .controller('VmisPrintController', VmisPrintController);

    VmisPrintController.$inject = ['$q', '$stateParams', 'opportunitiesService', 'authentication'];

    function VmisPrintController($q, $stateParams, opportunitiesService, authentication) {
        var vm = this;

        vm.opportunityId = $stateParams.id;
        activate();

        function activate() {
            getOpportunityDetails();
        }

        function getOpportunityDetails() {
            return $q.all({
                    details: opportunitiesService.getOpportunityDetails(vm.opportunityId),
                    opocs: authentication.getOpocsForOpportunity({volunteerOpportunityId: vm.opportunityId})
                })
                .then(function (results) {
                    vm.details = results.details;
                    vm.details.isBackgroundCheckRequired = vm.details.isBackgroundCheckRequired ? 'Yes' : 'No';
                    vm.details.supervisionConditionFlag = vm.details.supervisionConditionFlag ? 'Yes' : 'No';

                    vm.opocs = results.opocs.opocs;
                    vm.isVolunteerSubmitted = vm.details.volunteerOrganization === 'Volunteer Submitted';
                });

        }
    }
})();