(function () {
    'use strict';

    angular
        .module('vmis.activityCalendar')
        .controller('DeleteHoursController', DeleteHoursController);

    DeleteHoursController.$inject = ['$modalInstance', 'authentication', 'activity', 'adminEditing'];

    function DeleteHoursController($modalInstance, authentication, activity, adminEditing) {
        var vm = this;

        vm.activity = activity;
        vm.adminEditing = adminEditing;

        vm.title = "Delete ";

        if (vm.activity.type === 'Daily')
            vm.title += "Daily Hours - " + moment(vm.activity.date).format("MMMM DD, YYYY");
        else
            vm.title += "Period Hours - " + moment(vm.activity.date).format("MMMM YYYY");

        vm.onClickDelete = onClickDelete;

        function onClickDelete() {
            vm.isWaiting = true;
            return authentication.deleteHours({
                hoursIds: [vm.activity.id],
                reviewerNotes: vm.adminEditing ? vm.activity.reviewerNote : undefined
            }).then($modalInstance.close).catch(function(error) {
                vm.formError = error;
            });
        }
    }
})();