(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .controller('DocumentDeleteController', DocumentDeleteController);

    DocumentDeleteController.$inject = ['$modalInstance', 'authentication', 'document', 'id'];

    function DocumentDeleteController($modalInstance, authentication, document, id) {
        var vm = this;

        vm.document = document;
        vm.id = id;

        vm.onClickDelete = onClickDelete;

        function onClickDelete() {
            vm.processing = true;

            var params = {
                userFileId: vm.document.id,
                masterUserId: vm.id
            };

            return authentication.deleteVolunteerDocument(params)
                .then($modalInstance.close)
                .catch($modalInstance.dismiss);
        }
    }
})();