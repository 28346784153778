(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('ConfirmAvccAssistReplaceController', ConfirmAvccAssistReplaceController);

    ConfirmAvccAssistReplaceController.$inject = ['$modalInstance', 'params'];

    function ConfirmAvccAssistReplaceController($modalInstance, params) {
        var vm = this;

        vm.name = params.userInfo.aosUser.firstName + " " + params.userInfo.aosUser.lastName;
        vm.title = "Replace AVCC Assistant - " + vm.name;
        // Convert responsibilities lists to string separated by comma + space
        vm.avccResponsibilities = params.avccResponsibilities.map(function (resp) {
            return resp.communityAffiliationName;
        }).join(", ");
        // Confirm each object exists (it shouldn't be empty, though)
        vm.avccAssistantResponsibilities = (params.userInfo.aosUser.vmisUser.adminProfile ?
            (params.userInfo.aosUser.vmisUser.adminProfile.responsibilities.avccAssistant ?
                params.userInfo.aosUser.vmisUser.adminProfile.responsibilities.avccAssistant : "") : "")
            .map(function (resp) {
                return resp.communityAffiliationName;
            }).join(", ");

        vm.onAccept = onAccept;

        function onAccept() {
            $modalInstance.close();
        }
    }
})();
