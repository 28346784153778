(function () {
    'use strict';

    angular
        .module('app.vmis')
        .controller('AboutModalController', AboutModalController);

    AboutModalController.$inject = ['$modalInstance', '$q', 'authentication', 'config', 'stayOpen'];

    function AboutModalController($modalInstance, $q, authentication, config, stayOpen) {
        var vm = this;
        vm.title = "About VMIS";

        vm.isLoading = true;

        vm.stayOpen = stayOpen;

        vm.vmisUi = {
            display: false,
            version: "-",
            date: "-",
            url: "",
            numericalVersion: "-"
        };
        vm.vmisApi = {
            version: "-",
            online: false
        };
        vm.coreApi = {
            version: "-",
            online: false
        };

        var promises = [];

        promises.push(config.get().then(function (configData) {
            vm.vmisUi = configData.build;
            vm.vmisUi.numericalVersion = APP_VERSION; // From app/common/version.js
        }));

        // Get VMIS and Core API versions through check_health

        promises.push(authentication.checkVmisApiHealth().then(function (healthData) {
            vm.vmisApi.version = healthData.apiVersion;
            vm.vmisApi.online = healthData.success;
        }));

        promises.push(authentication.checkCoreApiHealth().then(function (healthData) {
            vm.coreApi.version = healthData.apiVersion;
            vm.coreApi.online = healthData.success;
        }));

        $q.all(promises).catch(function (error) {
            console.error(error);
        }).finally(function () {
            vm.isLoading = false;
        });
    }
})();
