(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('EditRegionManagerController', EditRegionManagerController);

    EditRegionManagerController.$inject = ['$modalInstance', '$modal', 'params', 'authentication'];

    function EditRegionManagerController($modalInstance, $modal, params, authentication) {
        var vm = this;

        vm.isLoading = true;

        vm.regionManager = params.regionManager || {};
        vm.isNewRegionManager = params.regionManager === null;
        vm.canRemove = !vm.isNewRegionManager && params.canRemove;
        if (vm.isNewRegionManager)
            vm.title = "Add New Region Manager";
        else
            vm.title = "Edit Region Manager - " + vm.regionManager.aosUser.firstName + " " + vm.regionManager.aosUser.lastName;

        vm.errorText = "";

        vm.regions = params.activeUserResponsibilities;
        vm.rmResponsibilities = [];
        var originalResponsibilities = [];

        vm.onClickRemove = onClickRemove;
        vm.onSubmit = onSubmit;

        activate();

        function activate() {
            if (!vm.isNewRegionManager && vm.regionManager.aosUser.vmisUser.adminProfile.responsibilities.rm) {
                // If existing role with responsibilities, fill ui-select
                vm.regionManager.aosUser.vmisUser.adminProfile.responsibilities.rm.forEach(function (resp) {
                    vm.rmResponsibilities.push(_.find(vm.regions, function (region) {
                        return region.communityAffiliationId === resp.communityAffiliationId;
                    }));
                });
                originalResponsibilities = angular.copy(vm.rmResponsibilities);
            }

            vm.isLoading = false;
        }

        function onClickRemove() {
            vm.isLoading = true;

            var modalInstance = $modal.open({
                templateUrl: 'app/components/administrator/people/actionDirectives/confirmRoleRemove.view.html',
                controller: 'ConfirmRoleRemoveController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    params: function () {
                        return {
                            userInfo: vm.regionManager,
                            role: "AVC Region Manager"
                        };
                    }
                }
            });

            modalInstance.result
                .then(function () {
                    close('remove', vm.regionManager.masterUserId, originalResponsibilities);
                    vm.isLoading = false;
                })
                .catch(function (error) {
                    if (angular.isDefined(error))
                        setError(error);
                    vm.isLoading = false;
                });
        }

        function onSubmit() {
            vm.isLoading = true;

            var getProfilePromise = Promise.resolve(null);
            if (vm.isNewRegionManager){
                getProfilePromise = authentication.getMasterUserIdFromEmail({email: vm.regionManager.masterUserEmail})
                    .then(function(response){
                        if(response.success === true){
                            clearError();
                            vm.regionManager.masterUserId = response.masterUserId;
                            return authentication.getUserProfile({masterUserId: response.masterUserId});
                        }
                        else {
                            setError("The entered email address does not match any existing user");
                            vm.isLoading = false;
                        }
                    });
            }
            else {
                getProfilePromise = authentication.getUserProfile({masterUserId: vm.regionManager.masterUserId});
            }

            getProfilePromise
                .then(function(userProfile) {
                    var isUserRM = userProfile.roles.vmisUserRoles.filter(function (role) {
                        return role === "AVC Region Manager";
                    }).length === 1;

                    if (vm.isNewRegionManager && isUserRM) {
                        setError("The account associated with the email address is already an RM. You must find and edit that user's roles in the table.");
                        vm.isLoading = false;
                        return;
                    }

                    if (vm.isNewRegionManager)
                        close('save', vm.regionManager.masterUserId, vm.rmResponsibilities);
                    else
                        close('edit', vm.regionManager.masterUserId, vm.rmResponsibilities);
                })
                .catch(function (error) {
                    setError(error);
                    vm.isLoading = false;
                });
        }

        function setError(error) {
            var errorStr = error.error ? error.error : error;
            if (errorStr === 'cancel' || errorStr === 'backdrop click')
                return;
            vm.errorText = errorStr;
        }

        function clearError() {
            vm.errorText = "";
        }

        function close(action, masterUserId, responsibilities) {
            $modalInstance.close({
                action: action,
                masterUserId: masterUserId,
                responsibilities: responsibilities
            });
        }
    }
})();