(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('ApplicationDetailsController', ApplicationDetailsController);

    ApplicationDetailsController.$inject = ['params', '$q', '$modal', '$modalInstance', 'authentication'];

    function ApplicationDetailsController(params, $q, $modal, $modalInstance, authentication) {
        var vm = this;
        vm.isLoading = true;
        vm.title = params.title;
        vm.canReject = false;
        vm.canDelete = false;
        vm.applicationId = params.volunteerApplicationId;
        vm.isOrgActive = true;

        vm.onSetStatus = onSetStatus;

        activate();

        function activate() {
            getApplicationDetails(params);
        }

        function finishedLoading(){
            vm.isLoading = false;
        }

        function getApplicationDetails(params) {
            if (!angular.isUndefined(params.volunteerApplicationId)) {
                return authentication.getApplicationDetails(params)
                    .then(function (response) {
                        vm.application = response.application;
                        return $q.all({
                            positionDetails: getPositionDetails(vm.application.volunteerOrganizationOpportunityId),
                            userProfile: getProfile(vm.application.masterUserId),
                            opportunityHours: getHoursAdmin(vm.application.volunteerOrganizationOpportunityId),
                            applicationHours: getHoursUserAdmin(vm.application.volunteerOrganizationOpportunityId, vm.application.masterUserId),
                        });
                    })
                    .then(function (result) {
                        vm.position = result.positionDetails;
                        vm.isOrgActive = vm.position.organization.isActive ? true:false;
                        vm.position.isBackgroundCheckRequired = vm.position.isBackgroundCheckRequired ? 'Yes' : 'No';
                        vm.position.volunteerOrganization = vm.position.organization.isActive ? vm.position.organization.volunteerOrganizationFullName : vm.position.organization.volunteerOrganizationFullName+ " (inactive)";
                        vm.profile = result.userProfile;

                        if (result.opportunityHours.count <= 0) {
                            vm.canReject = true;
                        }

                        vm.canDelete = true;
                        for (var i = 0; i < result.applicationHours.hours.length; i++) {
                            if(result.applicationHours.hours[i].applicationId === params.applicationId){
                                vm.canDelete = false;
                                break;
                            }
                        }
                        // result.applicationHours.hours.forEach(function(hour){
                        //     if(hour.applicationId === params.applicationId){
                        //         vm.canDelete = false;
                        //     }
                        // });

                        vm.showRank = vm.profile.aosUser.dispositions.some(function (disposition) {
                            return disposition === 'Service Member';
                        });
                        vm.showCivilianRank = vm.profile.aosUser.dispositions.some(function (disposition) {
                            return disposition === 'DoD Civilian';
                        });
                        vm.isVolunteerSubmitted = vm.position.volunteerOrganization === 'Volunteer Submitted';
                        vm.position.supervisionConditionFlag = vm.position.supervisionConditionFlag ? 'Yes' : 'No';
                        finishedLoading();
                    });

            }
            else {
                return authentication.getCommunityApplicationDetails(params)
                    .then(function (response) {
                        vm.application = response.application;
                        vm.position = response.application;
                        vm.position.volunteerOrganization = vm.position.commArchived ? 'Volunteer Submitted (inactive)' : 'Volunteer Submitted';
                        vm.isOrgActive = vm.position.commArchived ? false:true;
                        vm.position.isBackgroundCheckRequired = vm.position.isBackgroundCheckRequired ? 'Yes' : 'No';

                        return $q.all({
                            userProfile: getProfile(vm.application.masterUserId),
                            opportunityHours: getHoursAdmin(params.volunteerOrganizationOpportunityId)
                        });
                    })
                    .then(function (result) {
                        vm.profile = result.userProfile;
                        if (result.opportunityHours.count <= 0) {
                            vm.canReject = true;
                        }
                        vm.showRank = vm.profile.aosUser.dispositions.some(function (disposition) {
                            return disposition === 'Service Member';
                        });
                        vm.showCivilianRank = vm.profile.aosUser.dispositions.some(function (disposition) {
                            return disposition === 'DoD Civilian';
                        });
                        vm.isVolunteerSubmitted = true;
                        vm.position.supervisionConditionFlag = vm.position.supervisionConditionFlag ? 'Yes' : 'No';
                        finishedLoading();
                    });
            }
        }

        function getPositionDetails(positionId) {
            return authentication.getOpportunityDetails({
                volunteerOpportunityId: positionId
            })
                .then(function (response) {
                    return response;
                });
        }

        function getProfile(userId) {
            return authentication.getUserProfile({
                masterUserId: userId
            })
                .then(function (response) {
                    return response;
                });
        }

        function getHoursAdmin(opportunityId) {
            var startDate = new Date(0);
            var endDate = new Date();
            return authentication.getHoursAdmin({
                startDate: startDate,
                endDate: endDate,
                volunteerOpportunityId: opportunityId,
                startingRowIndex: 0,
                numberOfRows: 1
            })
                .then(function (response) {
                    return response;
                });
        }

        function getHoursUserAdmin(opportunityId,masterUserId) {
            var startDate = new Date(0);
            var endDate = new Date();
            return authentication.getHoursAdmin({
                volunteerOpportunityId: opportunityId,
                masterUserId: masterUserId
            })
                .then(function (response) {
                    return response;
                });
        }

        function onSetStatus(status) {
            var templateUrl, controller;
            switch (status) {
                case 'Approved':
                    templateUrl = 'app/components/administrator/applications/approveConfirmation.template.html';
                    controller = 'ApproveConfirmationController';
                    break;
                case 'Declined':
                    templateUrl = 'app/components/administrator/applications/rejectConfirmation.template.html';
                    controller = 'RejectConfirmationController';
                    break;
                case 'Delete':
                    templateUrl = 'app/components/administrator/applications/deleteConfirmation.template.html';
                    controller = 'DeleteConfirmationController';
                    break;
                default:
                    return;
            }
            var modalSize = 'notTiny';
            if (controller === 'ApproveConfirmationController') {
                modalSize = 'tiny';
            }

            var modalInstance = $modal.open({
                templateUrl: templateUrl,
                controller: controller,
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    firstName: function () {
                        return vm.profile.aosUser.firstName;
                    },
                    lastName: function () {
                        return vm.profile.aosUser.lastName;
                    },
                    positionTitle: function () {
                        return vm.position.title;
                    }
                },
                size: modalSize
            });

            modalInstance.result
                .then(function (reason) {
                    var params;
                    if(status == 'Delete'){
                        params = {
                          role: "admin",
                        };
                        if (vm.application.volunteerOrganizationOpportunityId) {
                            params.volunteerApplicationId = vm.applicationId;
                        } else if (vm.position.communityVolunteerOrganizationApplicationId) {
                            params.communityVolunteerOrganizationApplicationId = vm.position.communityVolunteerOrganizationApplicationId;
                        }
                        return authentication.deleteApplication(params)
                            .then($modalInstance.close);
                    }
                    else {
                        params = {
                            userId: vm.profile.masterUserId,
                            volunteerApplicationStatus: status
                        };

                        if (vm.application.volunteerOrganizationOpportunityId) {
                            params.opportunityId = vm.application.volunteerOrganizationOpportunityId;
                            params.volunteerApplicationId = vm.applicationId;
                        } else if (vm.position.communityVolunteerOrganizationApplicationId) {
                            params.communityVolunteerOrganizationApplicationId = vm.position.communityVolunteerOrganizationApplicationId;
                        }

                        if (angular.isDefined(reason)) {
                            params.statusReason = reason;
                        }

                        return authentication.setOpportunityStatusForUser(params)
                                .then($modalInstance.close);
                    }
                }, function () {
                })
                .catch(function(error){
                    $modalInstance.close({
                        error: error
                    });
                });
        }
    }
})();