(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .config(routes);

    routes.$inject = ['$stateProvider'];

    function routes($stateProvider) {
        var states = {
            volunteer: 'vmis.volunteer.serviceHistory',
            admin: 'vmis.volunteer.serviceHistoryEdit'
        };
        var views = function (state) {
            var views = {};
            views['@vmis.volunteer'] = {
                templateUrl: 'app/components/volunteer/serviceHistory/view.html',
                controller: 'ServiceHistoryController',
                controllerAs: 'vm',
                resolve: {
                    defaultFilters: ['filters', function (filters) {
                        return filters;
                    }]
                }
            };
            views['avcPositions@' + state] = {
                templateUrl: 'app/components/volunteer/serviceHistory/avcPositions/view.html',
                controller: 'PositionListController',
                controllerAs: 'vm',
                resolve: {
                    defaultFilters: ['filters', function (filters) {
                        var defaultFilters = angular.copy(filters);
                        defaultFilters.organizationTypes = ['AVC', 'Private Organization'];
                        defaultFilters.onlyAVCInfo = 1;
                        return defaultFilters;
                    }]
                }
            };
            views['nonAVCPositions@' + state] = {
                templateUrl: 'app/components/volunteer/serviceHistory/nonAVCPositions/view.html',
                controller: 'PositionListController',
                controllerAs: 'vm',
                resolve: {
                    defaultFilters: ['filters', function (filters) {
                        var defaultFilters = angular.copy(filters);
                        defaultFilters.organizationTypes = ['Non-AVC'];
                        defaultFilters.onlyAVCInfo = 0;
                        return defaultFilters;
                    }]
                }
            };
            views['annualHoursRecord@' + state] = {
                templateUrl: 'app/components/volunteer/serviceHistory/annualHoursRecord/view.html',
                controller: 'ServiceHistoryAnnualHoursRecordController',
                controllerAs: 'vm'
            };
            return views;
        };
        var resolve = {
            filters: function () {
                return {
                    startDate: moment().date() < 15 ? moment().subtract(1, 'months').startOf('month').toDate() : moment().startOf('month').toDate(),
                    endDate: moment().toDate(),
                    statuses: ['Approved']
                };
            },
            volunteer: ['$stateParams', 'authentication', function ($stateParams, authentication) {
                return $stateParams.id ? authentication.getUserProfile({masterUserId: $stateParams.id}).then(function (response) {
                        return {
                            firstName: response.aosUser.firstName,
                            lastName: response.aosUser.lastName
                        };
                    }) : null;
            }]
        };

        $stateProvider.state(states.volunteer, {
            url: '/service-history',
            views: views(states.volunteer),
            resolve: resolve,
            title: 'Service History'
        }).state(states.admin, {
            url: '/service-history/{id:int}',
            views: views(states.admin),
            resolve: resolve,
            title: 'Service History'
        });
    }
})();