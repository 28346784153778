(function () {
    'use strict';

    angular
        .module('app.vmisCalendar')
        .directive('vmisCalendar', calendar);

    calendar.$inject = ['moment'];

    function calendar(moment) {
        return {
            restrict: 'E',
            templateUrl: 'common/directives/vmisCalendar/vmisCalendar.view.html',
            scope: {
                date: '=?', // Date object, date string, or moment
                hideDayOfWeek: '=?',
                hideDays: '=?',
                view: '=?' //'month'(default), 'week'
            },
            link: link
        };

        function link(scope, element, attrs) {

            element.on('$destroy', destroy);

            activate();

            function activate() {
                if(typeof attrs.date === 'undefined') {
                    scope.date = moment();
                } else {
                    scope.date = moment(attrs.date);
                    if(!scope.date.isValid()) {
                        return;
                    }
                }

                if(typeof attrs.hideDayOfWeek === 'undefined') {
                    scope.showDayOfWeek = true;
                }
                if(typeof attrs.hideDays === 'undefined') {
                    scope.showDays = true;
                }
                if(typeof attrs.view === 'undefined') {
                    attrs.view = 'month';
                }

                if(scope.showDays) {
                    switch(attrs.view) {
                        case 'month':
                            scope.weeks = buildMonth(scope.date);
                            break;
                        case 'week':
                            scope.weeks = [buildWeek(scope.date)];
                            break;
                        default:
                            return;
                    }
                }
            }

            function destroy() {

            }
        }

        function buildMonth(startDate) {
            var date = startDate.clone().startOf('month').weekday(0);
            var weeks = [];

            for (var i = 0; i < 6; i++) {
                weeks.push(buildWeek(date));
                date.add(1, 'w');
            }

            return weeks;
        }

        function buildWeek(startDate) {
            var date = startDate.clone().startOf('week');
            var days = [];

            for (var i = 0; i < 7; i++) {
                days.push({
                    date: date.clone(),
                    data: {}
                });
                date.add(1, 'd');
            }

            return days;
        }
    }
})();