(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .controller('DocumentDetailsController', DocumentDetailsController);

    DocumentDetailsController.$inject = ['$modalInstance', '$modal', '$q', '$timeout', 'authentication', 'document', 'id', 'viewOnly', 'config'];

    function DocumentDetailsController($modalInstance, $modal, $q, $timeout, authentication, document, id, viewOnly, config) {
        var vm = this;

        vm.isLoading = true;
        vm.isDocumentUploading = false;

        vm.newUpload = false;
        vm.maxDocumentsUploaded = false;

        // This Boolean checks whether the modal was opened
        // to view/delete an existing document or to upload new ones.
        vm.existingDocument = angular.isNumber(document.id);

        vm.documents = vm.existingDocument ? [document] : [];
        // Existence of master user ID indicates whether user is editing another user
        vm.masterUserId = parseInt(id) || null;
        // This Boolean determines what buttons are available at the bottom of the modal
        vm.viewOnly = viewOnly;
        vm.title = vm.existingDocument ? 'Document Details' : 'Add Documents'; // Update

        vm.allowedFileTypesForUpload = null;
        vm.maxFileSizeInMegabytes = null;
        vm.formError = "";

        vm.onSelectDocs = onSelectDocs;
        vm.onDeleteSelectedDoc = onDeleteSelectedDoc;
        vm.onClickSave = onClickSave;
        vm.onClickDelete = onClickDelete;

        activate();

        function activate() {
            config.get().then(function (configData) {
                vm.allowedFileTypesForUpload = configData.allowedFileTypesForUpload;
                vm.maxFileSizeInMegabytes = configData.maxFileSizeInMegabytes;
            });
            vm.isLoading = false;
        }

        function displayError(error) {
            if (_.isUndefined(error)) return;
            var errorStr = utilsServices.parseErrorString(error);

            vm.isLoading = false;

            if (errorStr === 'cancel' || errorStr === 'backdrop click' || errorStr === 'ok')
                return;
            if (errorStr)
                vm.formError = errorStr;
        }

        function onSelectDocs(documents, errDocuments) {
            if (errDocuments.length > 0) {
                vm.formError = "Wrong file type or file size is larger than " + vm.maxFileSizeInMegabytes + ".";
                displayError(vm.formError);
                updateNewUpload();
                return;
            } else {
                vm.formError = "";
            }

            if (documents.length > 0) {
                angular.forEach(documents, function (doc) {
                    vm.documents.push({
                        theFile: doc,
                        id: doc.id,
                        name: doc.name,
                        description: "",
                        isSelected: true,
                        originalName: doc.name
                    });
                });
            }

            // Update flag for whether new documents to upload were selected
            updateNewUpload();
        }

        function onDeleteSelectedDoc(document) {
            var index = vm.documents.indexOf(document);
            if (index > -1)
                vm.documents.splice(index, 1);
            vm.newUpload = false;
        }

        // Only for adding, not editing or deleting
        function onClickSave() {
            if (vm.masterUserId)
                uploadDocuments(vm.documents, vm.masterUserId);
            else
                uploadDocuments(vm.documents);
        }

        // Deleting another user's documents is not currently allowed
        function onClickDelete(document) {
            var options = {
                templateUrl: 'app/components/volunteer/records/documents/delete/view.html',
                controller: 'DocumentDeleteController',
                controllerAs: 'vm',
                size: 'tiny',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    document: function () {
                        return document ? angular.copy(document) : {};
                    },
                    id: function () {
                        return id ? angular.copy(id) : null;
                    }
                }
            };

            return $modal.open(options)
                .result
                .then(function (response) {
                    vm.isLoading = true;
                    response.action = 'delete';
                    return $modalInstance.close(response);
                })
                .catch(function (error) {
                    var params = {
                        error: error,
                        success: false,
                        action: 'delete'
                    };
                    return $modalInstance.dismiss(params);
                });
        }


        function uploadDocuments(documents, id) {
            vm.isDocumentUploading = true;

            var promiseArray = [];

            angular.forEach(documents, function (document) {
                var suffix = document.name.split(".").pop();
                // If user-created document name does not have a suffix
                if (suffix === document.name)
                    suffix = document.originalName.split(".").pop();

                var uploadDocumentParams = {
                    theFile: document.theFile,
                    fileSuffix: suffix,
                    documentName: document.name,
                    masterUserId: angular.isNumber(id) ? id : null,
                    documentDescription: document.description
                };

                promiseArray.push(authentication.uploadVolunteerDocument(uploadDocumentParams)
                    .then(function (response) {
                        document.uploaded = true;
                    }));
                //.catch(function (error) {
                //
                //}));
            });

            $q.all(promiseArray)
                .then(function (response) {
                    return $timeout(function () {
                        vm.isDocumentUploading = false;
                        response.action = 'add';
                        return $modalInstance.close(response);
                    }, 1000);
                })
                .catch(function (error) {
                    return $timeout(function () {
                        vm.isDocumentUploading = false;
                        var params = {
                            error: error,
                            success: false,
                            action: 'add'
                        };
                        return $modalInstance.dismiss(params);
                    }, 1000);
                });
        }

        function updateNewUpload() {
            vm.newUpload = (vm.documents.length > 0);
        }
    }
})();