(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('GlobalUserSearchController', GlobalUserSearchController);

    GlobalUserSearchController.$inject = ['$q', '$modal', '$scope', '$location', 'authentication', 'loadStatus', 'config', 'params', 'moment', '_', '$timeout'];

    function GlobalUserSearchController($q, $modal, $scope, $location, authentication, loadStatus, config, params, moment, _, $timeout) {
        var vm = this;

        vm.isLoading = true;
        vm.isExactNameSearch = true;
        vm.usersWithRole = [];
        vm.roleObjectList = angular.copy(params.roleObjectList);
        vm.responsibilities = angular.copy(params.responsibilities);
        vm.selectedRoleObject = vm.roleObjectList.filter(function (role) {
            return role.name === "Global Lookup";
        })[0];

        vm.filter = {
            lastName: $location.search().lastName || '',
            firstName: $location.search().firstName || '',
            email: $location.search().firstName || ''
        };

        vm.loadStatus = loadStatus.init();

        vm.sortColumn = 'aosUser.lastName';
        vm.sortReverse = false;

        vm.onSelectRole = onSelectRole;
        vm.onToggleExactNameSearch = onToggleExactNameSearch;
        vm.onClickSearch = onClickSearch;
        vm.onLoadMore = onLoadMore;
        vm.onLoadAll = onLoadAll;

        activate();

        function activate() {
            vm.isLoading = false;
        }

        function onSelectRole() {
            $scope.$emit('people:role', vm.selectedRoleObject);
        }

        function onToggleExactNameSearch() {}

        function onClickSearch() {
            $location.search({
                isActive: vm.filter.email || null,
                firstName: vm.filter.firstName || null,
                lastName: vm.filter.lastName || null
            });
            config.get().then(function (configData) {
                getUsers(0, configData.defaultNumRows.users);
            });
        }

        function getUsers(index, num) {
            vm.isLoading = true;

            var paramsGetUsers = {
                startingRowIndex: index,
                numberOfRows: num,
                isExactFirstNameLastName: vm.isExactNameSearch,
                firstName: vm.filter.firstName ? vm.filter.firstName : undefined,
                lastName: vm.filter.lastName ? vm.filter.lastName : undefined,
                email: vm.filter.email ? vm.filter.email : undefined,
            };

            authentication.getAllUsers(paramsGetUsers)
                .then(function (response) {
                    getUsersSuccess(response, index, num);
                })
                .catch(function (error) {
                    getUsersError(error);
                });
            function getUsersSuccess(response, index, num) {
                if (index === 0) {
                    vm.usersWithRole = [];
                    vm.loadStatus.currentIndex = 0;
                }

                vm.usersWithRole = response.users;

                // Either set current index to max if all users loaded or increment by rows loaded
                vm.loadStatus.currentIndex = (vm.loadStatus.currentIndex + num > response.count) ?
                    response.count : (vm.loadStatus.currentIndex + num);
                // Track total number of users not loaded for "Load All" button
                vm.loadStatus.numResultsNotLoaded = response.count - vm.loadStatus.currentIndex;
                // Set text for "Load More" button
                vm.loadStatus.nextResultLoadNum = (response.count === vm.loadStatus.currentIndex) ? 0 :
                    (vm.loadStatus.numResultsNotLoaded > num) ? num : vm.loadStatus.numResultsNotLoaded;

                $timeout(function () {
                    vm.isLoading = false;
                }, 100);
            }

            function getUsersError(error) {
                if (angular.isDefined(error)) {
                    var errorStr = error.error ? error.error : error;
                    $scope.$emit('people:alert', errorStr);
                }
                else
                    $scope.$emit('people:alert', "Failed to load list! Please try again.");

                $timeout(function () {
                    vm.isLoading = false;
                }, 100);
            }

        }

        function onLoadMore() {
            getUsers(vm.loadStatus.currentIndex, vm.loadStatus.nextResultLoadNum);
            vm.checkAll = false;
        }

        function onLoadAll() {
            getUsers(vm.loadStatus.currentIndex, vm.loadStatus.numResultsNotLoaded);
            vm.checkAll = false;
        }


    }
})();