(function () {
    'use strict';

    angular
        .module('app.vmis')
        .controller('LoggedOutController', LoggedOutController);

    LoggedOutController.$inject = ['$cookies','config','authentication'];

    function LoggedOutController($cookies,config,authentication) {
        var vm = this;

        // Show reason for being logged out if any
        vm.formError = $cookies.get('logged-out-reason');

        activate();

        function activate() {
            if(authentication.isLoggedIn()){
                vm.isLoading=true;
                var ticketErrorStr = "";

                return config.get().then(function (configData) {
                    ticketErrorStr = configData.loggedOutReasons.ticketError;
                }).then(authentication.logOut)
                .catch(function (error) {
                    console.error(error);
                    $cookies.put('logged-out-reason', ticketErrorStr);
                }).finally(vm.isLoading=false);
            }
        }
    }
})();
