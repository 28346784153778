(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('CoordinatorsController', CoordinatorsController);

    CoordinatorsController.$inject = ['$q', '$modal', '$scope', '$location', 'authentication', 'loadStatus', 'params', 'moment', '_', '$timeout', '$window', '$state'];

    function CoordinatorsController($q, $modal, $scope, $location, authentication, loadStatus, params, moment, _, $timeout, $window, $state) {
        var vm = this;

        vm.isLoading = true;
        vm.roleObjectList = angular.copy(params.roleObjectList);
        vm.responsibilities = angular.copy(params.responsibilities);

        var isProgramManager = params.isProgramManager;

        vm.selectedRoleObject = vm.roleObjectList.filter(function (role) {
            return role.name === "AVCC";
        })[0];
        vm.numUsersSelected = 0;
        vm.isExporting = false;

        vm.sortColumn = 'aosUser.lastName';
        vm.sortReverse = false;

        vm.filter = {
            selectedCommunities: $location.search().community|| undefined,
            lastName: $location.search().lastName || '',
            firstName: $location.search().firstName || '',
            startDate: isNaN(new Date($location.search().startDate)) ? undefined : new Date($location.search().startDate),
            endDate: isNaN(new Date($location.search().endDate)) ? undefined : new Date($location.search().endDate),
            workEmail: $location.search().workEmail || ''
        };

        if(vm.filter.selectedCommunities !== undefined){
            if(Array.isArray(vm.filter.selectedCommunities)){
                vm.filter.selectedCommunities.forEach(function(value,index){
                    vm.filter.selectedCommunities[index] = _.parseInt(value);
                });
            }
            else {
                vm.filter.selectedCommunities = [_.parseInt(vm.filter.selectedCommunities)];
            }
        }

        vm.loadStatus = loadStatus.init();

        vm.usersWithRole = [];

        vm.onSelectRole = onSelectRole;
        vm.onClickFilterUsers = onClickFilterUsers;
        vm.onClickSearch = onClickSearch;
        vm.onClickAddNewCoordinator = onClickAddNewCoordinator;
        vm.onClickEditCoordinator = onClickEditCoordinator;
        vm.onClickOpenCoordinator = onClickOpenCoordinator;
        vm.onCheckAll = onCheckAll;
        vm.onCheckUser = onCheckUser;
        vm.onMassEmail = onMassEmail;
        vm.onExport = onExport;
        vm.onLoadMore = onLoadMore;
        vm.onLoadAll = onLoadAll;
        vm.massActionDisabled = massActionDisabled;

        activate();

        function activate() {
            vm.massActions = [
                {
                    name: "Export",
                    action: onExport
                },
                {
                    name: "Mass Email",
                    action: onMassEmail
                }
            ];

            loadStatus.setup(vm.loadStatus, "users")
                .then(function () {
                    fillCommunities(); // Calls getUsers
                });
        }

        function fillCommunities() {
            vm.communities = [];
            var promiseArray = [];
            if (isProgramManager) {
                promiseArray.push(authentication.getCommunities({
                    getAll: true
                })
                    .then(function (response) {
                        vm.communities = response.communityAffiliations;
                    })
                    .catch(function (error) {
                        $scope.$emit('people:alert', error);
                        vm.isLoading = false;
                    }));
            }
            else {
                vm.communities = vm.responsibilities.rmManaged;
            }

            return $q.all(promiseArray).then(function () {
                vm.communities.unshift({
                    communityAffiliationId: -1,
                    communityAffiliationName: "All"
                });
                return getUsers(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows);
            }).catch(function (error) {
                $scope.$emit('people:alert', error);
                vm.isLoading = false;
            });
        }

        function getUsers(index, num) {
            vm.isLoading = true;
            var allCommunities = !vm.filter.selectedCommunities;

            var paramsGetUsers = {
                vmisRole: "AVC Coordinator",
                startingRowIndex: index,
                numberOfRows: num,
                firstName: vm.filter.firstName ? vm.filter.firstName : undefined,
                lastName: vm.filter.lastName ? vm.filter.lastName : undefined,
                startDate: vm.filter.startDate ? moment(vm.filter.startDate).startOf('day').toISOString() : undefined,
                endDate: vm.filter.endDate ? moment(vm.filter.endDate).endOf('day').toISOString() : undefined,
                workEmail: vm.filter.workEmail ? vm.filter.workEmail : undefined
            };

            if (!allCommunities) {
                paramsGetUsers.communityAffiliationIds = vm.filter.selectedCommunities;
            }

            authentication.getManagersAdmin(paramsGetUsers)
                .then(function (response) {
                    getUsersSuccess(response, index, num);
                })
                .catch(function (error) {
                    getUsersError(error);
                });

            function getUsersSuccess(response, index, num) {
                if (index === 0) {
                    vm.usersWithRole = [];
                    vm.loadStatus.currentIndex = 0;
                }

                for (var i = 0; i < response.users.length; i++) {
                    // Create unique ID for user checkbox
                    response.users[i].checkboxId = "check-" + response.users[i].aosUser.lastName + response.users[i].aosUser.firstName;
                    // Add user to full list
                    vm.usersWithRole.push(response.users[i]);
                }

                updateCheckAllAndExport();

                // Either set current index to max if all users loaded or increment by rows loaded
                vm.loadStatus.currentIndex = (vm.loadStatus.currentIndex + num > response.count) ? response.count : (vm.loadStatus.currentIndex + num);
                // Track total number of users not loaded for "Load All" button
                vm.loadStatus.numResultsNotLoaded = response.count - vm.loadStatus.currentIndex;
                // Set text for "Load More" button
                vm.loadStatus.nextResultLoadNum = (response.count === vm.loadStatus.currentIndex) ? 0 :
                    (vm.loadStatus.numResultsNotLoaded > num) ? num : vm.loadStatus.numResultsNotLoaded;

                $timeout(function () {
                    vm.isLoading = false;
                }, 100);
            }

            function getUsersError(error) {
                if (angular.isDefined(error)) {
                    var errorStr = error.error ? error.error : error;
                    $scope.$emit('people:alert', errorStr);
                }
                else
                    $scope.$emit('people:alert', "Failed to load list! Please try again.");

                $timeout(function () {
                    vm.isLoading = false;
                }, 100);
            }
        }

        function onSelectRole() {
            $scope.$emit('people:role', vm.selectedRoleObject);
        }

        function onClickFilterUsers() {
            var modalInstance = $modal.open({
                templateUrl: 'app/components/administrator/people/actionDirectives/commFilter.view.html',
                controller: 'CommFilterController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    params: function () {
                        return {
                            communities: vm.communities,
                            selectedCommunities: vm.filter.selectedCommunities,
                            startDate: vm.filter.startDate || null,
                            endDate: vm.filter.endDate || null,
                            workEmail: vm.filter.workEmail || null
                        };
                    }
                }
            });

            modalInstance.result.then(function (result) {
                vm.isLoading = true;
                switch (result.action) {
                    case 'success':
                        // Apply results from filter modal
                        if(result.filter.selectedCommunities.length === 0){
                            vm.filter.selectedCommunities = null;
                        }
                        else {
                            vm.filter.selectedCommunities = [];
                            result.filter.selectedCommunities.forEach(function(selectedComm){
                                // If not "All", save
                                if (selectedComm.communityAffiliationId !== -1) {
                                    vm.filter.selectedCommunities.push(selectedComm.communityAffiliationId);
                                }
                            });
                            // If working list is empty, set comm filter back to empty
                            vm.filter.selectedCommunities = vm.filter.selectedCommunities.length === 0 ? null : vm.filter.selectedCommunities;
                        }

                        vm.filter.startDate = result.filter.startDate;
                        vm.filter.endDate = result.filter.endDate;
                        vm.filter.workEmail = result.filter.workEmail;

                        $location.search({
                            community: vm.filter.selectedCommunities,
                            startDate: vm.filter.startDate || null,
                            endDate: vm.filter.endDate || null,
                            firstName: vm.filter.firstName || null,
                            lastName: vm.filter.lastName || null,
                            workEmail: vm.filter.workEmail || null
                        });

                        return getUsers(0, vm.loadStatus.defaultNumRows);
                    case 'error':
                        vm.isLoading = false;
                        return $scope.$emit('people:alert', result.message);
                    default:
                        vm.isLoading = false;
                        return $scope.$emit('people:alert', "Failed to create new filter!");
                }
            }).catch(function (error) {
                $scope.$emit('people:alert', error);
                vm.isLoading = false;
            });
        }

        function onClickSearch() {
            $location.search({
                community: vm.filter.selectedCommunities,
                startDate: vm.filter.startDate || null,
                endDate: vm.filter.endDate || null,
                firstName: vm.filter.firstName || null,
                lastName: vm.filter.lastName || null,
                workEmail: vm.filter.workEmail || null
            });
            getUsers(0, vm.loadStatus.defaultNumRows);
            return updateCheckAllAndExport();
        }

        function onClickAddNewCoordinator() {
            openCoordinatorModal();
        }

        function onClickEditCoordinator(coordinator) {
            openCoordinatorModal(coordinator);
        }

        function onClickOpenCoordinator(coordinator) {
            var paramId = {
                id: coordinator.masterUserId
            };
            $window.open($state.href('vmis.profileEdit', paramId), '_blank');
        }

        function openCoordinatorModal(coordinator) {
            var modalResponsibilities = vm.communities.filter(function (comm) {
                return comm.communityAffiliationId !== -1;
            });

            var modalInstance = $modal.open({
                templateUrl: 'app/components/administrator/people/coordinators/editCoordinator.view.html',
                controller: 'EditCoordinatorController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    params: function () {
                        return {
                            activeUserResponsibilities: modalResponsibilities,
                            coordinator: angular.copy(coordinator) || null,
                            // If user has certain higher roles, they can remove coordinator role entirely
                            canRemove: isProgramManager
                        };
                    }
                }
            });

            // Process result from edit modal
            modalInstance.result
                .then(function (result) {
                    processModalResult(result, coordinator, modalResponsibilities);
                })
                .catch(function (error) {
                    $scope.$emit('people:alert', error);
                });
        }

        function processModalResult(result, coordinator, modalResponsibilities) {
            vm.isLoading = true;
            var promiseArray = [];
            var newResponsibilities = [];
            switch (result.action) {
                case 'edit':
                    newResponsibilities = result.responsibilities.map(function (comm) {
                        return comm.communityAffiliationId;
                    });

                    promiseArray.push(authentication.modifyUserRoles({
                        masterUserId: result.masterUserId,
                        aosUserRoles: ["AVC Coordinator"],
                        communityAffiliationIds: newResponsibilities,
                        isAddingOrRemovingResponsibilities: true
                    }));
                    break;
                case 'save':
                    newResponsibilities = result.responsibilities.map(function (comm) {
                        return comm.communityAffiliationId;
                    });

                    promiseArray.push(authentication.modifyUserRoles({
                        masterUserId: result.masterUserId,
                        aosUserRoles: ["AVC Coordinator"],
                        communityAffiliationIds: newResponsibilities,
                        isAdding: true
                    }));
                    break;
                case 'remove':
                    promiseArray.push(authentication.modifyUserRoles({
                        masterUserId: result.masterUserId,
                        aosUserRoles: ["AVC Coordinator"],
                        isDeleting: true
                    }));
                    break;
                default:
                    break;
            }

            return $q.all(promiseArray).then(function () {
                $scope.$emit('profile:update');
                return getUsers(0, vm.loadStatus.defaultNumRows);
            }).catch(function (error) {
                $scope.$emit('people:alert', error);
                vm.isLoading = false;
            });
        }

        function onCheckAll() {
            // Check users in either complete or filtered list
            for (var i = 0; i < vm.usersWithRole.length; i++)
                vm.usersWithRole[i].isChecked = vm.checkAll;

            updateCheckAllAndExport();
        }

        function onCheckUser() {
            updateCheckAllAndExport();
        }

        function updateCheckAllAndExport() {
            vm.numUsersSelected = 0;
            for (var i = 0; i < vm.usersWithRole.length; i++) {
                if (vm.usersWithRole[i].isChecked)
                    vm.numUsersSelected++;
            }

            // If list empty, uncheck
            if (vm.usersWithRole.length === 0) {
                vm.checkAll = false;
            }
            // Otherwise look for a checked user
            else {
                vm.checkAll = vm.usersWithRole.every(function (user) {
                    return user.isChecked;
                });
            }
        }

        function onMassEmail() {
            var emails = vm.usersWithRole.filter(function (user) {
                return user.isChecked;
            }).map(function (user) {
                return (
                    (user.aosUser.vmisUser.adminProfile.workEmail) ?
                        user.aosUser.vmisUser.adminProfile.workEmail :
                        (user.masterUserEmail) ? user.masterUserEmail : null
                );
            });

            if (emails.length === 0)
                return;

            emails = Array.from(new Set(emails));
            emails = emails.filter(Boolean);

            var modalInstance = $modal.open({
                templateUrl: 'app/components/administrator/people/actionDirectives/massEmail.view.html',
                controller: 'MassEmailController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    params: function () {
                        return {
                            emails: emails
                        };
                    }
                }
            });

            // Process result from modal
            modalInstance.result
                .then(function (result) {
                    switch (result.action) {
                        case 'success':
                            var message = "";
                            if (emails.length !== 1)
                                message = "Mass email \"" + result.message + "\" successfully sent to " + emails.length + " users!";
                            else
                                message = "Mass email \"" + result.message + "\" successfully sent to 1 user!";

                            return $scope.$emit('people:success', message);
                        case 'error':
                            return $scope.$emit('people:alert', result.message);
                        default:
                            return $scope.$emit('people:alert', "Mass email failed to send!");
                    }
                })
                .catch(function (error) {
                    $scope.$emit('people:alert', error);
                });
        }

        // Export currently-checked users to spreadsheet format for download
        function onExport() {
            vm.isExporting = true;

            var exportingUsersHeader = [
                "First Name",
                "Last Name",
                "Last Login Date",
                "Responsibilities",
                "Email\r\n"
            ].toString();

            var exportingUsers = [exportingUsersHeader];
            exportingUsers = exportingUsers.concat(vm.usersWithRole.filter(function (user) {
                return user.isChecked;
            }).map(function (checkedUser) {
                var responsibilitiesStr = "\"";
                if (angular.isDefined(checkedUser.aosUser.vmisUser.adminProfile.responsibilities.avcc)) {
                    checkedUser.aosUser.vmisUser.adminProfile.responsibilities.avcc.forEach(function (comm) {
                        responsibilitiesStr += comm.communityAffiliationName + "; ";
                    });
                    responsibilitiesStr = responsibilitiesStr.slice(0, -2);
                    responsibilitiesStr += "\"";
                }

                var email = (checkedUser.aosUser.vmisUser.adminProfile.workEmail !== null) ?
                    checkedUser.aosUser.vmisUser.adminProfile.workEmail : "";

                return [
                    checkedUser.aosUser.firstName,
                    checkedUser.aosUser.lastName,
                    checkedUser.lastSignedIn,
                    responsibilitiesStr,
                    email
                ].toString() + "\r\n";
            }));

            var blob = new Blob(exportingUsers, { type: "text/plain;charset=utf-8" });
            saveAs(blob, (moment().format('YYYYMMDD_HHmmss') + "_Report.csv"));

            vm.isExporting = false;
        }

        function onLoadMore() {
            getUsers(vm.loadStatus.currentIndex, vm.loadStatus.nextResultLoadNum);
            vm.checkAll = false;
        }

        function onLoadAll() {
            getUsers(vm.loadStatus.currentIndex, vm.loadStatus.numResultsNotLoaded);
            vm.checkAll = false;
        }

        function massActionDisabled() {
            return vm.numUsersSelected === 0 || vm.isExporting || vm.isLoading;
        }
    }
})();