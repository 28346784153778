(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .controller('NoteDeleteController', NoteDeleteController);

    NoteDeleteController.$inject = ['$modalInstance', 'authentication', 'note'];

    function NoteDeleteController($modalInstance, authentication, note) {
        var vm = this;

        vm.note = note;

        vm.onClickDelete = onClickDelete;

        function onClickDelete() {
            vm.processing = true;

            var params = {
                volunteerNoteId: vm.note.id,
                isDelete: true
            };

            return authentication.manageVolunteerNotes(params)
                .then($modalInstance.close)
                .catch($modalInstance.dismiss);
        }
    }
})();