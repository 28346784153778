(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .controller('NoteDetailsController', NoteDetailsController);

    NoteDetailsController.$inject = ['$modalInstance', '$modal', 'authentication', 'note', 'id', 'viewOnly'];

    function NoteDetailsController($modalInstance, $modal, authentication, note, id, viewOnly) {
        var vm = this;

        vm.isLoading = true;

        vm.note = note;
        // Existence of master user ID indicates whether user is editing another user
        vm.masterUserId = parseInt(id) || null;
        vm.viewOnly = viewOnly;
        vm.title = vm.note.id ? 'Note Details' : 'Add Note';

        vm.isSavedDisabled = (vm.note.adminMasterUserId !== null && (vm.note.createdByUserId !== vm.note.adminMasterUserId));

        vm.onClickSave = onClickSave;
        vm.onClickDelete = onClickDelete;

        activate();

        function activate() {
            vm.note.date = vm.note.date ? vm.note.date : null;

            vm.isLoading = false;
        }

        function onClickSave() {
            vm.isLoading = true;

            var params = {
                volunteerNoteId: vm.note.id,
                masterUserId: vm.masterUserId,
                volunteerNotes: vm.note.note
            };

            return authentication.manageVolunteerNotes(params)
                .then(function (response) {
                    response.action = vm.note.id ? 'edit' : 'add';
                    return $modalInstance.close(response);
                })
                .catch(function (error) {
                    var params = {
                        error: error,
                        success: false,
                        action: vm.note.id ? 'edit' : 'add'
                    };
                    return $modalInstance.dismiss(params);
                });
        }

        // Notes may only be deleted by their creator
        function onClickDelete() {
            vm.isLoading = true;

            var options = {
                templateUrl: 'app/components/volunteer/records/notes/delete/view.html',
                controller: 'NoteDeleteController',
                controllerAs: 'vm',
                size: 'tiny',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    note: function () {
                        return vm.note;
                    }
                }
            };

            return $modal.open(options)
                .result
                .then(function (response) {
                    response.action = 'delete';
                    return $modalInstance.close(response);
                })
                .catch(function (error) {
                    var params = {
                        error: error,
                        success: false,
                        action: 'delete'
                    };
                    return $modalInstance.dismiss(params);
                });
        }
    }
})();