(function () {
    'use strict';

    angular
        .module('app')
        .controller('DropdownController', DropdownController);

    DropdownController.$inject = ['$element', '$scope', '$transclude'];

    function DropdownController($element, $scope, $transclude) {
        var vm = this;
        var body = angular.element(document.querySelector('body'));

        vm.$onDestroy = onDestroy;
        vm.toggle = toggle;

        $transclude(function (clone) {
            var el = angular.element($element[0].querySelector('vmis-dropdown>span'));
            el.append(clone);
        }, $element.parent(), 'toggle');

        $transclude(function (clone, scope) {
            scope.$close = close;
            var el = angular.element($element[0].querySelector('vmis-dropdown>div'));
            el.append(clone);
        }, $element.parent(), 'pane');

        function onDestroy() {
            body.off('click', onClick);
        }

        function onClick(event) {
            var elementContents = Array.prototype.slice.apply($element[0].querySelectorAll('*'));

            if (elementContents.length <= 0) {
                return;
            }

            var isOuterElement = elementContents.every(function (node) {
                return node !== event.target;
            });

            if (isOuterElement || !vm.active) {
                close();
                $scope.$apply();
            }
        }

        function close() {
            vm.active = false;
            body.off('click', onClick);
        }

        function open() {
            vm.active = true;
            body.on('click', onClick);
        }

        function toggle() {
            if (vm.active) {
                close();
            } else {
                open();
            }
        }
    }
})();