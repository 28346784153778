(function () {
    'use strict';

    angular
        .module('app.vmis')
        .controller('OpocAvccAssistantDashboardController', OpocAvccAssistantDashboardController);

    OpocAvccAssistantDashboardController.$inject = ['$q', '$modal', 'moment', 'authentication', 'utilsServices', '_'];

    function OpocAvccAssistantDashboardController($q, $modal, moment, authentication, utilsServices, _) {
        var vm = this;

        vm.isLoading = true;
        vm.sortColumn = 'date';
        vm.sortReverse = true;
        vm.startDate = moment().date() <= 15 ? moment().subtract(1, 'months').startOf('month').toISOString() : moment().startOf('month').toISOString();
        vm.endDate = moment().toISOString();
        vm.hoursChart = {
            options: {
                legend: {
                    display: true,
                    labels: {
                        boxWidth: 12
                    },
                    onClick: function () {
                    }
                },
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            if (data.labels[tooltipItem.index] === undefined) {
                                return 'There are no hours';
                            }
                            var hours = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                            var type = data.labels[tooltipItem.index].toLowerCase();
                            return hours + ' ' + type + ' hour' + (hours === 1 ? '' : 's');
                        },
                        afterLabel: function (tooltipItem, data) {
                            return (data.labels[tooltipItem.index] === undefined) ? '' : 'for the current open period';
                        }
                    },
                    displayColors: false
                }
            },
            data: [0, 0, 0, 1],
            labels: ['Certified', 'Rejected', 'Submitted'],
            colors: ['#3c763d', '#a94442', '#337ab7', '#dcdcdc']

        };
        vm.positionsChart = {
            options: {
                legend: {
                    display: true,
                    labels: {
                        boxWidth: 12
                    },
                    onClick: function () {
                    }
                },
                tooltips: {
                    callbacks: {
                        label: function (tooltipItem, data) {
                            if (data.labels[tooltipItem.index] === undefined) {
                                return 'There are no open positions';
                            }
                            var positionCount = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                            var text;
                            switch (data.labels[tooltipItem.index]) {
                                case 'Filled':
                                    text = positionCount + ((positionCount > 1) ? ' positions have' : ' position has');
                                    break;
                                case 'Unfilled':
                                    text = positionCount + ((positionCount > 1) ? ' positions do' : ' position does') + ' not have';
                                    break;
                                default:
                                    text = '';
                                    break;
                            }
                            return text;
                        },
                        afterLabel: function (tooltipItem, data) {
                            if (data.labels[tooltipItem.index] === undefined) {
                                return 'for the selected organization.';
                            }
                            var text;
                            switch (data.labels[tooltipItem.index]) {
                                case 'Filled':
                                    text = 'active volunteers';
                                    break;
                                case 'Unfilled':
                                    text = 'any active volunteers';
                                    break;
                                default:
                                    text = '';
                                    break;
                            }
                            return text;
                        }
                    },
                    displayColors: false
                }
            },
            data: [0, 0, 1],
            labels: ['Filled', 'Unfilled'],
            colors: ['#3c763d', '#337ab7', '#dcdcdc']
        };

        vm.onRejectHoursClick = onRejectHoursClick;
        vm.onClickCertify = onClickCertify;
        vm.onChangeCommunity = onChangeCommunity;
        vm.onChangeOrganization = onChangeOrganization;

        activate();

        function activate() {
            isUserOPOC();
            getSubmittedHours();
        }

        function getHoursDistribution(community, organization) {
            var params = {
                startingRowIndex: 0,
                numberOfRows: 1,
                startDate: vm.startDate,
                endDate: vm.endDate,
                communityAffiliationId: community ? community.id : undefined,
                volunteerOrganizationId: organization ? organization.id : undefined
            };
            return authentication.getHoursAdmin(params).then(function (response) {
                params.numberOfRows = response.count || 1;
                return authentication.getHoursAdmin(params);
            }).then(function (response) {
                var hours = {
                    certified: 0,
                    rejected: 0,
                    submitted: 0
                };
                _.forEach(response.hours, function (activity) {
                    switch (activity.volunteerHoursStatus) {
                        case 'Submitted':
                            hours.submitted += activity.hours;
                            return true;
                        case 'Certified':
                            hours.certified += activity.hours;
                            break;
                        case 'Rejected':
                            hours.rejected += activity.hours;
                            break;
                    }
                });

                if (hours.certified > 0 || hours.rejected > 0 || hours.submitted > 0) {
                    vm.hoursChart.data = [
                        hours.certified,
                        hours.rejected,
                        hours.submitted
                    ];
                } else {
                    vm.hoursChart.data = [0, 0, 0, 1];
                }
            });
        }

        function getSubmittedHours() {
            vm.isLoading = true;
            var params = {
                startingRowIndex: 0,
                numberOfRows: 1,
                startDate: vm.startDate,
                endDate: vm.endDate,
                volunteerHoursStatus: 'Submitted'
            };
            return authentication.getHoursAdmin(params).then(function (response) {
                params.numberOfRows = response.count || 1;
                return authentication.getHoursAdmin(params);
            }).then(function (response) {
                vm.hours = _.map(response.hours, function (activity) {
                    return {
                        id: activity.volunteerHoursId,
                        position: activity.volunteerOpportunity,
                        organization: activity.volunteerOrganization,
                        community: activity.communityAffiliation,
                        volunteer: {
                            firstName: activity.firstName,
                            lastName: activity.lastName
                        },
                        documents: activity.documents,
                        hours: activity.hours,
                        status: activity.volunteerHoursStatus,
                        type: activity.dayOfService ? 'Daily' : 'Period',
                        date: activity.dayOfService || activity.periodOfService,
                        volunteerNote: activity.userNotes || '',
                        reviewerNote: activity.reviewerNotes || ''
                    };
                });
            }).finally(function () {
                vm.isLoading = false;
            });
        }

        function getPositionsFilled(community, organization) {
            var params = {
                numberOfRows: 1,
                volunteerPositionStatus: ['Open, searchable', 'Open, not searchable'],
                communityAffiliationId: community ? community.id : undefined,
                volunteerOrganizationId: organization ? organization.id : undefined
            };
            return authentication.getOpportunitiesAdmin(params).then(function (response) {
                params.numberOfRows = response.count || 1;
                return authentication.getOpportunitiesAdmin(params);
            }).then(function (response) {
                var filledPositions = response.opportunities.reduce(function (filled, currentPosition) {
                    return currentPosition.applicationCount > 0 ? filled + 1 : filled;
                }, 0);
                if (response.opportunities.length > 0) {
                    vm.positionsChart.data = [
                        filledPositions,
                        response.opportunities.length - filledPositions
                    ];
                } else {
                    vm.positionsChart.data = [0, 0, 1];
                }
            });
        }

        function isUserOPOC() {
            authentication.getUserRoles()
                .then(function (response) {
                    vm.isOpoc = false;
                    if (response.vmisUserRoles !== null) {
                        for (var i = 0; i < response.vmisUserRoles.length; i++) {
                            if (response.vmisUserRoles[i] === "AVC OPOC") {
                                vm.isOpoc = true;
                                break;
                            }
                        }
                    }
                    if (vm.isOpoc) {
                        return getCommunities();
                    }
                })
                .catch(function () {
                    $state.go('vmis', null, {reload: true});
                });
        }

        function getCommunities() {
            return authentication.getUserProfile().then(function (response) {
                return utilsServices.combineCommunityResponsibilities(response, 'AVC OPOC');
            }).then(function (communities) {
                vm.communities = communities;
                vm.hoursCommunity = communities[0];
                vm.positionsCommunity = communities[0];
            }).then(function () {
                return onChangeCommunity(vm.hoursCommunity, 'hours');
            }).then(function () {
                return onChangeCommunity(vm.positionsCommunity, 'positions');
            });
        }

        function onRejectHoursClick(activity) {
            $modal.open({
                templateUrl: 'app/components/administrator/hours/manageActivity.view.html',
                controller: 'ManageActivityController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    activity: function () {
                        return angular.copy(activity);
                    },
                    rejectOnly: function () {
                        return true;
                    }
                }
            }).result.then(function () {
                activity.isFading = true;
                return getSubmittedHours();
            });
        }

        function onClickCertify(activity) {
            authentication.manageVolunteerHours({
                hoursIds: [activity.id],
                volunteerHoursStatus: 'Certified'
            }).then(getSubmittedHours);
        }

        function onChangeCommunity(community, type) {
            var promise;
            if (community.organizations) {
                promise = $q.when();
            } else {
                promise = authentication.getOrganizations({
                    communityAffiliationId: community.id
                }).then(function (response) {
                    community.organizations = _.map(response.organizations, function (organization) {
                        return {
                            id: organization.volunteerOrganizationId,
                            name: organization.organizationName
                        };
                    });
                    if (community.organizations.length > 1) {
                        community.organizations.unshift({
                            name: 'All'
                        });
                    }
                });
            }

            return promise.then(function () {
                switch (type) {
                    case 'hours':
                        vm.hoursOrganization = community.organizations[0];
                        return getHoursDistribution(community, vm.hoursOrganization);
                    case 'positions':
                        vm.positionsOrganization = community.organizations[0];
                        return getPositionsFilled(community, vm.positionsOrganization);
                }
            });
        }

        function onChangeOrganization(organization, type) {
            switch (type) {
                case 'hours':
                    return getHoursDistribution(null, organization);
                case 'positions':
                    return getPositionsFilled(null, organization);
            }
        }
    }
})();