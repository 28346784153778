(function () {
    'use strict';

    angular
        .module('app')
        .factory('redirect', redirect);

    redirect.$inject = ['$window', '$location'];

    function redirect($window) {
        return {
            returnTo: returnTo
        };

        function returnTo(returnTo) {
            var url = $window.decodeURIComponent(returnTo);
            if (url.indexOf('?') == -1) {
                url = url.concat('?');
            } else {
                url = url.concat('&');
            }
            url = url.concat('logged-in');
            $window.location.href = url;
        }
    }
})();
