(function () {
    'use strict';

    angular
        .module('app.vmis')
        .controller('ClaimUserController', ClaimUserController);

    ClaimUserController.$inject = ['$cookies', '$stateParams', '$timeout', '$window', 'authentication', 'config'];

    function ClaimUserController($cookies, $stateParams, $timeout, $window, authentication, config) {
        var vm = this;

        vm.isLoading = false;
        vm.email = "";
        vm.confirmEmail = "";
        vm.password = "";
        vm.confirmPassword = "";
        vm.onSubmit = onSubmit;
        vm.username = "";
        vm.userFound = false;
        vm.formError = "";
        vm.passwordRequirements = null;

        activate();

        function activate() {
            vm.formError = "";

            config.get().then(function (configData) {
                vm.passwordRequirements = configData.passwordRequirements;
            });

            authentication.findUsernameByKey($stateParams.key)
                .then(function (response) {
                    vm.formError = "";
                    vm.isLoading = false;
                    vm.userFound = true;
                    vm.username = response.username;
                })
                .catch(function (error) {
                    vm.formError = error;
                    vm.isLoading = false;
                });

        }

        function onSubmit() {
            vm.isLoading = true;
            vm.formError = "";
            authentication.completeUserMigration($stateParams.key, vm.username, vm.email, vm.password)
                .then(function () {
                    config.get().then(function (configData) {
                        $window.location.href = configData.accountSettingsUrl + "registration-landing";
                    });
                })
                .catch(function (error) {
                    vm.formError = error ? (error.error ? error.error : error) : 'Unknown error';
                    vm.isLoading = false;
                });
        }
    }
})();
