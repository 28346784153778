(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('SendInviteController', SendInviteController);

    SendInviteController.$inject = ['$modal', '$modalInstance', '$q', '_', 'authentication', 'roles', 'selectedRole', 'title', 'invite'];

    function SendInviteController($modal, $modalInstance, $q, _, authentication, roles, selectedRole, title, invite) {
        var vm = this;
        var hash = '';
        var responsibilities = null;

        vm.isLoading = true;
        //vm.roles = roles; // TODO: Future expansion of invitations
        vm.role = selectedRole;
        vm.title = title;
        vm.isEditing = invite !== null;

        vm.communities = [];
        vm.selectedCommunity = null;
        vm.invitedResponsibilities = [];

        vm.errorText = "";


        vm.requestReadReceipt = false;
        vm.bcc = false;

        vm.onChangeRole = onChangeRole;
        vm.onChangeCommunity = onChangeCommunity;
        vm.onSubmit = onSubmit;
        vm.onClickDelete = onClickDelete;

        activate();

        function activate() {
            getResponsibilities();
        }

        function getResponsibilities() {
            return authentication.getUserProfile()
                .then(function (response) {
                    responsibilities = response.aosUser.vmisUser.adminProfile.responsibilities;
                    getOpocData();
                }).catch(function (err) {
                    setError(err);
                    vm.isLoading = false;
                });
        }

        function getOrgsForCommunity(comm) {
            var params = {
                communityAffiliationId: comm.communityAffiliationId,
                getAll: true
            };

            return authentication.getOrganizations(params)
                .then(function (response) {
                    comm.organizations = response.organizations;
                    comm.organizations.forEach(function (org) {
                        org.communityAffiliationId = comm.communityAffiliationId;
                        org.communityAffiliationName = comm.communityAffiliationName;
                    });
                }).catch(function (err) {
                    setError(err);
                    vm.isLoading = false;
                });
        }

        function getOpocData() {
            vm.communities = responsibilities.avcc;
            vm.selectedCommunity = vm.communities[0];

            // Create empty org list to populate when community selected
            vm.communities.forEach(function (comm) {
                comm.organizations = null;
            });

            getOrgsForCommunity(vm.selectedCommunity)
                .then(function () {
                    // If editing existing invitation with data and responsibility IDs, load that.
                    if (vm.isEditing)
                        getInvite(invite);
                    else
                        vm.isLoading = false;
                });
        }

        function getInvite(invite) {
            hash = invite.hash;

            vm.role = invite.aosRoles[0];
            vm.email = invite.emailAddress;
            vm.firstName = invite.firstName;
            vm.lastName = invite.lastName;
            vm.requestReadReceipt = Boolean(invite.requestReadReceipt);
            vm.bcc = Boolean(vm.bccEmailAddress = invite.bccEmailAddress);

            var additionalInfo = angular.fromJson(invite.additionalInfo);
            switch (vm.role) {
                /*
                case 'AVC Coordinator':
                    items = additionalInfo.communityAffiliationIds;
                    return selectCoordinator();
                case 'AVC Region Manager':
                    items = additionalInfo.communityAffiliationIds;
                    return selectRegionManager();
                */
                case 'AVC OPOC':
                    var promiseArray = [];
                    // Get all communities to be able to sort existing orgs
                    vm.communities.forEach(function (comm) {
                        if (comm.organizations === null)
                            promiseArray.push(getOrgsForCommunity(comm));
                    });

                    $q.all(promiseArray)
                        .then(function () {
                            // Assumed to be running after data is populated
                            additionalInfo.volunteerOrganizationIds.forEach(function (orgId) {
                                var foundOrg = false;
                                for (var i = 0; i < vm.communities.length; i++) {
                                    if (foundOrg)
                                        break;

                                    for (var j = 0; j < vm.communities[i].organizations.length; j++) {
                                        if (orgId === vm.communities[i].organizations[j].volunteerOrganizationId) {
                                            vm.invitedResponsibilities.push(vm.communities[i].organizations[j]);
                                            foundOrg = true;
                                            break;
                                        }
                                    }
                                }

                                if (!foundOrg) {
                                    setError("Error: could not find match for organization ID " + orgId);
                                }
                            });

                            vm.isLoading = false;
                        });


                    break;
            }
        }

        /*
        function selectCoordinator() {
            vm.isLoading = true;
            var elements = [
                'communityaffiliation'
            ];
            return authentication.getUiData(elements).then(function (response) {
                items = response.communityaffiliation.map(function (community) {
                    return {
                        name: community,
                        set: vm.items.indexOf(community) !== -1
                    };
                });
                vm.isLoading = false;
            });
        }

        function selectRegionManager() {
            vm.isLoading = true;
            return selectCoordinator().then(function () {
                vm.isLoading = false;
            });
        }

        function selectOrganizationProgramManager() {
            vm.isLoading = true;
            var params = {};

            return authentication.getStandardOrganizations(params).then(function (response) {
                items = response.standardOrganizations.map(function (standardOrganization) {
                    return {
                        name: standardOrganization.volunteerOrganizationTemplateName,
                        set: vm.items.indexOf(standardOrganization.volunteerOrganizationTemplateName) !== -1
                    };
                });
                vm.isLoading = false;
            });
        }
        */

        function onChangeRole() {
            vm.invitedResponsibilities = [];
            switch (vm.role) {
                /*
                case 'AVC Coordinator':
                    return selectCoordinator();
                case 'AVC Region Manager':
                    return selectRegionManager();
                */
                case 'AVC OPOC':
                    return selectOpoc();
            }
        }

        function onChangeCommunity() {
            if (vm.selectedCommunity.organizations === null)
                getOrgsForCommunity(vm.selectedCommunity);
        }

        function onSubmit() {
            vm.isLoading = true;
            var params = {
                email: vm.email,
                firstName: vm.firstName,
                lastName: vm.lastName,
                requestReadReceipt: vm.requestReadReceipt,
                bccEmailAddress: vm.bcc ? vm.bccEmailAddress : '',
                additionalInfo: {}
            };
            switch (vm.role) {
                /*
                case 'AVC Coordinator':
                case 'AVC Region Manager':
                    params.additionalInfo.communityAffiliationIds = _.map(vm.items, getItemId);
                    break;
                */
                case 'AVC OPOC':
                    params.additionalInfo.volunteerOrganizationIds = _.map(vm.invitedResponsibilities, function (resp) {
                        return resp.volunteerOrganizationId;
                    });
                    params.additionalInfo.communityAffiliationIds = _.uniqBy(_.map(vm.invitedResponsibilities, function (resp) {
                        return resp.communityAffiliationId;
                    }));
                    break;
            }

            var apiCall = authentication.sendInvite;

            if (invite !== null) {
                params.invitationId = invite.invitationId;
                apiCall = authentication.updateInvite;
            } else {
                params.aosRoles = [vm.role];
            }

            return apiCall(params)
                .then(function () {
                    $modalInstance.close();
                })
                .catch(function (error) {
                    params = {
                        error: error,
                        success: false
                    };
                    return $modalInstance.dismiss(params);
                });


            function getItemId(item) {
                return item.id;
            }
        }

        function onClickDelete() {
            var options = {
                templateUrl: 'app/components/administrator/invitations/deleteInvite.view.html',
                controller: 'DeleteInviteController',
                controllerAs: 'vm',
                size: 'tiny',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    fullName: function () {
                        return vm.firstName + " " + vm.lastName;
                    },
                    email: function () {
                        return vm.email;
                    },
                    role: function () {
                        return vm.role;
                    }
                }
            };

            $modal.open(options)
                .result
                .then(deleteInvite);
        }

        function deleteInvite() {
            vm.isLoading = true;
            return authentication.deleteInvite(hash)
                .then(function () {
                    $modalInstance.close();
                });
        }

        function setError(error) {
            var errorStr = error.error ? error.error : error;
            if (errorStr === 'ok' || errorStr === 'cancel' || errorStr === 'backdrop click')
                return;
            vm.errorText = errorStr;
        }

        function clearError() {
            vm.errorText = "";
        }
    }
})();