(function () {
    'use strict';

    angular
        .module('app.vmis')
        .controller('CoordinatorDashboardController', CoordinatorDashboardController);

    CoordinatorDashboardController.$inject = ['$modal', '$scope', '$q', 'authentication', 'utilsServices', 'loadStatus', 'moment'];

    function CoordinatorDashboardController($modal, $scope, $q, authentication, utilsServices, loadStatus, moment) {
        var vm = this;
        var seriesLabels = {
            hours: ['Submitted', 'Certified & Rejected', 'Certified', 'Rejected'],
            applications: ['Submitted', 'Approved & Rejected', 'Approved', 'Rejected']
        };

        vm.sortColumn = 'totalSubmitted';
        vm.sortReverse = true;
        vm.userEnteredDate = false;
        vm.overdueLoading = true;
        vm.chartLoading = true;
        vm.showChartIncompleteDataWarning = false;

        vm.loadStatusHoursApps = loadStatus.init();
        // TODO: chart load status object not used as fully
        vm.loadStatusChart = loadStatus.init();
        vm.troubledOrganizations = [];

        vm.community = null;

        vm.troubledOrganizations = [];
        vm.chartTypes = [
            'Daily Hours',
            'Period Hours',
            'Applications'
        ];
        vm.chartType = vm.chartTypes[0];

        var chart = {};

        vm.defaultStartDate = moment().startOf('day').subtract(30, 'days').toDate();
        vm.defaultEndDate = moment().startOf('day').toDate();

        vm.startDate = vm.defaultStartDate;
        vm.endDate = vm.defaultEndDate;

        vm.currentDate = new Date();
        vm.organization = null;
        vm.overdueThreshold = 15;
        vm.overdueThresholds = [];

        vm.onChangeOverdueThreshold = onChangeOverdueThreshold;
        vm.onChangeChart = onChangeChart;
        vm.onClickViewOpocs = onClickViewOpocs;
        vm.onLoadMore = onLoadMore;
        vm.onLoadAll = onLoadAll;
        vm.onSelectCommunity = onSelectCommunity;
        vm.onChangeDate = onChangeDate;

        activate();

        function activate() {
            chart = {
                data: [[], [], [], []],
                labels: [],
                options: {
                    legend: {
                        display: true,
                        labels: {
                            filter: function (dataset) {
                                return dataset.datasetIndex < 2;
                            }
                        },
                        onClick: function () {
                        }
                    },
                    tooltips: {
                        callbacks: {
                            label: function (tooltipItem, data) {
                                if (tooltipItem.datasetIndex === 1 || tooltipItem.index === 0) return;
                                var type = data.datasets[tooltipItem.datasetIndex].label;
                                var date = data.labels[0];
                                var count = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index - 1];
                                return type + ' since ' + date + ': ' + count;
                            },
                            afterLabel: function (tooltipItem, data) {
                                if (tooltipItem.datasetIndex === 1) return;
                                var type = data.datasets[tooltipItem.datasetIndex].label;
                                var date = data.labels[tooltipItem.index];
                                var count = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                                if (tooltipItem.index > 0) count -= data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index - 1];
                                return type + ' on ' + date + ': ' + count;
                            }
                        },
                        filter: function (dataset) {
                            return dataset.datasetIndex !== 1;
                        },
                        intersect: false,
                        displayColors: false,
                        position: 'nearest'
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                min: 0,
                                suggestedMax: 10
                            }
                        }, {
                            ticks: {
                                max: -100,
                                min: -100
                            },
                            display: false,
                            id: 'no-show'
                        }]
                    }
                },
                series: ['Submitted', '', 'Certified', 'Rejected'],
                colors: ['#337ab7', '#3c763d'],
                datasetOverride: [{
                    lineTension: 0
                }, {
                    lineTension: 0
                }, {
                    yAxisID: 'no-show'
                }, {
                    yAxisID: 'no-show'
                }]
            };

            vm.chart = angular.copy(chart, {});

            for (var i = 1; i <= 30; i++) {
                vm.overdueThresholds.push(i);
            }

            var promises = [loadStatus.setup(vm.loadStatusHoursApps, "avccHoursApplications"),
                loadStatus.setup(vm.loadStatusChart, "coordinatorChart")];

            return $q.all(promises)
                .then(function () {
                    getProfile()
                        .then(getOrganizations)
                        .then(function () {
                            getChartData(vm.organization, vm.chartType, vm.startDate, vm.endDate);
                            getTroubledOrganizations(vm.loadStatusHoursApps.currentIndex, vm.loadStatusHoursApps.defaultNumRows);
                        });
                });
        }

        function getProfile() {
            return authentication.getUserProfile().then(function (response) {
                return utilsServices.combineCommunityResponsibilities(response, 'AVC Coordinator');
            }).then(function (communities) {
                vm.communities = communities;
                vm.community = vm.communities[0];
            });
        }

        function onSelectCommunity() {
            getOrganizations().then(function () {
                return onChangeChart();
            });
        }

        function getOrganizations() {
            return authentication.getOrganizations({
                communityAffiliationId: vm.community ? vm.community.id : undefined,
                getAll: true
            }).then(function (response) {
                vm.organizations = _.map(response.organizations, function (organization) {
                    return {
                        id: organization.volunteerOrganizationId,
                        name: organization.organizationName
                    };
                });
                vm.organizations.unshift({
                    name: "All"
                    //id: -1
                });
                vm.organization = vm.organizations[0];
            });
        }

        function getChartData(organization, type, startDate, endDate) {
            vm.chartLoading = true;
            var promise = authentication.getHoursAdmin;
            var timeUnit = 'days';
            var labelFormat = 'MM/DD/YYYY';
            chart.series = seriesLabels.hours;
            switch (type) {
                case 'Daily Hours':
                    break;
                case 'Period Hours':
                    timeUnit = 'months';
                    labelFormat = 'MMM YYYY';
                    break;
                case 'Applications':
                    chart.series = seriesLabels.applications;
                    promise = authentication.getApplicationsAdmin;
                    break;
                default:
                    return;
            }

            var params = {
                startingRowIndex: 0,
                numberOfRows: vm.loadStatusChart.defaultNumRows,
                communityAffiliationId: vm.community ? vm.community.id : undefined,
                startDate: moment(startDate).format('YYYY-MM-DD[T00:00:00Z]'),
                endDate: moment(endDate).format('YYYY-MM-DD[T00:00:00Z]')
            };
            if (angular.isDefined(organization.id)) {
                // End point only uses org ID if available,
                // so remove extra work for community parameter
                params.communityAffiliationId = undefined;
                params.volunteerOrganizationId = organization.id;
            }
            return promise(params).then(function (response) {
                var items = [];
                switch (type) {
                    case 'Daily Hours':
                        items = items.concat(response.hours.filter(function (h) {
                            return h.dayOfService;
                        }).map(function (h) {
                            return {
                                date: h.dayOfService,
                                status: h.volunteerHoursStatus
                            };
                        }));
                        break;
                    case 'Period Hours':
                        items = items.concat(response.hours.filter(function (h) {
                            return h.periodOfService;
                        }).map(function (h) {
                            return {
                                date: h.periodOfService,
                                status: h.volunteerHoursStatus
                            };
                        }));
                        break;
                    case 'Applications':
                        items = items.concat(response.applications.map(function (a) {
                            return {
                                date: a.createdDate,
                                status: a.volunteerApplicationStatus
                            };
                        }));
                        break;
                }

                items.sort(function (a, b) {
                    return moment(a.date).diff(b.date, timeUnit);
                });

                var currentDay = moment(startDate, 'MM/DD/YYYY');
                chart.data = [[0], [0], [0], [0]];
                items.forEach(function (i) {
                    var diff = moment(i.date).diff(currentDay, timeUnit);

                    chart.data.forEach(function (d) {
                        for (var i = d.length; i <= diff; i++) {
                            d.push(d[d.length - 1]);
                        }
                    });

                    switch (i.status) {
                        case 'Submitted':
                            chart.data[0][diff]++;
                            break;
                        case 'Approved':
                        case 'Certified':
                            chart.data[0][diff]++;
                            chart.data[1][diff]++;
                            chart.data[2][diff]++;
                            break;
                        case 'Rejected':
                            chart.data[0][diff]++;
                            chart.data[1][diff]++;
                            chart.data[3][diff]++;
                            break;
                    }
                });

                chart.labels = [];
                while (moment(endDate, 'MM/DD/YYYY').isSameOrAfter(currentDay, timeUnit)) {
                    chart.labels.push(currentDay.format(labelFormat));
                    currentDay.add(1, timeUnit);
                }

                angular.forEach(chart.data, function (d) {
                    while (d.length < chart.labels.length) {
                        d.push(d[d.length - 1]);
                    }
                });

                vm.chart = angular.copy(chart, {});

                // If there are more data points than the chart has loaded,
                // display text indicating this and direct to Reports.
                vm.showChartIncompleteDataWarning = (response.count > vm.loadStatusChart.defaultNumRows);

                vm.chartLoading = false;
            });
        }

        function getTroubledOrganizations(index, num) {
            vm.overdueLoading = true;
            var params = {
                startingRowIndex: index,
                numberOfRows: num,
                communityAffiliationId: vm.community ? vm.community.id : undefined,
                hoursDaysOverdueThreshold: vm.overdueThreshold,
                applicationsDaysOverdueThreshold: vm.overdueThreshold
            };

            return authentication.getTroubledOrganizationsForCommunity(params)
                .then(function (response) {
                    getTroubledOrganizationsSuccess(response, params.startingRowIndex, params.numberOfRows);
                }).catch(function (err) {
                    getTroubledOrganizationsError(err);
                }).finally(function () {
                    vm.overdueLoading = false;
                });
        }

        function onChangeOverdueThreshold() {
            vm.loadStatusHoursApps.currentIndex = 0;
            getTroubledOrganizations(vm.loadStatusHoursApps.currentIndex, vm.loadStatusHoursApps.defaultNumRows);
        }

        function onChangeDate() {
            vm.userEnteredDate = true;
            onChangeChart();
        }

        function onChangeChart() {
            if (!vm.userEnteredDate) {
                if (vm.chartType === 'Period Hours') {
                    var currentMonth = moment().month();
                    var currentYear = moment().year();
                    // Set the date range to the previous FY
                    vm.startDate = moment().set({
                        'year': currentMonth >= 9 ? currentYear : currentYear - 1,
                        'month': 9,
                        'date': 1
                    }).startOf('day').toDate();
                    vm.endDate = moment().set({
                        'year': currentYear,
                        'month': currentMonth,
                        'date': moment().date()
                    }).startOf('day').toDate();
                }
                else {
                    vm.startDate = vm.defaultStartDate;
                    vm.endDate = vm.defaultEndDate;
                }
            }

            if (angular.isDefined(vm.startDate) && angular.isDefined(vm.endDate)) {
                return getChartData(vm.organization, vm.chartType, vm.startDate, vm.endDate);
            }
        }

        function onClickViewOpocs(opocs) {
            $modal.open({
                templateUrl: 'app/components/dashboard/opocDetails.view.html',
                controller: 'OpocDetailsController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    opocs: function () {
                        return opocs;
                    }
                }
            });
        }

        function getTroubledOrganizationsSuccess(response, index, num) {
            if (index === 0) {
                vm.loadStatusHoursApps.currentIndex = 0;
                vm.troubledOrganizations = [];
            }

            // Either set current index to max if all users loaded or increment by rows loaded
            vm.loadStatusHoursApps.currentIndex = (vm.loadStatusHoursApps.currentIndex + num > response.count) ? response.count : (vm.loadStatusHoursApps.currentIndex + num);
            // Track total number of users not loaded for "Load All" button
            vm.loadStatusHoursApps.numResultsNotLoaded = response.count - vm.loadStatusHoursApps.currentIndex;
            // Set text for "Load More" button
            vm.loadStatusHoursApps.nextResultLoadNum = (response.count === vm.loadStatusHoursApps.currentIndex) ? 0 :
                (vm.loadStatusHoursApps.numResultsNotLoaded > num) ? num : vm.loadStatusHoursApps.numResultsNotLoaded;

            vm.troubledOrganizations = vm.troubledOrganizations.concat(response.organizations);
            angular.forEach(vm.troubledOrganizations, function (organization) {
                organization.totalOverdueHours = 0;
                organization.totalOverdueApplications = 0;
                organization.totalSubmitted = 0;

                angular.forEach(organization.organizationOpportunity, function (position) {
                    organization.totalOverdueHours += position.overdueHours.length;
                    organization.totalOverdueApplications += position.overdueApplications.length;
                    organization.totalSubmitted = (organization.totalOverdueHours + organization.totalOverdueApplications);
                });
            });
        }

        function getTroubledOrganizationsError(error) {
            if (angular.isDefined(error)) {
                var errorStr = error.error ? error.error : error;
                $scope.$emit('troubledOrganizations:alert', errorStr);
            }
            else
                $scope.$emit('troubledOrganizations:alert', "Failed to load list! Please try again.");
        }


        function onLoadMore() {
            getTroubledOrganizations(vm.loadStatusHoursApps.currentIndex, vm.loadStatusHoursApps.nextResultLoadNum);
        }

        function onLoadAll() {
            getTroubledOrganizations(vm.loadStatusHoursApps.currentIndex, vm.loadStatusHoursApps.numResultsNotLoaded);
        }

    }
})();