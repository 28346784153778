(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('PositionDetailsController', PositionDetailsController);

    PositionDetailsController.$inject = ['_', '$q', '$state', '$window', 'isClonedPosition', 'positionId', 'title', '$modal', '$modalInstance', 'authentication', 'moment', 'config'];

    function PositionDetailsController(_, $q, $state, $window, isClonedPosition, positionId, title, $modal, $modalInstance, authentication, moment, config) {
        var vm = this;

        var positionTypes = [
            [], // AVC
            ['None'], // Private Organization
            ['None'] // Non-AVC
        ];
        var organizationTypes = [];

        vm.isProductionWebsite = null;
        vm.bannerMessage = null;

        vm.isClone = isClonedPosition;
        vm.isLoading = true;
        vm.isOrgActive = true;
        vm.title = title;
        vm._ = _;
        vm.dropDowns = {
            booleanOptions: [{
                label: 'No',
                value: false
            }, {
                label: 'Yes',
                value: true
            }]
        };
        vm.position = {
            id: positionId,
            supervisors: _.map(new Array(2), function () {
                return {
                    name: '',
                    email: '',
                    phone: ''
                };
            })
        };

        vm.onDelete = onDelete;
        vm.onSave = onSave;
        vm.onChangeCountry = onChangeCountry;
        vm.onChangeCommunity = onChangeCommunity;
        vm.onChangeOrganization = onChangeOrganization;
        vm.onClickClone = onClickClone;
        vm.onPrint = onPrint;
        vm.disableOrgDropDown = disableOrgDropDown;
        vm.startDate = moment().add(1,'day').toDate();

        activate();

        function activate() {
            // initializeNewPosition() can call onChangeCommunity(), which requires drop down data
            getDropDownData().then(function () {
                var positionPromise = null;
                if (vm.isClone === false) {
                    positionPromise = positionId ? getDetails(positionId) : initializeNewPosition();

                    $q.all([
                        positionPromise
                    ]).then(function () {
                        if (vm.position.organization) {
                            vm.dropDowns.positionTypes = positionTypes[vm.position.organization.type.id];
                        }

                        if (vm.position.address) {
                            onChangeCountry(vm.position.address.country);
                        }

                        vm.isLoading = false;
                    })
                        .catch(displayError);
                }
                // this means we're cloning an existing
                else {
                    positionPromise = initializeNewPosition().then(function () {
                        return getDetails(positionId);
                    });

                    $q.all([
                        positionPromise
                    ]).then(function () {

                        if (vm.position.organization) {
                            vm.dropDowns.positionTypes = positionTypes[vm.position.organization.type.id];
                        }

                        if (vm.position.address) {
                            onChangeCountry(vm.position.address.country);
                        }

                        // fill in community, organization
                        vm.position.community = _.find(vm.dropDowns.communities, function (communityElem) {
                            return communityElem.name === vm.position.organization.community.name;
                        });
                        var theOrganization = vm.position.organization;
                        onChangeCommunity(vm.position.community).then(function () {
                            vm.position.organization = _.find(vm.position.community.organizations, function (orgElem) {
                                return theOrganization.name === orgElem.name;
                            });
                        });

                        // always make cloned status "Open, searchable"
                        vm.position.status = "Open, searchable";

                        vm.position.id = null;
                        positionId = null;
                        vm.position.title = null;
                        vm.position.closedDate = null;
                        vm.isLoading = false;
                        vm.position.hasApplications = false;
                        vm.isLoading = false;

                        vm.ignoreInvalid = false;
                    })
                        .catch(displayError);
                }
            });

            config.get().then(function (configData) {
                vm.isProductionWebsite = configData.isProductionWebsite;
                vm.bannerMessage = configData.bannerMessage;
            });
        }

        function getDetails(positionId) {
            var params = {
                volunteerOpportunityId: positionId
            };

            return authentication.getOpportunityDetails(params)
                .then(function (response) {
                    vm.isOrgActive = response.organization.isActive ? true : false;
                    vm.position = {
                        id: response.volunteerOrganizationOpportunityId,
                        organization: {
                            name: response.organization.isActive ? response.organization.volunteerOrganizationFullName : response.organization.volunteerOrganizationFullName + " (inactive)",
                            type: {
                                name: response.organization.volunteerOrganizationType,
                                id: response.organization.volunteerOrganizationTypeId - 1
                            },
                            community: {
                                name: response.organization.communityAffiliation.communityAffiliationName
                            }
                        },
                        title: response.title,
                        closedDate: response.closedDate ? moment(response.closedDate, 'YYYY-MM-DD').toDate() : '',
                        keywords: response.keywords || '',
                        positionType: response.volunteerPositionType,
                        opportunityType: response.volunteerOpportunityType,
                        status: response.volunteerPositionStatus,
                        backgroundCheckRequired: !!response.isBackgroundCheckRequired,
                        supervisionRequired: !!response.supervisionConditionFlag,
                        summary: response.summary || '',
                        duties: response.duties,
                        qualifications: response.qualifications,
                        training: response.training,
                        orientation: response.orientation,
                        timeRequired: response.timeRequired,
                        evaluation: response.evaluation || '',
                        benefits: response.benefits || '',
                        address: {
                            country: response.country,
                            line1: response.address1,
                            line2: response.address2 || '',
                            city: response.city,
                            state: response.usState,
                            zipCode: response.zipCode
                        },
                        supervisors: [{
                            name: response.firstLineSupervisorName || '',
                            email: response.firstLineSupervisorEmail || '',
                            phone: response.firstLineSupervisorPhone || ''
                        }, {
                            name: response.secondLineSupervisorName || '',
                            email: response.secondLineSupervisorEmail || '',
                            phone: response.secondLineSupervisorPhone || ''
                        }],
                        hasApplications: response.volunteerApplications.length > 0
                    };

                    // give the user a one time shot at submitting invalid values if the title
                    // is empty
                    vm.ignoreInvalid = vm.position.title.length === 0;
                    vm.isVolunteerSubmitted = response.volunteerOrganization === 'Volunteer Submitted';
                });
        }

        function initializeNewPosition() {
            var communities = [];
            return authentication.getUserProfile().then(function (response) {
                var adminProfile = response.aosUser.vmisUser.adminProfile;

                //get Coordinator communities
                if (adminProfile.responsibilities.avcc) {
                    communities = _.map(adminProfile.responsibilities.avcc, function (community) {
                        return {
                            id: community.communityAffiliationId,
                            name: community.communityAffiliationName
                        };
                    });
                }

                //get OPOC organizations
                if (adminProfile.responsibilities.opoc) {
                    _.forEach(adminProfile.responsibilities.opoc, function (community) {
                        if (_.some(communities, { id: community.communityAffiliationId }) === false) {
                            communities.push({
                                id: community.communityAffiliationId,
                                name: community.communityAffiliationName,
                                opocOrganizations: _.map(community.organizations, function (organization) {
                                    return {
                                        id: organization.volunteerOrganizationId
                                    };
                                })
                            });
                        }
                    });
                }

                vm.dropDowns.communities = _.sortBy(communities, ['name']);

                // If only one community, go ahead and select it
                if (vm.dropDowns.communities.length === 1) {
                    vm.position.community = vm.dropDowns.communities[0];
                    onChangeCommunity(vm.position.community);
                }
            });
        }

        function onSave() {
            if (vm.position.id) {
                $modal.open({
                    templateUrl: 'app/components/administrator/positions/confirmation.template.html',
                    controller: 'ConfirmationController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    keyboard: false,
                    resolve: {
                        title: function () {
                            return 'Confirm Changes';
                        },
                        body: function () {
                            return 'Are you sure you want to save changes to ' + vm.position.title + '?';
                        }
                    },
                    size: 'tiny'
                }).result.then(onConfirm);
            } else {
                onConfirm();
            }
        }

        function onConfirm() {
            vm.isLoading = true;
            // Note: title, position type, background check required, and supervision required are prevented from
            // being changed in the UI (both disabled inputs and below) if there are applications for the position.

            if(angular.isDefined(vm.position.address.state) && vm.position.address.state !== null){
               var testString = vm.position.address.state.substring(vm.position.address.state.length-5);

               if(testString.substring(0,2) === ' (' && testString.substring(testString.length-1) === ')'){
                   vm.position.address.state = vm.position.address.state.substring(0,vm.position.address.state.length-5);
               }
            }

            var params = {
                volunteerOrganizationOpportunityId: vm.position.id ? vm.position.id : undefined,
                volunteerOrganizationId: vm.position.id ? undefined : vm.position.organization.id,
                title: (vm.position.hasApplications && !vm.ignoreInvalid) ? undefined : vm.position.title,
                closedDate: vm.position.closedDate ? moment(vm.position.closedDate).format('YYYY-MM-DD[T00:00:00Z]') : null,
                keywords: vm.position.keywords,
                volunteerPositionType: vm.position.hasApplications ? undefined : vm.position.positionType,
                volunteerOpportunityType: vm.position.opportunityType,
                volunteerPositionStatus: vm.isOrgActive ? vm.position.status : "Closed",
                isBackgroundCheckRequired: vm.position.hasApplications ? undefined : vm.position.backgroundCheckRequired,
                supervisionConditionFlag: vm.position.hasApplications ? undefined : vm.position.supervisionRequired,
                summary: vm.position.summary,
                duties: vm.position.duties,
                qualifications: vm.position.qualifications,
                training: vm.position.training,
                orientation: vm.position.orientation,
                timeRequired: vm.position.timeRequired,
                evaluation: vm.position.evaluation,
                benefits: vm.position.benefits,
                country: vm.position.address.country,
                address1: vm.position.address.line1,
                address2: vm.position.address.line2,
                city: vm.position.address.city,
                usState: vm.position.address.state,
                zipCode: vm.position.address.zipCode,
                firstLineSupervisorName: vm.position.supervisors[0].name,
                firstLineSupervisorEmail: vm.position.supervisors[0].email,
                firstLineSupervisorPhone: vm.position.supervisors[0].phone,
                secondLineSupervisorName: vm.position.supervisors[1].name,
                secondLineSupervisorEmail: vm.position.supervisors[1].email,
                secondLineSupervisorPhone: vm.position.supervisors[1].phone
            };

            return authentication.setOpportunity(params)
                .then($modalInstance.close)
                .catch(displayError);
        }

        function getDropDownData() {
            var elements = [
                'usstate',
                'stateabbrev',
                'country',
                'volunteerpositiontype',
                'volunteeropportunitytype',
                'volunteerpositionstatus',
                'volunteerorganizationtype'
            ];

            return authentication.getUiData(elements).then(function (response) {
                vm.dropDowns.states = authentication.createStateList(response.usstate,response.stateabbrev);
                vm.dropDowns.countries = response.country;
                positionTypes[0] = response.volunteerpositiontype;
                positionTypes[0].pop();
                vm.dropDowns.opportunityTypes = response.volunteeropportunitytype;
                vm.dropDowns.statuses = response.volunteerpositionstatus;
                organizationTypes = response.volunteerorganizationtype;
            });
        }

        function onDelete() {
            $modal.open({
                templateUrl: 'app/components/administrator/positions/confirmation.template.html',
                controller: 'ConfirmationController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    title: function () {
                        return 'Confirm to Delete Position';
                    },
                    body: function () {
                        return 'Are you sure you want to delete ' + vm.position.title + '?';
                    }
                }
            }).result.then(function () {
                authentication.deleteOpportunity({
                    opportunityId: vm.position.id
                }).then($modalInstance.close)
                    .catch(displayError);
            });
        }

        function displayError(error) {
            vm.formError = error;
            vm.isLoading = false;
        }

        function onChangeCommunity(community) {
            vm.position.organization = undefined;

            vm.isLoading = true;
            return authentication.getOrganizations({
                communityAffiliationId: community.id,
                getAll: true
            }).then(function (response) {
                var organizations = _.sortBy(_.map(_.filter(response.organizations, function (organization) {
                    return organization.organizationName !== 'Volunteer Submitted';
                }), function (organization) {
                    return {
                        id: organization.volunteerOrganizationId,
                        name: organization.organizationName,
                        type: {
                            name: organization.volunteerOrganizationType,
                            id: _.indexOf(organizationTypes, organization.volunteerOrganizationType)
                        }
                    };
                }), ['name']);

                community.organizations = _.isUndefined(community.opocOrganizations) ? organizations : _.intersectionBy(organizations, community.opocOrganizations, 'id');
                vm.isLoading = false;
            });
        }

        function onChangeOrganization(organization) {
            if (_.isEqual(vm.dropDowns.positionTypes, positionTypes[organization.type.id]) === false) {
                vm.dropDowns.positionTypes = positionTypes[organization.type.id];
                vm.position.positionTypeId = undefined;
            }

            vm.isOrgActive = true;

            if (organization.name.length > 11) {
                var suffix = organization.name.substring(organization.name.length - 11);
                if (suffix === " (inactive)") {
                    vm.isOrgActive = false;
                }
            }

            if (!vm.isOrgActive) {
                vm.position.status = "Closed";
            }

        }

        function onChangeCountry(country) {
            vm.isStateRequired = country === 'United States';
            if (!vm.isStateRequired) {
                vm.position.address.state = undefined;
            }
        }

        function onClickClone() {
            $modalInstance.close({ clonePosition: true });
        }

        function onPrint() {
            var printHref = $state.href("print.position", { id: vm.position.id }, { absolute: true });
            $window.open(printHref.replace("#", "print.html#"), "Print");
        }

        function disableOrgDropDown() {
            return vm.isLoading || (!angular.isDefined(vm.position.community) || vm.position.community === null);
        }
    }
})();