(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('OrganizationGroupsController', OrganizationGroupsController);

    OrganizationGroupsController.$inject = ['_', '$modal', 'authentication', 'loadStatus'];

    function OrganizationGroupsController(_, $modal, authentication, loadStatus) {
        var vm = this;

        vm.defaultCategoryName = "None";
        vm.sortColumn = 'name';
        vm.loadStatus = loadStatus.init();

        vm.onClickOrganizationGroup = onClickOrganizationGroup;
        vm.onLoadAll = onLoadAll;
        vm.onLoadMore = onLoadMore;

        activate();

        function activate() {
            loadStatus.setup(vm.loadStatus, "organizationGroups")
                .then(function () {
                    getOrganizationGroups(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows);
                });
        }

        function getOrganizationGroups(index, num) {
            vm.isLoading = true;

            return authentication.getOrganizationGroups({
                startingRowIndex: index,
                numberOfRows: num
            }).then(function (response) {
                if (index === 0) {
                    vm.loadStatus.currentIndex = 0;
                    vm.organizationGroups = [];
                }

                // Either set current index to max if all users loaded or increment by rows loaded
                vm.loadStatus.currentIndex = (vm.loadStatus.currentIndex + num > response.count) ? response.count : (vm.loadStatus.currentIndex + num);
                // Track total number of users not loaded for "Load All" button
                vm.loadStatus.numResultsNotLoaded = response.count - vm.loadStatus.currentIndex;
                // Set text for "Load More" button
                vm.loadStatus.nextResultLoadNum = (response.count === vm.loadStatus.currentIndex) ? 0 :
                    (vm.loadStatus.numResultsNotLoaded > num) ? num : vm.loadStatus.numResultsNotLoaded;

                vm.organizationGroups = _.concat(vm.organizationGroups, _.map(response.organizationGroups, function (organizationGroup) {
                    return {
                        id: organizationGroup.volunteerOrganizationGroupId,
                        name: organizationGroup.volunteerOrganizationGroupName
                    };
                })
                );
                vm.formError = '';
            }).catch(function (error) {
                if (angular.isDefined(error)) {
                    vm.formError = error.error ? error.error : error;
                }
                else
                    vm.formError = 'Failed to load list! Please try again.';
            }).finally(function () {
                vm.isLoading = false;
            });
        }

        function onClickOrganizationGroup(organizationGroup) {
            $modal.open({
                templateUrl: 'app/components/administrator/organizationGroups/organizationGroupDetails.view.html',
                controller: 'OrganizationGroupDetailsController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    title: function () {
                        return organizationGroup ? 'Organization Group Details' : 'Add New Organization Group';
                    },
                    organizationGroup: function () {
                        return organizationGroup ? _.clone(organizationGroup) : {};
                    }
                }
            }).result.then(function () {
                vm.loadStatus.currentIndex = 0;
                return getOrganizationGroups(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows);
            });
        }

        function onLoadMore() {
            getOrganizationGroups(vm.loadStatus.currentIndex, vm.loadStatus.nextResultLoadNum);
        }

        function onLoadAll() {
            getOrganizationGroups(vm.loadStatus.currentIndex, vm.loadStatus.numResultsNotLoaded);
        }
    }
})();