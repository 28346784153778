(function () {
    'use strict';

    angular
        .module('app')
        .directive('uploadDocumentList', UploadDocumentList);

    UploadDocumentList.$inject = [];

    function UploadDocumentList() {
        return {
            restrict: 'E',
            templateUrl: 'app/common/directives/uploadDocumentList/uploadDocumentList.template.html',
            controller: function () {},
            controllerAs: 'uploadDocumentList',
            scope: {
                uploadedDocuments: '=',
                selectedDocuments: '=',
                onDeleteUploadedDoc: '=',
                onDeleteSelectedDoc: '=',
                showUploadInstructions: '=',
                showDelete: '=',
                disableDelete: '='
            },
            bindToController: true
        };
    }
})();