(function () {
    'use strict';

    angular
        .module('vmis.activityCalendar')
        .controller('ActivityCalendarController', ActivityCalendarController);

    ActivityCalendarController.$inject = ['$scope', '$modal', 'moment', 'authentication', '_'];

    function ActivityCalendarController($scope, $modal, moment, authentication, _) {
        var vm = this;

        vm.isCalendarLoading = true;

        var periodStart = new Date();
        var position;
        var dailyActivities = [];
        var periodActivities = [];

        vm.current = new Date();
        vm.calendarView = buildMonth(moment(vm.current).year(), moment(vm.current).month());

        vm.isToday = isToday;
        vm.isCurrentMonth = isCurrentMonth;
        vm.isCurrentPeriod = isCurrentPeriod;
        vm.isBeforePeriod = isBeforePeriod;
        //vm.isAfterPeriod = isAfterPeriod;
        vm.onNextMonth = onNextMonth;
        vm.onPreviousMonth = onPreviousMonth;
        vm.onSelect = onSelect;
        vm.onSetPosition = onSetPosition;
        vm.onReload = onReload;

        activate();

        function activate() {
            if (moment().date() < 15) {
                periodStart = moment().subtract(1, 'months');
            }
            periodStart = moment(periodStart).startOf('month').toDate();

            if ((angular.isUndefined(position)) || (position === null)) {
                vm.isCalendarLoading = false;
            }
        }

        function buildCalendar(year, month) {
            // "!= undefined" catches null and undefined but allows for 0
            if ((year != undefined && !parseInt(year)) || (month != undefined && !parseInt(month))) {
                console.warn("Warning: attempted to build calendar with invalid year, month: " +
                    year + ", " + month);
                return;
            }

            if (year && month) {
                vm.current = new Date(year, month);
                vm.calendarView = buildMonth(year, month);
            } else {
                vm.calendarView = buildMonth(moment(vm.current).year(), moment(vm.current).month());
            }
            loadActivities();
        }

        function buildMonth(year, month) {
            var week = moment().year(year).month(month).startOf('month').week();
            var weeks = [];
            for (var i = 0; i < 6; i++) {
                weeks.push(buildWeek(year, week++));
            }
            return weeks;
        }

        function buildWeek(year, week) {
            // Set time to avoid timezone differences changing the selected date
            var date = moment().year(year).startOf('month').week(week).day(0).startOf('day');
            var days = [];
            for (var i = 0; i < 7; i++) {
                days.push({
                    date: date.toDate(), // Convert to native JS date object
                    activity: null
                });
                date.add(1, 'days');
            }
            return days;
        }

        function getFirstCalendarDate() {
            return vm.calendarView[0][0].date;
        }

        function getLastCalendarDate() {
            var lastWeek = vm.calendarView[vm.calendarView.length - 1];
            return lastWeek[lastWeek.length - 1].date;
        }

        function isToday(date) {
            return moment().isSame(date, 'day');
        }

        function isCurrentMonth(date) {
            return moment(vm.current).isSame(date, 'month');
        }

        function isCurrentPeriod(date) {
            return moment(date).isSameOrAfter(periodStart, 'day') && moment(date).isSameOrBefore(new Date(), 'day');
        }

        function isBeforePeriod(date) {
            return moment(date).isBefore(periodStart, 'day');
        }

        /*
        function isAfterPeriod(date) {
            return moment(date).isAfter(new Date(), 'day');
        }
        */

        function onNextMonth() {
            vm.current = moment(vm.current).add(1, 'months').toDate();
            buildCalendar();
        }

        function onPreviousMonth() {
            vm.current = moment(vm.current).subtract(1, 'months').toDate();
            buildCalendar();
        }

        function onSelect(day) {
            if (((!isCurrentPeriod(day.date) || vm.disabled) && day.activity === null) || angular.isUndefined(position))
                if (!vm.adminEditing)
                    return;

            var options = {};
            if (vm.adminEditing) {
                options = {
                    templateUrl: 'app/components/administrator/hours/manageActivity.view.html',
                    controller: 'ManageActivityController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    keyboard: false,
                    resolve: {
                        activity: function () {
                            // On creating new daily hours as admin, provide field
                            // to indicate that type as well as additional needed data
                            var activity = {};
                            if (day.activity) {
                                activity = angular.copy(day.activity);
                                activity.position = position;
                                activity.volunteer = {
                                    firstName: vm.targetUser.firstName,
                                    lastName: vm.targetUser.lastName,
                                    masterUserId: vm.targetUser.masterUserId
                                };
                                activity.organization = position.organization;
                            }
                            else {
                                activity = {
                                    type: 'Daily',
                                    date: day.date,
                                    position: position,
                                    volunteer: {
                                        firstName: vm.targetUser.firstName,
                                        lastName: vm.targetUser.lastName,
                                        masterUserId: vm.targetUser.masterUserId
                                    },
                                    organization: position.organization
                                };
                            }
                            return activity;
                        },
                        rejectOnly: function () {
                            return false;
                        }
                    }
                };
            }
            else {
                options = {
                    templateUrl: 'app/components/volunteer/activityCalendar/enterHours.view.html',
                    controller: 'EnterHoursController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    keyboard: false,
                    resolve: {
                        activity: function () {
                            var newActivity = {
                                type: 'Daily',
                                date: day.date
                            };
                            return day.activity !== null ? angular.copy(day.activity) : newActivity;
                        },
                        positionId: function () {
                            return position.id;
                        },
                        volunteerId: function () {
                            return vm.targetUser.masterUserId;
                        },
                        readOnly: function () {
                            return (vm.disabled || !isCurrentPeriod(day.date));
                        }
                    }
                };
            }

            $modal.open(options).result
                .catch(function (error) {
                    $scope.$emit('activity:alert', error);
                // Even if user does not submit, could have deleted documents
                })
                .finally(loadActivities);
        }

        function onSetPosition(pos) {
            position = pos;
            // Only go back in the calendar if the position is not "Active" (where hours can be entered)
            if (position && (position.serviceStatus === "Active")) {
                loadActivities();
            } else {
                loadRecentActivities();
            }
        }

        function loadRecentActivities() {
            if ((angular.isUndefined(position)) || (position === null)) {
                return;
            }

            // Similar setup to loadActivities()
            var getHours = vm.adminEditing ? authentication.getHoursAdmin : authentication.getHoursVol;
            var params = {
                volunteerOpportunityId: position.id,
            };
            if (vm.adminEditing) {
                params.onlyShowNonDeletedHours = true;
                params.masterUserId = vm.targetUser.masterUserId;
            }

            // Note: this assumes all hours are returned in this single call (and in chronological order)
            return getHours(params).then(function (response) {
                if (response.hours.length > 0) {
                    var mostRecentDate = moment(response.hours[0].dayOfService || response.hours[0].periodOfService);

                    // Removed because of "onlyShowNonDeletedHours" and that API returns most recent first
                    /*
                    var testIndex = 0;
                    // Find most recent entry that isn't deleted
                    while (response.hours[testIndex].volunteerHoursStatus === "Deleted") {
                        testIndex++;
                    }
                    var mostRecentDate = moment(response.hours[testIndex].dayOfService || response.hours[testIndex].periodOfService);

                    _.forEach(response.hours, function (hour) {
                        var curDate = moment(hour.dayOfService || hour.periodOfService);
                        if (curDate.isAfter(mostRecentDate, 'day')) {
                            mostRecentDate = curDate;
                        }
                    });
                    */

                    buildCalendar(mostRecentDate.year(), mostRecentDate.month());
                } else {
                    buildCalendar();
                }
            });
        }

        function loadActivities() {
            if ((angular.isUndefined(position)) || (position === null)) {
                return;
            }

            vm.isCalendarLoading = true;

            // Removed because it was clearing errors before they appeared
            //$scope.$emit('activity:alert', "");

            var params = {
                volunteerOpportunityId: position.id,
                startDate: getFirstCalendarDate().toISOString(),
                endDate: getLastCalendarDate().toISOString()
            };
            var getHours = authentication.getHoursVol;

            // If admin editing, get activities for others
            if (vm.adminEditing) {
                params.onlyShowNonDeletedHours = true;
                params.masterUserId = vm.targetUser.masterUserId;
                getHours = authentication.getHoursAdmin;
            }

            return getHours(params).then(function (response) {
                var activities = _.map(_.reject(response.hours, function (activity) {
                    return activity.volunteerHoursStatus === 'Deleted';
                }), function (activity) {
                    return {
                        type: activity.dayOfService ? 'Daily' : 'Period',
                        date: activity.dayOfService ? new moment(activity.dayOfService) : new moment(activity.periodOfService),
                        hours: activity.hours,
                        status: activity.volunteerHoursStatus,
                        reviewerNote: activity.reviewerNotes || "", // Make empty strings for compatibility in modals
                        volunteerNote: activity.userNotes || "",
                        id: activity.volunteerHoursId,
                        documents: activity.documents ? activity.documents : null,
                        createdByMasterUserId: activity.createdByMasterUserId
                    };
                });

                dailyActivities = _.sortBy(_.filter(activities, function (activity) {
                    return activity.type === 'Daily';
                }), ['date']);
                periodActivities = _.sortBy(_.filter(activities, function (activity) {
                    return activity.type === 'Period';
                }), ['date']);

                if (angular.isFunction(vm.onLoadActivities)) {
                    onLoadActivities();
                }
            }).then(function () {
                vm.calendarView.forEach(function (week) {
                    week.forEach(function (day) {
                        day.activity = _.find(dailyActivities, function (activity) {
                                return moment(activity.date).isSame(day.date, 'day');
                            }) || null;
                    });
                });
                vm.isCalendarLoading = false;
            }).catch(function (error) {
                $scope.$emit('activity:alert', error);
            });
        }

        function onReload() {
            buildCalendar();
            loadActivities();
        }

        function onLoadActivities() {
            var dailyActivitiesCopy = angular.copy(dailyActivities)
                .filter(function (activity) {
                    return moment(activity.date).month() === moment(vm.current).month();
                });
            var periodActivitiesCopy = angular.copy(periodActivities)
                .filter(function (activity) {
                    return moment(activity.date).month() === moment(vm.current).month();
                });
            vm.onLoadActivities({
                year: moment(vm.current).year(),
                month: moment(vm.current).month(),
                dailyActivities: dailyActivitiesCopy,
                periodActivities: periodActivitiesCopy
            });
        }
    }
})();