(function () {
    'use strict';

    angular
        .module('app.vmis')
        .controller('DetailsModalController', DetailsModalController);

    DetailsModalController.$inject = ['$state', '$modalInstance', 'details', 'authentication', '_'];

    function DetailsModalController($state, $modalInstance, details, authentication, _) {
        var vm = this;

        vm.notification = details;
        var invitationId = details.data.invitationId;
        var hash;

        vm.AcceptInvite = AcceptInvite;

        activate();

        function activate() {
            if(!angular.isDefined(details.notInvite)) {
                authentication.getInvites({invitationId: invitationId}).then(function (response) {
                    var invite = response.invitations[0];
                    vm.isAccepted = invite.status === 'Accepted';
                    hash = invite.hash;
                    vm.organizations = _.map(invite.responsibilities.volunteerOrganizations, function (organization) {
                        return {
                            id: organization.volunteerOrganizationId,
                            name: organization.volunteerOrganizationFullName
                        };
                    });
                    vm.communities = _.map(invite.responsibilities.communityAffiliations, function (communityAffiliation) {
                        return {
                            id: communityAffiliation.communityAffiliationId,
                            name: communityAffiliation.communityAffiliationName
                        };
                    });
                    return authentication.getCommunityInfo({communityAffiliationId: invite.communityAffiliationId});
                }).then(function (response) {
                    vm.community = {
                        id: response.communityAffiliations.communityAffiliationId,
                        name: response.communityAffiliations.communityAffiliationName
                    };
                }).catch(function (error) {
                    vm.formError = error;
                });
            }
        }

        function AcceptInvite() {
            return authentication.acceptInvite({
                hash: hash
            }).then(function () {
                $modalInstance.close();
                $state.go('vmis.inviteAccepted');
            }).catch(function (error) {
                vm.formError = error;
            });
        }
    }
})();