(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .controller('OpportunitiesFilterController', OpportunitiesFilterController);

    OpportunitiesFilterController.$inject = ['$modalInstance', 'authentication', 'filters'];

    function OpportunitiesFilterController($modalInstance, authentication, filters) {
        var vm = this;

        vm.isLoading = true;

        var dropDowns = [
            'volunteerorganizationgroup',
            'usstate',
            'stateabbrev',
            'country'
        ];

        vm.filters = angular.copy(filters);

        vm.communities = [];
        vm.dropDowns = {
            organizationGroups: [
                "All"
            ],
            states: [
                "All"
            ],
            countries: [
                "All"
            ]
        };

        vm.onSubmit = onSubmit;
        vm.onCancel = onCancel;
        vm.disableOrgDropDown = disableOrgDropDown;
        vm.onSelectCommunity = onSelectCommunity;
        vm.onSelectCountry = onSelectCountry;
        
        activate();
        
        function activate() {
            fillDropDowns(dropDowns);
            onSelectCountry(vm.filters.country);
        }

        function fillDropDowns(dropDowns) {
            vm.isLoading = true;
            authentication
                .getUiData(dropDowns)
                .then(function (response) {
                    response.volunteerorganizationgroup.forEach(function (orgGroup) {
                        vm.dropDowns.organizationGroups.push(orgGroup);
                    });
                    vm.dropDowns.states = vm.dropDowns.states.concat(authentication.createStateList(response.usstate,response.stateabbrev));
                    response.country.forEach(function (country) {
                        vm.dropDowns.countries.push(country);
                    });
                    if (!angular.isDefined(vm.filters.organizationGroup))
                        vm.filters.organizationGroup = vm.dropDowns.organizationGroups[0];
                    if (!angular.isDefined(vm.filters.state))
                        vm.filters.state = vm.dropDowns.states[0];
                    if (!angular.isDefined(vm.filters.country))
                        vm.filters.country = vm.dropDowns.countries[0];

                    authentication.getCommunities(
                        {
                            getAll: true,
                            isArchived: false
                        })
                        .then(function(comms) {
                            comms.communityAffiliations.forEach(function (comm) {
                                comm.organizations = [{
                                    organizationName: "All"
                                    //volunteerOrganizationId: -1
                                }];
                                vm.communities.push(comm);
                            });
                            // Use lack of ID as cue for this entry
                            vm.communities.unshift({
                                communityAffiliationName: "All",
                                //communityAffiliationId: -1
                                organizations: [{
                                    organizationName: "All"
                                    //volunteerOrganizationId: -1
                                }]
                            });

                            // If existing filter, set to those selections
                            if (angular.isDefined(vm.filters.community) &&
                                angular.isDefined(vm.filters.community.communityAffiliationId)) {
                                var foundComm = false;
                                for (var i = 0; i < vm.communities.length; i++) {
                                    if (vm.communities[i].communityAffiliationName === vm.filters.community.communityAffiliationName) {
                                        vm.filters.community = vm.communities[i];
                                        foundComm = true;
                                        break;
                                    }
                                }

                                if (!foundComm) {
                                    vm.formError = "Could not find selected filter community: " + vm.filters.community.communityAffiliationName;
                                    vm.isLoading = false;
                                }
                                else {
                                    if (angular.isDefined(vm.filters.organization) &&
                                        angular.isDefined(vm.filters.organization.volunteerOrganizationId)) {
                                        getOrgs(vm.filters.community.communityAffiliationId).then(function (orgs) {
                                            orgs.organizations.forEach(function (org) {
                                                // Populate organization list
                                                vm.filters.community.organizations.push(org);

                                                // Check if selected org
                                                if (org.organizationName === vm.filters.organization.organizationName) {
                                                    vm.filters.organization = org;
                                                }
                                            });

                                            vm.isLoading = false;
                                        }).catch(function (error) {
                                            vm.formError = error;
                                            vm.filters.organization = vm.filters.community.organizations[0];

                                            vm.isLoading = false;
                                        });
                                    }
                                    else {
                                        onSelectCommunity(); // Fill in organizations and select first one ("All")
                                        vm.isLoading = false;
                                    }
                                }
                            }
                            else {
                                vm.filters.community = vm.communities[0];
                                vm.filters.organization = vm.filters.community.organizations[0];

                                vm.isLoading = false;
                            }
                        });
                })
                .catch(function (error) {
                    vm.formError = error;
                });
        }

        function disableOrgDropDown() {
            return vm.isLoading || !angular.isDefined(vm.filters.community) || !angular.isDefined(vm.filters.community.communityAffiliationId);
        }
        
        function onSelectCountry(country) {
            vm.isStateDisabled = country !== 'United States';
            if (vm.isStateDisabled)
                vm.filters.state = vm.dropDowns.states[0];
        }
        
        function onSubmit() {
            if (!angular.isDefined(vm.filters.community.communityAffiliationId))
                vm.filters.community = undefined;
            if (vm.filters.organizationGroup === "All")
                vm.filters.organizationGroup = undefined;
            if (!angular.isDefined(vm.filters.organization.volunteerOrganizationId))
                vm.filters.organization = undefined;
            if (vm.filters.country === "All")
                vm.filters.country = undefined;
            if (vm.filters.state === "All")
                vm.filters.state = undefined;

            $modalInstance.close(vm.filters);
        }

        function onCancel() {
            $modalInstance.dismiss('cancel');
        }

        function onSelectCommunity() {
            if (angular.isDefined(vm.filters.community.communityAffiliationId)) {
                if (vm.filters.community.organizations.length === 1) {
                    getOrgs(vm.filters.community.communityAffiliationId)
                        .then(function (orgs) {
                            orgs.organizations.forEach(function (org) {
                                vm.filters.community.organizations.push(org);
                            });
                            vm.filters.organization = vm.filters.community.organizations[0];
                        });
                }
                else
                    vm.filters.organization = vm.filters.community.organizations[0];
            }
            else
                vm.filters.organization = vm.filters.community.organizations[0];
        }

        function getOrgs(commId) {
            return authentication.getOrganizations({
                communityAffiliationId: commId,
                getAll: true
            });
        }
    }
})();