(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .controller('PositionServiceDetailsModalController', PositionServiceDetailsModalController);

    PositionServiceDetailsModalController.$inject = ['$q', '$modalInstance', 'authentication', 'params', '_'];

    function PositionServiceDetailsModalController($q, $modalInstance, authentication, params, _) {
        var vm = this;
        var originalStatus;

        vm.save = save;
        vm.isReadonly = null;

        activate();

        function activate() {
            vm.isLoading = true;

            $q.all([
                getDropDowns(),
                getDetails(params.positionId, params.userId)
            ]).finally(function () {
                //vm.title = "Service Details - " + vm.position.title;
                vm.title = "Service Details";
                vm.isLoading = false;
            });
        }

        function getDropDowns() {
            return authentication.getUiData(['volunteerservicestatus']).then(function (response) {
                vm.serviceStatuses = params.userId ? response.volunteerservicestatus : _.slice(response.volunteerservicestatus, 0, 2);
            });
        }

        function getDetails(positionId, userId) {
            return $q.all({
                position: authentication.getOpportunityDetails({
                    volunteerOpportunityId: positionId
                })
            }).then(function (results) {
                var position = results.position;

                vm.position = {
                    community: position.communityAffiliation,
                    organization: position.volunteerOrganization,
                    title: position.title,
                    supervisors: [],
                    address: {
                        line1: position.address1,
                        line2: position.address2,
                        city: position.city,
                        state: position.usState,
                        postalCode: position.zipCode,
                        country: position.country
                    },
                    hours: params.hours
                };

                if (position.firstLineSupervisorName) {
                    vm.position.supervisors.push({
                        name: position.firstLineSupervisorName,
                        email: position.firstLineSupervisorEmail,
                        phone: position.firstLineSupervisorPhone
                    });
                }

                if (position.secondLineSupervisorName) {
                    vm.position.supervisors.push({
                        name: position.secondLineSupervisorName,
                        email: position.secondLineSupervisorEmail,
                        phone: position.secondLineSupervisorPhone
                    });
                }

                vm.service = {
                    status: params.status,
                    startDate: params.startDate,
                    endDate: params.endDate,
                    notes: null,
                    include: null
                };
                originalStatus = vm.service.status;
                vm.isReadonly = !userId && (vm.service.status !== 'Active');
            });
        }

        function save() {
            if (vm.service.status !== originalStatus) {
                return authentication.setOpportunityStatusForUser({
                    opportunityId: params.positionId,
                    volunteerServiceStatus: vm.service.status,
                    role: params.userId ? undefined : 'AVC Volunteer',
                    userId: params.userId || undefined
                }).then($modalInstance.close);
            } else {
                return $modalInstance.close();
            }
        }
    }
})();