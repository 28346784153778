/**
 * Created by kmorgan41 on 12/12/16.
 */
(function () {
    'use strict';

    angular
        .module('app')
        .controller('userController', userController);

    userController.$inject = ['authentication', '$scope'];

    function userController(authentication, $scope) {

        $scope.dropdownContents = [];

        $scope.loggedIn = authentication.isLoggedIn();

        if($scope.loggedIn) {
            authentication.getUserProfile()
                .then(function (response) {
                    $scope.firstName = response.aosUser.firstName;

                    $scope.userName = {
                        name: 'Username',
                        //value: 'some very very very long name'
                        value: $scope.firstName
                    };

                    $scope.logout = {
                        name: 'Logout',
                        value: 'Log Out'
                    };
                    $scope.dropdownContents.push($scope.userName, $scope.logout);
                    //console.log($scope.dropdownContents);

                    $scope.selectedItem = $scope.userName;

                    $scope.$watch("selectedItem", function (value) {
                        if ($scope.selectedItem.name == "Logout") {
                            //$scope.logout();
                            //console.log('log out');
                        }
                    });
                });
        }
    }
})();