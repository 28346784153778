(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .config(routes);

    routes.$inject = ['$stateProvider'];

    function routes($stateProvider) {
        $stateProvider
            .state('vmis.administrator.standardPositions', {
                url: '/standard-positions',
                templateUrl: 'app/components/administrator/standardPositions/standardPositions.view.html',
                controller: 'StandardPositionsController',
                controllerAs: 'vm',
                title: 'Standard Positions'
            });
    }
})();