(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .config(config);

    config.$inject = ['$stateProvider', '$urlRouterProvider'];

    function config($stateProvider, $urlRouterProvider) {
        $urlRouterProvider
            .when('/accept-invite', ['$window', '$location', '$state', 'authentication', function ($window, $location, $state, authentication) {
                var parameters = $location.search();
                if (!parameters.hasOwnProperty('key')) {
                    return false;
                }

                var regexp = /^[\da-z]{8}(-[\da-z]{4}){4}[\da-z]{8}$/;
                if (!regexp.test(parameters.key)) {
                    return false;
                }

                if (authentication.isLoggedIn()) {
                    return authentication.acceptInvite({hash: parameters.key}).then(function () {
                        $state.go('vmis.inviteAccepted');
                    }, function() {
                        $state.go('vmis.inviteNotFound');
                    });
                } else {
                    authentication.setRedirect('vmis.acceptInvite', {hash: parameters.key});
                    $state.go('vmis.logIn');
                    return true;
                }
            }]);

        $stateProvider.state('vmis.acceptInvite', {
            url: '/accept-invite?hash',
            templateUrl: 'app/components/administrator/invitations/acceptInvite.view.html',
            controller: 'AcceptInviteController',
            controllerAs: 'vm',
            title: 'Accept Invitation'
        }).state('vmis.inviteAccepted', {
            url: '/invite-accepted',
            templateUrl: 'app/components/administrator/invitations/inviteAccepted.view.html',
            title: 'Invitation Accepted'
        }).state('vmis.inviteNotFound', {
            url: '/invite-not-found',
            templateUrl: 'app/components/administrator/invitations/inviteNotFound.view.html',
            title: 'Invitation Not Found'
        });
    }
})();