(function () {
    'use strict';

    angular
        .module('app.vmis')
        .config(routes);

    routes.$inject = ['$stateProvider'];

    function routes($stateProvider) {
        $stateProvider
            .state('vmis.profile', {
                url: '/profile',
                templateUrl: 'app/components/profile/profile.view.html',
                controller: 'ProfileController',
                controllerAs: 'vm',
                title: 'Profile'
            })
            .state('vmis.profileEdit', {
                url: '/profile/{id:int}',
                templateUrl: 'app/components/profile/profile.view.html',
                controller: 'ProfileController',
                controllerAs: 'vm',
                title: 'Profile'
            })
            .state('vmis.profileNonUser', {
                url: '/profile/{email}',
                templateUrl: 'app/components/profile/profile.view.html',
                controller: 'ProfileController',
                controllerAs: 'vm',
                title: 'Profile'
            });
    }
})();
