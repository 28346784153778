(function () {
    'use strict';

    angular
        .module('app.vmis')
        .controller('LandingController', LandingController);

    LandingController.$inject = ['$scope', '$state', 'config'];

    function LandingController($scope, $state, config) {
        var vm = this;
        vm.websiteFamilyList = null;
        vm.showMenu = false;
        vm.logIn = logIn;
        vm.classList = [
            'afwp-home',
            'efmp-blue',
            'acs-green',
            'vmis-teal',
            'ims-red',
            'olms-orange',
            'vfrg-blue'];

        for (var website in vm.websiteFamilyList) {
            vm.websiteFamilyList[website].color = vm.classList[website];
        }

        // Close the dropdown menu if the user clicks outside of it
        window.onclick = function (event) {
            if (!$(event.target).hasClass("header-icon-menu")) {
                vm.showMenu = false;
                $scope.$apply();
            }
        };

        activate();

        function activate() {
            config.get().then(function (configData) {
                vm.websiteFamilyList = configData.afwpWebsiteFamilyList;
            });
        }

        function logIn() {
            $state.go('vmis.logIn');
        }
    }

})();