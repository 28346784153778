(function () {
    'use strict';

    angular
        .module('app')
        .directive('modal', modal);

    modal.$inject = [];

    function modal() {
        return {
            restrict: 'E',
            transclude: {
                body: '?modalBody',
                footer: '?modalFooter'
            },
            templateUrl: 'app/common/directives/modal/modal.template.html',
            controller: function() {},
            controllerAs: 'vm',
            bindToController: true,
            scope: {
                close: '<?',
                modalTitle: '@?'
            }
        };
    }
})();