(function () {
    'use strict';

    angular
        .module('app')
        .directive('loadMore', loadMore);

    loadMore.$inject = [];

    function loadMore() {
        return {
            restrict: 'E',
            templateUrl: 'app/common/directives/loadMore/loadMore.template.html',
            controller: function() {},
            controllerAs: 'vm',
            bindToController: true,
            scope: {
                isLoading: '<',
                loadStatus: '<',
                onLoadMore: '&',
                onLoadAll: '&'
            }
        };
    }
})();