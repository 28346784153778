(function () {
    'use strict';

    angular
        .module('app.vmis')
        .controller('IdleController', IdleController);

    IdleController.$inject = ['$scope', '$modalStack', 'config'];

    function IdleController($scope, $modalStack, config) {
        var vm = this;
        vm.countdown = null;

        $scope.$on('IdleWarn', function (e, countdown) {
            // follows after the IdleStart event, but includes a countdown until the user is considered timed out
            // the countdown arg is the number of seconds remaining until then.
            // you can change the title or display a warning dialog from here.
            // you can let them resume their session by calling Idle.watch()

            $scope.$apply(function () {
                vm.countdown = countdown;
            });
        });

        $scope.$on('IdleTimeout', function () {
            // the user has timed out (meaning idleDuration + timeout has passed without any activity)
            // this is where you'd log them

            return $modalStack.dismissAll();
        });

        activate();

        function activate() {
            config.get().then(function (configData) {
                vm.countdown = configData.idleConfig.timeout;
            });
        }
    }
}());