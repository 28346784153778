(function () {
    'use strict';

    angular
        .module('administrator.hours')
        .controller('ManageActivityController', ManageActivityController);

    ManageActivityController.$inject = ['$modalInstance', '$modal', '$timeout', 'authentication', 'utilsServices', 'moment', 'activity', 'rejectOnly', 'config'];

    // This modal is used by different pages that supply slightly different "activity" parameters.
    // These are the fields provided in one page that aren't in others:
    //   Hours:                     dateSubmitted, volunteer.email
    //   Service Log (Admin Mode):  position.$$hashKey

    function ManageActivityController($modalInstance, $modal, $timeout, authentication, utilsServices, moment, activity, rejectOnly, config) {
        var vm = this;

        vm.isLoading = true;
        vm.isDocumentUploading = false;

        vm.rejectOnly = rejectOnly;
        vm.activity = activity;

        vm.allowedFileTypesForUpload = null;
        vm.maxFileSizeInMegabytes = null;
        vm.formError = "";

        // This modal is used for both daily and period hours
        vm.isDaily = activity.type === 'Daily';

        // Flag for determining if creating new activity or editing existing one
        vm.isNew = !angular.isNumber(vm.activity.id);

        vm.isDeleted = vm.activity.status === 'Deleted';

        if (vm.rejectOnly)
            vm.title = "Reject ";
        else
            if (vm.isNew)
                vm.title = "Create ";
            else
                vm.title = "Manage ";

        if (vm.isDaily)
            vm.title += "Daily Hours - " + moment(vm.activity.date).format("MMMM DD, YYYY");
        else
            vm.title += "Period Hours - " + moment(vm.activity.date).format("MMMM YYYY");

        if (vm.rejectOnly) {
            vm.activity.position = {
                title: vm.activity.position
            };
        }

        // Only matters if activity is not new
        vm.submitterText = "";
        if (!vm.isNew) {
            if (vm.activity.createdByMasterUserId === vm.activity.volunteer.masterUserId)
                vm.submitterText += "Volunteer";
            else
                vm.submitterText += "Administrator";
        }

        if (vm.isNew) {
            vm.activity.hours = 0;
            vm.activity.reviewerNote = "";
            vm.activity.documents = [];
            vm.uploadedDocuments = [];
        }
        else {
            vm.uploadedDocuments = vm.activity.documents.length > 0 ? vm.activity.documents : [];
        }

        vm.originalHours = vm.activity.hours;
        vm.hoursEdited = false;

        vm.maxDocumentsUploaded = vm.uploadedDocuments.length === 3;
        vm.selectedDocuments = [];

        vm.onChangeHours = onChangeHours;
        vm.onClickCertify = onClickCertify;
        vm.onClickReject = onClickReject;
        vm.onClickDelete = onClickDelete;
        vm.onSelectDoc = onSelectDoc;
        vm.onDeleteUploadedDoc = onDeleteUploadedDoc;
        vm.onDeleteSelectedDoc = onDeleteSelectedDoc;

        activate();

        function activate() {
            config.get().then(function (configData) {
                vm.allowedFileTypesForUpload = configData.allowedFileTypesForUpload;
                vm.maxFileSizeInMegabytes = configData.maxFileSizeInMegabytes;
            });
            loadDropdowns();
        }

        function displayError(error) {
            if (_.isUndefined(error)) return;
            var errorStr = utilsServices.parseErrorString(error);

            vm.isLoading = false;

            if (errorStr === 'cancel' || errorStr === 'backdrop click' || errorStr === 'ok')
                return;
            if (errorStr)
                vm.formError = errorStr;
        }

        function loadDropdowns() {
            if (vm.isDaily)
                vm.maxHours = 24;
            else
                vm.maxHours = (moment(vm.activity.date).daysInMonth() * 8);

            vm.hrLabel = [];
            for (var i = 0; i <= vm.maxHours; i++)
                vm.hrLabel.push(i);

            vm.numHours = Math.floor(vm.activity.hours) || 0;
            vm.numMinutes = (vm.activity.hours - Math.floor(vm.activity.hours)) * 60 || 0;

            if (vm.numHours === 0)
                vm.minLabel = [15, 30, 45];
            else if (vm.numHours === 24)
                vm.minLabel = [0];
            else
                vm.minLabel = [0, 15, 30, 45];

            vm.isLoading = false;
        }

        function onChangeHours() {
            // If selected min hours in dropdown,
            // remove 0 and set minutes to something
            // because 0 hours, 0 minutes is not allowed
            if (vm.numHours === 0) {
                vm.minLabel = [15, 30, 45];
                if (vm.numMinutes === 0)
                    vm.numMinutes = vm.minLabel[0];
            }
            else if (vm.numHours === 24) {
                vm.minLabel = [0];
                if (vm.numMinutes !== 0)
                    vm.numMinutes = vm.minLabel[0];
            }
            // If left previous state,
            // fill back in that dropdown
            else if (vm.minLabel.length < 4) {
                vm.minLabel = [0, 15, 30, 45];
            }

            vm.activity.hours = (vm.numHours + (vm.numMinutes / 60));

            vm.hoursEdited = (vm.activity.hours !== vm.originalHours);
        }

        function onClickCertify() {
            vm.isLoading = true;
            if (vm.isNew)
                createActivity();
            else
                updateActivity('Certified');
        }

        function onClickReject() {
            vm.isLoading = true;
            if (!vm.isNew)
                updateActivity('Rejected');
            // Cannot reject new activity as admin
        }

        function onClickDelete() {
            $modal.open({
                templateUrl: 'app/components/volunteer/activityCalendar/deleteHours.view.html',
                controller: 'DeleteHoursController',
                controllerAs: 'vm',
                size: 'tiny',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    activity: function () {
                        var deleteActivity = angular.copy(vm.activity);
                        if (vm.hoursEdited)
                            deleteActivity.hours = vm.originalHours;
                        return deleteActivity;
                    },
                    adminEditing: function () {
                        return true;
                    }
                }
            }).result.then($modalInstance.close);
        }

        function createActivity() {
            var activityDate = moment(vm.activity.date).format("YYYY-MM-DD");
            var params = {
                volunteerOpportunityId: vm.activity.position.id,
                hours: {
                    dateEntered: new Date().toISOString(),
                    hours: vm.activity.hours,
                    userNotes: vm.activity.volunteerNote,
                    dayOfService: vm.isDaily ? activityDate : undefined,
                    periodOfService: vm.isDaily ? undefined : activityDate
                },
                setHoursAsOPOC: true,
                volunteerMasterUserId: vm.activity.volunteer.masterUserId
            };

            return authentication.setVolunteerHoursForOpportunity(params)
                .then(function (response) {
                    // If document to upload, do so!
                    if (vm.newUpload) {
                        vm.isDocumentUploading = true;

                        // If new
                        if (response.volunteerHoursId)
                            uploadDocuments(response.volunteerHoursId);
                        // If existing
                        else {
                            uploadDocuments(response.updatedHours[0].volunteerHoursId);
                        }
                    }
                    else {
                        response.action = 'add';
                        $modalInstance.close(response);
                    }
                })
                .catch($modalInstance.dismiss);
        }

        function updateActivity(status) {
            var params = {
                volunteerHoursStatus: status,
                hoursIds: [vm.activity.id],
                reviewerNotes: vm.activity.reviewerNote
            };

            // If hours were edited, include that
            if (vm.hoursEdited)
                params.hours = vm.activity.hours;

            return authentication.manageVolunteerHours(params)
                .then(function (response) {
                    // If document to upload, do so!
                    if (vm.newUpload) {
                        vm.isDocumentUploading = true;

                        // If new
                        if (response.volunteerHoursId)
                            uploadDocuments(response.volunteerHoursId);
                        // If existing
                        else {
                            uploadDocuments(response.updatedHours[0].volunteerHoursId);
                        }
                    }
                    else {
                        response.action = 'edit';
                        $modalInstance.close(response);
                    }
                })
                .catch($modalInstance.dismiss);
        }

        // Occurs after user submits, so close when completed
        function uploadDocuments(hoursId) {
            var numUploadsDone = 0;
            angular.forEach(vm.selectedDocuments, function (document) {
                var uploadDocumentParams = {
                    theFile: document,
                    fileSuffix: document.name.split(".").pop(),
                    documentName: document.name, // TODO: Allow user to title the upload
                    volunteerHoursId: hoursId
                };

                // TODO: Ideally would like to not create function in a loop,
                // but no known way to pass in "document" and the response
                authentication.uploadHoursDocument(uploadDocumentParams)
                    .then(function (response) {
                        uploadHoursSuccess(response, document);
                    })
                    .catch(function (error) {
                        uploadHoursFailure(error, document);
                    });
            });

            function uploadHoursSuccess(response, document) {
                document.uploaded = true; // Show check mark

                if (++numUploadsDone === vm.selectedDocuments.length) {
                    // Delay slightly to show final check mark
                    $timeout(function () {
                        $modalInstance.close("Document successfully uploaded!");
                    }, 1000);
                }
            }

            function uploadHoursFailure(error, document) {
                var errorStr = error.message ? error.message : error;

                if (++numUploadsDone === vm.selectedDocuments.length) {
                    $timeout(function () {
                        $modalInstance.dismiss("Document upload failed: " + errorStr);
                    }, 1000);
                }
            }
        }

        function onDeleteUploadedDoc(document) {
            var deleteDocumentParams = {
                hoursDocumentId: document.hoursDocumentId
            };

            return authentication.deleteHoursDocument(deleteDocumentParams)
                .then(function () {
                    vm.uploadedDocuments.splice(vm.uploadedDocuments.indexOf(document), 1);
                    updateMaxDocumentsUploaded();
                })
                .catch(function (error) {
                    var errorStr = error.error ? error.error : error;
                    $modalInstance.dismiss(errorStr);
                });
        }

        function onDeleteSelectedDoc(document) {
            vm.selectedDocuments.splice(vm.selectedDocuments.indexOf(document), 1);
            updateMaxDocumentsUploaded();
        }

        // This function ensures that only 3 total documents are uploaded or selected to be uploaded at once
        function onSelectDoc(documents, errDocuments) {
            if (errDocuments.length > 0) {
                vm.formError = "Wrong file type or file size is larger than " + vm.maxFileSizeInMegabytes + ".";
                displayError(vm.formError);
                $('#activityModal')[0].scrollIntoView();
                updateNewUpload();
                return;
            } else {
                vm.formError = "";
            }

            var numDocuments = documents.length + vm.selectedDocuments.length + vm.uploadedDocuments.length;
            if (documents.length === 0) {
                // Update flag for whether new documents to upload were selected
                updateNewUpload();
                return;
            }
            else if (numDocuments > 3) {
                updateNewUpload();
                return;
            }
            else if (numDocuments === 3) {
                vm.maxDocumentsUploaded = true;
            }

            vm.selectedDocuments = vm.selectedDocuments.concat(documents);
            //vm.errDocuments = errDocuments && errDocuments[0];

            updateNewUpload();
        }

        function updateMaxDocumentsUploaded() {
            vm.maxDocumentsUploaded = ((vm.selectedDocuments.length + vm.uploadedDocuments.length) === 3);
        }

        function updateNewUpload() {
            vm.newUpload = (vm.selectedDocuments.length > 0);
        }
    }
})();
