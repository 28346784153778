(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .controller('ApplyController', ApplyController);

    ApplyController.$inject = ['$state', '$stateParams', '$modal', '$timeout', 'opportunitiesService'];

    function ApplyController($state, $stateParams, $modal, $timeout, opportunitiesService) {
        var vm = this;
        vm.isLoading = true;

        var dropDowns = [
            'preferredcontactmethod'
        ];

        vm.opportunityId = $stateParams.opportunityId;
        vm.alerts = [];

        // Variables for tracking the loading of the page's data
        // for setting vm.isLoading to false.
        var numSetupPromises = 3; // For the 3 activate() functions
        var curNumPromises = 0;

        vm.onSubmit = onSubmit;
        vm.onCloseAlert = onCloseAlert;

        activate();

        function activate() {
            fillDropDowns();
            getOpportunityDetails();
            getProfileInformation();
        }

        function tallyPromise() {
            if (++curNumPromises === numSetupPromises)
                vm.isLoading = false;
        }

        function fillDropDowns() {
            opportunitiesService
                .getDropDownData(dropDowns)
                .then(function (result) {
                    dropDowns.forEach(function (element) {
                        vm[element] = result[element];
                    });
                    tallyPromise();
                });
        }

        function getOpportunityDetails() {
            opportunitiesService
                .getOpportunityDetails(vm.opportunityId)
                .then(function (result) {
                    vm.position = {
                        title: result.title,
                        organization: result.volunteerOrganization,
                        community: result.communityAffiliation,
                        backgroundCheckRequired: result.isBackgroundCheckRequired ? 'Yes' : 'No'
                    };
                    tallyPromise();
                });

        }

        function getProfileInformation() {
            opportunitiesService
                .getUserProfile()
                .then(function (response) {
                    var aosUser = response.aosUser;
                    if (aosUser !== null) {
                        vm.aosProfile = {
                            firstName: aosUser.firstName,
                            lastName: aosUser.lastName,
                            community: aosUser.communityAffiliation
                        };
                        if (aosUser.vmisUser !== null) {
                            var volunteerProfile = aosUser.vmisUser.volunteerProfile;
                            vm.volunteerProfile = {
                                preferredContactMethod: volunteerProfile.preferredContactMethod,
                                volunteerEmail: volunteerProfile.volunteerEmail,
                                primaryPhone: volunteerProfile.primaryPhone,
                                secondaryPhone: volunteerProfile.secondaryPhone || '',
                                workExperience: volunteerProfile.workExperience || '',
                                volunteerExperience: volunteerProfile.volunteerExperience || '',
                                specialSIH: volunteerProfile.specialSIH || ''
                            };
                        } else {
                            vm.volunteerProfile = {
                                volunteerEmail: response.masterUserEmail,
                                secondaryPhone: '',
                                workExperience: '',
                                volunteerExperience: '',
                                specialSIH: ''
                            };
                        }
                    }
                    tallyPromise();
                });
        }

        function onSubmit() {
            vm.isLoading = true;
            return setVolunteerProfile()
                .then(applyForOpportunity)
                .catch(function (error) {
                    displayError(error);
                    vm.isLoading = false;
                })
                .then(confirmApplication)
                .catch(function (error) {
                    displayError(error);
                    vm.isLoading = false;
                });
        }

        function setVolunteerProfile() {
            return opportunitiesService.setVolunteerProfile(vm.volunteerProfile);
        }

        function applyForOpportunity() {
            return opportunitiesService.applyForOpportunity(vm.opportunityId);
        }

        function confirmApplication() {
            if (vm.isLoading) {
                var modalInstance = $modal.open({
                    templateUrl: 'app/components/volunteer/opportunities/applicationConfirmation.template.html',
                    controller: 'ApplicationConfirmationController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    keyboard: false,
                    
                    resolve: {
                        name: function () {
                            return vm.aosProfile.firstName;
                        },
                        positionTitle: function () {
                            return vm.position.title;
                        }
                    },
                    size: 'tiny'
                });

                modalInstance.result
                    .then(function () {
                        redirectAfterApply();
                    }, function () {
                        redirectAfterApply();
                    });
            }

            // Whether the resulting modal after applying is exited with "OK", "X", or Escape,
            // redirect back to the opportunities page. Delay that process slightly to prevent console errors.
            function redirectAfterApply()
            {
                return $timeout(function () {
                    $state.go('^', null, {reload: true});
                    vm.isLoading = false;
                }, 100);
            }
        }

        function successHandler(event, message) {
            event.stopPropagation();

            displayAlert('success', message);
        }

        function onCloseAlert(index) {
            vm.alerts.splice(index, 1);
        }

        function displayAlert(type, message) {
            vm.alerts.push({
                type: type,
                message: message
            });
        }

        function displayError(error) {
            if (error === 'cancel' || error === 'ok' || error === 'backdrop click')
                return;
            if (error)
                displayAlert('alert', error);
        }
    }
})();