(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('MassEmailController', MassEmailController);

    MassEmailController.$inject = ['$modalInstance', 'authentication', 'utilsServices', 'params', 'config', '$timeout', '$q'];

    function MassEmailController($modalInstance, authentication, utilsServices, params, config, $timeout, $q) {
        var vm = this;

        vm.isLoading = true;
        vm.maxFilesUploaded = false;
        vm.totalSizeOfFilesSoFar = 0;
        vm.maxNumberOfFiles = 3;
        vm.isFileUploading = false;
        vm.newUpload = false;
        vm.selectedFiles = [];
        vm.docsToBeSent = [];
        vm.allowedFileTypesForUpload = null;
        vm.maxFileSizeInMegabytes = null;
        vm.UUID = null;
        vm.formError = "";

        vm.onSend = onSend;
        vm.onSelectFile = onSelectFile;
        vm.onDeleteSelectedDoc = onDeleteSelectedDoc;

        activate();

        function activate() {
            // Filter list to non-null/undefined and no duplicates
            vm.emails = params.emails.filter(function (email) {
                return email != null;
            }).filter(function (email, index) {
                return params.emails.indexOf(email) === index;
            });

            if (vm.emails.length < params.emails.length) {
                console.warn("Note: " + (params.emails.length - vm.emails.length) +
                    " emails were either missing or duplicates.");
            }

            vm.UUID = generateUUID();
            config.get().then(function (configData) {
                vm.allowedFileTypesForUpload = configData.allowedFileTypesForUpload;
                // This is an int, not a string like similar usages
                vm.maxFileSizeInMegabytes = configData.maxAttachmentSizeInMegabytes + "MB";
            });
            vm.isLoading = false;
        }

        function onSend() {
            vm.isLoading = true;
            vm.isFileUploading = true;

            var formattedBody = vm.body.replace(/(?:\r\n|\r|\n)/g, '<br>');

            uploadDocuments(vm.UUID).then(function(result){
                return authentication.sendEmailToList({
                    emails: vm.emails,
                    subject: vm.subject,
                    body: formattedBody,
                    sentEmailUUID: vm.UUID
                })
                    .then(function (response) {
                        close('success', vm.subject);
                    })
                    .catch(function (error) {
                        close('error', error);
                    });
             });
        }

        function onSelectFile(files, errFiles) {

            if (errFiles.length > 0) {
                vm.formError = "Wrong file type or file size is larger than " + vm.maxFileSizeInMegabytes + ".";
                displayError(vm.formError);
                updateMaxDocumentsUploaded();
                return;
            } else {
                vm.formError = "";
            }
            
            var numDocuments = files.length + vm.selectedFiles.length;

            if (files.length === 0) {
                // Update flag for whether new documents to upload were selected
                return;
            }
            else if (numDocuments > vm.maxNumberOfFiles) {
                return;
            }
            else {
                vm.selectedFiles = vm.selectedFiles.concat(files);
                updateMaxDocumentsUploaded();
            }
        }

        function onDeleteSelectedDoc(document) {

            vm.selectedFiles.splice(vm.selectedFiles.indexOf(document), 1);

            updateMaxDocumentsUploaded();
        }

        function updateMaxDocumentsUploaded() {
            vm.maxFilesUploaded = ((vm.selectedFiles.length) === vm.maxNumberOfFiles);
        }

        function uploadDocuments(sentEmailUUID) {
            // This code is derived from the hours documentation
            var numUploadsDone = 0;
            var uploadDocs = [];
            angular.forEach(vm.selectedFiles, function (document) {
                var uploadDocumentParams = {
                    theFile: document,
                    fileSuffix: document.name.split(".").pop(),
                    documentName: document.name,
                    sentEmailUUID: sentEmailUUID
                };

                // TODO: Ideally would like to not create function in a loop,
                // but no known way to pass in "document" and the response
                uploadDocs.push(authentication.uploadMassEmailAttachment(uploadDocumentParams)
                    .then(function (response) {
                        uploadAttachmentSuccess(response, document);
                    })
                    .catch(function (error) {
                        uploadAttachmentFailure(error, document);
                    }));
            });

            return $q.all(uploadDocs)
                .then(function(_){
                    return $q.when();
                });


            function uploadAttachmentSuccess(response, document) {
                document.uploaded = true; // Show check mark
            }

            function uploadAttachmentFailure(error, document) {
                if (++numUploadsDone === vm.selectedFiles.length) {
                    $timeout(function () {
                        throw ("Document upload failed: " + utilsServices.parseErrorString(error));
                    }, 1000);
                }
            }
        }

        function displayError(error) {
            if (_.isUndefined(error)) return;
            var errorStr = utilsServices.parseErrorString(error);

            vm.isLoading = false;

            if (errorStr === 'cancel' || errorStr === 'backdrop click' || errorStr === 'ok')
                return;
            if (errorStr)
                vm.formError = errorStr;
        }

        function generateUUID() {
            var d = new Date().getTime();
            var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                var r = (d + Math.random()*16)%16 | 0;
                d = Math.floor(d/16);
                return (c=='x' ? r : (r&0x3|0x8)).toString(16);
            });
            return uuid;
        }

        function close(action, msg) {
            $modalInstance.close({
                action: action,
                message: msg
            });
        }
    }
})();