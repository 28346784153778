(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('StandardPositionDeleteController', StandardPositionDeleteController);

    StandardPositionDeleteController.$inject = ['standardPositionName'];

    function StandardPositionDeleteController(standardPositionName) {
        var vm = this;

        vm.standardPositionName = standardPositionName;
    }
})();