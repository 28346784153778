(function () {
    'use strict';

    angular
        .module('vmis.activityCalendar')
        .controller('EnterHoursController', EnterHoursController);

    EnterHoursController.$inject = ['$modalInstance', '$timeout', '$modal', 'authentication', 'moment', 'activity', 'positionId', 'volunteerId', 'readOnly', 'config', 'utilsServices'];

    // This controller should only be accessed by volunteers, not admins
    function EnterHoursController($modalInstance, $timeout, $modal, authentication, moment, activity, positionId, volunteerId, readOnly, config, utilsServices) {
        var vm = this;

        vm.isModalLoading = true;
        vm.allowedFileTypesForUpload = null;
        vm.maxFileSizeInMegabytes = null;

        vm.activity = activity;
        // This modal is used for both daily and period hours
        vm.isDaily = vm.activity.type === 'Daily';
        vm.isDocumentUploading = false;
        vm.disableButton = true;
        vm.maxHours = vm.isDaily ? 24 : (moment(vm.activity.date).daysInMonth() * 8);
        vm.hrLabel = [];

        vm.title = "Enter ";
        if (vm.isDaily)
            vm.title += "Daily Hours - " + moment(vm.activity.date).format("D MMMM YYYY");
        else
            vm.title += "Period Hours - " + moment(vm.activity.date).format("MMMM YYYY");

        vm.hours = Math.floor(vm.activity.hours) || 0;
        vm.minutes = (vm.activity.hours - vm.hours) * 60 || 0;

        if (vm.hours === 0)
            vm.minLabel = [15, 30, 45];
        else if (vm.hours === 24)
            vm.minLabel = [0];
        else
            vm.minLabel = [0, 15, 30, 45];

        vm.status = vm.activity.status;
        vm.note = vm.activity.volunteerNote || '';
        vm.reviewerNote = vm.activity.reviewerNote || '';
        vm.certified = (vm.activity.status === 'Certified');
        vm.readOnly = readOnly || vm.certified;
        vm.positionId = positionId;
        vm.volunteerId = volunteerId;
        vm.hoursId = vm.activity.id || null; // Existence of hoursId is flag for editing existing activity
        vm.newUpload = false;

        vm.uploadedDocuments = vm.activity.documents ? vm.activity.documents : [];
        vm.maxDocumentsUploaded = vm.uploadedDocuments.length === 3;
        vm.selectedDocuments = [];

        // Only matters if activity is not new
        vm.submitterText = "";
        if (vm.hoursId) {
            if (vm.activity.createdByMasterUserId === vm.volunteerId)
                vm.submitterText += "Volunteer";
            else
                vm.submitterText += "Administrator";
        }

        vm.onChangeHours = onChangeHours;
        vm.onSelectDoc = onSelectDoc;
        vm.onDeleteUploadedDoc = onDeleteUploadedDoc;
        vm.onDeleteSelectedDoc = onDeleteSelectedDoc;
        vm.onSubmit = onSubmit;
        vm.isDeletable = isDeletable;
        vm.onClickDelete = onClickDelete;

        activate();

        function activate() {
            config.get().then(function (configData) {
                vm.allowedFileTypesForUpload = configData.allowedFileTypesForUpload;
                vm.maxFileSizeInMegabytes = configData.maxFileSizeInMegabytes;
            });
            for (var i = 0; i <= vm.maxHours; i++)
                vm.hrLabel.push(i);

            vm.isModalLoading = false;
        }

        function onChangeHours() {
            // If selected min hours in dropdown,
            // remove 0 and set minutes to something
            // because 0 hours, 0 minutes is not allowed
            if (vm.hours === 0) {
                vm.minLabel = [15, 30, 45];
                if (vm.minutes === 0)
                    vm.minutes = vm.minLabel[0];
            }
            else if (vm.hours === 24) {
                vm.minLabel = [0];
                if (vm.minutes !== 0)
                    vm.minutes = vm.minLabel[0];
            }
            // If left previous state,
            // fill back in that dropdown
            else if (vm.minLabel.length < 4) {
                vm.minLabel = [0, 15, 30, 45];
            }
        }

        function onSubmit() {
            vm.isModalLoading = true;

            var params = {
                volunteerOpportunityId: vm.positionId,
                hours: {
                    dateEntered: new Date().toISOString(),
                    hours: (vm.hours + (vm.minutes / 60)),
                    userNotes: vm.note
                }
            };

            var activityDate = moment(vm.activity.date).format("YYYY-MM-DD");
            if (vm.isDaily)
                params.hours.dayOfService = activityDate;
            else
                params.hours.periodOfService = activityDate;

            return authentication.setVolunteerHoursForOpportunity(params)
                .then(function (response) {
                    // If document to upload, do so!
                    if (vm.newUpload) {
                        vm.isDocumentUploading = true;
                        uploadDocuments(response.volunteerHoursId);
                    }
                    else {
                        $modalInstance.close("Activity submitted!");
                    }
                })
                .catch(function (error) {
                    $modalInstance.dismiss(utilsServices.parseErrorString(error));
                });
        }

        // Occurs after user clicks "Submit", so close when completed
        function uploadDocuments(hoursId) {
            var numUploadsDone = 0;
            angular.forEach(vm.selectedDocuments, function (document) {
                var uploadDocumentParams = {
                    theFile: document,
                    fileSuffix: document.name.split(".").pop(),
                    documentName: document.name, // TODO: Allow user to title the upload
                    volunteerHoursId: hoursId
                };

                // TODO: Ideally would like to not create function in a loop,
                // but no known way to pass in "document" and the response
                authentication.uploadHoursDocument(uploadDocumentParams)
                    .then(function (response) {
                        uploadHoursSuccess(response, document);
                    })
                    .catch(function (error) {
                        uploadHoursFailure(error, document);
                    });
            });

            function uploadHoursSuccess(response, document) {
                document.uploaded = true; // Show check mark

                if (++numUploadsDone === vm.selectedDocuments.length) {
                    // Delay slightly to show final check mark
                    $timeout(function () {
                        $modalInstance.close("Document successfully uploaded!");
                    }, 1000);
                }
            }

            function uploadHoursFailure(error, document) {
                if (++numUploadsDone === vm.selectedDocuments.length) {
                    $timeout(function () {
                        $modalInstance.dismiss("Document upload failed: " + utilsServices.parseErrorString(error));
                    }, 1000);
                }
            }
        }

        function onDeleteUploadedDoc(document) {
        
            $modal.open({
                templateUrl: 'app/components/volunteer/activityCalendar/deleteHoursDocument.view.html',
                controller: 'DeleteHoursDocumentController',
                controllerAs: 'vm',
                size: 'tiny',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    document: function () {
                        return document ? angular.copy(document) : {};
                    }
                }
            })
            .result
                .then(function(response) {
                    vm.uploadedDocuments.splice(vm.uploadedDocuments.indexOf(document), 1);
                    updateMaxDocumentsUploaded();
                })
                .catch(function (error) {
                $modalInstance.dismiss("Document delete failed: " + utilsServices.parseErrorString(error));
                });
                
        }

        function onDeleteSelectedDoc(document) {
            vm.selectedDocuments.splice(vm.selectedDocuments.indexOf(document), 1);
            updateMaxDocumentsUploaded();
        }

        // This function ensures that only 3 total documents are uploaded or selected to be uploaded at once
        function onSelectDoc(documents, errDocuments) {
            var numDocuments = documents.length + vm.selectedDocuments.length + vm.uploadedDocuments.length;
            if (errDocuments.length > 0) {
                vm.formError = "File size is larger than " + vm.maxFileSizeInMegabytes + ".";
                $('#hoursModal')[0].scrollIntoView();
                updateNewUpload();
                return;
            } else {
                vm.formError = "";
            }
            if (documents.length === 0) {
                // Update flag for whether new documents to upload were selected
                updateNewUpload();
                return;
            }
            else if (numDocuments > 3) {
                updateNewUpload();
                return;
            }
            else if (numDocuments === 3) {
                vm.maxDocumentsUploaded = true;
            }

            vm.selectedDocuments = vm.selectedDocuments.concat(documents);
            //vm.errDocuments = errDocuments && errDocuments[0];

            updateNewUpload();
        }

        function isDeletable() {
            return !vm.activity.flaggedForDelete &&
                (vm.activity.status === 'Submitted' ||
                    vm.activity.status === 'Rejected' ||
                    vm.activity.status === 'Resubmitted');
        }

        function onClickDelete() {
            $modal.open({
                templateUrl: 'app/components/volunteer/activityCalendar/deleteHours.view.html',
                controller: 'DeleteHoursController',
                controllerAs: 'vm',
                size: 'tiny',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    activity: function () {
                        return vm.activity;
                    },
                    adminEditing: function () {
                        return false;
                    }
                }
            }).result
                .then($modalInstance.close);
        }

        function updateMaxDocumentsUploaded() {
            vm.maxDocumentsUploaded = ((vm.selectedDocuments.length + vm.uploadedDocuments.length) === 3);
        }

        function updateNewUpload() {
            vm.newUpload = (vm.selectedDocuments.length > 0);
        }
    }
})();
