(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .config(routes);

    routes.$inject = ['$stateProvider'];

    function routes($stateProvider) {
        $stateProvider
            .state('vmis.administrator.mergeAccounts', {
                url: '/merge-accounts',
                controller: 'MergeAccountsController',
                controllerAs: 'vm',
                templateUrl: 'app/components/administrator/mergeAccounts/mergeAccounts.view.html',
                title: 'Merge Accounts'
            });
    }
})();