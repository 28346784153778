(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .controller('CreateNonAvcPositionController', CreateNonAvcPositionController);

    CreateNonAvcPositionController.$inject = ['$state', '$modal', '$q', '$anchorScroll', 'authentication'];

    function CreateNonAvcPositionController($state, $modal, $q, $anchorScroll, authentication) {
        var vm = this;

        vm.isLoading = true;

        var firstName;

        vm.dropDowns = {
            booleanOptions: [{
                label: 'No',
                value: false
            }, {
                label: 'Yes',
                value: true
            }]
        };
        vm.position = {
            backgroundCheckRequired: false
        };

        vm.onSubmit = onSubmit;
        vm.onChangeCountry = onChangeCountry;

        activate();

        function activate() {
            var promiseArray = [];
            promiseArray.push(authentication.getUserProfile()
                .then(function (response) {
                    vm.position.communityId = response.aosUser.communityAffiliationId;
                    firstName = response.aosUser.firstName;
                }));
            promiseArray.push(getDropDownData());

            return $q.all(promiseArray)
                .then(function (responses) {
                    vm.isLoading = false;
                })
                .catch(function (error) {
                    if (error)
                        vm.formError = "Error: " + error.error ? error.error : error;
                    vm.isLoading = false;
                });
        }

        function getDropDownData() {
            var elements = [
                'usstate',
                'stateabbrev',
                'country'
            ];

            return authentication.getUiData(elements).then(function (response) {
                vm.dropDowns.states = authentication.createStateList(response.usstate,response.stateabbrev);
                vm.dropDowns.countries = response.country;
            });
        }

        function confirm() {
            var options = {
                templateUrl: 'app/components/volunteer/opportunities/applicationConfirmation.template.html',
                controller: 'ApplicationConfirmationController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    name: function () {
                        return firstName;
                    },
                    positionTitle: function () {
                        return vm.position.title;
                    }
                },
                size: 'tiny'
            };

            return $modal.open(options).result.then(function () {
                $state.go('vmis.volunteer.applications', null, { reload: true });
                vm.isLoading = false;
            }, function () {
                $state.go('vmis.volunteer.applications', null, { reload: true });
                vm.isLoading = false;
            });
        }

        function setPosition() {
            if(angular.isDefined(vm.position.address.state) && vm.position.address.state !== null){
                var testString = vm.position.address.state.substring(vm.position.address.state.length-5);

                if(testString.substring(0,2) === ' (' && testString.substring(testString.length-1) === ')'){
                    vm.position.address.state = vm.position.address.state.substring(0,vm.position.address.state.length-5);
                }
            }

            var params = {
                communityAffiliationId: vm.position.communityId,
                title: vm.position.title,
                summary: vm.position.organizationName,
                isBackgroundCheckRequired: vm.position.backgroundCheckRequired,
                duties: vm.position.duties,
                qualifications: vm.position.qualifications,
                training: vm.position.training,
                orientation: vm.position.orientation,
                timeRequired: vm.position.timeRequired,
                opportunityApplicationComment: vm.position.comment,
                country: vm.position.address.country,
                address1: vm.position.address.line1,
                address2: vm.position.address.line2,
                city: vm.position.address.city,
                usState: vm.position.address.state,
                zipCode: vm.position.address.zipCode,
                firstLineSupervisorName: vm.position.supervisor.name,
                firstLineSupervisorEmail: vm.position.supervisor.email,
                firstLineSupervisorPhone: vm.position.supervisor.phone,
                role: 'AVC Volunteer'
            };
            return authentication.setOpportunity(params);
        }

        function onSubmit() {
            vm.isLoading = true;
            setPosition()
                .then(confirm)
                .catch(function (error) {
                    if (error)
                        vm.formError = "Error: " + error.error ? error.error : error;
                    vm.isLoading = false;
                })
                .finally(function () {
                    $anchorScroll('anchor-top');
                });
        }

        function onChangeCountry(country) {
            vm.isStateRequired = country === 'United States';
            if (!vm.isStateRequired)
                vm.position.address.state = undefined;
        }
    }
})();