(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .controller('AwardsController', AwardsController);

    AwardsController.$inject = ['$scope', '$modal', '$stateParams', 'authentication', 'adminInfo', 'moment'];

    function AwardsController($scope, $modal, $stateParams, authentication, adminInfo, moment) {
        var vm = this;

        vm.isLoading = true;
        vm.sortColumn = 'date';
        vm.sortReverse = true;

        var index = 0;
        var limit = 100;

        vm.awards = [];
        vm.masterUserId = parseInt($stateParams.id) || null;
        // Use existence of adminMasterUserId (not undefined) as the official check
        // of whether admin is editing another user
        vm.adminEditing = angular.isNumber(adminInfo.adminMasterUserId);

        vm.onClickDetails = onClickDetails;
        vm.onClickAddAward = onClickAddAward;
        vm.canUserEditAward = canUserEditAward;

        activate();

        function activate() {
            refreshAwards();
        }

        function getAwards(id) {
            vm.isLoading = true;

            var params = {
                startingRowIndex: index,
                numberOfRows: limit
            };

            if (angular.isNumber(id)) {
                params.masterUserId = id;
            }

            return authentication.getVolunteerAwards(params)
                .then(function (response) {
                    vm.awards = response.result.map(function (award) {
                        return {
                            id: award.volunteerAwardId,
                            date: moment(award.awardDate, 'YYYY-MM-DD').toDate(),
                            name: award.awardName,
                            certified: award.isCertified,
                            description: award.awardDetails,
                            // Note: API currently designed for 1 attachment
                            attachments: award.atoAttachmentId ?
                                [{
                                    id: award.atoAttachmentId,
                                    url: award.fileUrl,
                                    name: award.documentName,
                                    description: award.documentDescription
                                }] : []
                        };
                    });

                    vm.isLoading = false;
                })
                .catch(function (error) {
                    error = error.error ? error.error : error;
                    $scope.$emit('records:alert', error);
                    vm.isLoading = false;
                });
        }

        function openDetailsModal(award, id) {
            var options = {
                templateUrl: 'app/components/volunteer/records/awards/details/view.html',
                controller: 'AwardDetailsController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    award: function () {
                        return award ? angular.copy(award) : {};
                    },
                    id: function () {
                        return id ? angular.copy(id) : null;
                    },
                    // If award exists, editing and must check further;
                    // if not, adding and not view only
                    viewOnly: function () {
                        return award ? !canUserEditAward(award) : false;
                    }
                }
            };
            $modal.open(options)
                .result
                .then(function (result) {
                    vm.isLoading = true;

                    var verb = "";
                    switch (result.action) {
                        case 'add':
                            verb = "added";
                            break;
                        case 'edit':
                            verb = "updated";
                            break;
                        case 'delete':
                            verb = "deleted";
                            break;
                        default:
                            break;
                    }

                    var msg = "";
                    if (verb)
                        msg = "Award successfully " + verb + "!";
                    else
                        msg = "Unrecognized action!";
                    $scope.$emit('records:success', msg);
                })
                .catch(function (error) {
                    // If modal is left by pressing "Escape", error is undefined.
                    if (error) {
                        error = error.error ? error.error : error;
                        $scope.$emit('records:alert', error);
                    }
                })
                .finally(function () {
                    refreshAwards();
                });
        }

        function onClickDetails(award) {
            if (vm.adminEditing)
                openDetailsModal(award, vm.masterUserId);
            else
                openDetailsModal(award);
        }

        function onClickAddAward() {
            if (vm.adminEditing)
                openDetailsModal(null, vm.masterUserId);
            else
                openDetailsModal();
        }

        function refreshAwards() {
            if (vm.adminEditing)
                getAwards(vm.masterUserId);
            else
                getAwards();
        }

        // If award is uncertified, it can be edited by user themselves
        function canUserEditAward(award) {
            return vm.adminEditing || !award.certified;
        }
    }
})();