(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .config(routes);

    routes.$inject = ['$stateProvider'];

    function routes($stateProvider) {
        $stateProvider
            .state('vmis.administrator.organizationGroups', {
                url: '/organization-groups',
                templateUrl: 'app/components/administrator/organizationGroups/organizationGroups.view.html',
                controller: 'OrganizationGroupsController',
                controllerAs: 'vm',
                title: 'Organization Groups'
            });
    }
})();