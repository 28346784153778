/**
 * Created by nbhadsavle3 on 11/3/2017.
 */

(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('MergeCompletedController', MergeCompletedController);

    MergeCompletedController.$inject = ['$modalInstance', 'title', 'results', '$modal'];

    function MergeCompletedController($modalInstance, title, results, $modal) {
        var vm = this;
        
        vm.title = title;
        vm.hoursLost = 0;
        vm.applicationsLost = 0;
        vm.applicationsUpdated = 0;
        
        vm.applications = 0;
        vm.awards = 0;
        vm.backgroundChecks = 0;
        vm.hours = 0;
        vm.notes = 0;
        vm.orientations = 0;
        vm.trainings = 0;
        vm.documents = 0;

        vm.onCancel = onCancel;
        vm.onConfirm = onConfirm;
        vm.onClickHoursApps = onClickHoursApps;

        if(angular.isDefined(results.discardedHours)){
            vm.hoursLost = results.discardedHours.length;
        }

        if(angular.isDefined(results.discardedApplications)){
            vm.applicationsLost = results.discardedApplications.length;
        }

        if(angular.isDefined(results.updatedApplications)){
            vm.applicationsUpdated = results.updatedApplications.length;
        }

        if(angular.isDefined(results.totalApplications)){
            vm.applications = results.totalApplications.length;
        }

        if(angular.isDefined(results.totalHours)){
            vm.hours = results.totalHours.length;
        }

        if(angular.isDefined(results.mergedData)){
            vm.applications = results.mergedData.applications.length;
            vm.awards = results.mergedData.awards.length;
            vm.backgroundChecks = results.mergedData.backgroundChecks.length;
            vm.hours = results.mergedData.hours.length;
            vm.notes = results.mergedData.notes.length;
            vm.orientations = results.mergedData.orientations.length;
            vm.trainings = results.mergedData.trainings.length;
            vm.documents = results.mergedData.documents.length;
        }

        activate();

        function activate() {
        }

        function onClickHoursApps (type,dataSet) {
            var res;
            switch(dataSet){
                case "appLost":
                    res = results.discardedApplications;
                    break;
                case "hoursLost":
                    res = results.discardedHours;
                    break;
                case "totalApps":
                    res = results.totalApplications;
                    break;
                case "totalHours":
                    res = results.totalHours;
                    break;
                case "appUpdated":
                    res = results.updatedApplications;
                    break;
                default:
                    $modalInstance.close();
                    break;
            }

            if(type === "hours") {
                $modal.open({
                    templateUrl: 'app/components/administrator/mergeAccounts/hoursAppsDetails.view.html',
                    controller: 'HoursAppsDetailsController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    keyboard: false,
                    resolve: {
                        title: function () {
                            return "Hours Details";
                        },
                        data: function () {
                            return res;
                        }
                    }
                }).result.then(function () {
                });
            }

            else if(type === "apps"){
                $modal.open({
                    templateUrl: 'app/components/administrator/mergeAccounts/hoursAppsDetails.view.html',
                    controller: 'HoursAppsDetailsController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    keyboard: false,
                    resolve: {
                        title: function () {
                            return "Applications Details";
                        },
                        data: function () {
                            return res;
                        }
                    }
                }).result.then(function () {
                });
            }
        }

        function onConfirm() {
            $modalInstance.close();
        }

        function onCancel() {
            $modalInstance.dismiss('cancel');
        }
    }
})();