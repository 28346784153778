(function () {
    'use strict';

    angular
        .module('administrator.hours', [
            'ui.router',
            'angularMoment',
            
            'app.auth'
        ]);
})();