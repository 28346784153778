(function () {
    'use strict';

    angular
        .module('app.vmisHoursEntry')
        .directive('vmisHoursEntry', hoursEntry);

    hoursEntry.$inject = [];

    function hoursEntry() {
        return {
            restrict: 'E',
            templateUrl: 'app/common/directives/vmisHoursEntry/vmisHoursEntry.view.html',
            controller: 'HoursEntryController',
            controllerAs: 'vmWidget',
            bindToController: true,
            scope: {
                date: '=?',
                setHourCallback: '=?' // function callback;
            }
        };
    }
})();