(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('AddNonUserController', AddNonUserController);

    AddNonUserController.$inject = ['$modalInstance', 'authentication'];

    function AddNonUserController($modalInstance, authentication) {
        var vm = this;

        vm.isLoading = false;

        vm.onSubmit = onSubmit;

        function onSubmit() {
            vm.isLoading = true;

            vm.formError = "";

            var params = {
                email: vm.email
            };

            authentication.getVolunteersAdmin(params)
                .then(function (response) {
                    if (response.users.length === 0)
                        $modalInstance.close({
                            action: "success",
                            email: vm.email
                        });
                    else
                        vm.formError = "The entered email address already has an account.";

                    vm.isLoading = false;
                })
                .catch(function (error) {
                    vm.formError = error;
                    vm.isLoading = false;
                });
        }
    }
})();
