(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('ApproveConfirmationController', ApproveConfirmationController);

    ApproveConfirmationController.$inject = ['$modalInstance', 'firstName', 'lastName', 'positionTitle'];

    function ApproveConfirmationController($modalInstance, firstName, lastName, positionTitle) {
        var vm = this;

        vm.firstName = firstName;
        vm.lastName = lastName;
        vm.positionTitle = positionTitle;

        vm.onApprove = onApprove;
        vm.onCancel = onCancel;

        activate();

        function activate() {
        }

        function onApprove() {
            $modalInstance.close();
        }

        function onCancel() {
            $modalInstance.dismiss();
        }
    }
})();