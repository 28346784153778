(function () {
    'use strict';

    angular
        .module('app.vmis')
        .config(routes);

    routes.$inject = ['$stateProvider', '$urlRouterProvider'];

    function routes($stateProvider, $urlRouterProvider) {
        $urlRouterProvider
            .when('/register', registerRedirect);

        $stateProvider
            .state('vmis.register', {
                url: '/register?{return-to}',
                template: '<h1 class="center-horizontal">Redirecting to Account Management registration...</h1>'
            })
            .state('vmis.migrateUser', {
                url: '/migrate-user',
                templateUrl: 'app/components/register/migrateUser.view.html',
                controller: 'MigrateUserController',
                controllerAs: 'vm',
                title: 'Transition User Account'
            })
            .state('vmis.claimUser', {
                url: '/claim-user?{key}',
                templateUrl: 'app/components/register/claimUser.view.html',
                controller: 'ClaimUserController',
                controllerAs: 'vm',
                title: 'Claim User'
            });

        registerRedirect.$inject = ['$window', 'config'];

        function registerRedirect($window, config) {
            return config.get().then(function (configData) {
                // Removing return URL functionality because Account Management needs updates to use it properly.
                // User is required to verify and fill out profile there, so holding on to the link is difficult.
                // Having it visible on transfer gives false impression user will be sent back to their application.
                /*
                var returnUrl = $window.location.search !== "" ?
                    $window.location.search : $window.location.protocol + "//" + $window.location.host;
                $window.location.href = configData.accountSettingsUrl + 'register?service=' +
                    encodeURIComponent(returnUrl);
                */
                $window.location.href = configData.accountSettingsUrl + 'register';
            });
        }
    }
})();
