(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .controller('NotesController', NotesController);

    NotesController.$inject = ['$scope', '$modal', '$stateParams', 'authentication', 'adminInfo'];

    function NotesController($scope, $modal, $stateParams, authentication, adminInfo) {
        var vm = this;

        vm.isLoading = true;
        vm.sortColumn = 'date';
        vm.sortReverse = true;

        var index = 0;
        var limit = 100;

        vm.notes = [];
        vm.masterUserId = parseInt($stateParams.id) || null;
        // Admin master user ID is used to check whether user can edit individual notes that are theirs
        vm.adminMasterUserId = parseInt(adminInfo.adminMasterUserId) || null;
        // Use existence of adminMasterUserId (not undefined) as the official check
        // of whether admin is editing another user
        vm.adminEditing = angular.isNumber(vm.adminMasterUserId);
        vm.isOnlyAVCCAssistant = (adminInfo.adminRoles.length === 1) &&
            (adminInfo.adminRoles[0].name === "AVC Coordinator Assistant");

        vm.onClickDetails = onClickDetails;
        vm.onClickAddNote = onClickAddNote;
        vm.canUserEditNote = canUserEditNote;

        activate();

        function activate() {
            // If AVCC Assistant, do not show or set up this section
            if (vm.isOnlyAVCCAssistant)
                return;

            if (vm.adminEditing)
                getNotes(vm.masterUserId);
            else
                getNotes();
        }

        function getNotes(id) {
            vm.isLoading = true;

            var params = {
                startingRowIndex: index,
                numberOfRows: limit
            };

            if (angular.isNumber(id)) {
                params.masterUserId = id;
            }

            return authentication.getVolunteerNotes(params)
                .then(function (response) {
                    vm.notes = response.volunteerNotes.map(function (note) {
                        return {
                            id: note.volunteerNoteId,
                            note: note.volunteerNotes,
                            date: new Date(note.modifiedDate),
                            author: note.lastName + ', ' + note.firstName,
                            createdByUserId: note.createdByUserId,
                            adminMasterUserId: vm.adminMasterUserId
                        };
                    });

                    vm.isLoading = false;
                })
                .catch(function (error) {
                    error = error.error ? error.error : error;
                    $scope.$emit('records:alert', error);
                    vm.isLoading = false;
                });
        }

        function openDetailsModal(note, id) {
            var options = {
                templateUrl: 'app/components/volunteer/records/notes/details/view.html',
                controller: 'NoteDetailsController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    note: function () {
                        return note ? angular.copy(note) : {};
                    },
                    id: function () {
                        return id ? angular.copy(id) : null;
                    },
                    // If note already exists, check if it can be edited. If not, making new one.
                    viewOnly: function () {
                        return note ? !canUserEditNote(note) : false;
                    }
                }
            };
            $modal.open(options)
                .result
                .then(function (result) {
                    vm.isLoading = true;

                    var msg = "";
                    if (result) {
                        var verb = "";
                        switch (result.action) {
                            case 'add':
                                verb = "added";
                                break;
                            case 'edit':
                                verb = "updated";
                                break;
                            case 'delete':
                                verb = "deleted";
                                break;
                            default:
                                msg = "Unrecognized action!";
                                break;
                        }

                        if (verb)
                            msg = "Note successfully " + verb + "!";

                    }
                    else
                        msg = "Unrecognized action!";

                    $scope.$emit('records:success', msg);

                    if (vm.adminEditing)
                        getNotes(id);
                    else
                        getNotes();
                })
                .catch(function (error) {
                    // If modal is left by pressing "Escape", error is undefined.
                    if (error) {
                        error = error.error ? error.error : error;
                        $scope.$emit('records:alert', error);
                    }
                });
        }

        function onClickDetails(note) {
            if (vm.adminEditing)
                openDetailsModal(note, vm.masterUserId);
            else
                openDetailsModal(note);
        }

        function onClickAddNote() {
            if (vm.adminEditing)
                openDetailsModal(null, vm.masterUserId);
            else
                openDetailsModal();
        }

        function canUserEditNote(note) {
            return vm.adminEditing;
        }
    }
})();