(function () {
    'use strict';

    angular
        .module('app')
        .controller('ChangeCommunityController', ChangeCommunityController);

    ChangeCommunityController.$inject = ['params'];

    function ChangeCommunityController(params) {
        var vm = this;

        vm.community = params.community;
        vm.name = params.name;
    }
})();