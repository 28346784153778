(function () {
    'use strict';

    angular
        .module('app.vmis')
        .controller('MigrateUserController', MigrateUserController);

    MigrateUserController.$inject = ['$state', '$modal', 'authentication'];

    function MigrateUserController($state, $modal, authentication) {
        var vm = this;

        vm.isLoading = false;
        vm.emailSent = false;
        vm.onSubmit = onSubmit;
        vm.emailStatusMessage = "";

        function onSubmit(username) {
            vm.isLoading = true;

            vm.formError = "";

            authentication.findByUsername(vm.username)
                .then(function (response) {
                    vm.formError = "";
                    if (response.emailCount === 0) {
                        vm.formError = "Cannot find an account with this username.";
                    }
                    else{
                        return authentication.sendMigrationEmailByUsername(vm.username)
                            .then(function(){
                                vm.emailStatusMessage = "Please check the email(s) associated with your account for login instructions. Redirecting you to home page.";
                                vm.emailSent = true;

                                var options = {
                                    templateUrl: 'app/components/register/beginMigration.view.html',
                                    controller: 'BeginMigrationController',
                                    controllerAs: 'vm',
                                    size:"tiny",
                                    backdrop: 'static',
                                    keyboard: false
                                };
                                $modal.open(options).result
                                    .then(function(){
                                        $state.go('vmis', null, {reload: true});
                                    })
                                    .catch(function(){
                                        $state.go('vmis', null, {reload: true});
                                    });
                            });
                    }

                    vm.isLoading = false;

                    return response;
                })
                .then(function(response){
                })
                .catch(function (error) {
                    vm.formError = error;
                    vm.isLoading = false;
                });
        }
    }
})();
