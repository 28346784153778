(function () {
    'use strict';

    angular
        .module('app')
        .directive('retrievedResults', retrievedResults);

    retrievedResults.$inject = [];

    function retrievedResults() {
        return {
            restrict: 'E',
            templateUrl: 'app/common/directives/retrievedResults/retrievedResults.template.html',
            controller: function() {},
            controllerAs: 'vm',
            bindToController: true,
            scope: {
                isLoading: '<',
                loadStatus: '<'
            }
        };
    }
})();