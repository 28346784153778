(function () {
    'use strict';

    angular
        .module('app.vmis')
        .config(routes);

    routes.$inject = ['$stateProvider', '$urlRouterProvider'];

    function routes($stateProvider, $urlRouterProvider) {
        $urlRouterProvider
            .when('/log-in', logInRedirect);

        $stateProvider
            .state('vmis.logIn', {
                url: '/log-in',
                template: '<h1 class="center-horizontal">Redirecting to SSO Log-In...</h1>',
                title: 'Login'
            });

        logInRedirect.$inject = ['$window', 'config'];

        function logInRedirect($window, config) {
            return config.get().then(function (configData) {
                $window.location.href = configData.casServer + 'login?service=' +
                    encodeURIComponent($window.location.protocol + "//" + $window.location.host);
            });
        }
    }
})();
