(function () {
    'use strict';

    angular
        .module('app')
        .directive('icon', Icon);

    Icon.$inject = [];

    function Icon() {
        return {
            restrict: 'E',
            templateUrl: 'app/common/directives/icon/icon.template.html',
            controller: function () {},
            controllerAs: 'icon',
            bindToController: true,
            scope: {
                name: '@',
                spin: '=?'
            }
        };
    }
})();