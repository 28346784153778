(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .controller('AvccLocatorController', AvccLocatorController);

    AvccLocatorController.$inject = ['_', '$q', '$scope', 'authentication'];

    function AvccLocatorController(_, $q, $scope, authentication) {
        var vm = this;

        vm.isLoading = true;

        vm.communities = [];
        vm.communityId = null;

        vm.onChangeCommunityId = onChangeCommunityId;

        $scope.$on('avccLocatorList:update', onListUpdate);

        activate();

        function activate() {
            $q.all({
                communities: getCommunities(),
                communityId: getDefaultCommunityId()
            }).then(function (responses) {
                vm.communities = responses.communities;
                if (responses.communityId) {
                    vm.communityId = responses.communityId;
                    onChangeCommunityId(vm.communityId);
                }
                else
                    vm.isLoading = false;
            });
        }

        function getDefaultCommunityId() {
            if (authentication.isLoggedIn()) {
                return authentication.getUserProfile()
                    .then(function (response) {
                        return response.aosUser.communityAffiliationId;
                    });
            } else {
                return null;
            }
        }

        function getCommunities() {
            return authentication.getCommunities({
                numberOfRows: 1,
                isArchived: false
            }).then(function (response) {
                return authentication.getCommunities({
                    numberOfRows: response.total,
                    isArchived: false
                });
            }).then(function (response) {
                return _.map(response.communityAffiliations, function (community) {
                    return {
                        id: community.communityAffiliationId,
                        name: community.communityAffiliationName
                    };
                });
            });
        }

        function onChangeCommunityId(communityId) {
            vm.isLoading = true;
            $scope.$broadcast('avccLocatorFilters:update', {
                communityId: communityId
            });
        }

        function onListUpdate(event) {
            event.stopPropagation();
            vm.isLoading = false;
        }
    }
})();