(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('EditCoordinatorAssistantController', EditCoordinatorAssistantController);

    EditCoordinatorAssistantController.$inject = ['$modalInstance', '$modal', 'params', 'authentication', 'usersServices'];

    function EditCoordinatorAssistantController($modalInstance, $modal, params, authentication, usersServices) {
        var vm = this;

        vm.isLoading = true;

        vm.avccAssistant = params.avccAssistant || {};
        vm.isNewAVCCAssistant = params.avccAssistant === null;
        vm.canRemove = !vm.isNewAVCCAssistant && params.canRemove;
        vm.canEdit = params.canEdit;

        if (vm.isNewAVCCAssistant)
            vm.title = "Add New AVCC Assistant";
        else
            vm.title = "Edit AVCC Assistant - " + vm.avccAssistant.aosUser.firstName + " " + vm.avccAssistant.aosUser.lastName;

        vm.errorText = "";

        vm.communities = params.activeUserResponsibilities;
        vm.avccAssistantResponsibilities = [];
        var originalResponsibilities = [];

        vm.onClickRemove = onClickRemove;
        vm.onSubmit = onSubmit;

        activate();

        function activate() {
            if (!vm.isNewAVCCAssistant && vm.avccAssistant.aosUser.vmisUser.adminProfile.responsibilities.avccAssistant) {
                // If existing role with responsibilities, fill ui-select
                vm.avccAssistant.aosUser.vmisUser.adminProfile.responsibilities.avccAssistant.forEach(function (resp) {
                    // If AVCC Assistant responsibility is under active user's purview
                    if (isInActiveUserResponsibilities(resp)) {
                        vm.avccAssistantResponsibilities.push(_.find(vm.communities, function (comm) {
                            return comm.communityAffiliationId === resp.communityAffiliationId;
                        }));
                    }
                    // If AVCC Assistant responsibility is outside active user's purview
                    else {
                        resp.readOnly = true;
                        vm.avccAssistantResponsibilities.push(resp);
                    }
                });

                originalResponsibilities = angular.copy(vm.avccAssistantResponsibilities);
            }

            vm.isLoading = false;
        }

        function onClickRemove() {
            vm.isLoading = true;

            var modalInstance = $modal.open({
                templateUrl: 'app/components/administrator/people/actionDirectives/confirmRoleRemove.view.html',
                controller: 'ConfirmRoleRemoveController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    params: function () {
                        return {
                            userInfo: vm.avccAssistant,
                            role: "AVC Coordinator Assistant"
                        };
                    }
                }
            });

            modalInstance.result
                .then(function () {
                    close('remove', vm.avccAssistant.masterUserId, originalResponsibilities);
                    vm.isLoading = false;
                })
                .catch(function (error) {
                    if (angular.isDefined(error))
                        setError(error);
                    vm.isLoading = false;
                });
        }

        function onSubmit() {
            vm.isLoading = true;
            var getProfilePromise = Promise.resolve(null);
            if (vm.isNewAVCCAssistant){
                getProfilePromise = authentication.getMasterUserIdFromEmail({email: vm.avccAssistant.masterUserEmail})
                    .then(function(response){
                        if(response.success === true){
                            clearError();
                            vm.avccAssistant.masterUserId = response.masterUserId;
                            return authentication.getUserProfile({masterUserId: response.masterUserId});
                        }
                        else {
                            setError("The entered email address does not match any existing user");
                            vm.isLoading = false;
                        }
                    });
            }
            else {
                getProfilePromise = authentication.getUserProfile({masterUserId: vm.avccAssistant.masterUserId});
            }

            getProfilePromise
                .then(function(userProfile) {
                    var isUserAVCCAssistant = userProfile.roles.vmisUserRoles.filter(function (role) {
                        return role === "AVC Coordinator Assistant";
                    }).length === 1;

                    // If using "Add New" button, make sure not modifying an AVCC Assistant
                    // that the user could edit in the table.
                    if (vm.isNewAVCCAssistant && isUserAVCCAssistant) {
                        if (!usersServices.isBasicAdminUserDataComplete(userProfile))
                        {
                            setError("This user cannot be added due to incomplete data");
                            vm.isLoading = false;
                            return;
                        }

                        var avccAssistResp = userProfile.aosUser.vmisUser.adminProfile.responsibilities.avccAssistant;

                        // TODO: Use new school methods like in editCoordinator.controller.js
                        var isOverlappingResponsibility = false;
                        for (var i = 0; i < avccAssistResp.length; i++) {
                            if (isOverlappingResponsibility) break;
                            for (var j = 0; j < vm.communities.length; j++) {
                                if (avccAssistResp[i].communityAffiliationId ===
                                    vm.communities[j].communityAffiliationId) {
                                    isOverlappingResponsibility = true;
                                    break;
                                }
                            }
                        }

                        if (isOverlappingResponsibility) {
                            setError("The account associated with the email address is already an AVCC Assistant you can edit. You must find and edit that user's roles in the table.");
                            vm.isLoading = false;
                            return;
                        }
                        // If not new role but user not visible in table
                        else {
                            close('edit', vm.avccAssistant.masterUserId, vm.avccAssistantResponsibilities);
                            return;
                        }
                    }

                    // If new role and not already AVCC Assistant
                    if (vm.isNewAVCCAssistant)
                        close('save', vm.avccAssistant.masterUserId, vm.avccAssistantResponsibilities);
                    // If not new role
                    else
                        close('edit', vm.avccAssistant.masterUserId, vm.avccAssistantResponsibilities);
                })
                .catch(function (error) {
                    setError(error);
                    vm.isLoading = false;
                });

        }

        function isInActiveUserResponsibilities(resp) {
            for (var i = 0; i < vm.communities.length; i++) {
                if (resp.communityAffiliationId === vm.communities[i].communityAffiliationId)
                    return true;
            }

            return false;
        }

        function setError(error) {
            var errorStr = error.error ? error.error : error;
            if (errorStr === 'cancel' || errorStr === 'backdrop click')
                return;
            vm.errorText = errorStr;
        }

        function clearError() {
            vm.errorText = "";
        }

        function close(action, masterUserId, responsibilities) {
            $modalInstance.close({
                action: action,
                masterUserId: masterUserId,
                responsibilities: filterResponsibilities(responsibilities)
            });
        }

        // Ensure only new target user responsibilities
        // that the active user can edit are present
        function filterResponsibilities(resp) {
            return resp.filter(function (comm) {
                return isInActiveUserResponsibilities(comm);
            });
        }
    }
})();