(function () {
    'use strict';

    angular
        .module('app.vmis')
        .controller('DashboardController', DashboardController);

    DashboardController.$inject = ['$q', '$state', '$modal', '$window', '$scope', 'authentication', 'loadStatus', 'config', 'lite'];

    function DashboardController($q, $state, $modal, $window, $scope, authentication, loadStatus, config, lite) {
        var vm = this;

        vm.alerts = [];

        vm.loadStatus = loadStatus.init();
        vm.notifications = [];
        vm.isLoading = true;

        vm.onNotificationClick = onNotificationClick;
        vm.onDeleteNotificationClick = onDeleteNotificationClick;
        vm.onMarkAllReadClick = onMarkAllReadClick;
        vm.onDeleteAllClick = onDeleteAllClick;
        vm.onLoadMore = onLoadMore;
        vm.onLoadAll = onLoadAll;
        vm.onCloseAlert = onCloseAlert;

        vm.isLiteMode = lite.isLiteMode();

        activate();

        function activate() {
            checkBrowserVersion();

            authentication.getUserRoles()
                .then(function (response) {
                    if (response.vmisUserRoles !== null) {
                        for (var i = 0; i < response.vmisUserRoles.length; i++) {
                            switch (response.vmisUserRoles[i]) {
                                case 'AVC Volunteer':
                                    vm.isVolunteer = true;
                                    break;
                                case 'AVC Coordinator Assistant':
                                    vm.isAvccAssistant = true;
                                    break;
                                case 'AVC OPOC':
                                    vm.isOpoc = true;
                                    break;
                                case 'AVC Coordinator':
                                    vm.isCoordinator = true;
                                    break;
                                case 'AVC Program Manager':
                                    vm.isProgramManager = true;
                                    break;
                            }
                        }
                    }
                })
                .catch(function () {
                    $state.go('vmis', null, { reload: true });
                });

            loadStatus.setup(vm.loadStatus, "notifications")
                .then(function () {
                    loadNotifications(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows);
                });
        }

        function loadNotifications(index, num) {
            vm.isLoading = true;
            return authentication.getNotifications(index, num).then(function (response) {
                return getNotificationsSuccess(response, index, num);
            }).catch(function (error) {
                return getNotificationsError(error);
            });
        }

        function onNotificationClick(notification) {
            var data = notification;
            switch (notification.grammar) {
                case 'OPOC_INVITE_SENT':
                    showOPOCInviteDetails(data)
                        .then(function () {
                            return authentication.setNotificationRead([notification.notificationId]);
                        })
                        .then(function () {
                            checkAndSetRead(notification);
                        })
                        .catch(function (error) {
                            // Clicking outside the modal should still set the notification as read
                            if (error === "backdrop click")
                                return authentication.setNotificationRead([notification.notificationId])
                                    .then(function () {
                                        return checkAndSetRead(notification);
                                    })
                                    .catch(function (error) {
                                        $scope.$emit('dashboard:alert', error);
                                    });
                            else
                                $scope.$emit('dashboard:alert', error);
                        });
                    break;
                case 'DAILY_HOURS_SUBMITTED':
                case 'PERIOD_HOURS_SUBMITTED':
                case 'HOURS_STATUS_CHANGED':
                case 'OPPORTUNITY_APPLIED_FOR':
                case 'APPLICATION_STATUS_CHANGED':
                case 'OPOC_INVITE_DELETED':
                case 'OPOC_INVITE_ACCEPTED':
                case 'OPOC_INVITE_UPDATED':
                    showModalDetails(data)
                        .then(function () {
                            return authentication.setNotificationRead([notification.notificationId]);
                        })
                        .then(function () {
                            checkAndSetRead(notification);
                        })
                        .catch(function (error) {
                            // Clicking outside the modal should still set the notification as read
                            if (error === "backdrop click") {
                                return authentication.setNotificationRead([notification.notificationId])
                                    .then(function () {
                                        return checkAndSetRead(notification);
                                    })
                                    .catch(function (error) {
                                        $scope.$emit('dashboard:alert', error);
                                    });
                            }
                            else
                                $scope.$emit('dashboard:alert', error);
                        });
                    break;
                default:
                    showModalDetails(data)
                        .then(function () {
                            return authentication.setNotificationRead([notification.notificationId]);
                        })
                        .then(function () {
                            checkAndSetRead(notification);
                        })
                        .catch(function (error) {
                            // Clicking outside the modal should still set the notification as read
                            if (error === "backdrop click") {
                                return authentication.setNotificationRead([notification.notificationId])
                                    .then(function () {
                                        return checkAndSetRead(notification);
                                    })
                                    .catch(function (error) {
                                        $scope.$emit('dashboard:alert', error);
                                    });
                            }
                            else
                                $scope.$emit('dashboard:alert', error);
                        });
                    break;
            }

            function checkAndSetRead(notification) {
                if (!notification.read) {
                    notification.read = true;
                    updateUnreadNotificationCount("subtract");
                }
            }
        }

        function showModalDetails(data) {
            data.notInvite = true;
            return showDetailsModal('app/components/dashboard/notificationDetails.modal.view.html', data);
        }

        function showOPOCInviteDetails(data) {
            data.size = 'small';
            return showDetailsModal('app/components/dashboard/acceptOPOCInviteDetails.modal.html', data);
        }

        function showDetailsModal(templateUrl, details) {
            var modalSize = 'tiny';
            if (details.size) {
                modalSize = details.size;
            }
            var options = {
                templateUrl: templateUrl,
                controller: 'DetailsModalController',
                controllerAs: 'modal',
                size: modalSize,
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    details: function () {
                        return details;
                    }
                }
            };

            return $modal.open(options).result.then(function () {
            }, function (error) {
                if (error)
                    return $q.reject(error);
            });
        }

        function onDeleteNotificationClick(notification) {
            var isRead = notification.read;
            return authentication.deleteNotifications([notification.notificationId])
                .then(function () {
                    var index = vm.notifications.indexOf(notification);
                    if (index > -1)
                        vm.notifications.splice(index, 1);
                    if (isRead === 0) {
                        updateUnreadNotificationCount("subtract");
                    }

                    // Update loadStatus object so subsequent notifications aren't skipped
                    vm.loadStatus.currentIndex--;

                    if (vm.loadStatus.currentIndex === 0 && vm.loadStatus.numResultsNotLoaded > 0) {
                        loadNotifications(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows);
                    }
                });
        }

        function onMarkAllReadClick() {
            if (vm.notifications.length >= 1) {
                var ids = vm.notifications.map(function (notification) {
                    return notification.notificationId;
                });

                return authentication.setNotificationRead(ids)
                    .then(function () {
                        // Update count before marking read so count is accurate
                        updateUnreadNotificationCount("clear");
                        vm.notifications.forEach(function (notification) {
                            notification.read = true;
                        });
                    });
            }
        }

        function onDeleteAllClick() {
            if (vm.notifications.length >= 1) {
                var options = {
                    templateUrl: 'app/components/dashboard/deleteAllNotifications.modal.view.html',
                    size: 'tiny',
                    backdrop: 'static',
                    keyboard: false
                };

                $modal.open(options)
                    .result
                    .then(function () {
                        var ids = vm.notifications.map(function (notification) {
                            return notification.notificationId;
                        });

                        return authentication.deleteNotifications(ids);
                    })
                    .then(function () {
                        // Update count before wiping list so count is accurate
                        updateUnreadNotificationCount("clear");
                        vm.notifications = [];

                        // Reset loadStatus object and get more notifications
                        return loadStatus.reset(vm.loadStatus)
                            .then(function () {
                                loadNotifications(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows);
                            });
                    });
            }
        }

        function updateUnreadNotificationCount(updateVal) {
            if (updateVal === "subtract") {
                vm.unreadNotifications--;
            }
            else if (updateVal === "clear") {
                // Can't just set to zero, since not all notifications
                // are necessarily loaded when this action is taken.
                // Instead count unread notifications being deleted
                // and subtract that value from total.
                vm.unreadNotifications -= vm.notifications.reduce(function (count, notif) {
                    if (!notif.read)
                        count++;
                    return count;
                }, 0);
            }
            // If not one of those strings, must be integer
            else {
                vm.unreadNotifications = updateVal;
            }
            $scope.notificationObject.updateUnreadNotificationsCount(vm.unreadNotifications);
        }

        function getNotificationsSuccess(response, index, num) {
            if (index === 0) {
                vm.loadStatus.currentIndex = 0;
                vm.notifications = [];
            }

            // Either set current index to max if all users loaded or increment by rows loaded
            vm.loadStatus.currentIndex = (vm.loadStatus.currentIndex + num > response.count) ? response.count : (vm.loadStatus.currentIndex + num);
            // Track total number of users not loaded for "Load All" button
            vm.loadStatus.numResultsNotLoaded = response.count - vm.loadStatus.currentIndex;
            // Set text for "Load More" button
            vm.loadStatus.nextResultLoadNum = (response.count === vm.loadStatus.currentIndex) ? 0 :
                (vm.loadStatus.numResultsNotLoaded > num) ? num : vm.loadStatus.numResultsNotLoaded;

            vm.notifications = vm.notifications.concat(response.notifications);

            // Alert top bar to new value
            updateUnreadNotificationCount(response.unread);

            vm.isLoading = false;

            return vm.notifications;
        }

        function getNotificationsError(error) {
            if (angular.isDefined(error)) {
                var errorStr = error.error ? error.error : error;
                $scope.$emit('dashboard:alert', errorStr);
            }
            else
                $scope.$emit('dashboard:alert', "Failed to load list! Please try again.");

            vm.isLoading = false;
        }

        function onLoadMore() {
            loadNotifications(vm.loadStatus.currentIndex, vm.loadStatus.nextResultLoadNum);
        }

        function onLoadAll() {
            loadNotifications(vm.loadStatus.currentIndex, vm.loadStatus.numResultsNotLoaded);
        }

        function checkBrowserVersion() {
            var browserVersions = $window.navigator.userAgent;

            config.get().then(function (configData) {
                var edgeIndex = browserVersions.indexOf(configData.minBrowserCompatibility.edge.searchStr);
                if (edgeIndex > -1) {
                    var edgeVersion = parseFloat(
                        browserVersions.substr(edgeIndex + configData.minBrowserCompatibility.edge.searchStr.length));

                    if (edgeVersion < configData.minBrowserCompatibility.edge.version)
                        displayAlert('alert', configData.minBrowserCompatibility.edge.warning);
                }
                var firefoxIndex = browserVersions.indexOf(configData.minBrowserCompatibility.firefox.searchStr);
                if (firefoxIndex > -1) {
                    var firefoxVersion = parseFloat(
                        browserVersions.substr(firefoxIndex + configData.minBrowserCompatibility.firefox.searchStr.length));

                    if (firefoxVersion < configData.minBrowserCompatibility.firefox.version)
                        displayAlert('alert', configData.minBrowserCompatibility.firefox.warning);
                }
            });
        }

        function onCloseAlert(index) {
            vm.alerts.splice(index, 1);
        }

        function displayAlert(type, message) {
            vm.alerts.push({
                type: type,
                message: message
            });
        }

        function displayError(error) {
            if (error === 'cancel' || error === 'backdrop click')
                return;
            if (error)
                displayAlert('alert', error);
        }
    }
})();