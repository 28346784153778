(function () {
    'use strict';

    angular
        .module('vmis.administrator')
        .controller('PositionsController', PositionsController);

    PositionsController.$inject = ['_', '$modal', '$q', '$scope', '$timeout', 'authentication', 'utilsServices', 'loadStatus'];

    function PositionsController(_, $modal, $q, $scope, $timeout, authentication, utilsServices, loadStatus) {
        var vm = this;

        vm.isLoading = true;
        vm.sortReverse = true;
        vm.sortColumn = 'postedDate';
        vm.loadStatus = loadStatus.init();

        vm.positions = [];
        vm.responsibilities = [];
        vm.title = '';

        vm.onClickSearch = onClickSearch;
        vm.onPositionClick = onPositionClick;
        vm.onStatusChange = onStatusChange;
        vm.onLoadMore = onLoadMore;
        vm.onLoadAll = onLoadAll;

        vm.disableOrgDropDown = disableOrgDropDown;

        vm.respOrgs = [];
        vm.organizations = [];
        vm.communities = [];
        vm.filterName = undefined;
        vm.selectedOrganization = {};
        vm.selectedCommunity = {};
        vm.avccCommunities = [];
        vm.statuses = [];

        vm.onCommunityChange = onCommunityChange;
        vm.onOrganizationChange = onOrganizationChange;

        activate();

        function activate() {
            loadStatus.setup(vm.loadStatus, "positions")
                .then(function () {
                    authentication.getUiData(['volunteerpositionstatus']).then(function (response) {
                        vm.statuses = response.volunteerpositionstatus;
                        vm.statuses.unshift("All");
                        vm.status = vm.statuses[0];
                    });
                    getCommunities();
                });
        }

        function onPositionClick(positionId) {
            $modal.open({
                templateUrl: 'app/components/administrator/positions/positionDetails.view.html',
                controller: 'PositionDetailsController',
                controllerAs: 'vm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    isClonedPosition: function () {
                        return false;
                    },
                    positionId: function () {
                        return positionId;
                    },
                    title: function () {
                        return positionId ? 'Position Details' : 'Add New Position';
                    }
                }
            }).result.then(function (result) {
                if (result.clonePosition === true) {
                    setTimeout(
                        function () {
                            var cloneModal = $modal.open({
                                templateUrl: 'app/components/administrator/positions/positionDetails.view.html',
                                controller: 'PositionDetailsController',
                                controllerAs: 'vm',
                                backdrop: 'static',
                                keyboard: false,
                                resolve: {
                                    isClonedPosition: function () {
                                        return true;
                                    },
                                    positionId: function () {
                                        return positionId;
                                    },
                                    title: function () {
                                        return 'Update Fields for Cloned Position';
                                    }
                                }
                            });

                            cloneModal.result.then(function () {
                                getPositions(0, 200, vm.status);
                            }, function () {
                                getPositions(0, 200, vm.status);
                            });
                        },
                        250
                    );
                }
                else {
                    getPositions(0, 200, vm.status);
                }
            });
            // modalInstance.result
            //To Do: Add in error checking
        }

        function onStatusChange() {
            vm.loadStatus.currentIndex = 0;
            getPositions(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows);
        }

        function onCommunityChange() {
            var promise = vm.selectedCommunity.organizations === null ? fillCommOrgs() : $q.when();
            return promise.then(function () {
                vm.loadStatus.currentIndex = 0;
                vm.selectedOrganization = vm.selectedCommunity.organizations[0];
                return getPositions(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows);
            });

            function fillCommOrgs() {
                return authentication.getOrganizations({
                    communityAffiliationId: vm.selectedCommunity.id,
                    getAll: true
                }).then(function (response) {
                    var organizations = _.map(response.organizations, function (organization) {
                        return {
                            id: organization.volunteerOrganizationId,
                            name: organization.organizationName
                        };
                    });
                    if (_.isArray(organizations) && organizations.length !== 1) {
                        organizations.unshift({
                            name: "All"
                        });
                    }
                    vm.selectedCommunity.organizations = organizations;
                }).catch(function (error) {
                    $scope.$emit('people:alert', error);
                    vm.isLoading = false;
                });
            }
        }

        function onOrganizationChange() {
            vm.isLoading = true;
            vm.loadStatus.currentIndex = 0;

            if (!angular.isDefined(vm.selectedOrganization.id))
                vm.sortColumn = 'organization.name';
            else
                vm.sortColumn = 'title';

            vm.sortReverse = false;

            return getPositions(vm.loadStatus.currentIndex, vm.loadStatus.defaultNumRows);
        }

        function onClickSearch() {
            return getPositions(0, vm.loadStatus.defaultNumRows);
        }

        function getPositions(index, num) {
            vm.isLoading = true;
            return authentication.getOpportunitiesAdmin({
                startingRowIndex: index,
                numberOfRows: num,
                volunteerPositionStatus: (vm.status !== "All") ? vm.status : undefined,
                communityAffiliationId: vm.selectedCommunity.id || undefined,
                volunteerOrganizationId: vm.selectedOrganization.id || undefined,
                title: vm.filterName ? vm.filterName : undefined
            }).then(function (response) {
                getPositionsSuccess(response, index, num);
            }).catch(function (err) {
                getPositionsError(err);
            }).finally(function () {
                $timeout(function () {
                    vm.isLoading = false;
                }, 100);
            });
        }

        function getPositionsSuccess(response, index, num) {
            if (index === 0) {
                vm.loadStatus.currentIndex = 0;
                vm.positions = [];
            }

            // Either set current index to max if all users loaded or increment by rows loaded
            vm.loadStatus.currentIndex = (vm.loadStatus.currentIndex + num > response.count) ? response.count : (vm.loadStatus.currentIndex + num);
            // Track total number of users not loaded for "Load All" button
            vm.loadStatus.numResultsNotLoaded = response.count - vm.loadStatus.currentIndex;
            // Set text for "Load More" button
            vm.loadStatus.nextResultLoadNum = (response.count === vm.loadStatus.currentIndex) ? 0 :
                (vm.loadStatus.numResultsNotLoaded > num) ? num : vm.loadStatus.numResultsNotLoaded;

            var position = _.map(response.opportunities, function (position) {
                return {
                    id: position.volunteerOrganizationOpportunityId,
                    title: position.title,
                    organization: {
                        id: position.volunteerOrganizationId,
                        name: position.orgActive ? position.volunteerOrganization : position.volunteerOrganization + " (inactive)"
                    },
                    postedDate: new Date(position.postedDate),
                    closedDate: position.closedDate ? new Date(position.closedDate) : null,
                    status: position.volunteerPositionStatus,
                    totalApplicants: position.applicationCount
                };
            });



            vm.positions = vm.positions.concat(position);
            vm.isLoading = false;
        }

        function getPositionsError(error) {
            if (angular.isDefined(error)) {
                var errorStr = error.error ? error.error : error;
                $scope.$emit('hours:alert', errorStr);
            }
            else
                $scope.$emit('hours:alert', "Failed to load list! Please try again.");

            vm.isLoading = false;
        }

        function onLoadMore() {
            getPositions(vm.loadStatus.currentIndex, vm.loadStatus.nextResultLoadNum);
        }

        function onLoadAll() {
            getPositions(vm.loadStatus.currentIndex, vm.loadStatus.numResultsNotLoaded);
        }

        function getCommunities() {
            return authentication.getUserProfile().then(function (response) {
                return utilsServices.combineCommunityResponsibilities(response, ['AVC OPOC', 'AVC Coordinator']);
            }).then(function (communities) {
                // Use lack of ID as cue for this entry
                if (_.isArray(communities) && communities.length !== 1) {
                    vm.communities.unshift({
                        name: "All",
                        organizations: []
                    });
                }
                _.forEach(communities, function (community) {
                    if (_.isArray(community.organizations) && community.organizations.length !== 1) {
                        community.organizations.unshift({
                            name: 'All'
                        });
                    }
                });
                vm.communities = communities;
                vm.selectedCommunity = vm.communities[0];
                return onCommunityChange();
            });
        }

        function disableOrgDropDown() {
            return vm.isLoading || !angular.isDefined(vm.selectedCommunity.id);
        }
    }
})();