(function () {
    'use strict';

    angular
        .module('vmis.volunteer')
        .config(routes);

    routes.$inject = ['$stateProvider'];

    function routes($stateProvider) {
        $stateProvider
            .state('vmis.volunteer.records', {
                url: '/records',
                title: 'Records',
                views: {
                    "@vmis.volunteer": {
                        templateUrl: 'app/components/volunteer/records/records.view.html',
                        controller: 'RecordsController',
                        controllerAs: 'vm'
                    },
                    "awards@vmis.volunteer.records": {
                        templateUrl: 'app/components/volunteer/records/awards/view.html',
                        controller: 'AwardsController',
                        controllerAs: 'vm'
                    },
                    "trainings@vmis.volunteer.records": {
                        templateUrl: 'app/components/volunteer/records/trainings/view.html',
                        controller: 'TrainingsController',
                        controllerAs: 'vm'
                    },
                    "orientations@vmis.volunteer.records": {
                        templateUrl: 'app/components/volunteer/records/orientations/view.html',
                        controller: 'OrientationsController',
                        controllerAs: 'vm'
                    },
                    "backgroundChecks@vmis.volunteer.records": {
                        templateUrl: 'app/components/volunteer/records/backgroundChecks/view.html',
                        controller: 'BackgroundChecksController',
                        controllerAs: 'vm'
                    },
                    "documents@vmis.volunteer.records": {
                        templateUrl: 'app/components/volunteer/records/documents/view.html',
                        controller: 'DocumentsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    adminInfo: function () {
                        return {};
                    }
                }
            })
            .state('vmis.volunteer.recordsEdit', {
                url: '/records/{id}',
                title: 'Records',
                views: {
                    "@vmis.volunteer": {
                        templateUrl: 'app/components/volunteer/records/records.view.html',
                        controller: 'RecordsController',
                        controllerAs: 'vm'
                    },
                    "awards@vmis.volunteer.recordsEdit": {
                        templateUrl: 'app/components/volunteer/records/awards/view.html',
                        controller: 'AwardsController',
                        controllerAs: 'vm'
                    },
                    "trainings@vmis.volunteer.recordsEdit": {
                        templateUrl: 'app/components/volunteer/records/trainings/view.html',
                        controller: 'TrainingsController',
                        controllerAs: 'vm'
                    },
                    "orientations@vmis.volunteer.recordsEdit": {
                        templateUrl: 'app/components/volunteer/records/orientations/view.html',
                        controller: 'OrientationsController',
                        controllerAs: 'vm'
                    },
                    "notes@vmis.volunteer.recordsEdit": {
                        templateUrl: 'app/components/volunteer/records/notes/view.html',
                        controller: 'NotesController',
                        controllerAs: 'vm'
                    },
                    "backgroundChecks@vmis.volunteer.recordsEdit": {
                        templateUrl: 'app/components/volunteer/records/backgroundChecks/view.html',
                        controller: 'BackgroundChecksController',
                        controllerAs: 'vm'
                    },
                    "documents@vmis.volunteer.recordsEdit": {
                        templateUrl: 'app/components/volunteer/records/documents/view.html',
                        controller: 'DocumentsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    adminInfo: ['$stateParams', 'authentication', function ($stateParams, authentication) {
                        // If URL has an ID in it, admin editing
                        var masterUserId = parseInt($stateParams.id) || null;
                        if (angular.isNumber(masterUserId)) {
                            // ...Unless editing own profile
                            return authentication.getUserProfile()
                                .then(function (response) {
                                    // Return current user's info for some sections of this page
                                    if (masterUserId !== response.masterUserId)
                                        return {
                                            adminMasterUserId: response.masterUserId,
                                            adminFullName: response.aosUser.firstName + " " + response.aosUser.lastName,
                                            adminEmail: response.masterUserEmail,
                                            adminRoles: response.aosUser.vmisRoles
                                        };
                                    else
                                        return {};
                                })
                                .catch(function () {
                                    return {};
                                });
                        }
                        // Otherwise not admin editing
                        else
                            return {};
                    }]
                }
            });
    }
})();