/**
 * Created by scuzzort3 on 6/7/16.
 */
(function () {
    'use strict';

    angular
        .module('app.vmis')
        .controller('VolunteerDashboardController', VolunteerDashboardController);

    VolunteerDashboardController.$inject = ['lite', 'usersServices', 'moment', 'isAdmin'];

    function VolunteerDashboardController(lite, usersServices, moment, isAdmin) {
        var vm = this;

        vm.isLiteMode = lite.isLiteMode();

        vm.date = new Date();
        vm.startDate = moment().startOf('week').toDate();
        vm.endDate = moment().endOf('week').toDate();
        vm.isAdmin = isAdmin;

        vm.onHoursEntered = onHoursEntered;
        vm.onPreviousWeek = onPreviousWeek;
        vm.onNextWeek = onNextWeek;

        vm.hoursChart = {
            options: {
                tooltips: {
                    displayColors: false,
                    custom: function (tooltip) {
                        if (!tooltip) {
                            return;
                        }

                        if (tooltip.body && vm.hoursReport.certifiedHours === 0 && vm.hoursReport.submittedHours === 0 && vm.hoursReport.rejectedHours === 0) {
                            tooltip.body[0].lines[0] = 'Hours: 0';
                            tooltip.width = 58;
                        }
                    }
                }
            },
            data: [],
            labels: ['Certified', 'Submitted', 'Rejected'],
            colors: ['#3c763d', '#337ab7', '#a94442', '#dcdcdc']
        };

        activate();

        function activate() {
            updateTotalHoursForVolunteer();
        }

        function updateTotalHoursForVolunteer() {
            usersServices.getTotalHoursForUserForMonth(vm.date)
                .then(function (hoursReport) {
                    hoursReport.submittedAndCertified = hoursReport.submittedHours + hoursReport.certifiedHours;
                    vm.hoursReport = hoursReport;

                    if (hoursReport.submittedHours > 0 || hoursReport.certifiedHours > 0 || hoursReport.rejectedHours > 0) {
                        vm.hoursChart.data = [
                            hoursReport.certifiedHours,
                            hoursReport.submittedHours,
                            hoursReport.rejectedHours
                        ];
                    } else {
                        vm.hoursChart.data = [0, 0, 0, 1];
                    }
                });
        }

        function onHoursEntered() {
            updateTotalHoursForVolunteer();
        }

        function onPreviousWeek() {
            vm.date = moment(vm.date).subtract(1, 'weeks').toDate();
            updateDateRange();
        }

        function onNextWeek() {
            vm.date = moment(vm.date).add(1, 'weeks').toDate();
            updateDateRange();
        }

        function updateDateRange() {
            vm.startDate = moment(vm.date).startOf('week').toDate();
            vm.endDate = moment(vm.date).endOf('week').toDate();
            updateTotalHoursForVolunteer();
        }
    }
})();