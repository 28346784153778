(function () {
    'use strict';

    angular
        .module('app', [
            'ui.router', // @uirouter/angularjs, formerly angular-ui-router
            'ui.select', // ui-select
            'app.vmis',
            'mm.foundation', // angular-foundation-6, foundation-sites
            'templates',
            'ngCookies', // angular-cookies
            'ngSanitize', // angular-sanitize
            'ngIdle' // ng-idle
        ]);

    angular
        .module('app')
        .run(['$rootScope', '$state', '$location', '$window', '$interval', '$cookies', '$modalStack', 'Idle', 'authentication',
            function ($rootScope, $state, $location, $window, $interval, $cookies, $modalStack, Idle, authentication) {
                $rootScope.$on('$viewContentLoaded', function () {
                    $(document).foundation();
                });

                $rootScope.$on('$stateChangeStart', function (event, toState, toParams) {
                    // Dismiss all modals
                    $modalStack.dismissAll('', [1]);

                    // If user is logged in
                    if (authentication.isLoggedIn()) {
                        // Restart watching for idleness
                        if (Idle.idling()) {
                            Idle.watch();
                            $cookies.remove('logged-out-reason');
                        }

                        // TODO: Rework routing and remove.
                        if (toState.name === 'vmis.inviteAccepted') return;

                        authentication.getUserProfile().then(function (response) {
                            if (angular.isDefined(response.aosUser.communityAffiliationId)) {
                                authentication.getCommunityInfo({ communityAffiliationId: response.aosUser.communityAffiliationId }).then(function (comm) {
                                    // If not, force them to the profile page
                                    if ((!authentication.isAosProfileValid(response.aosUser)) || comm.communityAffiliations.isArchived || !response.aosUser.vmisUser ||
                                        (response.aosUser.vmisUser && !authentication.isVmisProfileValid(response.aosUser.vmisUser.volunteerProfile)) ||
                                        (response.aosUser.vmisUser && response.aosUser.vmisUser.adminProfile &&
                                            !authentication.isAdminProfileValid(response.aosUser.vmisUser.adminProfile))) {
                                        $state.go('vmis.profile');
                                    }
                                    else if (toState.redirectTo) {
                                        event.preventDefault();
                                        $state.go(toState.redirectTo, toParams);
                                    }
                                }).catch(function (error) {
                                    console.log(error);
                                });
                            }
                            else {
                                // If not, force them to the profile page
                                if (!authentication.isAosProfileValid(response.aosUser) ||
                                    (response.aosUser.vmisUser && !authentication.isVmisProfileValid(response.aosUser.vmisUser.volunteerProfile)) ||
                                    (response.aosUser.vmisUser.adminProfile && !authentication.isAdminProfileValid(response.aosUser.vmisUser.adminProfile))) {
                                    $state.go('vmis.profile');
                                }
                                else if (toState.redirectTo) {
                                    event.preventDefault();
                                    $state.go(toState.redirectTo, toParams);
                                }
                            }
                        }).catch(function (error) {
                            console.log(error);
                        });

                    }
                });
                $rootScope.$on('$stateChangeSuccess', function(event, currentRoute, previousRoute) {
                    // Change page title based on route info
                    var pageTitle = currentRoute.title;
                    if (pageTitle) {
                        $window.document.title = "VMIS - " + pageTitle;
                    }
                });
            }
        ])
        // Used for ui-select, from: http://angular-ui.github.io/ui-select/demo-basic.html
        .filter('propsFilter', function () {
            return function (items, props) {
                var out = [];

                if (angular.isArray(items)) {
                    var keys = Object.keys(props);

                    items.forEach(function (item) {
                        var itemMatches = false;

                        for (var i = 0; i < keys.length; i++) {
                            var prop = keys[i];
                            // This error on People > Volunteer: "TypeError: Cannot read property 'prop' of null"
                            // ...is resolved by checking if objects exist before accessing them.
                            var text = props ? props[prop].toLowerCase() : null;
                            if (item ? (item[prop].toString().toLowerCase().indexOf(text) !== -1) : false) {
                                itemMatches = true;
                                break;
                            }
                        }

                        if (itemMatches) {
                            out.push(item);
                        }
                    });
                } else {
                    // Let the output be the input untouched
                    out = items;
                }

                return out;
            };
        })
        /**
         * Uploaded attachment filter function for ensuring URLs work properly.
         * @returns function that parses strings for good URLs
         */
        .filter('uploadedFileFilter', function () {
            return function (link) {
                var http = "http://";
                var https = "https://";

                // Does not work in Internet Explorer 11
                //if (link.startsWith(http) || link.startsWith(https))
                if (link.indexOf(http) === 0 || link.indexOf(https) === 0) {
                    return link;
                }
                else {
                    return http + link;
                }
            };
        })
        .config(['uiSelectConfig', function (uiSelectConfig) {
            uiSelectConfig.theme = 'bootstrap';
        }])
        .config(['IdleProvider', 'TitleProvider', function (IdleProvider, TitleProvider) {
            IdleProvider.autoResume('notIdle');
            TitleProvider.enabled(false);
        }])

        // Configure Idle service (https://github.com/HackedByChinese/ng-idle) and begin watching for idleness if user is already authenticated.
        .run(['Idle', 'Keepalive', 'authentication', 'config', function (Idle, Keepalive, authentication, config) {
            config.get().then(function (configData) {
                // Seconds to wait until a user is considered idle.
                Idle.setIdle(configData.idleConfig.idle);
                // Seconds to wait after a user is idle to perfom some action (e.g. log out the user).
                Idle.setTimeout(configData.idleConfig.timeout);
                // Interval to send a keep alive signal to the backend.
                Keepalive.setInterval(configData.idleConfig.keepAlive);
            }).then(function () {
                if (authentication.isLoggedIn()) {
                    Idle.watch();
                }
            });
        }])
        .config(['$locationProvider', function ($locationProvider) {
            $locationProvider.html5Mode(true);
        }]);

    angular
        .module('app.auth', [
            'angularMoment', // angular-moment, requires moment
            'mm.foundation' // angular-foundation-6, requires foundation-sites
        ]);

    angular
        .module('templates', []);
})();